import React, { useCallback, useEffect, useState } from "react";
import { FC } from "react";
import { ListGroup } from "react-bootstrap-v5";
import { useDropzone } from 'react-dropzone';
import { toast } from "react-toastify";
import { GenericTooltip } from "./TooltipInfo";

type Props = {
    onFileChange: (base64File: string, file: File) => '';
    allowedFileTypes?: Set<string>,
    sizeLimitBytes?: number,
    selectText?: string,
}
export const FileUploader: FC<Props & React.HTMLProps<HTMLInputElement>> = ({ onFileChange, allowedFileTypes, sizeLimitBytes = 2 * 1024 * 1024, ...rest }) => {
    const [file, setFile] = useState<File>();
    const [rand] = useState<number>(Math.random());

    useEffect(() => {
        if (!file) return;
        if (file.size > sizeLimitBytes) {
            toast('Please upload a file smaller than ' + sizeLimitBytes / 1024 / 1024 + "MB", { type: 'warning' });
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            onFileChange(reader.result?.toString().split(";base64,")[1] as string, file);
        };
        reader.onerror = (error) => {
            //notify TODO
            console.log('Error: ', error);
        };
        // eslint-disable-next-line
    }, [file]);


    return <div>
        <label className="btn btn-sm btn-outline btn-outline-primary" htmlFor={`fileInput_${rand}`}>
            {rest.selectText || "Select File"}
        </label>
        <input
            style={{ display: "none" }}
            id={`fileInput_${rand}`}
            type="file"
            className=""
            {...rest}
            onFocus={rest.onFocus}
            onChange={({ target: { files } }) => {
                if (files) {
                    if (allowedFileTypes && allowedFileTypes.has(files[0].type)) {
                        setFile(files[0]);
                    } else {
                        //notify
                    }
                }
            }} />
        {file && <p className="text-success">You have selected the file {file.name}</p>}
    </div>
}

type BulkFileUploadProps = {
    maxFiles?: number,
    /**
     * The value must be a comma-separated list of unique content type specifiers:
        A file extension starting with the STOP character (U+002E). (e.g. .jpg, .png, .doc).
        A valid MIME type with no extensions.
        audio/* representing sound files.
        video/* representing video files.
        image/* representing image files.
     */
    allowedFileTypes?: Array<string>,
    onUpload: (files: any) => void,
    uploadBtnText?: string,
}

export const BulkFileUploader: FC<BulkFileUploadProps> = ({ maxFiles, allowedFileTypes, onUpload, uploadBtnText = "Upload" }) => {
    const [myFiles, setMyFiles] = useState<Array<any>>([])
    const onDrop = useCallback(acceptedFiles => {
        setMyFiles([...myFiles, ...acceptedFiles])
    }, [myFiles])

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxFiles: maxFiles ?? 10,
        accept: allowedFileTypes ? allowedFileTypes?.join(',') : undefined,
        onDrop: onDrop
    });

    const onReset = () => {
        setMyFiles([]);
    }

    return (
        <section className="row mb-2">
            <p className="text-muted m-2 p-2"> Select max {maxFiles || 10} files of {allowedFileTypes?.join(',') || 'any'} type to upload</p>
            <div {...getRootProps({ className: 'dropzone d-flex align-items-center m-2 p-5 border border-dashed border-primary' })}>
                <input {...getInputProps()} />
                {myFiles?.length === 0 ? <p>Drag 'n' drop files here, or click to select files</p>
                    : <div className="row mb-2">
                        <ListGroup className="mb-1">
                            {myFiles.map((file: any) => (<ListGroup.Item key={file.path}>
                                {file.path} - {file.size} bytes
                            </ListGroup.Item>)
                            )}
                        </ListGroup>
                    </div>}
            </div>

            {myFiles?.length > 0 && <>
                <div className="d-flex flex-grow-1">
                    <button className='btn border m-2 btn-sm' onClick={(ev) => { ev.preventDefault(); onUpload && onUpload(acceptedFiles) }}>
                        <GenericTooltip childEle={<i className="bi bi-cloud-upload text-primary"></i>} message={uploadBtnText} /> {uploadBtnText}
                    </button>
                    <button className="btn border m-2 btn-sm" onClick={() => onReset()}>
                        <GenericTooltip childEle={<i className="bi bi-recycle text-danger"></i>} message='Reset Files' /> Reset
                    </button>
                </div>
            </>}
        </section>
    );
}