import { FC } from "react"
import { InputGroup } from "react-bootstrap-v5"

type Props = {
    formik: any
}

export const SocialStep : FC<Props> = ({formik})=>{
    return <div className="w-100">
    <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
            Social information
        </h2>
    </div>
    <div className="fv-row mb-10 row">
        <div className='col-xl-6'>
            <InputGroup size='sm'>
                <InputGroup.Text>
                    <i className="bi bi-instagram text-danger"></i>
                </InputGroup.Text>
                <input
                    {...formik.getFieldProps('instagramUrl')}
                    placeholder='Instagram handle (Optional)'
                    type='text'
                    autoComplete='off'
                    className='form-control form-control-sm'
                />
            </InputGroup>
        </div>
        <div className='col-xl-6'>
            <InputGroup size='sm'>
                <InputGroup.Text>
                    <i className="bi bi-facebook text-primary"></i>
                </InputGroup.Text>
                <input
                    {...formik.getFieldProps('facebookUrl')}
                    placeholder='Facebook handle (Optional)'
                    type='text'
                    autoComplete='off'
                    className='form-control form-control-sm'
                />
            </InputGroup>
        </div>
    </div>
    <div className="fv-row mb-10 row">
        <div className='col-xl-6'>
            <InputGroup size='sm'>
                <InputGroup.Text>
                    <i className="bi bi-linkedin text-primary"></i>
                </InputGroup.Text>
                <input
                    {...formik.getFieldProps('linkedinUrl')}
                    placeholder='Linkedin handle (Optional)'
                    type='text'
                    autoComplete='off'
                    className='form-control form-control-sm'
                />
            </InputGroup>
        </div>
    </div>
</div>
}