import MaterialTable, { Column } from 'material-table';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import tableIcons from '../common/components/MaterialTableIcons';
import { GenericTooltip } from '../common/components/TooltipInfo';
import { useNotificationContext } from '../common/NotificationContext';
import { INotification } from './Notification';
import { getAll } from './notificationService';
import { DATE_TIME_FORMAT_DD_MMM_YYYY } from '../common/Helper';

const ViewAllNotificationsPage: FC = () => {
    const columns: Column<any>[] = [
        {
            title: 'Message',
            filtering: false,
            field: 'message',
        },
        {
            title: 'Read?',
            field: 'isRead',
            type: 'boolean',
            render: (rowData) => {
                return rowData.isRead ?
                    <><i className={`bi bi-check-circle text-success`}></i> Read</>
                    : <><i className={`bi bi-dot text-info`}></i> Unread</>;
                ;
            }
        },
        {
            title: 'Date',
            field: 'createdAt',
            render: (rowData) => {
                return <GenericTooltip message={moment(rowData.createdAt).fromNow()} childEle={<>{moment(rowData.createdAt).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}</>} />
            }
        },
    ];
    const notificationContext = useNotificationContext();
    const { handleTransition } = notificationContext;
    const [notifications, setNotifications] = useState<Array<INotification>>([]);

    useEffect(() => {
        const fetchData = () => {
            getAll().then(({ data }) => {
                data.sort((a, b) => b.id - a.id)
                setNotifications(data);
            })
        }
        fetchData();
    }, []);

    return <MaterialTable
        title={'All Notifications'}
        icons={tableIcons}
        columns={columns}
        data={notifications}
        actions={[
            (rowData) => {
                return {
                    icon: tableIcons.OpenInNew as any,
                    tooltip: `${rowData.isRead ? 'Disabled, already read' : 'Go'}`,
                    onClick: (event, rowData) => {
                        handleTransition(rowData.id)
                    },
                }
            },
        ]}
        options={{ debounceInterval: 700, sorting: true, filtering: false, pageSize: 10, actionsColumnIndex: -1 }}
    />;
}

export default ViewAllNotificationsPage;