import { FC } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useSharedState } from '../modules/common/SharedStateProvider';

export const GuardedRoute: FC<RouteProps<string> & { permission: string }> = ({ permission, ...props }) => {
    const { user } = useSharedState();
    const location = useLocation();

    if (permission && user?.Permission && !user?.Permission?.includes(permission)) {
        return <Redirect from={location.pathname + location.search} to={'/error/403'} />
    } else {
        return <Route {...props} />
    }
}