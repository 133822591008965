import Select from "react-select";
import clsx from "clsx";
import { InputGroup } from "react-bootstrap-v5";
import { gradingTypeOptions } from "../../../../modules/common/DropdownOptions";

type Props = {
    fieldPath: string,
    formik: any
}

export const GradingSystemSelector: React.FC<Props> = ({ fieldPath, formik }) => {
    return <InputGroup className="mb-3" size="sm">
        <InputGroup.Text className="required">Grading System</InputGroup.Text>
        <Select
            options={gradingTypeOptions}
            className={clsx(
                'form-control form-control-sm p-0',
                {
                    'is-invalid': formik.getFieldMeta(`${fieldPath}.gradingSystemId`).touched && formik.getFieldMeta(`${fieldPath}.gradingSystemId`).error,
                },
                {
                    'is-valid': formik.getFieldMeta(`${fieldPath}.gradingSystemId`).touched && !formik.getFieldMeta(`${fieldPath}.gradingSystemId`).error,
                }
            )}
            onChange={(opt) => {
                formik.setFieldValue(`${fieldPath}.gradingSystemId`, opt?.value);
            }}
            onFocus={() => { formik.setFieldTouched(`${fieldPath}.gradingSystemId`) }}
            value={gradingTypeOptions.find(x => x.value === formik.getFieldMeta(`${fieldPath}.gradingSystemId`).value)}
        />
    </InputGroup>
}