import * as errors from '../../app/modules/errors/redux/ErrorRedux'
import { captureException } from '@sentry/react'

export default function setupAxios(axios: any, { getState, dispatch }: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { accessToken },
      } = getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  );

  //response handler
  axios.interceptors.response.use((response: any) => response, (error: any) => {
    // whatever you want to do with the error
    if (error?.response?.status !== 200) {
      captureException(new Error(error?.response?.status))
    }
    switch (error.response?.status) {
      case 404:
        dispatch(errors.actions.setError({ statusCode: 404, errorMessage: 'Requested resource not found' }));
        break;
      case 400:
        if (error.response.data?.errors) {
          Object.values(error.response.data.errors)
            .join()
            .split(',')
            .forEach((x: any) => dispatch(errors.actions.setError({ statusCode: 400, errorMessage: `${x}` })))
          break;
        }
        const message = error.response.data?.Message || error.response.data?.message || 'One of more validation errors occurred, please recheck the required fields and submit the form again.';
        dispatch(errors.actions.setError({ statusCode: 400, errorMessage: `${message}` }));
        break;
      case 500:
        dispatch(errors.actions.setError({ statusCode: 500, errorMessage: error.response.data?.Message || error.response.data?.message || `Server Error: Error processing your request. Please try again later.` }));
        break;
      case 403:
        dispatch(errors.actions.setError({ statusCode: 403, errorMessage: `Permission Error: You are not authorized for this action.` }));
        break;
      case 401:
        dispatch(errors.actions.setError({ statusCode: 401, errorMessage: `Session Expired, please login again.` }));
        break;
    }
    setTimeout(() => {
      dispatch(errors.actions.resetError());
    }, 1000);
    throw error;
  });
}
