import axios from "axios"
import { IStudentResponseToUniversitySuggestionDto, ISuggestionOptionsDto, IUniversitySuggestionMetaResponseDto } from "../models/UniversitySuggestionDtos";

const API_URL = process.env.REACT_APP_API_URL;
const SUGGESTION_API_URL = `${API_URL}/api/v1/university-suggestion`;

export const getSuggestionsMeta = (profileId: number) => {
    return axios.get<IUniversitySuggestionMetaResponseDto>(`${SUGGESTION_API_URL}/${profileId}`)
}

export const getSuggestionOptions = (profileId: number) => {
    return axios.get<ISuggestionOptionsDto>(`${SUGGESTION_API_URL}/${profileId}/suggestion-options`)
}

export const suggestUniversities = (profileId: number, request: any) => {
    return axios.post(`${SUGGESTION_API_URL}/${profileId}`, request)
}

export const saveResponse = (profileId: number, responses: Array<IStudentResponseToUniversitySuggestionDto>) => {
    return axios.post(`${SUGGESTION_API_URL}/${profileId}/response`, { profileId, responses })
}

export const updateSuggestionPartner = ( suggestionId: number, partnerId: number) => {
    return axios.put(`${SUGGESTION_API_URL}/${suggestionId}/${partnerId}`);
}