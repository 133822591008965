/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import Notice from '../../../modules/common/components/Notice'
import { degreeOptions } from '../../../modules/common/DropdownOptions'
import { DropdownOption, useSharedState } from '../../../modules/common/SharedStateProvider'
import { IAcademicProfile } from '../../student/models'

type Props = {
    academicsData: IAcademicProfile
}

export const FutureStudyOverview: FC<Props> = ({ academicsData }) => {
    const { courses } = useSharedState();
    const NotFoundNotice: FC = () => (<Notice title='No future study details found!' message='Future study Details not found for student account.' />);

    return (
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Future Study Details</h3>
                </div>
            </div>

            <div className='card-body'>
                {academicsData ?
                    <>
                        <div className="row">
                            <div className='col-3 mb-7'>
                                <label className=' text-muted'>Desired Degree</label>
                                <span className='fw-bolder fs-6 text-dark mx-2'>
                                    {degreeOptions.find(x => x.value === academicsData.desiredDegreeType)?.label || "NA"}
                                </span>
                            </div>
                            <div className='col-3 mb-7 '>
                                <label className='fw-bold text-muted'>Desired Course</label>
                                <span className='fs-6 fw-bolder text-dark mx-2'>
                                    {courses.find(x => x.value === academicsData.courseId)?.label || "NA"}
                                </span>
                            </div>
                            <div className='col-3 mb-7'>
                                <label className='fw-bold text-muted'>Desired Field of Study</label>
                                <span className='fw-bolder fs-6 text-dark mx-2'>
                                    {courses.find(x => x.value === academicsData.courseId)?.children?.find(x => x.value === academicsData.fieldOfStudyId)?.label || "NA"}
                                </span>
                            </div>
                            <div className='col-3 mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Specialization</label>
                                <span className='fw-bolder fs-6 text-dark mx-2'>
                                    {academicsData.specializationId ? courses.find(x => x.value === academicsData.courseId)
                                        ?.children?.find(x => x.value === academicsData.fieldOfStudyId)
                                        ?.children?.find((x: DropdownOption) => x.value === academicsData.specializationId)?.label
                                        : "None"}
                                </span>
                            </div>
                        </div>
                    </>
                    :
                    <NotFoundNotice />
                }
            </div>
        </div>
    )
}
