import { FC, useState, useEffect } from "react"
import { InputGroup } from "react-bootstrap-v5"
import { ReadingScore, WritingScore, SpeakingScore, ListeningScore } from "./Score"

type Props = {
    formik: any,
    fieldPath: string,
}

export const TOEFLScore: FC<Props> = ({ formik, fieldPath }) => {
    const props = { formik, fieldPath: `${fieldPath}.toeflScore` }
    const [total, setTotal] = useState(0);
    useEffect(() => {
        const val = formik.values.languageExam?.toeflScore?.reading
            + formik.values.languageExam?.toeflScore?.listening
            + formik.values.languageExam?.toeflScore?.writing
            + formik.values.languageExam?.toeflScore?.speaking;
        if (val) {
            setTotal(val)
        } else {
            setTotal(0);
        }
        //eslint-disable-next-line
    }, [formik.values.languageExam?.toeflScore, setTotal])

    return <>
        <div className="row fv-row mb-10">
            <div className="col-xl-4">
                <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text>Total</InputGroup.Text>
                    <input placeholder="0" disabled={true} className={'form-control form-control-sm'} value={total} />
                </InputGroup>
            </div>
        </div>
        <div className="row fv-row mb-10">
            <div className="col-xl-4">
                <ReadingScore {...props} />
            </div>
            <div className="col-xl-4">
                <ListeningScore {...props} />
            </div>
            <div className="col-xl-4">
                <SpeakingScore {...props} />
            </div>
            <div className="col-xl-4">
                <WritingScore {...props} />
            </div>
        </div>
    </>
}
