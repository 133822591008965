/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { Carousel } from 'react-bootstrap-v5';
import { GenericTooltip } from '../common/components/TooltipInfo';
import { setCookie } from '../common/Helper';
import { IBanner } from './models/IBanner';

export const BannerCarousel: FC<{ banners: Array<IBanner> }> = ({ banners }) => {
    const [isVisible, setVisible] = useState<boolean>(true);

    return <>
        {isVisible && <>
            <div className='d-flex flex-grow-1 d-flex '>
                <p className='text-muted '>Click <a href="#" onClick={() => {
                    setCookie('__imfs_portal_runTour', '1');
                    window.location.href = '/home';
                }}>here</a> to get started with a guiding tour of application</p>
                <GenericTooltip message='Close' childEle={<i className='bi bi-x-square-fill text-danger cursor-pointer' onClick={() => setVisible(false)} />} />
            </div>
            <Carousel className='carousel-custom'>
                {banners.map((b, idx) => <Carousel.Item className='cursor-pointer' key={idx}>
                    <img
                        className="d-block w-100"
                        src={b.bannerUri}
                        alt="First slide"
                        style={{ height: "150px" }}
                    />
                    {
                        b.url &&
                        <Carousel.Caption>
                            <a href={b.url} className='btn btn-light-warning' >Know more</a>
                        </Carousel.Caption>
                    }
                </Carousel.Item>)}
            </Carousel>
        </>}
    </>
} 