import { Switch } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap-v5';
import Select from 'react-select';
import { AsyncDropdown } from '../../../modules/common/components/AsyncDropdown';
import { enumToKeyValue } from '../../../modules/common/EnumHelper';
import { DropdownOption } from '../../../modules/common/SharedStateProvider';
import { ISuggestedUniversityResponseDto, IUniversityBasicDetailsDto, SuggestedUniversityStatus, SuggestionLevel } from '../models/UniversitySuggestionDtos';

type Props = {
    onAdded: (data: ISuggestedUniversityResponseDto) => void,
    options: IUniversityBasicDetailsDto[],
};

export const AddSuggestionPanel: FC<Props> = ({ onAdded, options }) => {
    const [universityId, setUniversityId] = useState<number | null>();
    const [isDirect, setIsDirect] = useState<boolean>(true);
    const [partners, setPartners] = useState<Array<DropdownOption>>();
    const [partnerId, setPartnerId] = useState<number | null>();
    const [suggestionLevel, setLevel] = useState<SuggestionLevel>(SuggestionLevel.Ambitious);

    const getFormattedOption = (x: IUniversityBasicDetailsDto) => {
        const { acceptPerc, rejectPerc } = getAnalyticsPerc(x);
        const label = `${x.universityName} ${x.partnered ? '(Partnered)' : ''} (${acceptPerc}%↑ / ${rejectPerc}%↓)`;
        const option = ({ label, value: x.id })
        return option;
    }
    const suggestions = options
        .sort((a, b) => getAnalyticsPerc(b).acceptPerc - getAnalyticsPerc(a).acceptPerc)
        .sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0))
        .map(x => getFormattedOption(x));

    const handleSaveSuggestion = () => {
        const sugg: ISuggestedUniversityResponseDto = {
            universityId: universityId as number,
            university: options.find(x => x.id === universityId) as IUniversityBasicDetailsDto,
            status: SuggestedUniversityStatus.Suggested,
            comments: [],
            isDirect,
            partnerId,
            suggestionLevel,
        };
        onAdded(sugg);
        setUniversityId(null);
        setPartners([]);
        setPartnerId(null);
        setIsDirect(true);
        setLevel(SuggestionLevel.Ambitious);
    }

    useEffect(() => {
        if (universityId) {
            const partnerOptions = options.find(x => x.id === universityId)?.universityPartners?.map(x => ({ label: x.name, value: x.id }));
            setPartners(partnerOptions || []);
        }
    }, [universityId, options, setPartners]);

    return <div className="row fv-row mb-7">
        <div className="row">
            <div className="col-xl-12">
                <div className="row">
                    <div className="col-xl-5">
                        <label className="required mb-2 form-label fw-bold">University</label>
                        <AsyncDropdown
                            initialCount={400}
                            options={suggestions}
                            onSelected={(val) => {
                                setUniversityId(val)
                            }}
                            selected={universityId}
                        />
                    </div>
                    <div className="col-xl-2">
                        <label className='form-label fw-bold  mb-2'>Level</label>
                        <Select
                            menuPlacement="top"
                            options={enumToKeyValue(SuggestionLevel) as DropdownOption[]}
                            onChange={(opt) => {
                                setLevel(+opt?.value);
                            }}
                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                            value={enumToKeyValue(SuggestionLevel)?.find(x => +x.value === suggestionLevel)} />
                    </div>
                    <div className="col-xl-3">
                        <label className='form-label fw-bold  mb-2'>Partner</label>
                        <Select
                            menuPlacement="top"
                            options={partners}
                            onChange={(opt) => {
                                setPartnerId(opt?.value);
                            }}
                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                            value={partners?.find(x => x.value === partnerId) || null} />
                    </div>
                    <div className="col-xl-3">
                        <div className="py-5">
                            <label className='form-label fw-bold'>Is Direct?:</label>
                            <Switch defaultChecked={isDirect} onChange={(ev) => {
                                setIsDirect(ev.target.checked)
                            }} /> {isDirect ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="col-xl-2 my-2">
                        <Button variant="outline-primary" disabled={!universityId} className='btn btn-outline btn-sm'
                            onClick={() => {
                                handleSaveSuggestion();
                            }}>
                            Add To List
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

function getAnalyticsPerc(x: IUniversityBasicDetailsDto) {
    const { numberOfAccepts, numberOfRejects, numberOfSuggests } = x.universityFieldOfStudyMeta;
    const acceptPerc = numberOfSuggests > 0 ? Math.round(numberOfAccepts * 100 / numberOfSuggests) : 0;
    const rejectPerc = numberOfSuggests > 0 ? Math.round(numberOfRejects * 100 / numberOfSuggests) : 0;
    return { acceptPerc, rejectPerc };
}
