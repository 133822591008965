/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { PageTitle } from "../../../theme/layout/core";
import Notice from "../common/components/Notice";
import { useMessageContext } from "../common/MessageContext";
import { useSharedState } from "../common/SharedStateProvider";
import { ChatMessages } from "./components/ChatMessages";
import { ChatOptions } from "./components/ChatOptions";
import { ConversationTile } from "./components/ConversationTile";
import { StartNewThreadButton } from "./components/StartNewThreadButton";
import { Conversation, ConversationStatus, conversationStatusMap } from "./models/ConversationModel";
import { DATE_TIME_FORMAT_DD_MMM_YYYY } from "../common/Helper";

const MessagesPage: FC<RouteComponentProps<{ conversationId: string }>> = ({ match: { params: { conversationId } } }) => {
    const { messages, markAsRead } = useMessageContext();
    const [selectedConversation, setConversation] = useState<Conversation>();
    const [loaded, setLoaded] = useState<boolean>();
    const { isStudent } = useSharedState();

    useEffect(() => {
        if (messages?.length) {
            setLoaded(true);
        }
    }, [messages])

    useEffect(() => {
        if (conversationId) {
            const conv = messages?.find(x => x.id === +conversationId);
            setConversation(conv);
            //mark as read
            markAsRead(+conversationId);
        } else {
            setConversation(undefined);
        }
        //eslint-disable-next-line
    }, [conversationId, loaded]);

    return (
        <div className='d-flex flex-column flex-lg-row'>
            <PageTitle breadcrumbs={selectedConversation ? [{ title: 'Messages', path: `/messages/`, isActive: true }] : []}>{selectedConversation ? `${selectedConversation.title}` : 'Messages'}</PageTitle>
            <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
                <div className='card card-flush'>
                    <div className='card-header pt-7 row'>
                        {isStudent ? <StartNewThreadButton /> : <h3 className="text-center">All Messages</h3>}
                    </div>
                    <div className='card-body pt-5' id='kt_chat_contacts_body'>
                        <div
                            className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='{default: false, lg: true}'
                            data-kt-scroll-max-height='auto'
                            data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
                            data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
                            data-kt-scroll-offset='0px'
                        >
                            {messages.map(conv => (<ConversationTile key={`conversation${conv.id}`} conversation={conv} />))}
                            {messages.length === 0 && <Notice message="No messages found"></Notice>}
                        </div>
                    </div>
                </div>
            </div>

            {selectedConversation && <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
                <div className='card' id='kt_chat_messenger'>
                    <div className='card-header' id='kt_chat_messenger_header'>
                        <div className='card-title'>
                            <div className='symbol-group symbol-hover'></div>
                            <div className='d-flex justify-content-center flex-column me-3'>
                                <a
                                    className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                                >
                                    {selectedConversation.title}
                                </a>

                                <div className='mb-0 lh-1'>
                                    <span className='badge badge-success'> {conversationStatusMap.find(x => x.value === selectedConversation.status.toString())?.label} </span>
                                    <span className='fs-7 fw-bold text-gray-400 mx-2'>{moment(selectedConversation.lastUpdatedAt).format(DATE_TIME_FORMAT_DD_MMM_YYYY)}</span>
                                </div>
                            </div>
                        </div>

                        <div className='card-toolbar'>
                            <div className='me-n3'>
                                {selectedConversation?.status !== ConversationStatus.Closed &&
                                    <><button
                                        className='btn btn-sm btn-icon btn-active-light-primary'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                    >
                                        <i className='bi bi-three-dots fs-2'></i>
                                    </button>
                                        <ChatOptions conversationId={selectedConversation.id} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <ChatMessages conversationId={selectedConversation.id} />
                </div>
            </div>}
        </div>
    )
}


export default withRouter(MessagesPage);