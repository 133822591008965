export default class Permissions {
    static Can_Create_Student = "can-create-student";
    static Can_Update_Student = "can-update-student";
    static Can_Delete_Student = "can-delete-student";
    static Can_List_Students = "can-list-students";
    static Can_List_Users = "can-list-users";
    static Can_Create_User = "can-create-user";
    static Can_Update_User = "can-update-user";
    static Can_Create_Term = "can-create-term";
    static Can_Update_Term = "can-update-term";
    static Can_Update_Country = "can-update-country";
    static Can_Delete_User = "can-delete-user";
    static Can_Deactivate_User = "can-deactivate-user";
    static Can_Activate_User = "can-activate-user";
    static Can_Impersonate_User = "can-impersonate-user";
    static Can_Approve_Profile = "can-approve-profile";
    static Can_Create_University = "can-create-university";
    static Can_Update_University = "can-update-university";
    static Can_Update_Courses = "can-update-courses";
    static Can_Update_Permissions = "can-update-permissions";
    static Can_Change_Bll = "can-change-bll";
    static Can_Bulk_Change_Branch = "can-bulk-change-bll";
    static Can_Bulk_Change_Term = "can-bulk-change-term";
    static Can_Approve_Lor = "can-approve-lor";
    static Can_Approve_Sop = "can-approve-sop";
    static Can_Upload_Lor = "can-upload-lor";
    static Can_Upload_Sop = "can-upload-sop";
    static Can_Suggest_University = "can-suggest-university";
    static Can_Send_Bulk_Message = "can-send-bulk-message";
    static Can_Generate_Report = "can-generate-report";
    static Can_Manage_Files = "can-manage-files";
    static Can_Add_Or_Update_Partners = "can-add-or-update-partners";
    static Can_List_Partners = "can-view-partners";
    static Can_Update_Suggestion_Partner = "can-update-suggestion-partner";
    static Can_Manage_Programs = "can-manage-programs";
    static Can_Manage_Colleges = "can-manage-colleges";
}