import axios from "axios";
import { AllRoles } from "../../modules/common/enums/Roles";

const API_URL = process.env.REACT_APP_API_URL;
const PERMISSIONS_API_URL = `${API_URL}/api/v1/permissions`;

export const getAllPermissions = () => {
    return axios.get<string[]>(PERMISSIONS_API_URL);
}

export const getRolePermissions = (roleId: AllRoles) => {
    return axios.get<string[]>(`${PERMISSIONS_API_URL}/${roleId}`);
}

export const updateRolePermissions = (roleId: AllRoles, permissions: string[]) => {
    return axios.put<string[]>(`${PERMISSIONS_API_URL}/${roleId}`, permissions);
}