import { FC } from 'react'
import { ApiResponse } from '../../../../modules/common/ApiResponse';
import { AxiosResponse } from 'axios';
import { CompetitiveExamStep } from './steps/CompetitiveExamStep';
import { LanguageExamStep } from './steps/LanguageExamStep';
import { examDetailsSchemas, stepHeadings } from './ExamDetailsEditSchema';
import { Stepper } from '../../../../modules/stepper';
import { IStudentExamDetails } from '../../models';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

type Props = {
    examDetails: IStudentExamDetails,
    submitFn: (examDetails: any) => Promise<AxiosResponse<ApiResponse<string>>>
}

export const ExamWizard: FC<Props> = ({ examDetails, submitFn }) => {
    const history = useHistory();

    return <Stepper
        formValues={examDetails}
        submitFn={(formValues) => {
            return submitFn(formValues)
                .then(resp => {
                    if (resp.data.failed) {
                        return resp;
                    } else {
                        toast(`Saved information successfuly`, { type: 'success' });
                        history.push(`/student/${examDetails.profileId}/edit/submit`);
                        return resp;
                    }
                })
        }}
        validationSchemas={examDetailsSchemas}
        stepComponents={[CompetitiveExamStep, LanguageExamStep]}
        stepHeadings={stepHeadings} />
}
