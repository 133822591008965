/* eslint-disable jsx-a11y/anchor-is-valid */
import { createRef, FC, useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import tableIcons from '../../modules/common/components/MaterialTableIcons'
import { useHistory } from 'react-router-dom'
import { enumToKeyValue } from '../../modules/common/EnumHelper'
import { getDelegatedProfiles } from '../student-profiles/services/StudentManager'
import { useSharedState } from '../../modules/common/SharedStateProvider'
import { UploadDocumentDialog } from './_modals/UploadDocumentDialog'
import { uploadLor, uploadSop } from '../student/services/LorService'
import moment from 'moment'
import { DATE_TIME_FORMAT_DD_MMM_YYYY } from '../../modules/common/Helper'
import { StudentsWithDelegatedDocuments } from './components/StudentsWithDelegatedDocuments'

export enum DocumentType {
  LOR = 1,
  SOP,
  Essay,
}

export enum DelegationStatus {
  AssignedAndPending,
  UnderAdminReview,
  ApprovedAndSent,
  Rejected,
}

export interface ApproveOrRejectDocumentCommand {
  profileId: number
  documentType: DocumentType
  status: DelegationStatus,
  rejectionReason?: string
}

export interface DocumentDelegationResponseDto {
  profileId: number
  assignedToEmail: string
  documentType: DocumentType
  fullName: string,
  rejectionReason: string,
  documentComments: DelegationComment[]
  approvedAt: string
  delegatedOn: string
}

export interface DelegationComment {
  comment: string;
  commentDate: string;
  commentorName: string;
  commentorUsername: string;
}

enum DashboardTabs {
  PendingDocuments = 1,
  Completed,
}

export const ContentWriterDashboard: FC = () => {
  const [tab, setTab] = useState<DashboardTabs>(DashboardTabs.PendingDocuments);
  const history = useHistory();
  const tabNames = enumToKeyValue(DashboardTabs);

  useEffect(() => {
    if (!history.location.hash) return;
    setTab(+tabNames.find(x => `#${x.label}` === history.location.hash)?.value);
    //eslint-disable-next-line
  }, [history.location.hash]);

  return <><div className='card mb-5 mb-xl-10'>
    <div className='card-body pt-9 pb-0'>
      <div className='d-flex overflow-auto h-55px'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item cursor-pointer'>
            <a className={`nav-link text-active-primary me-6 ${tab === DashboardTabs.PendingDocuments ? 'active' : ''}`} onClick={() => {
              history.push({ hash: tabNames.find(x => x.value === DashboardTabs.PendingDocuments.toString())?.label })
            }}>
              Pending Documents
              {/* <span className="badge badge-light-primary m-2">{tabCounts[DashboardTabs.PendingDocuments]}</span> */}
            </a>
          </li>

          <li className='nav-item cursor-pointer'>
            <a className={`nav-link text-active-primary me-6 ${tab === DashboardTabs.Completed ? 'active' : ''}`} onClick={() => {
              history.push({ hash: tabNames.find(x => x.value === DashboardTabs.Completed.toString())?.label })
            }}>
              Completed
              {/* <span className="badge badge-light-primary m-2">{tabCounts[DashboardTabs.Completed]}</span> */}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

    <div className={`${tab !== DashboardTabs.PendingDocuments ? 'd-none' : ''}`}>
      <ContentWriterPendingDocs />
    </div>

    <div className={`${tab !== DashboardTabs.Completed ? 'd-none' : ''}`}>
      <StudentsWithDelegatedDocuments hideUserfilter={true} />
    </div>
  </>
}

const ContentWriterPendingDocs = () => {
  const history = useHistory()
  const [isLoading, setLoading] = useState(false)
  const [profiles, setProfiles] = useState([])
  const [uploadDialogShown, setUploadDialogShown] = useState<boolean>(false)
  const [refreshKey, setRefreshKey] = useState<number>(0)
  const [selectedRow, setSelected] = useState<DocumentDelegationResponseDto>()

  const state = useSharedState()

  const tableRef = createRef()
  const defaultColumns = [
    { title: 'Name', field: 'fullName' },
    {
      title: 'Document Type',
      render: (data: DocumentDelegationResponseDto) => {
        return (
          <span className='badge badge-light-primary'>
            {enumToKeyValue(DocumentType).find((x) => x.value === `${data.documentType}`)?.label}
          </span>
        )
      },
    },
    {
      title: 'Rejected?',
      render: (data: DocumentDelegationResponseDto) => {
        return data.documentComments?.length > 0 ? data.documentComments.map(x => (
          <li>{x.comment} - {moment(x.commentDate).format(DATE_TIME_FORMAT_DD_MMM_YYYY)} - {x.commentorName}</li>
        )) : "NO";
      },
    },
    {
      title: 'Approved Date',
      render: (data: DocumentDelegationResponseDto) => {
        return (
          <span className='badge badge-light-primary'>
            {moment(data.approvedAt).format('DD-MMM-YY')}
          </span>
        )
      },
    },
    {
      title: 'Delegated Date',
      render: (data: DocumentDelegationResponseDto) => {
        return (
          <span className='badge badge-light-primary'>
            {moment(data.delegatedOn).format('DD-MMM-YY')}
          </span>
        )
      },
    },
  ]

  const defaultActions = [
    {
      icon: tableIcons.OpenInNew as any,
      tooltip: 'View Questionnaire Response',
      disabled: false,
      onClick: (event: any, rowData: any) => {
        const docType: string = enumToKeyValue(DocumentType).find(
          (x) => x.value === `${rowData.documentType}`
        )?.label
        const url = `/student/${rowData.profileId}/${docType.toLowerCase()}`
        history.push(url)
      },
    },
    {
      icon: tableIcons.Upload as any,
      disabled: false,
      tooltip: 'Upload',
      onClick: (event: any, rowData: any) => {
        setSelected(rowData)
        setUploadDialogShown(true)
      },
    },
  ]

  useEffect(() => {
    const fetchData = () => {
      if (!state.user) return
      getDelegatedProfiles(state.user.username)
        .then((resp) => setProfiles(resp.data))
        .finally(() => setLoading(false))
    }
    fetchData()
    // eslint-disable-next-line
  }, [refreshKey])

  return (
    <div className='row'>
      <UploadDocumentDialog
        visible={uploadDialogShown}
        setVisibility={(state) => {
          setUploadDialogShown(state)
          setSelected(undefined)
        }}
        onSend={(req) => {
          const api = selectedRow?.documentType === DocumentType.LOR ? uploadLor : uploadSop
          return api(selectedRow?.profileId as number, req).then((res) => {
            setUploadDialogShown(false)
            setRefreshKey(Math.random())
            setSelected(undefined)
            return res
          })
        }}
      />
      <div className='col-xl-12'>
        <MaterialTable
          title={'Pending Documents'}
          icons={tableIcons}
          tableRef={tableRef}
          columns={defaultColumns}
          actions={defaultActions}
          data={profiles}
          isLoading={isLoading}
          options={{ debounceInterval: 700, filtering: false, pageSize: 10 }}
        />
      </div>
    </div>
  )
}
