/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { Link } from 'react-router-dom'
import { Conversation } from '../../../../app/modules/messaging/models/ConversationModel'
import { ConversationTile } from '../../../../app/modules/messaging/components/ConversationTile'
import { StartNewThreadButton } from '../../../../app/modules/messaging/components/StartNewThreadButton'
import { useSharedState } from '../../../../app/modules/common/SharedStateProvider'

const HeaderMessagesMenu: FC<{ conversations: Array<Conversation> }> = ({ conversations }) => {
  const { isStudent } = useSharedState();
  
  return <div
    className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
    data-kt-menu='true'
  >
    <div
      className='d-flex flex-row bgi-no-repeat rounded-top align-items-center'
      style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')` }}
    >
      <h3 className='text-white fw-bold px-9 mt-10 mb-6 d-flex flex-grow-1'>
        Messages
      </h3>
      {isStudent && <StartNewThreadButton className="btn btn-icon text-white" showIcon={true} />}
    </div>

    <div className='tab-pane ' style={{ textAlign: 'left' }} role='tabpanel'>
      <div className='scroll-y mh-325px my-5 px-3'>
        {conversations.map((conversation) => (<ConversationTile key={conversation.id} conversation={conversation} />))}
        {conversations?.length === 0 && <p className='text-center'>All caught up!</p>}
      </div>

      <div className='py-3 text-center border-top'>
        <Link
          to='/messages'
          className='btn btn-color-gray-600 btn-active-color-primary'
        >
          View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
        </Link>
      </div>
    </div>
  </div>
}

export { HeaderMessagesMenu }


