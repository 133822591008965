export enum ProfileStatus{
    Unsigned,
    AccountVerified,
    ProfileSubmitted,
    ProfileApproved,
    ScoresUpdated,
    LorQuestionnaireSubmitted,
    SopQuestionnaireSubmitted,
    LorQuestionnaireApproved,
    SopQuestionnaireApproved,
    LorSent,
    SopSent,
    UniversitiesSuggested,
    RequestedChanges,
    AwaitingAdmit,
    Finalized,
    Archived,
    LorQuestionnaireRejected,
    SopQuestionnaireRejected,
    Reactivated,

     //not mapped, only used by notifications
     EscalatedIssue,
     DocumentAssigned,
     CollegeAdded,
     ProfileComment,
     DocumentApproval,
}