import { FC, useEffect, useState } from 'react';
import { Link, RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import Joyride from 'react-joyride';
import { useSharedState } from '../../../modules/common/SharedStateProvider';
import { getCookie, setCookie } from '../../../modules/common/Helper';

type PathParamsType = {
    profileId: string,
}

const EditProfilePageHeader: FC<RouteComponentProps<PathParamsType> & { isSubmitted: boolean }> = ({ history, match: { params: { profileId } }, isSubmitted }) => {
    const location = useLocation();

    const [run, setRun] = useState(false);

    useEffect(() => {
        const shouldRun = getCookie('__imfs_portal_runTour');
        setRun(shouldRun !== '0');
    }, []);

    const state = useSharedState();

    const tabs = [
        {
            icon: 'bi bi-person-lines-fill',
            title: 'Personal',
            path: `/student/${profileId}/edit/personal`,
            htmlId: 'personal-tab',
            disabled: isSubmitted
        },
        {
            icon: 'bi bi-mortarboard',
            title: 'Academic',
            path: `/student/${profileId}/edit/academic`,
            htmlId: 'academic-tab',
            disabled: isSubmitted
        },
        {
            icon: 'bi bi-card-checklist',
            title: 'Exam',
            path: `/student/${profileId}/edit/exam`,
            htmlId: 'exam-tab'
        },
        {
            icon: 'bi bi-send-check',
            title: isSubmitted ? 'Verify' : 'Verify & Submit',
            path: `/student/${profileId}/edit/submit`,
            htmlId: 'submit-tab'
        },
        {
            icon: 'bi bi bi-activity',
            title: 'Timeline & Activity',
            path: `/student/${profileId}/edit/activity`,
            htmlId: 'activity-tab'
        }]

    return <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
            {state.isStudent && <Joyride
                run={run}
                showProgress={true}
                showSkipButton={true}
                continuous={true}
                disableScrolling={true}
                callback={(ev) => {
                    if (ev.action === 'skip') {
                        //set cookie
                        setCookie('__imfs_portal_runTour', '0');
                    }
                }}
                steps={[
                    {
                        target: 'body',
                        content: 'Lets begin a guiding tour to get you set up quickly.',
                        placement: 'center',
                    },
                    {
                        target: '.personal-tab',
                        content: 'This is the first step of your profile, Here, we will collect some important basic details, e.g. name, email, phone',
                        title: 'Section 1',
                        placement: 'right-start',
                    },
                    {
                        target: '.passport-details',
                        content: 'This is technically last step in basic section, where you upload passport docs.',
                    },
                    {
                        target: '.social-details',
                        content: 'Its good to be connected, but optional, you can save this information and move to next section',
                    },
                    {
                        target: '.continue-button',
                        content: 'Once information is filled, you can continue to next substep.',
                        title: 'Move back and forth',
                    },
                    {
                        target: '.academic-tab',
                        content: <div className='row'>
                            <p className='text-muted'>Here, you will fill in all academic records, your grades, college, degree etc.</p>
                            <div className='row' style={{ top: '100px' }}>
                                <img alt='Click on academic tab' src='/media/misc/giphy.webp' />
                            </div>
                        </div>,
                        title: 'Section 2',
                        placement: 'right-start',
                        spotlightClicks: true,
                        hideCloseButton: true,
                        styles: { tooltip: { top: "100px" } }
                    },
                    {
                        target: '.graduation-details',
                        content: 'We will store your current grades and programs you attended to suggest best universities',
                        title: 'Graduation Info',
                    },
                    {
                        target: '.future-details',
                        content: 'Your interests for future education',
                        title: 'Future Education Interests',
                    },
                    {
                        target: '.work-details',
                        content: 'You can add optional work experience',
                        title: 'Optional Work Experience',
                    },
                    {
                        target: '.exam-tab',
                        content: <div className=''>
                            <h3 className='p-2 border border-success mb-20'>Click on tab to know more</h3>
                            <p className='text-muted'>In this step, you will be asked for exam details (e.g. GRE, TOEFL..) and scores if available</p>
                            <div className='row' style={{ top: '100px' }}>
                                <img alt='Click on exam tab' className='symbol' src='/media/misc/giphy.webp' />
                            </div>
                        </div>,
                        title: 'Step 3',
                        placement: 'right-end',
                        spotlightClicks: true,
                        styles: { tooltip: { top: "100px" } }
                    },
                    {
                        target: '.competitive-details',
                        content: 'Qualifying exam score and date',
                        title: 'Qualifying Exam Info',
                    },
                    {
                        target: '.language-details',
                        content: 'Language exam score and date',
                        title: 'Language Exam Details',
                    },
                    {
                        target: '.submit-tab',
                        content: 'This is the last step of your profile, you can verify information provided and submit the profile.',
                        title: 'Last Section - Review',
                        placement: 'left',
                        spotlightClicks: true,
                    },
                ]}
                styles={{
                    options: {
                        zIndex: 9999,
                    },
                }}
            />}
            <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                    {tabs.map(node => (<li className={`nav-item ${node.disabled ? 'disabled' : ''}`} key={node.path}>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ${node.disabled ? 'disabled' : ''} ${node.htmlId} ` +
                                (location.pathname === node.path && 'active')
                            }
                            to={node.path}
                        >
                            <i className={node.icon}></i>{node.title}
                        </Link>
                    </li>))}
                </ul>
            </div>
        </div>
    </div>
}

export default withRouter(EditProfilePageHeader);