import { useState, FC, ReactNode } from "react";
import { Button, Modal } from "react-bootstrap-v5";

type Props = {
    onConfirm: () => void;
    onCancel?: () => void;
    title: string;
    children: ReactNode;
    showFooter?: boolean
}

export const ConfirmationModal: FC<Props> = ({ onConfirm, onCancel, title, children, showFooter = true }) => {
    const [show, setShow] = useState(true);

    const handleClose = (isConfirm: boolean) => {
        setShow(false);
        if (isConfirm)
            onConfirm && onConfirm()
        else
            onCancel && onCancel()
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered 
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            {showFooter && <Modal.Footer>
                <Button variant="secondary" onClick={() => handleClose(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => { handleClose(true) }}>Confirm</Button>
            </Modal.Footer>}
        </Modal>
    );
}