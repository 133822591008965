import * as Yup from 'yup';
import { StepperHeadingProps } from '../../../../modules/stepper/StepHeading';
import { GradingSystemId, gradingSystemValidator, GraduationType } from '../../models';

export const stepHeadings: StepperHeadingProps[] = [
    {
        index: 1,
        title: 'Graduation Details',
        desc: 'Tell us about your current education',
        htmlId: 'graduation-details',
    },
    {
        index: 2,
        title: 'Future Study',
        desc: 'Tell us about what you want to study',
        htmlId: 'future-details',
    },
    {
        index: 3,
        title: 'Work Experience',
        desc: 'Tell us about work experience(if any)',
        htmlId: 'work-details',
    }
];

const collegeYupShape = Yup.object().nullable().shape({
    collegeName: Yup.string().required(),
    passingYear: Yup.number().required().min(1999).max(2030).label('Year of passing'),
    fieldOfStudyName: Yup.string().label('Field of study').required(),
    universityId: Yup.number().required().label('University'),
}).required().label('College Details');

const scoreYupShape = Yup.object().when(['gradingSystemId'], (gradingSystemId: GradingSystemId, schema) => {
    const maxScore: number = gradingSystemValidator(gradingSystemId);
    return schema.shape({
        score: Yup.number().required().min(0).max(maxScore).label('Score')
    });
}).nullable().label('Grade Details');

const bachelorsYupShape = Yup.object().shape({
    gradingSystemId: Yup.number().required(),
    duration: Yup.number().nullable().default(4),
    firstYearScore: scoreYupShape,
    secondYearScore: scoreYupShape,
    thirdYearScore: scoreYupShape,
    // fourthYearScore: scoreYupShape.nullable().when('duration', (duration: number, schema) => {
    //     if (duration < 4) {
    //         return Yup.object().nullable();
    //     }
    //     return schema;
    // }),
    // fifthYearScore: scoreYupShape.nullable().when('duration', (duration: number, schema) => {
    //     if (duration < 5) {
    //         return Yup.object().nullable();
    //     }
    //     return schema;
    // }),
    fourthYearScore: Yup.object().nullable().notRequired(),
    fifthYearScore: Yup.object().nullable().notRequired(),
    collegeDetail: collegeYupShape
});

const diplomaYupShape = Yup.object().shape({
    gradingSystemId: Yup.number().required().label('Grading system'),
    firstYearScore: scoreYupShape,
    secondYearScore: scoreYupShape,
    thirdYearScore: scoreYupShape,
    collegeDetail: collegeYupShape
});

export const profileElementSchemas = [
    Yup.object().shape({
        graduationDetail: Yup.object().shape({
            graduationType: Yup.number().required().label('Graduation Type'),
            juniorCollegeDetail: Yup.object().when(['graduationType'], {
                is: (graduationType: GraduationType) => graduationType === GraduationType.JuniorCollege,
                then: Yup.object().shape({
                    gradingSystemId: Yup.number().required().label('Grading system'),
                    tenthStdScore: scoreYupShape,
                    eleventhStdScore: scoreYupShape,
                    twelfthStdScore: scoreYupShape,
                    collegeDetail: collegeYupShape
                }),
                otherwise: Yup.object().nullable().notRequired(),
            }),
            diplomaDegreeDetail: Yup.object().when(['graduationType'], {
                is: (graduationType: GraduationType) => graduationType === GraduationType.Diploma,
                then: diplomaYupShape,
                otherwise: Yup.object().nullable().notRequired(),
            }),
            bachelorDegreeDetail: Yup.object().when(['graduationType'], {
                is: (graduationType: GraduationType) => graduationType === GraduationType.Bachelors,
                then: bachelorsYupShape,
                otherwise: Yup.object().nullable().notRequired(),
            }),
            masterDegreeDetail: Yup.object().when(['graduationType'], {
                is: (graduationType: GraduationType) => graduationType === GraduationType.Masters,
                then: Yup.object().shape({
                    gradingSystemId: Yup.number().required(),
                    duration: Yup.number().nullable().default(2),
                    firstYearScore: scoreYupShape,
                    secondYearScore: scoreYupShape,
                    thirdYearScore: scoreYupShape.nullable().when('duration', (duration: number, schema) => {
                        if (duration < 3) {
                            return Yup.object().nullable();
                        }
                        return schema;
                    }),
                    collegeDetail: collegeYupShape,
                    bachelorDegreeScore: bachelorsYupShape
                }),
                otherwise: Yup.object().nullable().notRequired(),
            }),
            diplomaPlusBachelorDegreeDetail: Yup.object().when(['graduationType'], {
                is: (graduationType: GraduationType) => graduationType === GraduationType.DiplomaPlusBachelorDegree,
                then: Yup.object().shape({
                    diplomaDegreeScore: diplomaYupShape,
                    bachelorDegreeScore: bachelorsYupShape,
                }),
                otherwise: Yup.object().nullable().notRequired(),
            })
        })
    }),
    Yup.object().shape({
        desiredDegreeType: Yup.number().required().label('Desired degree for future study'),
        courseId: Yup.number().required().label('Desired course for future study'),
        fieldOfStudyId: Yup.number().required().label('Desired field of study for future study'),
        specializationId: Yup.number().nullable().notRequired(),
    }),
    Yup.object().shape({
        workExperiences: Yup.array().nullable().of(Yup.object().shape({
            companyName: Yup.string().label('Company name').required(),
            designation: Yup.string().label('Designation').required(),
            description: Yup.string().label('Short description of roles and responsibilities').notRequired().nullable(),
            startDate: Yup.date().label('Start date').required(),
            endDate: Yup.date().nullable().label('End date').when(['isOngoing'], (isOngoing: boolean, schema) => {
                return isOngoing ? schema.notRequired() : schema.required()
            }),
            isOngoing: Yup.bool().nullable().notRequired().default(false)
        }))
    })
];
