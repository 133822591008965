import axios from 'axios';
import { Branch } from '../../../modules/common/enums/Branch';
import { IStudentFilter } from '../models/IStudentFilter';


const API_URL = process.env.REACT_APP_API_URL;
export const USER_API = `${API_URL}/api/v1/profilesearch`

export function getAllStudentsPaginated(searchTerm: string | undefined, pageNumber: number, pageSize: number, filter: IStudentFilter, sortOrder: string = 'asc', sortField: string = 'firstName') {
    return axios.post(`${USER_API}`, {
        searchTerm,
        pageNumber,
        pageSize,
        sortOrder,
        sortField,
        ...filter
    })
}


export function getStudentsPendingApprovalPaginated(searchTerm: string | undefined, pageNumber: number, pageSize: number, filter: IStudentFilter, sortOrder: string = 'asc', sortField: string = 'firstName') {
    return axios.post(`${USER_API}/get-profiles-pending-approval`, {
        searchTerm,
        pageNumber,
        pageSize,
        sortOrder,
        sortField,
        ...filter
    })
}

export function getStudentsPendingSuggestionPaginated(searchTerm: string | undefined, pageNumber: number, pageSize: number, filter: IStudentFilter, sortOrder: string = 'asc', sortField: string = 'firstName') {
    return axios.post(`${USER_API}/get-profiles-pending-suggestion`, {
        searchTerm,
        pageNumber,
        pageSize,
        sortOrder,
        sortField,
        onlyIncludeChangeRequests: false,
        ...filter
    })
}

export function getAllOAStudentsPaginated(searchTerm: string | undefined, pageNumber: number, pageSize: number, filter: IStudentFilter, sortOrder: string = 'asc', sortField: string = 'firstName') {
    return axios.post(`${USER_API}/get-profiles-all-oa-students`, {
        searchTerm,
        pageNumber,
        pageSize,
        sortOrder,
        sortField,
        onlyIncludeChangeRequests: false,
        ...filter
    })
}

export function getSuggestionChangeRequests(searchTerm: string | undefined, pageNumber: number, pageSize: number, filter: IStudentFilter, sortOrder: string = 'asc', sortField: string = 'firstName') {
    return axios.post(`${USER_API}/get-profiles-pending-suggestion`, {
        searchTerm,
        pageNumber,
        pageSize,
        sortOrder,
        sortField,
        onlyIncludeChangeRequests: true,
        ...filter
    })
}

export function getStudentsWithSuggestions(searchTerm: string | undefined, pageNumber: number, pageSize: number, filter: IStudentFilter, sortOrder: string = 'asc', sortField: string = 'firstName', includeFinalized: boolean = false) {
    return axios.post(`${USER_API}/get-profiles-with-suggestions`, {
        searchTerm,
        pageNumber,
        pageSize,
        sortOrder,
        sortField,
        includeFinalized,
        ...filter
    })
}

export function getStudentsPendingSuggestionApprovalPaginated(searchTerm: string | undefined, pageNumber: number, pageSize: number, filter: IStudentFilter, sortOrder: string = 'asc', sortField: string = 'firstName') {
    return axios.post(`${USER_API}/get-profiles-pending-suggestion-approval`, {
        searchTerm,
        pageNumber,
        pageSize,
        sortOrder,
        sortField,
        ...filter
    })
}

export function getStudentsPendingLORApprovalPaginated(searchTerm: string | undefined, pageNumber: number, pageSize: number, filter: IStudentFilter, sortOrder: string = 'asc', sortField: string = 'firstName') {
    return axios.post(`${USER_API}/get-profiles-pending-lor-approval`, {
        searchTerm,
        pageNumber,
        pageSize,
        sortOrder,
        sortField,
        ...filter
    })
}


export function getStudentsPendingSOPApprovalPaginated(searchTerm: string | undefined, pageNumber: number, pageSize: number, filter: IStudentFilter, sortOrder: string = 'asc', sortField: string = 'firstName') {
    return axios.post(`${USER_API}/get-profiles-pending-sop-approval`, {
        searchTerm,
        pageNumber,
        pageSize,
        sortOrder,
        sortField,
        ...filter
    })
}

export function getStudentsPendingLORPaginated(searchTerm: string | undefined, pageNumber: number, pageSize: number, filter: IStudentFilter, sortOrder: string = 'asc', sortField: string = 'firstName') {
    return axios.post(`${USER_API}/get-profiles-pending-lor-doc`, {
        searchTerm,
        pageNumber,
        pageSize,
        sortOrder,
        sortField,
        ...filter
    })
}


export function getStudentsPendingSOPPaginated(searchTerm: string | undefined, pageNumber: number, pageSize: number, filter: IStudentFilter, sortOrder: string = 'asc', sortField: string = 'firstName') {
    return axios.post(`${USER_API}/get-profiles-pending-sop-doc`, {
        searchTerm,
        pageNumber,
        pageSize,
        sortOrder,
        sortField,
        ...filter
    })
}

export function getPendingDocumentApprovalsPaginated(searchTerm: string | undefined, pageNumber: number, pageSize: number, filter: IStudentFilter, sortOrder: string = 'asc', sortField: string = 'firstName') {
    return axios.post(`${USER_API}/get-profiles-pending-document-approval`, {
        searchTerm,
        pageNumber,
        pageSize,
        sortOrder,
        sortField,
        ...filter
    })
}

export function getAllStudentsDocumentPaginated(searchTerm: string | undefined, pageNumber: number, pageSize: number, filter: IStudentFilter, sortOrder: string = 'asc', sortField: string = 'firstName') {
    return axios.post(`${USER_API}/get-all-profiles-with-document`, {
        searchTerm,
        pageNumber,
        pageSize,
        sortOrder,
        sortField,
        ...filter
    })
}

export function searchProfilesByQuery(query: string | undefined) {
    return axios.get(`${USER_API}/search/${query}`)
}

export function getStudentsByBll(bllUsername: string, pageNumber: number, pageSize: number) {
    return axios.post(`${USER_API}/get-students-by-bll`, {
        bllUsername,
        pageNumber,
        pageSize,
    })
}

export function getStudentsByBranch(branchId: Branch, pageNumber: number, pageSize: number) {
    return axios.post(`${USER_API}/get-students-by-branch`, {
        branchId,
        pageNumber,
        pageSize,
    })
}

export function getStudentsByTerm(termId: number, pageNumber: number, pageSize: number) {
    return axios.post(`${USER_API}/get-students-by-term`, {
        termId,
        pageNumber,
        pageSize,
    })
}

export function getDelegatedProfiles(assignedToEmail: string) {
    return axios.get(`${USER_API}/get-delegated-profiles/${assignedToEmail}`)
}