import React, { createContext, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Conversation } from '../messaging/models/ConversationModel';
import * as messageService from '../messaging/messageService'
import { useSharedState } from './SharedStateProvider';
import moment from 'moment';

export interface IMessageStateModel {
    handleTransition: (id: number) => void,
    markAsRead: (id: number) => void,
    refresh: (id: number) => void,
    messages: Array<Conversation>,
}

const MessageContext = createContext<IMessageStateModel>({
    handleTransition: (id: number) => { },
    markAsRead: (id: number) => { },
    messages: [],
    refresh: (id: number) => { },
})

const MessageProvider: React.FC = ({ children }) => {
    const [messages, setMessages] = useState<Array<Conversation>>([]);
    const { user } = useSharedState();
    const history = useHistory();

    useEffect(() => {
        //get data
        let isSubscribed = true;
        const fetchData = () => {
            messageService.getAll().then(({ data }) => {
                const messages = data.map(conv => {
                    return { ...conv, unreadMessageCount: conv.messages?.filter(x => x.creatorBy !== user?.username && !x.isRead).length }
                })
                const sorted = messages.sort((x, y) => moment(y.lastUpdatedAt).diff(moment(x.lastUpdatedAt)))
                if (isSubscribed) {
                    setMessages(sorted);
                }
            })
        }
        if (user) {
            fetchData();
        }
        return () => { isSubscribed = false };
        //eslint-disable-next-line
    }, [user])

    const markAsRead = (id: number) => {
        messageService.markAsRead(id).then(x => {
            refresh(id);
        })
    }

    const handleTransition = (id: number) => {
        history.push(`/messages/${id}`);
    }

    const refresh = (conversationId: number) => {
        messageService.getConversation(conversationId)
            .then((conv) => {
                const sorted = [...messages.filter(x => x.id !== conversationId), conv.data]
                    .sort((x, y) => moment(y.lastUpdatedAt).diff(moment(x.lastUpdatedAt)));
                setMessages(sorted);
            })
    }

    const value: IMessageStateModel = {
        handleTransition,
        markAsRead,
        messages,
        refresh
    }
    return <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
}

export { MessageContext, MessageProvider }

export function useMessageContext() {
    return useContext(MessageContext)
}
