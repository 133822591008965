import { FC } from 'react';
import { CollegeDetail } from '../../../Common/CollegeDetail';
import { GradingSystemSelector } from '../../../Common/GradingSystemSelector';
import { GraduationScore } from '../../../Common/GraduationScore';

type Props = {
    formik: any,
    basePath: string
}

const courseConfig = [
    {
        fieldPath: 'firstYearScore',
        title: 'First Year Grades',
        show: true
    },
    {
        fieldPath: 'secondYearScore',
        title: 'Second Year Grades',
        show: true
    },
    {
        fieldPath: 'thirdYearScore',
        title: 'Third Year Grades',
        show: true
    },
]

export const DiplomaGradesForm: FC<Props> = ({ formik, basePath }) => {
    return <>
        <div className="row fv-row mb-10">
            <div className='col-xl-6'>
                <label className='form-label fw-bolder text-dark fs-6 mb-5'>Diploma Degree Grades</label>
            </div>
        </div>

        <div className="row fv-row mb-10">
            <div className="col-xl-6">
                <GradingSystemSelector formik={formik} fieldPath={basePath} />
            </div>
        </div>
        {courseConfig.filter(x => x.show).map(c => <div className="row fv-row mb-10" key={c.fieldPath}>
            <GraduationScore
                formik={formik}
                fieldPath={`${basePath}.${c.fieldPath}`}
                title={c.title} />
        </div>)}
        <div className="row fv-row mb-10">
            <CollegeDetail formik={formik} fieldPath={`${basePath}.collegeDetail`} />
        </div>
    </>
}