import { FC } from "react";
import { MultiValue, SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { components } from 'react-select'
import { DropdownOption } from "../SharedStateProvider";

const filterOptions = (inputValue: string, options: Array<DropdownOption>) => {
    return options.filter((opt: DropdownOption) =>
        opt.label.toLowerCase().includes(inputValue.toLowerCase())
    );
};

const loadOptions = (
    query: string,
    callback: (options: DropdownOption[]) => void,
    options: Array<DropdownOption>
) => {
    setTimeout(() => {
        callback(filterOptions(query, options));
    }, 300);
};

export const AsyncDropdown: FC<{
    className?: string,
    options: Array<DropdownOption>,
    onSelected: (val: any | any[]) => void,
    selected: any | any[],
    isMulti?: boolean,
    onFocus?: any,
    initialCount?: number,
    loadOptionsFn?: (
        query: string,
        callback: (options: DropdownOption[]) => void,
    ) => void,
}> = ({ options, onSelected, selected, loadOptionsFn, initialCount = 20, isMulti = false, className, onFocus }) => {
    const NoOptionsMessage = (props: any) => {
        return (
            <components.NoOptionsMessage {...props}>
                <span className="custom-css-class">Type to search for options</span>
            </components.NoOptionsMessage>
        );
    };
    return (
        <AsyncSelect
            placeholder="Type to search"
            className={className}
            onFocus={onFocus}
            isClearable
            components={{ NoOptionsMessage }}
            cacheOptions
            loadOptions={(inputValue, callback) => loadOptionsFn ? loadOptionsFn(inputValue, callback) : loadOptions(inputValue, callback, options)}
            defaultOptions={options.slice(0, initialCount)}
            onChange={(opt) => isMulti ? onSelected((opt as MultiValue<DropdownOption>).map(c => c.value)) : onSelected((opt as SingleValue<DropdownOption>)?.value)}
            value={isMulti ? options.filter(x => selected.includes(x.value)) || [] : options.find(x => x.value === selected) || null}
            isMulti={isMulti}
            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
        />
    );
}