import Divider from '@material-ui/core/Divider';
import { FC, useEffect, useState } from 'react';
import { gradingTypeOptions } from '../../../../modules/common/DropdownOptions';
import { IGraduationScore, IMasterDegreeDetail } from '../../../student/models';
import { BachelorGradesView } from './BachelorGradesView';
import { CollegeDetailView } from './CollegeDetailView';
import { GraduationScoreView } from './GraduationScoreView';

type Props = {
    data: IMasterDegreeDetail,
}

const courseConfig = [
    {
        fieldPath: 'firstYearScore',
        title: 'First Year Grades',
        show: true
    },
    {
        fieldPath: 'secondYearScore',
        title: 'Second Year Grades',
        show: true
    },
    {
        fieldPath: 'thirdYearScore',
        title: 'Third Year Grades',
        show: true
    },
]

export const MastersGradesView: FC<Props> = ({ data }) => {
    const [config, setConfig] = useState(courseConfig);

    useEffect(() => {
        const currentConfig = [...config];
        switch (data.duration) {
            case 2:
                currentConfig[2].show = false
                break;
            case 3:
                currentConfig[2].show = true
                break;
            default:
                break;
        }
        setConfig([...currentConfig]);
        //eslint-disable-next-line
    }, [data.duration])

    return <>
        <div className="row fv-row mb-5">
            <div className='col-xl-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Masters Degree Grades</label>
            </div>
            <Divider variant='middle' className='m-2 bg-danger' />
        </div>
        <div className="row">
            <div className='col-3 mb-7'>
                <label className='fw-bold text-muted'>Course Duration</label>
                <span className='fw-bolder fs-6 text-dark'>
                    {data.duration || 2} Years
                </span>
            </div>
            <div className='col-3 mb-7'>
                <label className='fw-bold text-muted'>Grading System</label>
                <span className='fw-bolder fs-6 text-dark'>
                    {gradingTypeOptions.find(x => x.value === data.gradingSystemId)?.label}
                </span>
            </div>
        </div>
        <div className="row">
            {config.filter(x => x.show).map(c => <div className="col-3 fv-row mb-10" key={c.fieldPath}>
                <GraduationScoreView
                    data={(data as any)[c.fieldPath] as IGraduationScore}
                    title={c.title} />
            </div>)}
        </div>
        <div className="row fv-row mb-10">
            <CollegeDetailView data={data.collegeDetail} />
        </div>

        <BachelorGradesView data={data.bachelorDegreeScore} />
    </>
}

