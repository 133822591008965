import Divider from '@material-ui/core/Divider';
import { FC } from 'react';
import { gradingTypeOptions } from '../../../../modules/common/DropdownOptions';
import { IGraduationScore, IJuniorCollegeDetail } from '../../../student/models';
import { CollegeDetailView } from './CollegeDetailView';
import { GraduationScoreView } from './GraduationScoreView';

type Props = {
    data: IJuniorCollegeDetail,
}

const config = [
    {
        fieldPath: 'tenthStdScore',
        title: '10th/SSC Grades',
        show: true
    },
    {
        fieldPath: 'eleventhStdScore',
        title: '11th Grades',
        show: true
    },
    {
        fieldPath: 'twelfthStdScore',
        title: '12th/HSC Grades',
        show: true
    },
]


export const JuniorCollegeGradesView: FC<Props> = ({ data }) => {
    return <>
        <div className="row fv-row mb-5">
            <div className='col-xl-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Junior College Grades</label>
            </div>
            <Divider variant='middle' className='m-2 bg-danger' />
        </div>

        <div className='row mb-7'>
            <label className='fw-bold text-muted'>Grading System:</label>
            <span className='fw-bolder fs-6 text-dark'>
                {gradingTypeOptions.find(x => x.value === data.gradingSystemId)?.label}
            </span>
        </div>
        <div className="row">
            {config.map(c => <div className="col-3 fv-row mb-10" key={c.fieldPath}>
                <GraduationScoreView
                    data={(data as any)[c.fieldPath] as IGraduationScore}
                    title={c.title} />
            </div>)}
        </div>
        <div className="row fv-row mb-10">
            <CollegeDetailView data={data.collegeDetail} />
        </div>
    </>
}