/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { StudentsPendingDocuments } from "./components/StudentsPendingDocuments";
import { StudentsPendingLor } from "./components/StudentsPendingLor";
import { StudentsPendingSop } from "./components/StudentsPendingSop";
import { StudentsPendingUniversitySuggestionApproval } from "./components/StudentsPendingUniversitySuggestionApproval";
import { useHistory } from "react-router-dom";
import { enumToKeyValue } from "../../modules/common/EnumHelper";
import { StudentsWithDelegatedDocuments } from "./components/StudentsWithDelegatedDocuments";

enum DashboardTabs {
    Lor,
    Sop,
    University,
    Documents,
    AllDelegated,
}

export const SuperAdminDashboard: FC = () => {
    const [tab, setTab] = useState<DashboardTabs>(DashboardTabs.Lor);
    const [tabCounts, setTabCounts] = useState<Array<number>>([]);
    const history = useHistory();
    const tabNames = enumToKeyValue(DashboardTabs);

    useEffect(() => {
        if (!history.location.hash) return;
        setTab(+tabNames.find(x => `#${x.label}` === history.location.hash)?.value);
        //eslint-disable-next-line
    }, [history.location.hash]);
    return <>
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item cursor-pointer'>
                            <a className={`nav-link text-active-primary me-6 ${tab === DashboardTabs.Lor ? 'active' : ''}`} onClick={() => {
                                history.push({ hash: tabNames.find(x => x.value === DashboardTabs.Lor.toString())?.label })
                            }}>
                                Pending LOR
                                <span className="badge badge-light-primary m-2">{tabCounts[DashboardTabs.Lor]}</span>
                            </a>
                        </li>

                        <li className='nav-item cursor-pointer'>
                            <a className={`nav-link text-active-primary me-6 ${tab === DashboardTabs.Sop ? 'active' : ''}`} onClick={() => {
                                history.push({ hash: tabNames.find(x => x.value === DashboardTabs.Sop.toString())?.label })
                            }}>
                                Pending SOP
                                <span className="badge badge-light-primary m-2">{tabCounts[DashboardTabs.Sop]}</span>
                            </a>
                        </li>
                        <li className='nav-item cursor-pointer'>
                            <a className={`nav-link text-active-primary me-6 ${tab === DashboardTabs.University ? 'active' : ''}`} onClick={() => {
                                history.push({ hash: tabNames.find(x => x.value === DashboardTabs.University.toString())?.label })
                            }}>
                                Review University Suggestions
                                <span className="badge badge-light-primary m-2">{tabCounts[DashboardTabs.University]}</span>
                            </a>
                        </li>
                        <li className='nav-item cursor-pointer'>
                            <a className={`nav-link text-active-primary me-6 ${tab === DashboardTabs.Documents ? 'active' : ''}`} onClick={() => {
                                history.push({ hash: tabNames.find(x => x.value === DashboardTabs.Documents.toString())?.label })
                            }}>
                                Review CW Documents
                                <span className="badge badge-light-primary m-2">{tabCounts[DashboardTabs.Documents]}</span>
                            </a>
                        </li>
                        <li className='nav-item cursor-pointer'>
                            <a className={`nav-link text-active-primary me-6 ${tab === DashboardTabs.AllDelegated ? 'active' : ''}`} onClick={() => {
                                history.push({ hash: tabNames.find(x => x.value === DashboardTabs.AllDelegated.toString())?.label })
                            }}>
                                All Delegated Docs
                                <span className="badge badge-light-primary m-2">{tabCounts[DashboardTabs.AllDelegated]}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className={`${tab !== DashboardTabs.Lor ? 'd-none' : ''}`}>
            <StudentsPendingLor onLoad={(count: number) => {
                const counts = tabCounts;
                counts[DashboardTabs.Lor] = count;
                setTabCounts([...counts]);
            }} />
        </div>
        <div className={`${tab !== DashboardTabs.Sop ? 'd-none' : ''}`}>
            <StudentsPendingSop onLoad={(count: number) => {
                const counts = tabCounts;
                counts[DashboardTabs.Sop] = count
                setTabCounts([...counts]);
            }} />
        </div>
        <div className={`${tab !== DashboardTabs.University ? 'd-none' : ''}`}>
            <StudentsPendingUniversitySuggestionApproval onLoad={(count: number) => {
                const counts = tabCounts;
                counts[DashboardTabs.University] = count
                setTabCounts([...counts]);
            }} />
        </div>
        <div className={`${tab !== DashboardTabs.Documents ? 'd-none' : ''}`}>
            <StudentsPendingDocuments onLoad={(count: number) => {
                const counts = tabCounts;
                counts[DashboardTabs.Documents] = count
                setTabCounts([...counts]);
            }} />
        </div>
        <div className={`${tab !== DashboardTabs.AllDelegated ? 'd-none' : ''}`}>
            <StudentsWithDelegatedDocuments hideUserfilter={false} onLoad={(count: number) => {
                const counts = tabCounts;
                counts[DashboardTabs.AllDelegated] = count
                setTabCounts([...counts]);
            }} />
        </div>
    </>

}