import axios from "axios"
import { ITerm } from "./ITerm";

const API_URL = process.env.REACT_APP_API_URL;
const TERMS_API_URL = `${API_URL}/api/v1/terms`;

export const getAllTerms = () => {
    return axios.get<ITerm[]>(TERMS_API_URL)
}

export const createTerm = (term: ITerm) => {
    return axios.post(TERMS_API_URL, term);
}

export const updateTerm = (term: ITerm) => {
    return axios.put(TERMS_API_URL + "/" + term.id, term);
}