import {UniversityFieldOfStudyDto} from '../../university-management/models/UniversityRequests'

export interface IStudentResponseToUniversitySuggestionDto {
  suggestionId: number
  universityId: number
  status: SuggestedUniversityResponseStatus
  comment?: string
}

export enum SuggestedUniversityStatus {
  Suggested = 0,
  RequestedChanges,
  Accepted,
  AdmitReceived,
  RejectionReceived,
  Finalized,
}

export enum SuggestedUniversityResponseStatus {
  RequestedChanges = 1,
  Accepted,
  AdmitReceived,
  RejectionReceived,
  Finalized,
  NotApplied,
}

export enum UniversitySuggestionState {
  Suggestion = 0,
  WaitingOnAdmin,
  WaitingOnSuperAdmin,
  WaitingOnStudent,
  Accepted,
  Finalized,
}

export interface ISuggestedUniversityResponseDto {
  id?: number
  universityId: number
  suggestedBy?: string
  status: SuggestedUniversityStatus
  comments: ISuggestedUniversityCommentResponseDto[]
  university: IUniversityBasicDetailsDto
  isArchived?: boolean
  studentVisible?: boolean
  isDirect?: boolean
  cwidNumber?: string
  suggestionLevel: SuggestionLevel
  //internal use
  comment?: string
  partnerId?: number | null
  createdAt?: string
}

export enum SuggestionLevel {
  Ambitious = 1,
  Moderate,
  Safe
}

export interface IUniversityBasicDetailsDto {
  id: number
  universityName: string
  universityCode: string
  annualExpenditure?: number
  applicationDeadline?: string
  applicationFee?: number
  maxTemperature?: number
  minTemperature?: number
  partnered: boolean
  universityPartners?: Array<any>
  universityFieldOfStudyMeta: UniversityFieldOfStudyDto
  rank?: number
}

export interface ISuggestUniversityRequestDto {
  universityId: number
  comment?: string
  suggestionLevel: SuggestionLevel
  partnerId?: number | null
  isDirect?: boolean
}

export interface IUniversitySuggestionMetaResponseDto {
  id: number
  profileId: number
  suggestions: ISuggestedUniversityResponseDto[]
  archivedSuggestions: ISuggestedUniversityResponseDto[]
  comments?: IAdminSuggestionCommentResponseDto[]
  state: UniversitySuggestionState
}

export interface ISuggestedUniversityCommentResponseDto {
  suggestedUniversityId: number
  comment: string
  commentorUsername: string
  commentorName: string
  commentDate: string
}

export interface ISuggestionOptionsDto {
  suggestionOptions: IUniversityBasicDetailsDto[]
  course: INameIdPairDto
  fieldOfStudy: INameIdPairDto
  specialization: INameIdPairDto
}

export interface INameIdPairDto {
  id?: number
  name: string
}

export interface IAdminSuggestionCommentResponseDto {
  id: number
  metaId: number
  comment: string
  commentorUsername: string
  commentorName: string
  commentDate: string
}
