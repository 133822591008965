import { FC } from "react"
import { GMATExamScoreDto } from "../../../student/models/ExamModels"

type Props = {
    data: GMATExamScoreDto,
}

export const GMATScoreView: FC<Props> = ({ data }) => {

    return <div className='row mb-7'>
        <div className="row mb-5">
            <label className="fw-bold text-dark mt-5">Score Breakdown</label>
        </div>
        <div className="row mb-7">
            <label className='col-lg-4 fw-bold text-muted'>Total</label>
            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                    {data.total}
                </span>
            </div>
        </div>

        <div className="row mb-7">
            <label className='col-lg-4 fw-bold text-muted'>Verbal</label>
            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                    {data.verbal}
                </span>
            </div>
        </div>

        <div className="row mb-7">
            <label className='col-lg-4 fw-bold text-muted'>Quantitative</label>
            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                    {data.quantitative}
                </span>
            </div>
        </div>

        <div className="row mb-7">
            <label className='col-lg-4 fw-bold text-muted'>AWA</label>
            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                    {data.awa}
                </span>
            </div>
        </div>

        <div className="row mb-7">
            <label className='col-lg-4 fw-bold text-muted'>Integrated Reasoning</label>
            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                    {data.integratedReasoning}
                </span>
            </div>
        </div>
    </div>
}