import Divider from '@material-ui/core/Divider';
import { FC } from 'react';
import { IDiplomaPlusBachelorDegreeDetail } from '../../../student/models';
import { BachelorGradesView } from './BachelorGradesView';
import { DiplomaGradesView } from './DiplomaGradesView';

type Props = {
    data: IDiplomaPlusBachelorDegreeDetail,
}

export const DiplomaPlusBachelorGradesView: FC<Props> = ({ data }) => {
    return <>
        <div className="row fv-row mb-5">
            <div className='col-xl-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Diploma cum Bachelor Degree Grades</label>
            </div>
            <Divider variant='middle'  className='m-2 bg-danger' />
        </div>
        <DiplomaGradesView data={data.diplomaDegreeScore} />
        <BachelorGradesView data={data.bachelorDegreeScore} />
    </>
}