/* eslint-disable no-loop-func */
import moment from 'moment';
import { FC, useState, useEffect } from 'react';
import { Card, Spinner, Modal, Form, Button } from 'react-bootstrap-v5';
import { BulkFileUploader } from '../../modules/common/components/FileUploader';
import { IBanner } from '../../modules/carousel/models/IBanner';
import { IUpdateBannerCommand, IUploadBannerCommand } from './models/IUploadBannerCommand';
import { deleteBanner, getBanners, updateBanner, uploadBanners } from './BannerService';
import { toast } from 'react-toastify';
import { GenericTooltip } from '../../modules/common/components/TooltipInfo';

export const ManageBanners: FC = () => {
    const [banners, setBanners] = useState<Array<IBanner>>([]);
    const [filesToUpload, setFilesToUpload] = useState<Array<IUploadBannerCommand>>([]);
    const [base64File, setFile] = useState<IUploadBannerCommand>();
    const [loading, setLoading] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        if (base64File) setFilesToUpload([...filesToUpload, base64File]);
        // eslint-disable-next-line
    }, [base64File])

    useEffect(() => {
        //get banners from server
        function fetchData() {
            getBanners().then(resp => setBanners(resp.data));
        }
        fetchData();
    }, [refreshKey]);

    const performUpload = () => {
        if (filesToUpload?.length > 0) {
            setLoading(true);
            uploadBanners(filesToUpload)
                .then(resp => {
                    setRefreshKey(Math.random());
                    toast('Banners uploaded successfully', { type: 'success' });
                })
                .finally(() => setLoading(false));
        }
    }

    const onUpload = (files: any) => {
        let numberOfFiles = files.length;
        for (let index = 0; index < files.length; index++) {
            const reader = new FileReader();
            const file = files[index];
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64 = reader.result?.toString().split(";base64,")[1] as string;
                setFile({ base64Banner: base64, bannerFileName: file.name });
            };
            reader.onerror = (error) => {
                //notify TODO
                console.log('Error: ', error);
            };
            reader.onloadend = (e) => {
                --numberOfFiles;
                if (numberOfFiles === 0) {
                    setTimeout(() => { performUpload(); }, 500);
                }
            }
        }
    }

    const onDelete = (id: number) => {
        setLoading(true)
        deleteBanner(id)
            .then(resp => {
                setRefreshKey(Math.random());
                toast('Banner deleted successfully', { type: 'success' })
            })
            .finally(() => setLoading(false));
    }

    const performUpdate = (id: number, req: IUpdateBannerCommand) => {
        setLoading(true);
        updateBanner(id, req)
            .then(resp => {
                setRefreshKey(Math.random());
                toast('Banner updated successfully', { type: 'success' })
            })
            .finally(() => setLoading(false));
    }

    return <Card>
        <Card.Header>
            <Card.Title>Banners</Card.Title>
        </Card.Header>
        <Card.Body>
            <BulkFileUploader onUpload={onUpload} allowedFileTypes={['image/*']} />
            {loading && <Spinner variant='primary' animation='grow' />}
            {<ListBanners banners={banners} onDelete={onDelete} performUpdate={performUpdate} />}
        </Card.Body>
    </Card>;
}

type ListProps = { banners: IBanner[], onDelete: (id: number) => void, performUpdate: (id: number, req: IUpdateBannerCommand) => void };

const ListBanners: FC<ListProps> = ({ banners, onDelete, performUpdate }) => {
    const [showModal, setShowModal] = useState(false);
    const [banner, setBanner] = useState<IBanner>();
    const onUpdate = (id: number) => {
        setBanner(banners.find(x => x.id === id));
        setShowModal(!showModal)
    }

    return <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
        {banners.map((banner, idx) => <div className='col-12 col-sm-12 col-xl-12' key={idx}>
            <Card className="bg-dark text-white">
                <Card.Img src={banner.bannerUri} alt="Banner image" />
                <Card.ImgOverlay>
                    {onUpdate && <button className='btn btn-sm btn-icon' onClick={() => onUpdate(banner.id)}>
                        <GenericTooltip childEle={<i className='bi bi-link text-primary'></i>} message='Update link?' />
                    </button>}
                    {onDelete && <button className='btn btn-sm btn-icon' onClick={() => onDelete(banner.id)}>
                        <GenericTooltip childEle={<i className='bi bi-trash text-danger'></i>} message='Delete this banner?' />
                    </button>}
                    <Card.Text><span className='badge badge-success'>Uploaded {moment(banner.createdOn).local().fromNow()}</span></Card.Text>
                </Card.ImgOverlay>
            </Card>
        </div>)}
        {
            showModal && banner && <UpdateLinkModal req={{ id: banner.id, url: banner.url }} onClose={(req) => {
                if (req) { performUpdate(banner.id, req); }
                setShowModal(false);
            }} />
        }
    </div>
};


const UpdateLinkModal: FC<{ req: IUpdateBannerCommand, onClose: (req?: IUpdateBannerCommand) => void }> = ({ req, onClose }) => {
    const [link, setLink] = useState<string | undefined>(req.url);

    return (
        <>
            <Modal centered show={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Link</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Link</Form.Label>
                            <Form.Control key={req.id}
                                type="text"
                                placeholder="https://imfs.co.in/register"
                                autoFocus
                                value={link}
                                onChange={(ev) => { setLink(ev.target.value) }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onClose()}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => onClose({ ...req, url: link })}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
