/* eslint-disable react-hooks/exhaustive-deps, @typescript-eslint/no-unused-vars*/
import clsx from 'clsx';
import { Form, Formik, setNestedObjectValues } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap-v5';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Notice from '../../../modules/common/components/Notice';
import { QuestionnaireTypeId } from '../../../modules/common/enums/QuestionnaireTypeId';
import { IProfileState, IQuestionAnswerResponseDto, IQuestionnaireDetailResponseDto } from '../models';
import { getQuestionsForLor, getSopDetails, updateSopDetails } from '../services/LorService';
import { getConfig } from '../services/StudentProfileService';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { PageTitle } from '../../../../theme/layout/core';
import { useSharedState } from '../../../modules/common/SharedStateProvider';
import { WordCounter } from '../../../modules/common/components/WordCounter';

type Props = {
    profileId: string
}

const validationSchema = Yup.object().shape({
    answers: Yup.array().of(Yup.object().shape({
        answer: Yup.string().required(),
        wordCount: Yup.number().required().max(300)
    }))
});

const SopQuestionnairePage: FC<RouteComponentProps<Props>> = ({ history, match: { params: { profileId } } }) => {
    const [requiresSop, setRequiresSop] = useState<boolean>();
    const [sopDetail, setSopDetail] = useState<IQuestionnaireDetailResponseDto>();
    const [loading, setLoading] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [profileState, setProfileState] = useState<IProfileState>();
    const [hasPendingPayment, setHasPendingPayment] = useState<boolean>(false);
    const sharedState = useSharedState();

    useEffect(() => {
        function fetchData() {
            setLoading(true);
            getConfig(+profileId)
                .then(resp => {
                    setRequiresSop(resp.data.requiresSop);
                    setProfileState(resp.data.profileState);
                    if (resp.data.overridePaymentRule) {
                        setHasPendingPayment(false);
                    } else if (resp.data.balanceFees) {
                        setHasPendingPayment(true);
                    }
                })
                .catch(err => setRequiresSop(false))
                .finally(() => setLoading(false));
        }
        fetchData();
    }, [profileId])

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const { data: currentSopDetail } = await getSopDetails(+profileId);
                //also get questions
                if (currentSopDetail) {
                    setSopDetail({
                        ...currentSopDetail,
                        answers: currentSopDetail.answers.map((q, i) => ({
                            questionId: q.questionId,
                            questionText: q.questionText,
                            answer: q.answer,
                            editable: i !== 1,
                            id: q.id
                        })) as IQuestionAnswerResponseDto[]
                    })
                } else {
                    await handleReset();
                }
            } finally {
                setLoading(false);
            }
        }

        requiresSop && fetchData();
    }, [requiresSop, refreshKey]);

    const handleReset = async () => {
        const { data: questions } = await getQuestionsForLor(QuestionnaireTypeId.Sop, +profileId);
        setSopDetail({
            id: 0,
            profileId: +profileId,
            questionnaireTypeId: QuestionnaireTypeId.Sop,
            answers: questions.map(q => ({
                questionId: q.id,
                questionText: q.questionText,
                answer: q.defaultAnswer,
                editable: !q.defaultAnswer?.trim()?.length,
                id: undefined
            })) as IQuestionAnswerResponseDto[]
        })
    }

    const handleSubmit = (profileId: string, values: IQuestionnaireDetailResponseDto) => {
        setLoading(true);
        const updatedSop = {
            sopDetailsId: sopDetail?.id,
            profileId: +profileId,
            questionnaireTypeId: QuestionnaireTypeId.Sop,
            questionsWithAnswer: values.answers.map(a => {
                return {
                    id: a.id,
                    answer: a.answer,
                    questionId: a.questionId
                };
            })
        };
        updateSopDetails(+profileId, updatedSop)
            .then(() => {
                toast('Changes saved successfully', { type: 'success' });
                setRefreshKey(Math.random())
            })
            .finally(() => setLoading(false));
    }
    if (!loading && profileState && !profileState.isApproved) {
        return <Notice message='This module is not yet active as your profile is not approved.' />
    }

    // if (!loading && hasPendingPayment) {
    //     return <Notice message='This module is not yet active due to pending payment.' />
    // }

    return <>
        <PageTitle description='Manage SOP Questionnaires' breadcrumbs={[]}>SOP Questionnaire</PageTitle>
        {
            !loading && !profileId ? <Notice message='Profile not found' /> :
                <div className='card mb-5 mb-xl-10'>
                    <div className="card-header">
                        <h3 className='card-title mb-2'>
                            Questionnaire for Statement of Purpose
                        </h3>
                        <Button className='btn btn-sm btn-outline' variant='outline'>
                            <Link to={`/student/${profileId}/sop`}>
                                <i className='bi bi-view-list'></i> Preview
                            </Link>
                        </Button>
                    </div>
                    <div className='card-body pt-9 pb-0'>
                        <div className='row mb-10'>
                            {!requiresSop && <div className='col-xl-6'>
                                <Notice message='Profile does not require SOP.' />
                            </div>
                            }
                            {loading && <Spinner variant='primary' animation='grow' />}

                            {sharedState.isStudent && profileState && profileState.isSopQuestionnaireApproved && <>
                                <div className='col-xl-12'>
                                    <Notice message='SOP has been approved.' />
                                </div>
                            </>}

                            {requiresSop && !loading && profileState &&
                                <div>
                                    <SOPInstructions />
                                    {sopDetail &&
                                        <div className='d-flex flex-row-fluid flex-center bg-white rounded' key={`selectedLor_${sopDetail.id}`}>
                                            <Formik initialValues={sopDetail} onSubmit={(values) => {
                                                handleSubmit(profileId, values);
                                            }} validationSchema={validationSchema}>
                                                {({ ...formik }) => (
                                                    <Form>
                                                        {/* Component */}
                                                        {sopDetail &&
                                                            <>
                                                                {
                                                                    sopDetail.answers.map((x, idx) => (
                                                                        <div key={`lor_${sopDetail.id}_${idx}`} className='fv-row mb-10 row'>
                                                                            <label className='form-label fw-bolder text-dark fs-6 required row'>
                                                                                <span className='badge badge-light-primary' style={{ width: "20px" }}>{idx + 1}</span>
                                                                                <span className='col-11' dangerouslySetInnerHTML={{ __html: x.questionText }}></span>
                                                                            </label>
                                                                            <div className="col-xl-10">
                                                                                <textarea
                                                                                    placeholder='Answer'
                                                                                    autoComplete='off'
                                                                                    rows={10}
                                                                                    disabled={!x.editable}
                                                                                    {...formik.getFieldProps(`answers[${idx}].answer`)}
                                                                                    className={clsx(
                                                                                        'form-control form-control-sm ',
                                                                                        {
                                                                                            'is-invalid': formik.getFieldMeta(`answers[${idx}].answer`).touched && formik.getFieldMeta(`answers[${idx}].answer`).error,
                                                                                        },
                                                                                        {
                                                                                            'is-valid': formik.getFieldMeta(`answers[${idx}].answer`).touched && !formik.getFieldMeta(`answers[${idx}].answer`).error,
                                                                                        }
                                                                                    )} />
                                                                                {formik.getFieldMeta(`answers[${idx}].answer`).touched && formik.getFieldMeta(`answers[${idx}].answer`).error && (
                                                                                    <div className='fv-plugins-message-container'>
                                                                                        <div className='fv-help-block text-danger mt-2'>
                                                                                            <span role='alert'>Answer is Required</span>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                <WordCounter input={formik.values.answers[idx].answer} limit={300} onChange={(val) => {
                                                                                    formik.setFieldValue(`answers[${idx}].wordCount`, val);
                                                                                }} />
                                                                                {formik.values.answers[idx]?.wordCount && formik.values.answers[idx]?.wordCount > 300 && (
                                                                                    <div className='fv-plugins-message-container'>
                                                                                        <div className='fv-help-block text-danger mt-2'>
                                                                                            <span role='alert'>Answer should not exceed 300 words</span>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </>
                                                        }
                                                        <div className='d-flex pt-10'>
                                                            <div className='m-2'>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-md btn-primary w-100 mb-5'
                                                                    onClick={async () => {
                                                                        const validationErrors = await formik.validateForm()
                                                                        if (Object.keys(validationErrors).length > 0) {
                                                                            formik.setTouched(setNestedObjectValues(validationErrors, true));
                                                                            alert("Please fix validation errors")
                                                                            return;
                                                                        } else {
                                                                            formik.submitForm();
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="bi bi-box-arrow-down"></i>
                                                                    {!loading && 'Save'}

                                                                    {loading && (
                                                                        <span className='indicator-progress' style={{ display: 'inline-block' }}>
                                                                            Please wait...{' '}
                                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                        </span>
                                                                    )}

                                                                </button>
                                                            </div>
                                                            <div className='m-2'>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-md btn-secondary me-3'
                                                                    disabled={profileState.isSopQuestionnaireSubmitted}
                                                                    onClick={() => {
                                                                        history.push(`/student/${profileId}/sop/submit`);
                                                                    }}
                                                                >
                                                                    <i className="bi bi-hand-thumbs-up"></i>

                                                                    Preview and Submit
                                                                    {profileState.isSopQuestionnaireSubmitted && <p className='text-danger mt-2'>You have already submitted your SOP.</p>}
                                                                </button>
                                                            </div>
                                                            <div className='m-2'>
                                                                <button
                                                                    onClick={handleReset}
                                                                    type='button'
                                                                    className='btn btn-md btn-danger me-3'
                                                                >
                                                                    <i className="bi bi-arrow-clockwise"></i>
                                                                    {!loading && 'Reset'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Form>)}
                                            </Formik>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>}
    </>;
}

const SOPInstructions: FC = () => {
    return <div className="col-xl-12 row">
        <p><strong>Directions</strong>:</p>
        <ol className='m-5 mt-0'>
            <li>Please answer all questions in ONE paragraph each.</li>
            <li>Please do NOT give points in bullet form</li>
            <li>Write the answers in a paragraph form</li>
            <li>Please use grammatically correct English</li>
            <li>Please do not commit spelling errors</li>
            <li>Please do NOT use short forms or short cuts, use full forms of technical words/ subjects</li>
            <li>&nbsp;Please be as detailed as possible</li>
            <li>The more information you share, the better the SOP will turn out</li>
        </ol>
        <p><strong>Happy drafting!</strong></p>
    </div>
}
export default withRouter(SopQuestionnairePage);