export enum FileUploadType {
    Unknown = 0,
    PaymentReceipt,
    ProfilePicture,
    PassportFront,
    PassportBack,
    StudentLOR,
    StudentSOP,
    OtherResources,
    Banners,
    MessageAttachment,
    Report,
    Common
}