import { FormControlLabel, Radio } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import { createRef, FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../../theme/helpers';
import { addProfileComment, getTimeline } from '../../../pages/student/services/StudentProfileService';
import { ProfileStatus } from '../enums/ProfileStatus';
import { useSharedState } from '../SharedStateProvider';
import { ConfirmationModal } from './ConfirmationModal';
import { GenericTooltip } from './TooltipInfo';
import { DATE_TIME_FORMAT_DD_MMM_YYYY } from '../Helper';

export interface TimelineItemDto {
    event: ProfileStatus;
    eventDescription: string;
    eventDate?: string;
    createdBy?: string;
}

export const ProfileTimeline: FC<{ profileId: number }> = ({ profileId }) => {
    const [timelineItems, setTimeline] = useState<Array<any>>([]);
    const [viewOption, setViewOption] = useState<string>('table');
    const { isStudent } = useSharedState();

    useEffect(() => {
        function fetchData() {
            getTimeline(profileId).then(({ data }) => {
                const items: Array<any> = [];
                (data.items as Array<TimelineItemDto>).forEach(x => items[x.event] = x);
                const comments = data.profileComments.map((x: any) => ({
                    eventDate: x.commentDate,
                    eventDescription: x.comment,
                    createdBy: x.commentorName
                })) || [];
                setTimeline([...items, ...comments].filter(x => x));
            })
        }
        fetchData();
    }, [profileId])

    return <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Timeline & Activity</h3>
            </div>
        </div>
        <div className='card-body p-9'>
            <div className="d-flex">
                <FormControlLabel
                    label='Table'
                    control={<Radio checked={viewOption === 'table'}
                        onChange={(ev) => setViewOption('table')} />} />
                <FormControlLabel
                    label='Chart'
                    control={<Radio checked={viewOption === 'chart'}
                        onChange={(ev) => setViewOption('chart')} />} />
                {!isStudent && <AddCommentPanel profileId={profileId} />}
            </div>
            {viewOption === 'chart' && <ChartTimeline timelineItems={timelineItems} />}
            {viewOption === 'table' && <TableTimeline timelineItems={timelineItems} />}
        </div>
    </div>
}

const ChartTimeline: FC<{ timelineItems: Array<any> }> = ({ timelineItems }) => {
    return <div className="profile-timeline row bg-white border-gray-300 border-dashed rounded">
        <div id="wrapper" className='m-5'>
            <span className={clsx(`label`, {
                'bg-light-success': timelineItems[ProfileStatus.Unsigned]?.eventDate
            })}>
                {timelineItems[ProfileStatus.Unsigned]?.eventDate &&
                    <GenericTooltip message={`${moment(timelineItems[ProfileStatus.Unsigned]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}`}
                        childEle={<span><KTSVG
                            path='/media/icons/duotune/general/gen026.svg'
                            className='svg-icon-1 svg-icon-primary'
                        /></span>} />}
                Account Created
            </span>

            <div className="branch lv1">
                <div className="entry sole">
                    <span className={clsx(`label`, {
                        'bg-light-success': timelineItems[ProfileStatus.AccountVerified]?.eventDate
                    })}>
                        {timelineItems[ProfileStatus.AccountVerified]?.eventDate
                            && <GenericTooltip message={moment(timelineItems[ProfileStatus.AccountVerified]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                                childEle={<span><KTSVG
                                    path='/media/icons/duotune/general/gen026.svg'
                                    className='svg-icon-1 svg-icon-primary'
                                /></span>} />}
                        Email Verified
                    </span>
                    <div className="branch lv2">
                        <div className="entry sole">
                            <span className={clsx(`label`, {
                                'bg-light-success': timelineItems[ProfileStatus.ProfileSubmitted]?.eventDate
                            })}>
                                {timelineItems[ProfileStatus.ProfileSubmitted]?.eventDate
                                    && <GenericTooltip message={moment(timelineItems[ProfileStatus.ProfileSubmitted]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                                        childEle={<span>
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen026.svg'
                                                className='svg-icon-1 svg-icon-primary'
                                            />
                                        </span>} />}
                                Profile Submitted
                            </span>
                            <div className="branch lv2">
                                <div className="entry sole">
                                    <span className={clsx(`label`, {
                                        'bg-light-success': timelineItems[ProfileStatus.ProfileApproved]?.eventDate
                                    })}>
                                        {timelineItems[ProfileStatus.ProfileApproved]?.eventDate
                                            && <GenericTooltip message={moment(timelineItems[ProfileStatus.ProfileApproved]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                                                childEle={<span>
                                                    <KTSVG
                                                        path='/media/icons/duotune/general/gen026.svg'
                                                        className='svg-icon-1 svg-icon-primary'
                                                    />
                                                </span>} />}
                                        Profile Approved
                                    </span>
                                    <div className="branch lv3">
                                        <div className="entry">
                                            <span className={clsx(`label`, {
                                                'bg-light-success': timelineItems[ProfileStatus.LorQuestionnaireSubmitted]?.eventDate
                                            })}>
                                                {timelineItems[ProfileStatus.LorQuestionnaireSubmitted]?.eventDate
                                                    && <GenericTooltip message={moment(timelineItems[ProfileStatus.LorQuestionnaireSubmitted]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                                                        childEle={<span>
                                                            <KTSVG
                                                                path='/media/icons/duotune/general/gen026.svg'
                                                                className='svg-icon-1 svg-icon-primary'
                                                            />
                                                        </span>} />}
                                                LOR Submitted
                                            </span>
                                            <div className="branch lv3">
                                                <div className="entry sole">
                                                    <span className={clsx(`label`, {
                                                        'bg-light-success': timelineItems[ProfileStatus.LorQuestionnaireApproved]?.eventDate
                                                    })}>
                                                        {timelineItems[ProfileStatus.LorQuestionnaireApproved]?.eventDate
                                                            && <GenericTooltip message={moment(timelineItems[ProfileStatus.LorQuestionnaireApproved]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                                                                childEle={<span>
                                                                    <KTSVG
                                                                        path='/media/icons/duotune/general/gen026.svg'
                                                                        className='svg-icon-1 svg-icon-primary'
                                                                    />
                                                                </span>} />}
                                                        LOR Approved
                                                    </span>
                                                    <div className="branch lv3">
                                                        <div className="entry sole">
                                                            <span className={clsx(`label`, {
                                                                'bg-light-success': timelineItems[ProfileStatus.LorSent]?.eventDate
                                                            })}>
                                                                {timelineItems[ProfileStatus.LorSent]?.eventDate
                                                                    && <GenericTooltip message={moment(timelineItems[ProfileStatus.LorSent]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                                                                        childEle={<span>
                                                                            <KTSVG
                                                                                path='/media/icons/duotune/general/gen026.svg'
                                                                                className='svg-icon-1 svg-icon-primary'
                                                                            />
                                                                        </span>} />}
                                                                LOR Sent
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="entry">
                                            <span className={clsx(`label`, {
                                                'bg-light-success': timelineItems[ProfileStatus.ScoresUpdated]?.eventDate
                                            })}>
                                                {timelineItems[ProfileStatus.ScoresUpdated]?.eventDate
                                                    && <GenericTooltip message={moment(timelineItems[ProfileStatus.ScoresUpdated]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                                                        childEle={<span>
                                                            <KTSVG
                                                                path='/media/icons/duotune/general/gen026.svg'
                                                                className='svg-icon-1 svg-icon-primary'
                                                            />
                                                        </span>} />}
                                                Exam Scores
                                            </span>
                                            <div className="branch">
                                                <div className="entry sole">
                                                    <span className={clsx(`label`, {
                                                        'bg-light-success': timelineItems[ProfileStatus.UniversitiesSuggested]?.eventDate
                                                    })}>
                                                        {timelineItems[ProfileStatus.UniversitiesSuggested]?.eventDate
                                                            && <GenericTooltip message={moment(timelineItems[ProfileStatus.UniversitiesSuggested]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                                                                childEle={<span>
                                                                    <KTSVG
                                                                        path='/media/icons/duotune/general/gen026.svg'
                                                                        className='svg-icon-1 svg-icon-primary'
                                                                    />
                                                                </span>} />}
                                                        Suggestions
                                                    </span>
                                                    <div className="branch">
                                                        <div className="entry sole">
                                                            <span className={clsx(`label`, {
                                                                'bg-light-success': timelineItems[ProfileStatus.AwaitingAdmit]?.eventDate
                                                            })}>
                                                                {timelineItems[ProfileStatus.AwaitingAdmit]?.eventDate
                                                                    && <GenericTooltip message={moment(timelineItems[ProfileStatus.AwaitingAdmit]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                                                                        childEle={<span>
                                                                            <KTSVG
                                                                                path='/media/icons/duotune/general/gen026.svg'
                                                                                className='svg-icon-1 svg-icon-primary'
                                                                            />
                                                                        </span>} />}
                                                                Acceptance
                                                            </span>
                                                            <div className="branch">
                                                                <div className="entry sole">
                                                                    <span className={clsx(`label`, {
                                                                        'bg-light-success': timelineItems[ProfileStatus.Finalized]?.eventDate
                                                                    })}>
                                                                        {timelineItems[ProfileStatus.Finalized]?.eventDate
                                                                            && <GenericTooltip message={moment(timelineItems[ProfileStatus.Finalized]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                                                                                childEle={<span>
                                                                                    <KTSVG
                                                                                        path='/media/icons/duotune/general/gen026.svg'
                                                                                        className='svg-icon-1 svg-icon-primary'
                                                                                    />
                                                                                </span>} />}
                                                                        Admission
                                                                    </span>
                                                                    <div className="branch">
                                                                        <div className="entry sole">
                                                                            <span className={clsx(`label`, {
                                                                                'bg-light-success': timelineItems[ProfileStatus.Finalized]?.eventDate
                                                                            })}>
                                                                                {timelineItems[ProfileStatus.Finalized]?.eventDate
                                                                                    && <GenericTooltip message={moment(timelineItems[ProfileStatus.Finalized]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                                                                                        childEle={<span>
                                                                                            <KTSVG
                                                                                                path='/media/icons/duotune/general/gen026.svg'
                                                                                                className='svg-icon-1 svg-icon-primary'
                                                                                            />
                                                                                        </span>} />}
                                                                                Visa
                                                                            </span>
                                                                            <div className="branch">
                                                                                <div className="entry sole">
                                                                                    <span className={clsx(`label`, {
                                                                                        'bg-light-success': timelineItems[ProfileStatus.Finalized]?.eventDate
                                                                                    })}>
                                                                                        {timelineItems[ProfileStatus.Finalized]?.eventDate
                                                                                            && <GenericTooltip message={moment(timelineItems[ProfileStatus.Finalized]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                                                                                                childEle={<span>
                                                                                                    <KTSVG
                                                                                                        path='/media/icons/duotune/general/gen026.svg'
                                                                                                        className='svg-icon-1 svg-icon-primary'
                                                                                                    />
                                                                                                </span>} />}
                                                                                        Complete
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="entry">
                                            <span className={clsx(`label`, {
                                                'bg-light-success': timelineItems[ProfileStatus.SopQuestionnaireSubmitted]?.eventDate
                                            })}>
                                                {timelineItems[ProfileStatus.SopQuestionnaireSubmitted]?.eventDate
                                                    && <GenericTooltip message={moment(timelineItems[ProfileStatus.SopQuestionnaireSubmitted]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                                                        childEle={<span>
                                                            <KTSVG
                                                                path='/media/icons/duotune/general/gen026.svg'
                                                                className='svg-icon-1 svg-icon-primary'
                                                            />
                                                        </span>} />}
                                                SOP Submitted
                                            </span>
                                            <div className="branch lv3">
                                                <div className="entry sole">
                                                    <span className={clsx(`label`, {
                                                        'bg-light-success': timelineItems[ProfileStatus.SopQuestionnaireApproved]?.eventDate
                                                    })}>
                                                        {timelineItems[ProfileStatus.SopQuestionnaireApproved]?.eventDate
                                                            && <GenericTooltip message={moment(timelineItems[ProfileStatus.SopQuestionnaireApproved]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                                                                childEle={<span>
                                                                    <KTSVG
                                                                        path='/media/icons/duotune/general/gen026.svg'
                                                                        className='svg-icon-1 svg-icon-primary'
                                                                    />
                                                                </span>} />}
                                                        SOP Approved
                                                    </span>
                                                    <div className="branch lv3">
                                                        <div className="entry sole">
                                                            <span className={clsx(`label`, {
                                                                'bg-light-success': timelineItems[ProfileStatus.SopSent]?.eventDate
                                                            })}>
                                                                {timelineItems[ProfileStatus.SopSent]?.eventDate
                                                                    && <GenericTooltip message={moment(timelineItems[ProfileStatus.SopSent]?.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                                                                        childEle={<span>
                                                                            <KTSVG
                                                                                path='/media/icons/duotune/general/gen026.svg'
                                                                                className='svg-icon-1 svg-icon-primary'
                                                                            />
                                                                        </span>} />}
                                                                SOP Sent
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const TableTimeline: FC<{ timelineItems: Array<TimelineItemDto> }> = ({ timelineItems }) => {
    if (!timelineItems?.length) {
        return null;
    }
    const items = timelineItems.filter(x => x.eventDate).sort((x, y) => moment(y.eventDate).isBefore(x.eventDate) ? -1 : 1)
    return <table className='table table-striped table-borderless'>
        <thead>
            <tr>
                <td>Event</td>
                <td>Date & Time</td>
                <td>Actioned By</td>
            </tr>
        </thead>
        <tbody>
            {items.map(row => <tr>
                <td>{row.eventDescription}</td>
                <td>{moment(row.eventDate).local().format(DATE_TIME_FORMAT_DD_MMM_YYYY)}</td>
                <td>{row.createdBy || "NA"}</td>
            </tr>)}
        </tbody>
    </table>
}

export const AddCommentPanel: FC<{ profileId: number, onComplete?: (comment: string) => void, onReject?: () => void }> = ({ profileId, onComplete, onReject }) => {
    const [showCommentPopup, setShowCommentPopup] = useState(false);
    const inputRef = createRef<HTMLTextAreaElement>();

    const handleSave = () => {
        if (inputRef?.current?.value) {
            const comment = inputRef.current.value;
            addProfileComment(profileId, inputRef.current.value)
                .then(() => {
                    toast('Comment saved successfully', { type: 'success' });
                    onComplete && onComplete(comment);
                })
        }
    }

    return <>
        <div className="row">
            {/* eslint-disable-next-line */}
            <a style={{ alignSelf: 'center' }} href='#' onClick={() => setShowCommentPopup(!showCommentPopup)}>Add comment</a>
            {showCommentPopup && <ConfirmationModal title='Add new comment' onConfirm={() => {
                handleSave();
                setShowCommentPopup(false);
            }}
                onCancel={() => {
                    setShowCommentPopup(false);
                    onReject && onReject();
                }}
            >
                <textarea ref={inputRef} className='form-control' />
            </ConfirmationModal>}
        </div>
    </>
} 