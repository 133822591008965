/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { AllOAStudents, AllStudentsWithUniversitySuggestion, StudentsPendingUniversitySuggestion } from "./components/StudentsPendingUniversitySuggestion";
import { useHistory } from "react-router-dom";
import { enumToKeyValue } from "../../modules/common/EnumHelper";

enum DashboardTabs {
    PendingSuggestions,
    ChangeRequests,
    AllSuggested,
    AllStudents,
}

export const OverallAdminDashboard: FC = () => {
    const [tab, setTab] = useState<DashboardTabs>(DashboardTabs.PendingSuggestions);
    const [tabCounts, setTabCounts] = useState<Array<number>>([]);
    const history = useHistory();
    const tabNames = enumToKeyValue(DashboardTabs);

    useEffect(() => {
        if (!history.location.hash) return;
        setTab(+tabNames.find(x => `#${x.label}` === history.location.hash)?.value);
        //eslint-disable-next-line
    }, [history.location.hash]);

    return <>
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item cursor-pointer'>
                            <a className={`nav-link text-active-primary me-6 ${tab === DashboardTabs.PendingSuggestions ? 'active' : ''}`} onClick={() => {
                                history.push({ hash: tabNames.find(x => x.value === DashboardTabs.PendingSuggestions.toString())?.label })
                            }}>
                                Pending University Suggestions
                                <span className="badge badge-light-primary m-2">{tabCounts[DashboardTabs.PendingSuggestions]}</span>
                            </a>
                        </li>
                        <li className='nav-item cursor-pointer'>
                            <a className={`nav-link text-active-primary me-6 ${tab === DashboardTabs.ChangeRequests ? 'active' : ''}`} onClick={() => {
                                history.push({ hash: tabNames.find(x => x.value === DashboardTabs.ChangeRequests.toString())?.label })
                            }}>
                                Change Requests
                                <span className="badge badge-light-primary m-2">{tabCounts[DashboardTabs.ChangeRequests]}</span>
                            </a>
                        </li>
                        <li className='nav-item cursor-pointer'>
                            <a className={`nav-link text-active-primary me-6 ${tab === DashboardTabs.AllSuggested ? 'active' : ''}`} onClick={() => {
                                history.push({ hash: tabNames.find(x => x.value === DashboardTabs.AllSuggested.toString())?.label })
                            }}>
                                All Suggested
                                <span className="badge badge-light-primary m-2">{tabCounts[DashboardTabs.AllSuggested]}</span>
                            </a>
                        </li>
                        <li className='nav-item cursor-pointer'>
                            <a className={`nav-link text-active-primary me-6 ${tab === DashboardTabs.AllStudents ? 'active' : ''}`} onClick={() => {
                                history.push({ hash: tabNames.find(x => x.value === DashboardTabs.AllStudents.toString())?.label })
                            }}>
                                All
                                <span className="badge badge-light-primary m-2">{tabCounts[DashboardTabs.AllStudents]}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className={`${tab !== DashboardTabs.PendingSuggestions ? 'd-none' : ''}`}>
            <StudentsPendingUniversitySuggestion onlyIncludeChangeRequests={false} onLoad={(count: number) => {
                const counts = tabCounts;
                counts[DashboardTabs.PendingSuggestions] = count;
                setTabCounts([...counts]);
            }} />
        </div>
        <div className={`${tab !== DashboardTabs.ChangeRequests ? 'd-none' : ''}`}>
            <StudentsPendingUniversitySuggestion onlyIncludeChangeRequests={true} onLoad={(count: number) => {
                const counts = tabCounts;
                counts[DashboardTabs.ChangeRequests] = count
                setTabCounts([...counts]);
            }} />
        </div>
        <div className={`${tab !== DashboardTabs.AllSuggested ? 'd-none' : ''}`}>
            <AllStudentsWithUniversitySuggestion includeFinalized={false} onLoad={(count: number) => {
                const counts = tabCounts;
                counts[DashboardTabs.AllSuggested] = count
                setTabCounts([...counts]);
            }} />
        </div>
        <div className={`${tab !== DashboardTabs.AllStudents ? 'd-none' : ''}`}>
            <AllOAStudents onLoad={(count: number) => {
                const counts = tabCounts;
                counts[DashboardTabs.AllStudents] = count;
                setTabCounts([...counts]);
            }} />
        </div>
    </>
}