export enum Branch {
  Unknown = 0,
  Dadar = 1,
  Thane,
  Borivali,
  Andheri,
  Vashi,
  Pune,
  Manipal,
  Hyderabad,
  Delhi,
  Vadodara,
  Warangal,
  Churchgate,
  Nellore,
  Tirupati,
  Hyderabad_LB_Nagar,
  Hyderabad_Madhapur,
  Pimpri,
}

export enum BranchStats {
  Dadar = 1,
  Thane,
  Borivali,
  Vashi,
  Pune,
  Manipal,
  Churchgate,
}
