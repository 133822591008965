import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import tableIcons from "../../../modules/common/components/MaterialTableIcons";
import StudentList from "../../student-profiles/components/StudentList";
import { getStudentsPendingSOPPaginated } from "../../student-profiles/services/StudentManager";
import { StudentProfile } from "../../student/models";
import { delegateSop, uploadSop } from "../../student/services/LorService";
import { AssignDocumentDialog } from "../_modals/AssignDocumentDialog";
import { UploadDocumentDialog } from "../_modals/UploadDocumentDialog";

export const StudentsPendingSop: FC<{ onLoad?: (count: number) => void }> = ({ onLoad }) => {
  const history = useHistory();
  const [uploadDialogShown, setUploadDialogShown] = useState<boolean>(false);
  const [assignDialogShown, setAssignDialogShown] = useState<boolean>(false);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [selectedStudent, setSelected] = useState<number>(0);

  return <>
    <UploadDocumentDialog visible={uploadDialogShown} setVisibility={(state) => {
      setUploadDialogShown(state);
      setSelected(0);
    }} onSend={(req) => {
      return uploadSop(selectedStudent, req).then((res) => {
        setUploadDialogShown(false);
        setRefreshKey(Math.random());
        setSelected(0);
        return res;
      });
    }} />

    <AssignDocumentDialog visible={assignDialogShown} setVisibility={(state) => {
      setAssignDialogShown(state);
      setSelected(0);
    }} onSend={(assignedToEmail) => {
      return delegateSop(selectedStudent, assignedToEmail).then((res) => {
        setAssignDialogShown(false);
        setRefreshKey(Math.random());
        setSelected(0);
        return res;
      });
    }} />

    <StudentList
      key={refreshKey}
      title='Profiles Pending SOP'
      getStudentsFromServer={getStudentsPendingSOPPaginated}
      actions={[
        {
          icon: tableIcons.SOP as any,
          tooltip: 'View Questionnaire Response',
          onClick: (event: any, rowData: StudentProfile) => {
            history.push(`/student/${rowData.id}/sop`)
          },
        },
        {
          icon: tableIcons.Upload as any,
          tooltip: 'Upload SOP',
          onClick: (event: any, rowData: StudentProfile) => {
            setSelected(rowData.id);
            setUploadDialogShown(true);
          },
        },
        {
          icon: tableIcons.LastPage as any,
          tooltip: 'Assign SOP',
          onClick: (event: any, rowData: StudentProfile) => {
            setSelected(rowData.id);
            setAssignDialogShown(true);
          },
        },
      ]}
      disabledFilters={['statuses']}
      onLoad={onLoad}
    />
  </>
}

