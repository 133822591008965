import clsx from "clsx"
import { FC, useEffect } from "react"
import Select from "react-select";
import { languageExamOptions } from "../../../../../modules/common/DropdownOptions";
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import moment from "moment";
import { LanguageExam } from "../../../models/ExamModels";
import { IELTSScore } from "./ExamScoreComponents/IELTSScore";
import { TOEFLScore } from "./ExamScoreComponents/TOEFLScore";

type Props = {
    formik: any,
}

export const LanguageExamStep: FC<Props> = ({ formik }) => {
    useEffect(() => {
        if(!formik.values.languageExam?.isExamGiven){
            formik.setFieldValue('languageExam.toeflScore', null);
            formik.setFieldValue('languageExam.ieltsScore', null);
            return;
        }
        switch (formik.values.languageExam?.examType) {
            case LanguageExam.TOEFL:
                formik.setFieldValue('languageExam.toeflScore', { ...formik.values.languageExam?.toeflScore });
                formik.setFieldValue('languageExam.ieltsScore', null);
                break;
            case LanguageExam.IELTS:
                formik.setFieldValue('languageExam.ieltsScore', { ...formik.values.languageExam?.ieltsScore });
                formik.setFieldValue('languageExam.toeflScore', null);
                break;
            default:
                break;
        }
        //eslint-disable-next-line
    }, [formik.values.languageExam?.examType, formik.values.languageExam?.isExamGiven]);

    return <div className='w-100'>
        <div className='pb-10 pb-lg-15'>
            <h2 className='fw-bolder d-flex align-items-center text-dark'>
                Fill in details about language exam
            </h2>
        </div>
        {/* begin::Form group Firstname */}
        <div className='fv-row mb-10 row'>
            <div className="col-xl-6">
                <label className='form-label fw-bolder text-dark fs-6 required'>Exam Type</label>
                <Select
                    options={languageExamOptions}
                    value={languageExamOptions.find(x => x.value === formik.values.languageExam?.examType)}
                    onFocus={() => formik.setFieldTouched('languageExam.examType')}
                    onChange={(opt) => formik.setFieldValue('languageExam.examType', opt?.value)}
                    className={clsx(`form-control form-control-sm p-0 `, {
                        'is-valid': formik.touched.languageExam?.examType && !formik.errors.languageExam?.examType,
                        'is-invalid': formik.touched.languageExam?.examType && formik.errors.languageExam?.examType
                    })}
                />
                {formik.touched.languageExam?.examType && formik.errors.languageExam?.examType && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{formik.errors.languageExam?.examType}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className="col-xl-6 mt-7">
                <FormControlLabel
                    control={<Checkbox
                        checked={formik.values.languageExam?.isExamGiven}
                        onChange={(ev) => formik.setFieldValue('languageExam.isExamGiven', ev.target.checked)}
                    />}
                    label='Already given and have scores?'
                />
            </div>
        </div>

        <div className="fv-row row mb-10">
            <div className='col-xl-4'>
                <label className='form-label required'>Exam Date</label>
                <input
                    placeholder='Exam Date'
                    type='date'
                    autoComplete='off'
                    {...formik.getFieldProps(`languageExam.examDate`)}
                    className={clsx(
                        'form-control form-control-sm ',
                        {
                            'is-invalid': formik.getFieldMeta(`languageExam.examDate`).touched
                                && formik.getFieldMeta(`languageExam.examDate`).error,
                        },
                        {
                            'is-valid': formik.getFieldMeta(`languageExam.examDate`).touched
                                && !formik.getFieldMeta(`languageExam.examDate`).error,
                        }
                    )}
                    min="2010-01-01"
                    onChange={(ev) => {
                        formik.setFieldValue(`languageExam.examDate`, ev.target.valueAsDate);
                    }}
                    value={formik.values.languageExam?.examDate ? moment(formik.values.languageExam?.examDate).format('YYYY-MM-DD') : null}
                />
                {formik.getFieldMeta(`languageExam.examDate`).touched
                    && formik.getFieldMeta(`languageExam.examDate`).error && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger mt-2'>
                                <span role='alert'>{formik.getFieldMeta(`languageExam.examDate`).error}</span>
                            </div>
                        </div>
                    )}
            </div>
        </div>

        {formik.values.languageExam?.isExamGiven && <div className="fv-row row mb-10">
            {formik.values.languageExam?.examType === LanguageExam.IELTS && <IELTSScore formik={formik} fieldPath={"languageExam"} />}
            {formik.values.languageExam?.examType === LanguageExam.TOEFL && <TOEFLScore formik={formik} fieldPath={"languageExam"} />}
        </div>}
    </div>
}
