import { FC } from "react"
import { InputGroup } from "react-bootstrap-v5"
import clsx from 'clsx';
import { camelToSentenceCase } from '../../../../../../modules/common/EnumHelper';

type Props = {
    formik: any,
    fieldPath: string,
}

type TotalScoreProps = {
    total: number;
}

export const Score: FC<Props & { fieldName: string }> = ({ formik, fieldPath, fieldName }) => <>
    <InputGroup className="mb-3" size="sm">
        <InputGroup.Text className='required'>{camelToSentenceCase(fieldName)}</InputGroup.Text>
        <input {...formik.getFieldProps(`${fieldPath}.${fieldName}`)} placeholder="0" type={'number'}
            className={clsx('form-control form-control-sm', {
                'is-valid': formik.getFieldMeta(`${fieldPath}.${fieldName}`).touched && !formik.getFieldMeta(`${fieldPath}.${fieldName}`).error,
                'is-invalid': formik.getFieldMeta(`${fieldPath}.${fieldName}`).touched && formik.getFieldMeta(`${fieldPath}.${fieldName}`).error
            })}
        />
        {formik.getFieldMeta(`${fieldPath}.${fieldName}`).touched && formik.getFieldMeta(`${fieldPath}.${fieldName}`).error && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger mt-2'>
                    <span role='alert'>{formik.getFieldMeta(`${fieldPath}.${fieldName}`).error}</span>
                </div>
            </div>
        )}
    </InputGroup>
</>



export const VerbalScore: FC<Props> = ({ ...props }) => {
    return <Score {...props} fieldName="verbal" />
}

export const QuantitativeScore: FC<Props> = ({ ...props }) => {
    return <Score {...props} fieldName="quantitative" />
}
export const AWAScore: FC<Props> = ({ ...props }) => {
    return <Score {...props} fieldName="awa" />
}

export const IntegratedReasoningScore: FC<Props> = ({ ...props }) => {
    return <Score {...props} fieldName="integratedReasoning" />
}

export const TotalScore: FC<Props> = ({ ...props }) => {
    return <Score {...props} fieldName="total" />
}

export const ReadingScore: FC<Props> = ({ ...props }) => {
    return <Score {...props} fieldName="reading" />
}

export const ListeningScore: FC<Props> = ({ ...props }) => {
    return <Score {...props} fieldName="listening" />
}

export const WritingScore: FC<Props> = ({ ...props }) => {
    return <Score {...props} fieldName="writing" />
}

export const SpeakingScore: FC<Props> = ({ ...props }) => {
    return <Score {...props} fieldName="speaking" />
}

export const CriticalReadingScore: FC<Props> = ({ ...props }) => {
    return <Score {...props} fieldName="criticalReading" />
}

export const MathScore: FC<Props> = ({ ...props }) => {
    return <Score {...props} fieldName="math" />
}

export const EnglishScore: FC<Props> = ({ ...props }) => {
    return <Score {...props} fieldName="english" />
}

export const ScienceScore: FC<Props> = ({ ...props }) => {
    return <Score {...props} fieldName="science" />
}

export const ReadonlyTotalScore: FC<TotalScoreProps> = ({ total }) => {
    return <div className="row fv-row-mb-10">
        <div className="col-xl-4">
            <InputGroup className="mb-3" size="sm" aria-readonly="true">
                <InputGroup.Text>Total</InputGroup.Text>
                <input type={'number'} value={total} className='form-control' readOnly={true} />
            </InputGroup>
        </div>
    </div>
}