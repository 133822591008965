import { FC } from 'react'
import moment from 'moment';
import { UpdateStudentProfileRequest } from '../../models/UpdateStudentProfileRequest';
import { StudentProfile } from '../../models/StudentProfile';
import { ApiResponse } from '../../../../modules/common/ApiResponse';
import { AxiosResponse } from 'axios';
import { PassportStep } from './steps/PassportStep';
import { SocialStep } from './steps/SocialStep';
import { PersonalStep } from './steps/PersonalStep';
import { profileElementSchemas, stepHeadings } from './ProfileEditSchema';
import { Stepper } from '../../../../modules/stepper';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

type Props = {
    profile: StudentProfile | UpdateStudentProfileRequest,
    submitFn: (profile: any) => Promise<AxiosResponse<ApiResponse<string>>>
}

export const ProfileWizard: FC<Props> = ({ profile, submitFn }) => {
    const history = useHistory();
    return <Stepper
        formValues={profile}
        submitFn={(formValues) => {
            return submitFn({ ...formValues, dateOfBirth: moment((formValues as UpdateStudentProfileRequest).dateOfBirth).format() })
                .then(resp => {
                    if (resp.data.failed) {
                        return resp;
                    } else {
                        toast(`Saved information successfuly`, { type: 'success' });
                        history.push(`/student/${profile.id}/edit/academic`);
                        return resp;
                    }
                })
        }}
        validationSchemas={profileElementSchemas}
        stepComponents={[PersonalStep, PassportStep, SocialStep]}
        stepHeadings={stepHeadings} />
}
