import axios from "axios"
import { ICountry } from "./ICountry";

const API_URL = process.env.REACT_APP_API_URL;
const COUNTRIES_API_URL = `${API_URL}/api/v1/countries`;

export const getAllCountries = () => {
    return axios.get<ICountry[]>(COUNTRIES_API_URL)
}

export const updateCountry = (term: ICountry) => {
    return axios.put(COUNTRIES_API_URL + "/" + term.id, term);
}