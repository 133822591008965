import axios from 'axios'
import {GenerateReportRequestDto} from './GenerateReportRequestDto'

const API_URL = process.env.REACT_APP_API_URL
export const REPORTS_API = `${API_URL}/api/v1/reports`

export function callGenerateReportApi(req: GenerateReportRequestDto) {
  return axios.post(`${REPORTS_API}`, req)
}

export function getAllUniversitiesApi() {
  return axios.get(`${API_URL}/api/v1/common/get-all-universities`)
}
