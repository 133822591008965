import {DelegationStatus, DocumentType} from '../../pages/dashboard/ContentWriterDashboard'
import {GradingSystemId, GraduationType} from '../../pages/student/models'
import {CompetitiveExamType, LanguageExam} from '../../pages/student/models/ExamModels'
import {
  SuggestedUniversityStatus,
  UniversitySuggestionState,
} from '../../pages/university-suggestion/models/UniversitySuggestionDtos'
import {camelToSentenceCase, enumToKeyValue} from './EnumHelper'
import {Branch, BranchStats} from './enums/Branch'
import {BulkProfileActionType} from './enums/BulkProfileActionType'
import {DegreeTypeId} from './enums/DegreeTypeId'
import {ProfileStatus} from './enums/ProfileStatus'
import {QuestionnaireTypeId} from './enums/QuestionnaireTypeId'
import {AdminRoles, AllRoles, StudentRoles} from './enums/Roles'

const sentenceLabelSelector = (x: {[x: string]: any}): {label: string; value: number} => ({
  label: camelToSentenceCase(x.label),
  value: +x.value,
})
const keyNameSelector = (x: {[x: string]: any}): {label: string; value: number} => ({
  label: x.label,
  value: +x.value,
})
export const adminRoleOptions = enumToKeyValue(AdminRoles).map(sentenceLabelSelector)
export const allRoleOptions = enumToKeyValue(AllRoles).map(sentenceLabelSelector)
export const studentRoleOptions = enumToKeyValue(StudentRoles).map(sentenceLabelSelector)
export const branchOptions = enumToKeyValue(Branch).map(sentenceLabelSelector)
export const branchOptionsStats = enumToKeyValue(BranchStats).map(sentenceLabelSelector)
export const graduationTypeOptions = enumToKeyValue(GraduationType).map(sentenceLabelSelector)
export const gradingTypeOptions = [
  {label: 'Percentage(100)', value: GradingSystemId.percentage},
  {label: 'GPA(4)', value: GradingSystemId.gpaOutOfFour},
  {label: 'GPA(5)', value: GradingSystemId.gpaOutOfFive},
  {label: 'GPA(7)', value: GradingSystemId.gpaOutOfSeven},
  {label: 'GPA(10)', value: GradingSystemId.gpaOutOfTen},
  {label: 'CGPA(4)', value: GradingSystemId.cgpaOutOfFour},
  {label: 'CGPA(5)', value: GradingSystemId.cgpaOutOfFive},
  {label: 'CGPA(7)', value: GradingSystemId.cgpaOutOfSeven},
  {label: 'CGPA(10)', value: GradingSystemId.cgpaOutOfTen},
  {label: 'Pointer(10)', value: GradingSystemId.pointerOutOfTen},
]
export const degreeOptions = enumToKeyValue(DegreeTypeId).map(sentenceLabelSelector)
export const languageExamOptions = enumToKeyValue(LanguageExam).map(keyNameSelector)
export const competitiveExamOptions = enumToKeyValue(CompetitiveExamType).map(keyNameSelector)
export const profileStatusOptions = enumToKeyValue(ProfileStatus).map(sentenceLabelSelector)
export const bulkProfileActionTypeOptions =
  enumToKeyValue(BulkProfileActionType).map(sentenceLabelSelector)
export const questionnaireOptions = enumToKeyValue(QuestionnaireTypeId)
  .map(sentenceLabelSelector)
  .map((x) => ({...x, label: x.label.toUpperCase()}))
export const suggestedUniversityStatusOptions = enumToKeyValue(SuggestedUniversityStatus)
  .map(sentenceLabelSelector)
  .map((x) => ({...x, label: x.label.toUpperCase()}))
export const suggestionStateOptions = enumToKeyValue(UniversitySuggestionState)
  .map(sentenceLabelSelector)
  .map((x) => ({...x, label: x.label.toUpperCase()}))
export const delegationOptions = enumToKeyValue(DelegationStatus).map(sentenceLabelSelector)
export const documentTypeOptions = enumToKeyValue(DocumentType).map(keyNameSelector)
