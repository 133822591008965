import clsx from "clsx"
import { FC, useEffect } from "react"
import { Spinner } from "react-bootstrap-v5"
import Select from "react-select"
import { graduationTypeOptions } from "../../../../../modules/common/DropdownOptions"
import { GraduationType } from "../../../models"
import { BachelorGradesForm } from "./GraduationTypeComponents/BachelorGradesForm"
import { DiplomaGradesForm } from "./GraduationTypeComponents/DiplomaGradesForm"
import { DiplomaPlusBachelorGradesForm } from "./GraduationTypeComponents/DiplomaPlusBachelorGradesForm"
import { JuniorCollegeGradesForm } from "./GraduationTypeComponents/JuniorCollegeGradesForm"
import { MastersGradesForm } from "./GraduationTypeComponents/MastersGradesForm"

type Props = {
    formik: any,
}
export const
    GraduationDetailStep: FC<Props> = ({ formik }) => {
        useEffect(() => {
            const initialForm = formik.values.graduationDetail || {};
            formik.setFieldValue('graduationDetail', initialForm);
            // eslint-disable-next-line
        }, [])//only once;

        const renderSwitch = (gradType?: GraduationType) => {
            switch (gradType) {
                case GraduationType.Bachelors:
                    return <BachelorGradesForm formik={formik} basePath='graduationDetail.bachelorDegreeDetail' />
                case GraduationType.Diploma:
                    return <DiplomaGradesForm formik={formik} basePath='graduationDetail.diplomaDegreeDetail' />
                case GraduationType.DiplomaPlusBachelorDegree:
                    return <DiplomaPlusBachelorGradesForm formik={formik} basePath='graduationDetail.diplomaPlusBachelorDegreeDetail' />
                case GraduationType.Masters:
                    return <MastersGradesForm formik={formik} basePath='graduationDetail.masterDegreeDetail' />
                case GraduationType.JuniorCollege:
                    return <JuniorCollegeGradesForm formik={formik} basePath='graduationDetail.juniorCollegeDetail' />
                default:
                    return null;
            }
        }

        if (!formik.values.graduationDetail) {
            return <Spinner animation={'grow'} />
        }

        return <div className="w-100">
            <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>
                    Current Education Details
                </h2>
            </div>

            <div className='fv-row mb-10 row'>
                <div className="col-xl-6">
                    <label className='class="form-label fw-bolder text-dark fs-6 required mb-5'>Latest Degree</label>
                    <Select options={graduationTypeOptions}
                        className={clsx(
                            'form-control form-control-sm p-0',
                            {
                                'is-invalid': formik.getFieldMeta('graduationDetail.graduationType').touched && formik.getFieldMeta('graduationDetail.graduationType').error,
                            },
                            {
                                'is-valid': formik.getFieldMeta('graduationDetail.graduationType').touched && !formik.getFieldMeta('graduationDetail.graduationType').error,
                            }
                        )}
                        onChange={(opt) => {
                            formik.setFieldValue('graduationDetail.graduationType', opt?.value)
                        }}
                        value={graduationTypeOptions.find(x => x.value === formik.values.graduationDetail.graduationType)}
                        menuPlacement="top"
                        onFocus={() => { formik.setFieldTouched(`graduationDetail.graduationType`) }}
                    />

                    {formik.getFieldMeta('graduationDetail.graduationType').touched && formik.getFieldMeta('graduationDetail.graduationType').error && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger mt-2'>
                                <span role='alert'>{formik.getFieldMeta('graduationDetail.graduationType').error}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="row">
                {renderSwitch(formik.values?.graduationDetail?.graduationType)}
                <div className='fv-row mb-7 row'>
                    <div className='col-xl-6'>
                        <label className='form-label fw-bolder text-dark fs-6'> Other Remarks </label>
                        <textarea
                            placeholder='Other remarks'
                            autoComplete='off'
                            {...formik.getFieldProps('otherRemarks')}
                            className='form-control form-control-sm'
                            onChange={(ev)=>{
                                console.log(formik.values);
                                console.log(ev.target.value);
                                formik.setFieldValue('otherRemarks', ev.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    }