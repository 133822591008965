import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { InputGroup, Spinner } from 'react-bootstrap-v5'
import Select from 'react-select'
import { degreeOptions } from '../../../../../modules/common/DropdownOptions'
import { useSharedState } from '../../../../../modules/common/SharedStateProvider'

type Props = {
    formik: any,
}

export const FutureStudyDetailStep: FC<Props> = ({ formik }) => {
    const [fieldOfStudyOptions, setFieldOfStudyOptions] = useState<{ label: string, value: number, children: any[] }[]>([]);
    const [specializationOptions, setSpecializationOptions] = useState<{ label: string, value: number }[]>([]);
    const sharedState = useSharedState();

    function populateFieldOfStudyOptions() {
        const course = sharedState.courses.find(x => x.value === formik.values.courseId);
        setFieldOfStudyOptions(course?.children || []);
    }

    function populateSpecializationOptions() {
        const fieldOfStudy = fieldOfStudyOptions.find(x => x.value === formik.values.fieldOfStudyId);
        setSpecializationOptions(fieldOfStudy?.children || []);
    }

    //on course change
    useEffect(() => {
        populateFieldOfStudyOptions();
        // eslint-disable-next-line
    }, [formik.values.courseId, sharedState.courses])

    //on fieldOfStudy change
    useEffect(() => {
        populateSpecializationOptions();
        // eslint-disable-next-line
    }, [formik.values.fieldOfStudyId, fieldOfStudyOptions])

    if (sharedState.courses.length === 0) return <Spinner animation={'grow'} variant={'primary'} />

    return <div className="w-100">
        <div className='pb-10 pb-lg-15'>
            <h2 className='fw-bolder d-flex align-items-center text-dark'>
                Future Study Details
            </h2>
        </div>
        <div className="fv-row mb-10 row">
            <div className='col-xl-6'>
                <InputGroup size='sm'>
                    <InputGroup.Text className='required w-35'>
                        Desired Degree
                    </InputGroup.Text>
                    <Select
                        options={degreeOptions}
                        onFocus={() => { formik.setFieldTouched('desiredDegreeType') }}
                        onChange={(opt) => {
                            formik.setFieldValue('desiredDegreeType', opt?.value);
                        }}
                        value={degreeOptions.find((x: any) => x.value === formik.values.desiredDegreeType)}
                        className={clsx(
                            'form-control form-control-sm p-0 ',
                            {
                                'is-invalid': formik.touched.desiredDegreeType && formik.errors.desiredDegreeType,
                            },
                            {
                                'is-valid': formik.touched.desiredDegreeType && !formik.errors.desiredDegreeType,
                            }
                        )}
                    />
                </InputGroup>
                {formik.touched.desiredDegreeType && formik.errors.desiredDegreeType && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{formik.errors.desiredDegreeType}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
        <div className="fv-row mb-10 row">
            <div className='col-xl-6'>
                <InputGroup size='sm'>
                    <InputGroup.Text className='required w-35'>
                        Course
                    </InputGroup.Text>
                    <Select
                        options={sharedState.courses}
                        onFocus={() => { formik.setFieldTouched('courseId') }}
                        onChange={(opt) => {
                            formik.setFieldValue('courseId', opt?.value);
                            formik.setFieldValue('fieldOfStudyId', undefined);
                            formik.setFieldValue('specializationId', undefined);
                        }}
                        value={sharedState.courses.find((x: any) => x.value === formik.values.courseId)}
                        className={clsx(
                            'form-control form-control-sm p-0 ',
                            {
                                'is-invalid': formik.touched.courseId && formik.errors.courseId,
                            },
                            {
                                'is-valid': formik.touched.courseId && !formik.errors.courseId,
                            }
                        )}
                    />
                </InputGroup>
                {formik.touched.courseId && formik.errors.courseId && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{formik.errors.courseId}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
        <div className="fv-row mb-10 row">
            <div className='col-xl-6'>
                <InputGroup size='sm'>
                    <InputGroup.Text className='required w-35'>
                        Field of Study
                    </InputGroup.Text>
                    <Select
                        key={'fieldOfStudyId_' + formik.values.courseId}
                        options={fieldOfStudyOptions}
                        onFocus={() => { formik.setFieldTouched('fieldOfStudyId') }}
                        onChange={(opt) => {
                            formik.setFieldValue('fieldOfStudyId', opt?.value);
                            formik.setFieldValue('specializationId', undefined);
                        }}
                        value={fieldOfStudyOptions.find((x: any) => x.value === formik.values.fieldOfStudyId)}
                        className={clsx(
                            'form-control form-control-sm p-0 ',
                            {
                                'is-invalid': formik.touched.fieldOfStudyId && formik.errors.fieldOfStudyId,
                            },
                            {
                                'is-valid': formik.touched.fieldOfStudyId && !formik.errors.fieldOfStudyId,
                            }
                        )}
                    />
                </InputGroup>
                {formik.touched.fieldOfStudyId && formik.errors.fieldOfStudyId && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{formik.errors.fieldOfStudyId}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
        <div className="fv-row mb-10 row">
            <div className='col-xl-6'>
                <InputGroup size='sm'>
                    <InputGroup.Text className='w-35'>
                        Specialization
                    </InputGroup.Text>
                    <Select
                        key={'specializationId_' + formik.values.fieldOfStudyId}
                        options={specializationOptions}
                        onFocus={() => { formik.setFieldTouched('specializationId') }}
                        onChange={(opt) => {
                            formik.setFieldValue('specializationId', opt?.value);
                        }}
                        className='form-control form-control-sm p-0'
                        value={specializationOptions.find((x: any) => x.value === formik.values.specializationId)}
                    />
                </InputGroup>
            </div>
        </div>
    </div>
}