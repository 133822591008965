import { FC } from "react";
import tableIcons from "../../../modules/common/components/MaterialTableIcons";
import { getStudentsPendingSOPApprovalPaginated } from "../../student-profiles/services/StudentManager";
import StudentList from "../../student-profiles/components/StudentList";
import { useHistory } from "react-router-dom";

export const StudentsPendingSopApproval: FC<{ onLoad?: (count: number) => void }> = ({ onLoad }) => {
  const history = useHistory();

  return <>
    <StudentList
      title='Profiles Pending Sop Questionnaire Approval'
      getStudentsFromServer={getStudentsPendingSOPApprovalPaginated}
      actions={[
        {
          icon: tableIcons.SOP as any,
          tooltip: 'View SOP Response',
          disabled: false,
          onClick: (event: any, rowData: any) => {
            const url = `/student/${rowData.id}/sop/approve`;
            history.push(url);
          },
        },
      ]}
      disabledFilters={['examDate', 'statuses', 'hasCompetitiveExamScores', 'hasLanguageExamScores']}
      useDefaultActions={false}
      onLoad={onLoad}
    />
  </>
}

