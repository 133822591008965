import React, {FC, useEffect, useState} from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import {Box} from '@material-ui/core'
import {Button, Modal, Table} from 'react-bootstrap-v5'
import {ExportToCsv} from 'export-to-csv'
import {Column} from 'material-table'
import SaveAlt from '@material-ui/icons/SaveAlt'

const CounsellorTab: FC<any> = ({counsellorData, branchData}): JSX.Element => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [filteredCouncellorData, setFilteredCouncellorData] = useState<any>([])
  const [show, setShow] = useState(false)
  const [viewTableData, setViewTableData] = useState([])
  const [modalTitle, setModalTitle] = useState('View Details')

  const columns: Column<any>[] = [
    {title: 'Full Name', field: 'fullName', headerStyle: {width: '200px'}},
    {title: 'Email', field: 'email', headerStyle: {width: '200px'}},
    {title: 'Parent Phone Number', field: 'parentPhoneNumber', headerStyle: {width: '200px'}},
    {title: 'Student Phone Number', field: 'studentPhoneNumber', headerStyle: {width: '200px'}},
    {title: 'Finalized University', field: 'finalizedUniversity', headerStyle: {width: '200px'}},
    {title: 'Partners', field: 'finalizedUniversityPartnerName', headerStyle: {width: '200px'}},
  ]

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.title as string),
  }
  const csvExporter = new ExportToCsv(csvOptions)

  const handleClose = () => setShow(false)

  const handleShow = (type: any, title: any) => {
    // console.log(filteredCouncellorData)
    setModalTitle(title)
    filterViewDetailsData(type)
    setShow(true)
  }

  const handleExportData = () => {
    const keysToKeep: any = [
      'fullName',
      'email',
      'studentPhoneNumber',
      'parentPhoneNumber',
      'finalizedUniversity',
      'finalizedUniversityPartnerName',
    ]
    const filteredArray = viewTableData.map((obj: any) => {
      const filteredObj: any = {}
      keysToKeep.forEach((key: any) => {
        if (obj.hasOwnProperty(key)) {
          filteredObj[key] = obj[key]
        }
      })
      return filteredObj
    })
    // console.log(filteredArray)
    csvExporter.generateCsv(filteredArray)
  }

  useEffect(() => {
    const filteredData = branchData.filter((el: any) => {
      return el.accountCreatedBy === counsellorData[currentTabIndex].accountCreatedBy
    })
    console.log('currentTabIndex: ', currentTabIndex)
    console.log('filteredData: ', filteredData)
    setFilteredCouncellorData(filteredData)
  }, [currentTabIndex, counsellorData, branchData])

  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTabIndex(tabIndex)
  }

  const filterViewDetailsData = (type: any) => {
    if (type === 'total') {
      setViewTableData(filteredCouncellorData)
    }
    if (type === 'finalized') {
      const filteredData = filteredCouncellorData.filter((el: any) => {
        return el.finalizedUniversity == null && el?.finalizedUniversity !== 'DROPPED/CANCELLED'
      })
      //   console.log(filteredData)
      setViewTableData(filteredData)
    }
    if (type === 'partnerApplications') {
      const filteredData = filteredCouncellorData.filter((el: any) => {
        return el.finalizedUniversityPartnerName !== 'No Partner'
      })
      //   console.log(filteredData)
      setViewTableData(filteredData)
    }
    if (type === 'lor') {
      const filteredData = filteredCouncellorData.filter((el: any) => {
        return el?.requiresLor === 'True' && !el?.profileStatuses.includes('LorSent')
      })
      //   console.log(filteredData)
      setViewTableData(filteredData)
    }
    if (type === 'sop') {
      const filteredData = filteredCouncellorData.filter((el: any) => {
        return el?.requiresSop === 'True' && !el?.profileStatuses.includes('SopSent')
      })
      console.log(filteredData)
      setViewTableData(filteredData)
    }
    if (type === 'feesPending') {
      const filteredData = filteredCouncellorData.filter((el: any) => {
        return (
          el?.finalizedUniversity !== 'DROPPED/CANCELLED' &&
          Number(el?.totalFeesCharged) > Number(el?.feesPaid)
        )
      })
      //   console.log(filteredData)
      setViewTableData(filteredData)
    }
  }

  return (
    <React.Fragment>
      <Tabs
        value={currentTabIndex || 0}
        onChange={handleTabChange}
        textColor='secondary'
        indicatorColor='secondary'
        aria-label='secondary tabs example'
      >
        {counsellorData.map((el: any, index: any) => (
          <Tab label={el?.accountCreatedBy?.split('.')[0]} key={index} />
        ))}
      </Tabs>
      {filteredCouncellorData && (
        <Box className='pt-2 pb-2'>
          <div className='row fv-row mt-5'>
            <div className='col-xl-2'>
              <div className='card text-center'>
                <div className='card-body'>
                  <h5 className='card-title'>Total Students</h5>
                  <p>{filteredCouncellorData.length}</p>
                  <p
                    style={{cursor: 'pointer', color: 'blue'}}
                    onClick={() => handleShow('total', 'View All Students')}
                  >
                    View
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3'>
              <div className='card text-center'>
                <div className='card-body'>
                  <h5 className='card-title'>Finalized University</h5>
                  <p>
                    {
                      filteredCouncellorData.filter(
                        (el: any) =>
                          el?.finalizedUniversity !== null &&
                          el?.finalizedUniversity !== 'DROPPED/CANCELLED'
                      ).length
                    }
                  </p>
                  <p
                    style={{cursor: 'pointer', color: 'blue'}}
                    onClick={() => handleShow('finalized', 'Students which are not finalized yet')}
                  >
                    View Remaining
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3'>
              <div className='card text-center'>
                <div className='card-body'>
                  <h5 className='card-title'>Applications for Partners</h5>
                  <p>
                    {
                      filteredCouncellorData.filter(
                        (el: any) => el?.finalizedUniversityPartnerName !== 'No Partner'
                      ).length
                    }
                  </p>
                  <p
                    style={{cursor: 'pointer', color: 'blue'}}
                    onClick={() => handleShow('partnerApplications', 'Partner Applications')}
                  >
                    View
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-2'>
              <div className='card text-center'>
                <div className='card-body'>
                  <h5 className='card-title'>LOR/SOP Sent</h5>
                  <p>
                    {
                      filteredCouncellorData.filter(
                        (el: any) =>
                          el?.requiresLor === 'True' && el?.profileStatuses.includes('LorSent')
                      ).length
                    }
                    /
                    {
                      filteredCouncellorData.filter(
                        (el: any) =>
                          el?.requiresSop === 'True' && el?.profileStatuses.includes('SopSent')
                      ).length
                    }
                  </p>
                  <p style={{cursor: 'pointer', color: 'blue'}}>
                    <span onClick={() => handleShow('lor', 'Pending Students (LOR)')}>LOR</span> /{' '}
                    <span onClick={() => handleShow('sop', 'Pending Students (SOP)')}> SOP</span>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-2'>
              <div className='card text-center'>
                <div className='card-body'>
                  <h5 className='card-title'>Fees pending</h5>
                  <p>
                    {
                      filteredCouncellorData.filter(
                        (el: any) =>
                          el?.finalizedUniversity !== 'DROPPED/CANCELLED' &&
                          Number(el?.totalFeesCharged) > Number(el?.feesPaid)
                      ).length
                    }
                  </p>
                  <p
                    style={{cursor: 'pointer', color: 'blue'}}
                    onClick={() => handleShow('feesPending', 'Pending List')}
                  >
                    View Remaining
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row fv-row mt-5'>
            <h5 className='card-title mb-2'>Partner Application Details</h5>
            <div className='col-xl-3 mb-3'>
              <div className='card text-center'>
                <div className='card-body'>
                  <h5 className='card-title primary text-success'>IMFS (Stevens)</h5>
                  <h6>
                    {
                      filteredCouncellorData.filter(
                        (el: any) =>
                          el?.finalizedUniversityPartnerName ===
                          '(IMFS-Direct) - Stevens Institute of Technology'
                      ).length
                    }
                  </h6>
                </div>
              </div>
            </div>
            <div className='col-xl-3'>
              <div className='card text-center'>
                <div className='card-body'>
                  <h5 className='card-title primary text-success'>SHORELIGHT</h5>
                  <h6>
                    {
                      filteredCouncellorData.filter(
                        (el: any) => el?.finalizedUniversityPartnerName === 'SHORELIGHT'
                      ).length
                    }
                  </h6>
                </div>
              </div>
            </div>
            <div className='col-xl-3'>
              <div className='card text-center'>
                <div className='card-body'>
                  <h5 className='card-title text-success'>Career Mosaic</h5>
                  <h6>
                    {
                      filteredCouncellorData.filter(
                        (el: any) => el?.finalizedUniversityPartnerName === 'Career Mosaic'
                      ).length
                    }
                  </h6>
                </div>
              </div>
            </div>
            <div className='col-xl-3'>
              <div className='card text-center'>
                <div className='card-body'>
                  <h5 className='card-title text-success'>KAPLAN</h5>
                  <h6>
                    {
                      filteredCouncellorData.filter(
                        (el: any) => el?.finalizedUniversityPartnerName === 'KAPLAN'
                      ).length
                    }
                  </h6>
                </div>
              </div>
            </div>

            <div className='col-xl-3'>
              <div className='card text-center'>
                <div className='card-body'>
                  <h5 className='card-title text-success'>EDUCO</h5>
                  <h6>
                    {
                      filteredCouncellorData.filter(
                        (el: any) => el?.finalizedUniversityPartnerName === 'EDUCO'
                      ).length
                    }
                  </h6>
                </div>
              </div>
            </div>
            <div className='col-xl-3'>
              <div className='card text-center'>
                <div className='card-body'>
                  <h5 className='card-title text-success'>INTO (DIRECT)</h5>
                  <h6>
                    {
                      filteredCouncellorData.filter(
                        (el: any) => el?.finalizedUniversityPartnerName === 'INTO (Direct)'
                      ).length
                    }
                  </h6>
                </div>
              </div>
            </div>
            <div className='col-xl-3'>
              <div className='card text-center'>
                <div className='card-body'>
                  <h5 className='card-title text-success'>SIUK</h5>
                  <h6>
                    {
                      filteredCouncellorData.filter(
                        (el: any) => el?.finalizedUniversityPartnerName === 'SIUK'
                      ).length
                    }
                  </h6>
                </div>
              </div>
            </div>
          </div>
          {/* <Button variant='primary' onClick={handleShow}>
            Launch demo modal
          </Button> */}
          <Modal show={show} onHide={handleClose} size='xl'>
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='row me-auto'>
                <p className='text-end' onClick={() => handleExportData()}>
                  <SaveAlt color='primary' fontSize='large' style={{cursor: 'pointer'}} />
                </p>
              </div>
              {viewTableData.length > 0 ? (
                <Table striped bordered hover size='lg'>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Contact No</th>
                      <th>Alternate No</th>
                      <th>Finalized University</th>
                      <th>Partner University</th>
                    </tr>
                  </thead>
                  <tbody>
                    {viewTableData.map((el: any, index: any) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{el?.fullName}</td>
                        <td>{el?.email}</td>
                        <td>{el?.studentPhoneNumber}</td>
                        <td>{el?.parentPhoneNumber}</td>
                        <td>{el.finalizedUniversity !== null ? el.finalizedUniversity : '-'}</td>
                        <td>
                          {el?.finalizedUniversityPartnerName
                            ? el?.finalizedUniversityPartnerName
                            : '-'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p className='text-center'>No Data Found!</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Box>
      )}
    </React.Fragment>
  )
}

export default CounsellorTab
