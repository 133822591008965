import { FC, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap-v5'
import tableIcons from '../../../modules/common/components/MaterialTableIcons'
import MaterialTable, { Query } from 'material-table'
import { getAllStudentsDocumentPaginated } from '../../student-profiles/services/StudentManager'
import { StudentProfile } from '../../student/models'
import { AxiosResponse } from 'axios'
import { branchOptions, delegationOptions } from '../../../modules/common/DropdownOptions'
import { getAllUsersPaginated } from '../../user-management/services/UserManager'
import { AllRoles } from '../../../modules/common/enums/Roles'
import { AsyncDropdown } from '../../../modules/common/components/AsyncDropdown'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { DocumentType } from '../ContentWriterDashboard'
import { useSharedState } from '../../../modules/common/SharedStateProvider'

type DocumentFilterModel = { selecteUser?: string, selectedStatus?: number, selectedTerm?: number, selectedFos?: number };

export const StudentWithDelegatedDocumentsFilter: FC<{ onChange: (filters: DocumentFilterModel) => any, hideUserfilter: boolean }> = ({ onChange, hideUserfilter }) => {
  const [selecteUser, setSelectedUser] = useState(undefined);
  const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [selectedTerm, setSelectedTerm] = useState(undefined);
  const [selectedFos, setSelectedFos] = useState(undefined);
  const [users, setUsers] = useState([]);
  const sharedState = useSharedState();

  useEffect(() => {
    //get all users with role content writer
    const fetchData = () => {
      getAllUsersPaginated('', 1, 100, [], [AllRoles.ContentWriter])
        .then(({ data }) => {
          setUsers(data.data.map((x: any) => ({ label: `${x.firstName} ${x.lastName}(${x.userName})`, value: x.userName })));
        });
    }
    !hideUserfilter && fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    onChange({ selecteUser, selectedStatus, selectedTerm, selectedFos });
    // eslint-disable-next-line
  }, [selecteUser, selectedStatus, selectedTerm, selectedFos])

  return <div className="row">
    {!hideUserfilter && <div className="col-xl-3 my-5">
      <label>Select Content Writer</label>
      <AsyncDropdown
        options={users}
        onSelected={(val) => {
          setSelectedUser(val);
        }}
        selected={selecteUser}
      />
    </div>}
    <div className="col-xl-3 my-5">
      <label>Select Document Status</label>
      <AsyncDropdown
        options={delegationOptions}
        onSelected={(val) => {
          setSelectedStatus(val);
        }}
        selected={selectedStatus}
      />
    </div>
    <div className="col-xl-3 my-5">
      <label>Term</label>
      <AsyncDropdown
        options={sharedState.terms.map(x => ({ label: x.name, value: x.id }))}
        onSelected={(val) => {
          setSelectedTerm(val);
        }}
        selected={selectedTerm}
      />
    </div>
    <div className="col-xl-3 my-5">
      <label>Future Study</label>
      <AsyncDropdown
        options={sharedState.fieldsOfStudy}
        onSelected={(val) => {
          setSelectedFos(val);
        }}
        selected={selectedFos}
      />
    </div>
  </div>
}

export const StudentsWithDelegatedDocuments: FC<{ onLoad?: (count: number) => void, hideUserfilter: boolean }> = ({ onLoad, hideUserfilter }) => {
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useState(10);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [filters, setFilters] = useState<DocumentFilterModel>();
  const history = useHistory();
  const { fieldsOfStudy } = useSharedState();

  useEffect(() => {
    setRefreshKey(Math.random());
    //eslint-disable-next-line
  }, [filters])

  const transformApiResponse = (response: AxiosResponse<any>, resolve: any) => {
    let { data } = response.data;
    onLoad && onLoad(response.data.totalCount);
    return resolve({
      data,
      page: response.data.page - 1,
      totalCount: response.data.totalCount,
    })
  }

  return (
    <>
      {loading && <Spinner animation={'grow'} variant='primary' />}
      <StudentWithDelegatedDocumentsFilter hideUserfilter={hideUserfilter} onChange={(filtersVal) => {
        setFilters(filtersVal);
      }} />
      <div className="col-xl-12">
        <MaterialTable
          title={'All delegated Profiles'}
          icons={tableIcons}
          columns={[
            { title: 'Name', field: 'firstName', render: (rowData: StudentProfile) => [rowData.firstName, rowData.lastName].filter(Boolean).join(' ').toUpperCase(), },
            { title: 'Email', field: 'email' },
            { title: 'BLL', field: 'createdBy' },
            { title: 'Branch', field: 'branch', render: (rowData: StudentProfile) => branchOptions.find(x => x.value === rowData.branchId)?.label },
            { title: 'Term', field: 'termName' },
            { title: 'Future Study', render: (rowData: StudentProfile) => fieldsOfStudy.find(x => x.value === rowData.desiredFieldOfStudyId)?.label },
            { title: 'Delegation Status', field: 'delegationStatus', render: (rowData: StudentProfile) => delegationOptions.find(x => x.value === rowData.delegationStatus)?.label },
            { title: 'Delegated To', field: 'delegatedTo' },
            { title: 'Delegated On', field: 'delegatedAt', render: (rowData: StudentProfile) => moment(rowData.delegatedAt).format("DD-MMM-YYYY") },
          ]}
          actions={[
            (rowData: StudentProfile) => ({
              icon: tableIcons.OpenInNew,
              tooltip: rowData.documentType === DocumentType.LOR ? "View LOR Questionnaire" : 'View SOP Questionnaire',
              onClick: (event: any, rowData: StudentProfile) => {
                if (rowData.documentType === DocumentType.LOR) {
                  history.push(`/student/${rowData.id}/lor`)
                } else {
                  history.push(`/student/${rowData.id}/sop`)
                }
              },
            }) as any,

            (rowData: StudentProfile) => ({
              icon: rowData.documentType === DocumentType.LOR ? tableIcons.LOR : tableIcons.SOP,
              tooltip: rowData.documentType === DocumentType.LOR ? "Download LOR" : 'Download SOP',
              onClick: (event: any, rowData: StudentProfile) => {
                const url = rowData.documentType === DocumentType.LOR ? rowData.lorUri : rowData.sopUri;
                if(!url){
                  alert("No document attached to download")
                  return;
                }
                const tab = window.open(url, "_blank");
                setTimeout(() => {
                  tab?.close();
                }, 2000);
              },
            }),
          ]}
          data={(query: Query<StudentProfile>) => {
            const { page, pageSize, search, orderBy, orderDirection } = query;
            setPageSize(pageSize);
            const field = orderBy ? orderBy.field : "firstName";
            return new Promise((resolve, reject) => {
              setLoading(true);
              getAllStudentsDocumentPaginated(search?.trim(), page + 1, pageSize, { contentWriter: filters?.selecteUser, documentStatus: filters?.selectedStatus, selectedTermId: filters?.selectedTerm, desiredFieldOfStudyId: filters?.selectedFos } as any, orderDirection, field)
                .then(resp => transformApiResponse(resp, resolve))
                .catch(error => reject(error))
                .finally(() => setLoading(false));
            })
          }}
          isLoading={loading}
          key={refreshKey}
          options={{ debounceInterval: 700, filtering: false, pageSize, pageSizeOptions: [5, 10, 20, 50, 100] }}
        />
      </div>
    </>
  )
}
