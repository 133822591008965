/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import {
    toAbsoluteUrl,
} from '../../../../theme/helpers'
import { ReplyMessageDto, Conversation, Message, ConversationStatus } from '../models/ConversationModel'
import { FileUploader } from '../../common/components/FileUploader'
import { useSharedState } from '../../common/SharedStateProvider'
import * as messageService from '../messageService';
import { useMessageContext } from '../../common/MessageContext'
import moment from 'moment'
import { GenericTooltip } from '../../common/components/TooltipInfo'
import Notice from '../../common/components/Notice'
import { DATE_TIME_FORMAT_DD_MMM_YYYY } from '../../common/Helper'

type Props = {
    isDrawer?: boolean,
    conversationId: number
}

const ChatMessages: FC<Props> = ({ isDrawer = false, conversationId }) => {
    const [chatUpdateFlag, toggleChatUpdateFlag] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [messages, setMessages] = useState<Array<Message>>([])
    const [base64File, setFile] = useState<any>();
    const { user } = useSharedState();
    const { refresh, messages: conversations } = useMessageContext();
    const [selectedConversation, setConversation] = useState<Conversation>();

    useEffect(() => {
        toggleChatUpdateFlag(!chatUpdateFlag);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const conv = conversations.find(x => x.id === conversationId) as Conversation;
        setConversation(conv);
        setMessages(conv.messages);
        setTimeout(() => {
            window.document.querySelector('.chat-window')
                ?.scrollBy(0, window.document.querySelector('.chat-window')?.scrollHeight || 1000);
        }, 100)
        //eslint-disable-next-line
    }, [chatUpdateFlag, conversationId]);

    const sendMessage = () => {
        const newMessage: ReplyMessageDto = {
            message,
            ...base64File
        }
        messageService.reply(conversationId, newMessage).then(() => {
            refresh(conversationId);
            setMessage('')
            setFile(undefined);
            setTimeout(() => { toggleChatUpdateFlag(!chatUpdateFlag) }, 100)
        })
    }

    const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault()
            sendMessage()
        }
    }

    return (
        <div
            className='card-body'
            id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
        >
            <div
                className={clsx('scroll-y me-n5 pe-5 chat-window', { 'h-300px h-lg-auto': !isDrawer })}
                data-kt-element='messages'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies={
                    isDrawer
                        ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                        : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                }
                data-kt-scroll-wrappers={
                    isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
                }
                data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
            >
                {messages.map((message, index) => {
                    const messageType = message.creatorBy === user?.username ? "out" : "in";
                    const state = messageType === 'in' ? 'info' : 'primary'
                    const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${messageType === 'in' ? 'start' : 'end'
                        } mb-10`
                    return (
                        <div
                            key={`message${index}`}
                            className={clsx('d-flex', contentClass, 'mb-10')}
                        >
                            <div
                                className={clsx(
                                    'd-flex flex-column align-items',
                                    `align-items-${messageType === 'in' ? 'start' : 'end'}`
                                )}
                            >
                                <div className='d-flex align-items-center mb-2'>
                                    {messageType === 'in' ? (
                                        <>
                                            <div className='symbol  symbol-35px symbol-circle '>
                                                <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/blank.png`)} />
                                            </div>
                                            <div className='ms-3'>
                                                <a
                                                    href='#'
                                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                                                >
                                                    {message.creatorBy}
                                                </a>
                                                <span className='text-muted fs-7 mb-1'>
                                                    <GenericTooltip message={moment(message.createdAt).format(DATE_TIME_FORMAT_DD_MMM_YYYY)} childEle={<>{moment(message.createdAt).fromNow()}</>} />
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='me-3'>
                                                <span className='text-muted fs-7 mb-1'>
                                                    <GenericTooltip message={moment(message.createdAt).format(DATE_TIME_FORMAT_DD_MMM_YYYY)} childEle={<>{moment(message.createdAt).fromNow()}</>} />
                                                </span>
                                                <a
                                                    href='#'
                                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                                                >
                                                    You
                                                </a>
                                            </div>
                                            <div className='symbol  symbol-35px symbol-circle '>
                                                <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/blank.png`)} />
                                            </div>
                                        </>
                                    )}
                                </div>

                                <div
                                    className={clsx(
                                        'p-5 rounded',
                                        `bg-light-${state}`,
                                        'text-dark fw-bold ',
                                        `text-${messageType === 'in' ? 'start' : 'end'}`
                                    )}
                                    data-kt-element='message-text'
                                >
                                    {message.messageBody}
                                    <div className="row">
                                        {message.messageAttachmentUri && <a href={message.messageAttachmentUri} target={'_blank'} rel="noreferrer"><i className='bi bi-paperclip fs-3'></i> File Attachment</a>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {selectedConversation?.status !== ConversationStatus.Closed && <div
                className='card-footer pt-4'
                id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
            >
                <textarea
                    className='form-control form-control-flush mb-3'
                    rows={2}
                    data-kt-element='input'
                    placeholder='Type a message'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={onEnterPress}
                ></textarea>

                <div className='d-flex flex-stack'>
                    <div className='d-flex align-items-center me-2'>
                        <FileUploader sizeLimitBytes={5 * 1024 * 1024} onFileChange={(base64File: string, file: File) => {
                            setFile({ base64File, fileName: file.name });
                            return "";
                        }} />
                    </div>
                    <button
                        className='btn btn-primary'
                        type='button'
                        data-kt-element='send'
                        onClick={sendMessage}
                    >
                        Send
                    </button>
                </div>
            </div>}
            {selectedConversation?.status === ConversationStatus.Closed && <Notice message={`Conversation closed by ${selectedConversation.closedBy}`} />}
        </div>
    )
}

export { ChatMessages }
