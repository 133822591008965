/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/img-redundant-alt */
import moment from 'moment'
import React from 'react'
import {Button} from 'react-bootstrap-v5'
import {Link, useHistory} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../theme/helpers'
import {branchOptions, profileStatusOptions} from '../../../modules/common/DropdownOptions'
import {useSharedState} from '../../../modules/common/SharedStateProvider'
import {StudentProfile} from '../../student/models'
import {StudentTypeEditor} from './StudentTypeEditor'
import { DATE_TIME_FORMAT_DD_MMM_YYYY } from '../../../modules/common/Helper'

type Props = {
  profile: StudentProfile
  exportPDF: any
  showActions?: boolean
}

const ProfileHeader: React.FC<Props> = ({profile, exportPDF, showActions, children}) => {
  const history = useHistory()
  const statuses = profileStatusOptions
    .filter((x) => profile?.profileStatuses?.includes(x.value))
    .map((x) => x.label)
  const {isStudent, terms} = useSharedState();

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img
                src={profile.profilePictureUri || toAbsoluteUrl('/media/avatars/blank.png')}
                alt='Profile Picture'
              />
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {`${profile.firstName} ${profile.lastName}`}
                  </a>
                  <a href='#'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />
                  </a>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    key={'email'}
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <i className='bi bi-envelope-check text-primary'></i>
                    {profile.email}
                  </a>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <i className='bi bi-phone text-success'></i>
                    {profile.phoneNumber || 'NA'}
                  </a>
                  <a
                    key={'branch'}
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <i className='bi bi-geo-alt text-danger'></i>
                    {branchOptions.find((x) => x.value === profile.branchId)?.label}
                  </a>
                  <a
                    key={'term'}
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <i className='bi bi-person-check text-primary'></i>
                    {terms && terms.find(x=>x.id === profile.termId)?.name}
                  </a>
                  <a
                    key={'createdBy'}
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <i className='bi bi-person-check text-primary'></i>
                    {profile.createdBy}
                  </a>
                  <a
                    key={'createdOn'}
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <i className='bi bi-calendar-date text-warning'></i>
                    {moment(profile.createdOn).format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
                  </a>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  {renderStatues(statuses)}
                  {profile.interestedCountries.map((x) => (
                    <span className='m-1 badge badge-info' key={x}>
                      {x}
                    </span>
                  ))}
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>{children}</div>
              </div>
              {
                <div className='d-flex my-4'>
                  {showActions && (
                    <>
                      <Button
                        onClick={() => {
                          history.push(`/student/${profile.id}/edit`)
                        }}
                        variant='outline-primary'
                        className='btn-outline btn-sm mx-2'
                      >
                        Edit Profile
                      </Button>
                      <Button
                        onClick={exportPDF}
                        variant='outline-primary'
                        className='btn-outline btn-sm mx-2'
                      >
                        Export
                      </Button>
                    </>
                  )}
                  {!isStudent && (
                    <>
                      <StudentTypeEditor profileId={profile.id} studentType={profile.studentType} />
                      <Link className='mx-4' to={`/${profile.id}/setup-email-alerts`}>
                        <i className='bi bi-link px-1'></i>Setup Emails{' '}
                      </Link>
                      <Link to={`/student/${profile.id}/university-suggestions`}>
                        <i className='bi bi-link px-1'></i>University Suggestions
                      </Link>
                    </>
                  )}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
function renderStatues(statues: string[]) {
  if (!statues || statues.length === 0) {
    return <span className='badge badge-danger m-1'>Migrated</span>
  } else {
    return statues.map((x) => (
      <span key={x} className={`badge badge-primary m-1`}>
        {x}
      </span>
    ))
  }
}
export {ProfileHeader}
