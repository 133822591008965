import { FC } from "react"
import { GREExamScoreDto } from "../../../student/models/ExamModels"

type Props = {
    data: GREExamScoreDto,
}

export const GREScoreView: FC<Props> = ({ data }) => {

    return <div className='row mb-2'>
        <div className="row mb-5">
            <label className="fw-bold text-dark mt-5">Score Breakdown</label>
        </div>
        <div className="row">
            <div className="col-3 mb-2">
                <label className=' fw-bold text-muted'>Total</label>
                <span className='fw-bolder fs-6 text-dark mx-2'>
                    {data.verbal + data.quantitative}
                </span>
            </div>

            <div className="col-3 mb-2">
                <label className=' fw-bold text-muted'>Verbal</label>
                <span className='fw-bolder fs-6 text-dark mx-2'>
                    {data.verbal}
                </span>
            </div>

            <div className="col-3 mb-2">
                <label className=' fw-bold text-muted'>Quantitative</label>
                <span className='fw-bolder fs-6 text-dark mx-2'>
                    {data.quantitative}
                </span>
            </div>

            <div className="col-3 mb-2">
                <label className=' fw-bold text-muted'>AWA</label>
                <span className='fw-bolder fs-6 text-dark mx-2'>
                    {data.awa}
                </span>
            </div>
        </div>
    </div>
}