/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState, useRef } from "react"
import { Spinner } from "react-bootstrap-v5";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { PageLink, PageTitle } from "../../../theme/layout/core";
import { IAcademicProfile, IStudentExamDetails, StudentProfile } from "../student/models";
import { getStudentAcademicProfile, getStudentProfile } from "../student/services/StudentProfileService";
import { ProfileHeader } from "./components/ProfileHeader";
import { GraduationDetailsOverview } from "./components/GraduationDetailsOverview";
import { PDFExport } from "@progress/kendo-react-pdf";
import { FutureStudyOverview } from "./components/FutureStudyOverview";
import { WorkDetailsOverview } from "./components/WorkDetailsOverview";
import { ExamScoresOverview } from "./components/ExamScoresOverview";
import { PassportDetailsOverview } from "./components/PassportDetailsOverview";
import { Error500 } from "../../modules/errors/components/Error500";
import { ProfileTimeline } from "../../modules/common/components/ProfileTimeline";
import moment from "moment";
import { branchOptions } from "../../modules/common/DropdownOptions";
import { useSharedState } from "../../modules/common/SharedStateProvider";
import { DATE_TIME_FORMAT_DD_MMM_YYYY } from "../../modules/common/Helper";

type PathParamsType = {
    profileId: string,
}

type Student = {
    personal?: StudentProfile,
    academic?: IAcademicProfile,
    examDetails?: IStudentExamDetails,
}

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: 'All Profiles',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

export const ViewProfilePage: FC<PathParamsType & { showActions?: boolean, onError?: () => void }> = ({ profileId, showActions, onError }) => {
    const [profile, setProfile] = useState<Student>({});
    const [failed, setFailed] = useState(false);

    const pdfExportComponent = useRef(null);

    const exportPDF = () => {
        if (pdfExportComponent.current) {
            (pdfExportComponent.current as any).save();
        }
    };

    useEffect(() => {
        const fetchPersonalProfile = (profileId: number) => {
            getStudentProfile(profileId)
                .then(({ data: serverProfile }) => {
                    serverProfile.dateOfBirth = new Date(serverProfile.dateOfBirth).toLocaleDateString();
                    setProfile({ ...profile, personal: serverProfile });
                }).catch(err => {
                    //notify TODO
                    console.error(err);
                    setFailed(true);
                    onError && onError();
                })
        }

        if (profileId) {
            fetchPersonalProfile(+profileId);
        }
    }, [profileId]);

    useEffect(() => {
        const fetchAcademicProfile = (profileId: number) => {
            if (!profile.personal) return;
            const examDetails: IStudentExamDetails = { profileId: profile.personal.id, competitiveExam: profile.personal.competitiveExam, languageExam: profile.personal.languageExam }
            getStudentAcademicProfile(profileId)
                .then(({ data: serverProfile }) => {
                    if (!serverProfile.workExperiences) {
                        serverProfile = { ...serverProfile, workExperiences: [] };
                    }
                    setProfile({ ...profile, academic: serverProfile, examDetails: examDetails });
                }).catch(err => {
                    //notify TODO
                    console.error(err);
                    // history.push('/error/404')
                    setFailed(true);
                    onError && onError();
                })
        }
        fetchAcademicProfile(+profileId);
    }, [profile.personal]);

    if (failed) {
        return <Error500 />
    }

    if (!profile || !profile.personal || !profile.academic) {
        return <Spinner animation="grow" variant="primary" />
    }
    return (
        <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>{profile.personal?.firstName + " " + profile.personal?.lastName}</PageTitle>
            <ProfileHeader showActions={showActions} exportPDF={exportPDF} profile={profile.personal} />
            <PDFExport
                ref={pdfExportComponent}
                paperSize="auto"
                margin={40}
                fileName={`Profile Export - ${profile.personal.email}.pdf`}
                author="Aniket Dandawate"
                creator="Aniket Dandawate">
                <BasicProfileDetails profile={profile.personal}></BasicProfileDetails>
                <GraduationDetailsOverview academicsData={profile.academic} />
                <FutureStudyOverview academicsData={profile.academic} />
                {profile.academic?.workExperiences && <WorkDetailsOverview data={profile.academic.workExperiences} />}
                <ExamScoresOverview data={profile.examDetails} />
                <PassportDetailsOverview data={profile.personal} />
                <div className="d-flex bg-white p-3 m-1">
                    <label className="label m-1">Other Remarks:</label>
                    <b className="text-bold m-1">{profile.personal.otherInfo}</b>
                </div>
                <div className="d-flex bg-white p-3 m-1">
                    <label className="label m-1">Other Info Admin:</label>
                    <b className="text-bold m-1">{profile.personal.otherRemarks}</b>
                </div>
            </PDFExport>
        </>
    )
}

type BasicDetailsProps = {
    profile: StudentProfile
}

const BasicProfileDetails: React.FC<BasicDetailsProps> = ({ profile }) => {
    const { terms } = useSharedState();
    return <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
        <a
            key={'email'}
            href='#'
            className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
        >
            Email:
            {profile.email}
        </a>
        <a
            href='#'
            className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
        >
            Phone:
            {profile.phoneNumber || 'NA'}
        </a>
        <a
            key={'branch'}
            href='#'
            className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
        >
            Branch:
            {branchOptions.find((x) => x.value === profile.branchId)?.label}
        </a>
        <a
            key={'term'}
            href='#'
            className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
        >
            Intake:
            {terms && terms.find(x => x.id === profile.termId)?.name}
        </a>
        <a
            key={'createdBy'}
            href='#'
            className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
        >
            BLL:
            {profile.createdBy}
        </a>
        <a
            key={'createdOn'}
            href='#'
            className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
        >
            Created On:
            {moment(profile.createdOn).format(DATE_TIME_FORMAT_DD_MMM_YYYY)}
        </a>
    </div>
}

const ViewProfilePageWrapper: FC<RouteComponentProps<PathParamsType>> = ({ match: { params: { profileId } } }) => {
    return <>
        <ViewProfilePage profileId={profileId} showActions={true} />
        <ProfileTimeline profileId={+profileId} />
    </>
}

export default withRouter(ViewProfilePageWrapper);