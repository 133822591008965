import { FC } from 'react';
import { gradingTypeOptions } from '../../../../modules/common/DropdownOptions';
import { IDiplomaDegreeDetail, IGraduationScore } from '../../../student/models';
import { CollegeDetailView } from './CollegeDetailView';
import { GraduationScoreView } from './GraduationScoreView';

type Props = {
    data: IDiplomaDegreeDetail,
}

const config = [
    {
        fieldPath: 'firstYearScore',
        title: 'First Year Grades',
        show: true
    },
    {
        fieldPath: 'secondYearScore',
        title: 'Second Year Grades',
        show: true
    },
    {
        fieldPath: 'thirdYearScore',
        title: 'Third Year Grades',
        show: true
    },
]

export const DiplomaGradesView: FC<Props> = ({ data }) => {
    return <>
        <div className="row fv-row mb-5">
            <div className='col-xl-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Diploma Degree Grades</label>
            </div>
        </div>

        <div className='row mb-7'>
            <label className='fw-bold text-muted'>Grading System</label>
            <span className='fw-bolder fs-6 text-dark'>
                {gradingTypeOptions.find(x => x.value === data.gradingSystemId)?.label}
            </span>
        </div>
        <div className="row">
            {config.map(c => <div className="col-3 fv-row mb-10" key={c.fieldPath}>
                <GraduationScoreView
                    data={(data as any)[c.fieldPath] as IGraduationScore}
                    title={c.title} />
            </div>)}
        </div>
        <div className="row fv-row mb-10">
            <CollegeDetailView data={data.collegeDetail} />
        </div>
    </>
}