import clsx from "clsx"
import { FC, useEffect } from "react"

import Select from "react-select";
import { competitiveExamOptions } from "../../../../../modules/common/DropdownOptions";
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import moment from "moment";
import { CompetitiveExamType } from "../../../models/ExamModels";
import { GREScore } from "./ExamScoreComponents/GREScore";
import { GMATScore } from "./ExamScoreComponents/GMATScore";
import { SATScore } from "./ExamScoreComponents/SATScore";
import { ACTScore } from "./ExamScoreComponents/ACTScore";

type Props = {
    formik: any,
}

export const CompetitiveExamStep: FC<Props> = ({ formik }) => {
    useEffect(() => {
        if (!formik.values.competitiveExam?.isExamGiven) {
            formik.setFieldValue('competitiveExam.greScore', null);
            formik.setFieldValue('competitiveExam.gmatScore', null);
            formik.setFieldValue('competitiveExam.satScore', null);
            formik.setFieldValue('competitiveExam.actScore', null);
            return;
        }
        switch (formik.values.competitiveExam?.examType) {
            case CompetitiveExamType.GRE:
                formik.setFieldValue('competitiveExam.greScore', { ...formik.values.competitiveExam?.greScore });
                formik.setFieldValue('competitiveExam.gmatScore', null);
                formik.setFieldValue('competitiveExam.satScore', null);
                formik.setFieldValue('competitiveExam.actScore', null);
                break;
            case CompetitiveExamType.GMAT:
                formik.setFieldValue('competitiveExam.gmatScore', { ...formik.values.competitiveExam?.gmatScore });
                formik.setFieldValue('competitiveExam.greScore', null);
                formik.setFieldValue('competitiveExam.satScore', null);
                formik.setFieldValue('competitiveExam.actScore', null);
                break;
            case CompetitiveExamType.SAT:
                formik.setFieldValue('competitiveExam.satScore', { ...formik.values.competitiveExam?.satScore });
                formik.setFieldValue('competitiveExam.greScore', null);
                formik.setFieldValue('competitiveExam.gmatScore', null);
                formik.setFieldValue('competitiveExam.actScore', null);
                break;
            case CompetitiveExamType.ACT:
                formik.setFieldValue('competitiveExam.actScore', { ...formik.values.competitiveExam?.actScore });
                formik.setFieldValue('competitiveExam.greScore', null);
                formik.setFieldValue('competitiveExam.satScore', null);
                formik.setFieldValue('competitiveExam.gmatScore', null);
                break;
            default:
                break;
        }
        //eslint-disable-next-line
    }, [formik.values.competitiveExam?.examType, formik.values.competitiveExam?.isExamGiven]);

    return <div className='w-100'>
        <div className='pb-10 pb-lg-15'>
            <h2 className='fw-bolder d-flex align-items-center text-dark'>
                Fill in details about Qualifying Exam
            </h2>
        </div>
        {/* begin::Form group Firstname */}
        <div className='fv-row mb-10 row'>
            <div className="col-xl-6">
                <label className='form-label fw-bolder text-dark fs-6 required'>Qualifying Exam</label>
                <Select
                    options={competitiveExamOptions}
                    value={competitiveExamOptions.find(x => x.value === formik.values.competitiveExam?.examType)}
                    onFocus={() => formik.setFieldTouched('competitiveExam.examType')}
                    onChange={(opt) => formik.setFieldValue('competitiveExam.examType', opt?.value)}
                    className={clsx(`form-control form-control-sm p-0 `, {
                        'is-valid': formik.touched.competitiveExam?.examType && !formik.errors.competitiveExam?.examType,
                        'is-invalid': formik.touched.competitiveExam?.examType && formik.errors.competitiveExam?.examType
                    })}
                />
                {formik.touched.competitiveExam?.examType && formik.errors.competitiveExam?.examType && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{formik.errors.competitiveExam?.examType}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className="col-xl-6 mt-7">
                <FormControlLabel
                    control={<Checkbox
                        checked={formik.values.competitiveExam?.isExamGiven}
                        onChange={(ev) => formik.setFieldValue('competitiveExam.isExamGiven', ev.target.checked)}
                    />}
                    label='Already given and have scores?'
                />
            </div>
        </div>
        <div className="fv-row row mb-10">
            <div className='col-xl-4'>
                <label className='form-label required'>Exam Date</label>
                <input
                    placeholder='Exam Date'
                    type='date'
                    autoComplete='off'
                    {...formik.getFieldProps(`competitiveExam.examDate`)}
                    className={clsx(
                        'form-control form-control-sm ',
                        {
                            'is-invalid': formik.getFieldMeta(`competitiveExam.examDate`).touched
                                && formik.getFieldMeta(`competitiveExam.examDate`).error,
                        },
                        {
                            'is-valid': formik.getFieldMeta(`competitiveExam.examDate`).touched
                                && !formik.getFieldMeta(`competitiveExam.examDate`).error,
                        }
                    )}
                    onChange={(ev) => {
                        formik.setFieldValue(`competitiveExam.examDate`, ev.target.valueAsDate);
                    }}
                    min="2010-01-01"
                    value={formik.values.competitiveExam?.examDate ? moment(formik.values.competitiveExam?.examDate).format('YYYY-MM-DD') : null}
                />
                {formik.getFieldMeta(`competitiveExam.examDate`).touched
                    && formik.getFieldMeta(`competitiveExam.examDate`).error && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger mt-2'>
                                <span role='alert'>{formik.getFieldMeta(`competitiveExam.examDate`).error}</span>
                            </div>
                        </div>
                    )}
            </div>
        </div>

        {formik.values.competitiveExam?.isExamGiven && <div className="fv-row row mb-10">
            {formik.values.competitiveExam?.examType === CompetitiveExamType.GRE && <GREScore formik={formik} fieldPath={"competitiveExam"} />}
            {formik.values.competitiveExam?.examType === CompetitiveExamType.GMAT && <GMATScore formik={formik} fieldPath={"competitiveExam"} />}
            {formik.values.competitiveExam?.examType === CompetitiveExamType.SAT && <SATScore formik={formik} fieldPath={"competitiveExam"} />}
            {formik.values.competitiveExam?.examType === CompetitiveExamType.ACT && <ACTScore formik={formik} fieldPath={"competitiveExam"} />}
        </div>}
    </div>
}