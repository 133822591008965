import { FC } from "react";
import { getStudentsPendingApprovalPaginated } from "../../student-profiles/services/StudentManager";
import StudentList from "../../student-profiles/components/StudentList";
import tableIcons from "../../../modules/common/components/MaterialTableIcons";
import { useHistory } from "react-router-dom";

export const StudentsPendingApproval: FC<{ onLoad?: (count: number) => void }> = ({ onLoad }) => {
  const history = useHistory();
  return <>
    <StudentList
      title='Profiles Pending Approval'
      getStudentsFromServer={getStudentsPendingApprovalPaginated}
      onLoad={onLoad}
      useDefaultActions={false}
      actions={[{
        icon: tableIcons.OpenInNew as any,
        tooltip: 'View & Approve',
        disabled: false,
        onClick: (event: any, rowData: any) => {
          const url = `/student/${rowData.id}/approve`;
          history.push(url);
        },
      }]}
      disabledFilters={['branches', 'examDate', 'statuses', 'hasCompetitiveExamScores', 'hasLanguageExamScores']}
    />
  </>
}

