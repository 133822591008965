import Divider from "@material-ui/core/Divider"
import { FC } from "react"
import { useSharedState } from "../../../../modules/common/SharedStateProvider"
import { ICollegeDetail } from "../../../student/models"

type Props = {
    data: ICollegeDetail,
}

export const CollegeDetailView: FC<Props> = ({ data }) => {
    const sharedState = useSharedState();

    return <div className='row'>
        <div className="row mb-5">
            <label>College Details</label>
            <Divider variant='middle'  className='bg-danger mt-5' />
        </div>
       <div className="row mb-7">
       <div className="col-6">
            <div className="row">
                <label className='col-lg-4 fw-bold text-muted'>College</label>
                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>
                        {data.collegeName}
                    </span>
                </div>
            </div>
        </div>
        <div className="col-6">
            <div className="row">
                <label className='col-lg-4 fw-bold text-muted'>Passing Year</label>
                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>
                        {data.passingYear}
                    </span>
                </div>
            </div>
        </div>

       </div>
        <div className="row mb-7">
            <div className="col-6">
                <div className="row">
                    <label className='col-lg-4 fw-bold text-muted'>Field Of Study</label>
                    <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>
                            {data.fieldOfStudyName}
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <label className='col-lg-4 fw-bold text-muted'>University</label>
                    <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>
                            {sharedState.universities.find(x => x.value === data.universityId)?.label}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
}