import React, { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import FolderOpen from "@material-ui/icons/FolderOpen";
import OpenInNew from "@material-ui/icons/OpenInNew";
import Approve from "@material-ui/icons/CheckCircle";
import Suggest from '@material-ui/icons/EmojiObjectsOutlined'
import Publish from '@material-ui/icons/Publish'
import Impersonate from '@material-ui/icons/AccountBox'
import Download from '@material-ui/icons/CloudDownload'
import { KTSVG } from "../../../../theme/helpers";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} color="primary" />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  FolderOpen: forwardRef((props, ref) => <FolderOpen {...props} ref={ref} />),
  OpenInNew: forwardRef((props, ref) => <OpenInNew {...props} ref={ref} />),
  Approve: forwardRef((props, ref) => <Approve {...props} ref={ref} color="action" />),
  Suggest: forwardRef((props, ref) => <Suggest {...props} ref={ref} color="secondary" />),
  Publish: forwardRef((props, ref) => <Publish {...props} ref={ref} style={{ width: "1.7em", height: "1.7em" }} />),
  Upload: forwardRef((props, ref) => <Publish {...props} ref={ref} />),
  Download: forwardRef((props, ref) => <Download {...props} ref={ref} />),
  Impersonate: forwardRef((props, ref) => <Impersonate {...props} ref={ref} color="secondary"/>),
  LOR: forwardRef((props, ref) => <KTSVG {...props} ref={ref} path="/media/icons/duotune/social/lor.svg" className='svg-icon-2'/>),
  SOP: forwardRef((props, ref) => <KTSVG {...props} ref={ref} path="/media/icons/duotune/social/sop.svg" className='svg-icon-2'/>),
  NA: forwardRef((props, ref) => <KTSVG {...props} ref={ref} path="/media/icons/duotune/social/NA.svg" className='svg-icon-2'/>),
};

export default tableIcons;
