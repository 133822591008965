import React, { FC, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../theme/layout/core";
import { ErrorsPage } from "../../errors/ErrorsPage";
import { verifyEmail } from "../redux/AuthCRUD";

export const VerifyEmailPage: FC = () => {
    const query = useLocation().search;
    const queryParams = new URLSearchParams(query);
    const userId = queryParams.get('userId');
    const code = queryParams.get('code');

    const history = useHistory();

    useEffect(() => {
        const sendVerificationRequest = async (userId: string, code: string) =>{
            try {
                const {data: {data: token}} = await verifyEmail(userId, code);
                history.push(`/auth/reset?code=${token}`);
            } catch (error) {
                //notification with toastr
                history.push('/error/500');
            }
        }
        if(userId && code){
            sendVerificationRequest(userId, code);
        }
    }, [userId, code, history]);

    if (!userId || !code) {
        return <ErrorsPage />
    }

    return <LayoutSplashScreen />
}

