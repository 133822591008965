/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment"
import { FC } from "react"
import { toAbsoluteUrl } from "../../../../theme/helpers"
import { IAdminSuggestionCommentResponseDto, ISuggestedUniversityCommentResponseDto } from "../models/UniversitySuggestionDtos"
import TooltipInfo, { GenericTooltip } from '../../../modules/common/components/TooltipInfo';
import { DATE_FORMAT_DD_MMM_YYYY } from "../../../modules/common/Helper";

export const CommentForm: FC<{ onChange: (input: string) => void }> = ({ onChange }) => {
    return <form className='position-relative mb-6 ps-10'>
        <textarea
            className='form-control pe-10 resize-none min-h-50px'
            data-kt-autosize='true'
            rows={1}
            placeholder='Comments'
            onChange={(ev) => onChange(ev.target.value)}
        ></textarea>
    </form>
}

type CommentProps = {
    comment: ISuggestedUniversityCommentResponseDto | IAdminSuggestionCommentResponseDto
}

export const Comment: FC<CommentProps> = ({ comment }) => {
    return <div className='d-flex mb-5'>
        <div className='symbol symbol-45px me-5'>
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
        </div>
        <div className='d-flex flex-column flex-row-fluid'>
            <div className='d-flex align-items-center flex-wrap mb-1'>
                <a href='#' className='text-gray-800 text-hover-primary fw-bolder me-2'>
                    <GenericTooltip message={comment.commentorUsername} childEle={<a href="#">{comment.commentorName}</a>} />
                </a>
                <span className='text-gray-400 fw-bold fs-7'>{moment(comment.commentDate).fromNow()}
                    <TooltipInfo message={moment(comment.commentDate).format(DATE_FORMAT_DD_MMM_YYYY)} />
                </span>
            </div>
            <span className='text-gray-800 fs-7 fw-normal pt-1'>
                {comment.comment}
            </span>
        </div>
    </div>
}

export const SuggestionComments: FC<{ comments: Array<ISuggestedUniversityCommentResponseDto> }> = ({ comments }) => {
    return <div className="mb-7 ps-10">
        <h4 className="text-gray-800 fs-7 fw-bolder pb-1 mb-5">Comments</h4>
        {comments?.length === 0 && <p>No comments available</p>}
        {comments?.map((comment, index) => <Comment comment={comment} key={index} />)}
    </div>
}

export const AdminComments: FC<{ comments: Array<IAdminSuggestionCommentResponseDto> }> = ({ comments }) => {
    return <div className="mb-7 ps-10">
        <h4 className="text-gray-800 fs-7 fw-bolder pb-1 mb-5">Admin Comments</h4>
        {comments?.length === 0 && <p>No comments available</p>}
        {comments?.map((comment, index) => <Comment comment={comment} key={index} />)}
    </div>
}
