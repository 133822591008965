import { FC, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { getProfileIdFromUserId } from '../../../../modules/common/CommonService';

type PathParamsType = {
    userId: string
}
const UserToProfileRedirect: FC<RouteComponentProps<PathParamsType>> = ({ history, match: { params: { userId } } }) => {
    const [profileId, setProfileId] = useState<number | undefined>(undefined);
    const [hasError, setError] = useState<boolean>(false);
    useEffect(() => {
        const fetchProfileId = async (userId: string) => {
            try {
                const resp = await getProfileIdFromUserId(userId);
                if(!resp.data){
                    setError(true);
                    return;
                }
                setProfileId(resp.data);
            } catch (error) {
                setError(true);
            }
        }
        if (userId) {
            fetchProfileId(userId);
        }
    }, [userId])
    
    if(hasError){
        return <p className='alert alert-danger'>User does not have student profile</p>
    }
    return profileId ? <Redirect from={`/student/${userId}/redirectToProfileEdit`} to={`/student/${profileId}/edit`} /> : <Spinner animation='grow' variant='primary' />
}

export default withRouter(UserToProfileRedirect);