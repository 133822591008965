import axios from 'axios'
import { QuestionnaireTypeId } from '../../../modules/common/enums/QuestionnaireTypeId'
import { ApproveOrRejectDocumentCommand } from '../../dashboard/ContentWriterDashboard'
import { UploadFileDto } from '../../dashboard/_modals/UploadDocumentDialog'
import {
  IQuestionResponseDto,
  IQuestionnaireDetailResponseDto,
  ICreateLORCommand,
  IUpdateLORCommand,
  IUpdateSOPCommand,
} from '../models'

const API_URL = process.env.REACT_APP_API_URL
export const LOR_API = `${API_URL}/api/v1/lor`
export const SOP_API = `${API_URL}/api/v1/sop`

export const getQuestionsForLor = (questionnaireTypeId: QuestionnaireTypeId, profileId?: number) => {
  return axios.get<Array<IQuestionResponseDto>>(`${LOR_API}/questions/${questionnaireTypeId}?profileId=${profileId}`)
}

export const getLorDetails = (profileId: number) => {
  return axios.get<Array<IQuestionnaireDetailResponseDto>>(`${LOR_API}/details/${profileId}`)
}

export const addLorDetails = (profileId: number, request: ICreateLORCommand) => {
  return axios.post(`${LOR_API}/details/${profileId}`, request)
}

export const updateLorDetails = (profileId: number, request: IUpdateLORCommand) => {
  return axios.put(`${LOR_API}/details/${profileId}`, request)
}

export const deleteLorDetails = (profileId: number, lorDetailsId: number) => {
  return axios.delete(`${LOR_API}/details/${profileId}/${lorDetailsId}`)
}

export const getSopDetails = (profileId: number) => {
  return axios.get<IQuestionnaireDetailResponseDto>(`${SOP_API}/details/${profileId}`)
}

export const updateSopDetails = (profileId: number, request: IUpdateSOPCommand) => {
  return axios.put(`${SOP_API}/details/${profileId}`, request)
}

export const submitSop = (profileId: number) => {
  return axios.post(`${SOP_API}/submit/${profileId}`)
}

export const submitLor = (profileId: number) => {
  return axios.post(`${LOR_API}/submit/${profileId}`)
}

export function approveLor(profileId: number) {
  return axios.post(`${LOR_API}/approve/${profileId}`)
}

export function approveSop(profileId: number) {
  return axios.post(`${SOP_API}/approve/${profileId}`)
}

export function rejectLor(profileId: number, comment: string) {
  return axios.post(`${LOR_API}/reject/${profileId}`, { profileId, comment })
}

export function rejectSop(profileId: number, comment: string) {
  return axios.post(`${SOP_API}/reject/${profileId}`, { profileId, comment })
}

export function uploadLor(profileId: number, req: UploadFileDto) {
  return axios.post(`${LOR_API}/upload/${profileId}`, req)
}

export function uploadSop(profileId: number, req: UploadFileDto) {
  return axios.post(`${SOP_API}/upload/${profileId}`, req)
}

export function delegateLor(profileId: number, assignedToEmail: string) {
  return axios.post(`${LOR_API}/delegate/${profileId}/${assignedToEmail}`)
}

export function delegateSop(profileId: number, assignedToEmail: string) {
  return axios.post(`${SOP_API}/delegate/${profileId}/${assignedToEmail}`)
}
export function approveOrRejectDocument(req: ApproveOrRejectDocumentCommand) {
  return axios.post(`${LOR_API}/delegate/${req.profileId}/approve-or-reject/`, req)
}
