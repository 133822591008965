import { Switch } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap-v5';
import Select from 'react-select';
import { AsyncDropdown } from '../../../modules/common/components/AsyncDropdown';
import { enumToKeyValue } from '../../../modules/common/EnumHelper';
import { DropdownOption } from '../../../modules/common/SharedStateProvider';
import { ISuggestedUniversityResponseDto, IUniversityBasicDetailsDto, SuggestionLevel } from '../models/UniversitySuggestionDtos';

type Props = {
    onUpdate: (data: ISuggestedUniversityResponseDto) => void,
    data: ISuggestedUniversityResponseDto
    options: IUniversityBasicDetailsDto[],
};

export const UpdateSuggestionPanel: FC<Props> = ({ data, onUpdate, options }) => {
    const [suggestion, setSuggestion] = useState<ISuggestedUniversityResponseDto>(data);
    const [partners, setPartners] = useState<Array<DropdownOption>>();

    const getFormattedOption = (x: IUniversityBasicDetailsDto) => {
        const { acceptPerc, rejectPerc } = getAnalyticsPerc(x);
        const label = `${x.universityName} ${x.partnered ? '(Partnered)' : ''} (${acceptPerc}%↑ / ${rejectPerc}%↓)`;
        const option = ({ label, value: x.id })
        return option;
    }
    const suggestions = options
        .sort((a, b) => getAnalyticsPerc(b).acceptPerc - getAnalyticsPerc(a).acceptPerc)
        .sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0))
        .map(x => getFormattedOption(x));

    const handleSaveSuggestion = () => {
        onUpdate(suggestion);
    }

    useEffect(() => {
        if (suggestion.universityId) {
            const partnerOptions = options.find(x => x.id === suggestion.universityId)?.universityPartners?.map(x => ({ label: x.name, value: x.id }));
            setPartners(partnerOptions || []);
        }
    }, [suggestion.universityId, options, setPartners]);

    return <div className="row fv-row mb-7">
        <div className="row">
            <div className="col-xl-12">
                <div className="row">
                    <div className="col-xl-5">
                        <label className="required mb-2 form-label fw-bold">University</label>
                        <AsyncDropdown
                            initialCount={400}
                            options={suggestions}
                            onSelected={(val) => {
                                const uni: IUniversityBasicDetailsDto = options.find(x => x.id === val)!;
                                setSuggestion({ ...suggestion, universityId: uni.id, university: uni })
                            }}
                            selected={suggestion.universityId}
                        />
                    </div>
                    <div className="col-xl-2">
                        <label className='form-label fw-bold  mb-2'>Level</label>
                        <Select
                            menuPlacement="top"
                            options={enumToKeyValue(SuggestionLevel) as DropdownOption[]}
                            onChange={(opt) => {
                                setSuggestion({ ...suggestion, suggestionLevel: +opt?.value })
                            }}
                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                            value={enumToKeyValue(SuggestionLevel)?.find(x => +x.value === suggestion.suggestionLevel)} />
                    </div>
                    <div className="col-xl-3">
                        <label className='form-label fw-bold  mb-2'>Partner</label>
                        <Select
                            menuPlacement="top"
                            options={partners}
                            onChange={(opt) => {
                                setSuggestion({ ...suggestion, partnerId: +opt?.value })
                            }}
                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                            value={partners?.find(x => x.value === suggestion.partnerId) || null} />
                    </div>
                    <div className="col-xl-3">
                        <div className="py-5">
                            <label className='form-label fw-bold'>Is Direct?:</label>
                            <Switch defaultChecked={suggestion.isDirect} onChange={(ev) => {
                                setSuggestion({ ...suggestion, isDirect: ev.target.checked })
                            }} /> {suggestion.isDirect ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="row col-xl-2 my-2">
                        <Button variant="outline-primary" disabled={!suggestion.universityId} className='btn btn-outline btn-sm'
                            onClick={() => {
                                handleSaveSuggestion();
                            }}>
                            Update
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

function getAnalyticsPerc(x: IUniversityBasicDetailsDto) {
    const { numberOfAccepts, numberOfRejects, numberOfSuggests } = x.universityFieldOfStudyMeta;
    const acceptPerc = numberOfSuggests > 0 ? Math.round(numberOfAccepts * 100 / numberOfSuggests) : 0;
    const rejectPerc = numberOfSuggests > 0 ? Math.round(numberOfRejects * 100 / numberOfSuggests) : 0;
    return { acceptPerc, rejectPerc };
}
