import axios, { AxiosResponse } from 'axios';
import { ApiResponse } from '../../../modules/common/ApiResponse';
import { AddPartnerRequest, IPartner, UpdatePartnerRequest } from '../models/PartnerRequests';

const PARTNER_API_BASE_URL = `${process.env.REACT_APP_API_URL}/api/v1/partners`;

export function addPartner(request: AddPartnerRequest): Promise<AxiosResponse<ApiResponse<number>>> {
    return axios.post<ApiResponse<number>>(PARTNER_API_BASE_URL, request);
}

export function updatePartner(request: UpdatePartnerRequest): Promise<AxiosResponse<ApiResponse<number>>> {
    return axios.put<ApiResponse<number>>(`${PARTNER_API_BASE_URL}/${request.id}`, request);
}

export function getPartners(search: string) {
    return axios.get<ApiResponse<IPartner[]>>(`${PARTNER_API_BASE_URL}?query=${search}`);
}

export function getPartner(id: number) {
    return axios.get<IPartner>(`${PARTNER_API_BASE_URL}/${id}`);
}