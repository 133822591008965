import { FC } from "react"
import { ACTExamScoreDto } from "../../../student/models/ExamModels"

type Props = {
    data: ACTExamScoreDto,
}

export const ACTScoreView: FC<Props> = ({ data }) => {

    return <div className='row mb-7'>
        <div className="row mb-5">
            <label className="fw-bold text-dark mt-5">Score Breakdown</label>
        </div>
        <div className="row mb-7">
            <label className='col-lg-4 fw-bold text-muted'>Total</label>
            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                    {data.total}
                </span>
            </div>
        </div>

        <div className="row mb-7">
            <label className='col-lg-4 fw-bold text-muted'>English</label>
            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                    {data.english}
                </span>
            </div>
        </div>

        <div className="row mb-7">
            <label className='col-lg-4 fw-bold text-muted'>Math</label>
            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                    {data.math}
                </span>
            </div>
        </div>

        <div className="row mb-7">
            <label className='col-lg-4 fw-bold text-muted'>Reading</label>
            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                    {data.reading}
                </span>
            </div>
        </div>

        <div className="row mb-7">
            <label className='col-lg-4 fw-bold text-muted'>Science</label>
            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                    {data.science}
                </span>
            </div>
        </div>
    </div>
}