import { FC, useEffect, useState } from "react"
import { ExamWizard } from "../../components/Exam/ExamWizard"
import { IStudentExamDetails } from "../../models"
import { getExamDetails, updateExamDetails } from "../../services/StudentProfileService"
import { Spinner } from "react-bootstrap-v5"
import { Error500 } from "../../../../modules/errors/components/Error500"

type PathParamsType = {
    profileId: string,
}

const EditExamDetails: FC<PathParamsType> = ({ profileId }) => {
    const [exam, setExamDetails] = useState<IStudentExamDetails>();
    const [failed, setFailed] = useState(false);

    useEffect(() => {
        const fetchProfile = (profileId: number) => {
            getExamDetails(profileId)
                .then(({ data: details }) => {
                    setExamDetails({...details, profileId});
                }).catch(err => {
                    console.error(err);
                    setFailed(true);
                })
        }
        if (profileId) {
            fetchProfile(+profileId);
        }
        // eslint-disable-next-line
    }, [profileId]);

    if (failed) {
        return <Error500 />
    }

    if (!exam) {
        return <Spinner animation="grow" variant="primary" />
    }

    return <ExamWizard examDetails={exam} submitFn={(examDetails: IStudentExamDetails) => {
        return updateExamDetails(+profileId, { ...examDetails, profileId: +profileId })
    }} />
}

export default EditExamDetails;