export enum AllRoles {
    Student = 1,
    BranchLevelLogin = 2,
    BranchAdmin = 3,
    OverallAdmin = 4,
    SuperAdmin = 5,
    PartneredUniversityLogin = 6,
    ContentWriter = 7,
}
export enum AdminRoles {
    BranchLevelLogin = 2,
    BranchAdmin = 3,
    OverallAdmin = 4,
    SuperAdmin = 5,
    PartneredUniversityLogin = 6,
    ContentWriter = 7,
}

export enum StudentRoles{
    Student = 1,
}