/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import { useMessageContext } from '../../common/MessageContext';
import * as messageService from '../messageService';

export const ChatOptions: FC<{ conversationId: number }> = ({ conversationId }) => {
    const { refresh } = useMessageContext();
    const [selectedOption, setSelectedOption] = useState('');
    const apply = () => {
        switch (selectedOption) {
            case 'close':
                messageService.markAsClosed(conversationId)
                    .then(() => {
                        toast('Conversation is marked as closed', { type: 'success' });
                        refresh(conversationId);
                    });
                break;
            case 'escalate':
                messageService.escalate(conversationId)
                    .then(() => {
                        toast('Conversation is escalated to higher level', { type: 'success' });
                        refresh(conversationId);
                    });
        }
    }
    return (
        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Chat Options</div>
            </div>
            <div className='separator border-gray-200'></div>
            <div className='px-7 py-5'>
                <div className='mb-10'>
                    <ListGroup>
                        <ListGroupItem action className='cursor-pointer' onClick={() => setSelectedOption('close')}>
                            <label className='form-label fw-bold col-12'>
                                <a href='#'>Mark as Closed</a>
                            </label>
                        </ListGroupItem>
                        <ListGroupItem action className='cursor-pointer' onClick={() => setSelectedOption('escalate')}>
                            <label className='form-label fw-bold col-12'>
                                <a href='#'>Escalate</a>
                            </label>
                        </ListGroupItem>
                    </ListGroup>
                </div>
                <div className='d-flex justify-content-end'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-white btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                    >
                        Reset
                    </button>
                    <button onClick={apply} className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                        Apply
                    </button>
                </div>
            </div>
        </div>
    )
}
