/* eslint-disable jsx-a11y/anchor-is-valid */
import Divider from '@material-ui/core/Divider'
import moment from 'moment'
import { FC } from 'react'
import Notice from '../../../modules/common/components/Notice'
import { IWorkExperience } from '../../student/models'
import { DATE_FORMAT_DD_MMM_YYYY } from '../../../modules/common/Helper'

type Props = {
    data: IWorkExperience[]
}

export const WorkDetailsOverview: FC<Props> = ({ data }) => {
    const NotFoundNotice: FC = () => <Notice title='No Work Experience' message='' />

    return (
        <>
            <div className='card mb-xl-10'>
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Work Experience</h3>
                    </div>
                </div>

                <div className='card-body'>
                    {data?.length ?
                        data.map(exp =>
                            <>
                                <div className='row fv-row my-2'>
                                    <h3> {exp.designation} @ {exp.companyName}</h3>
                                    <Divider className='my-2 bg-primary' />
                                </div>
                                <WorkExpView exp={exp} />
                                <Divider className='my-5 bg-primary' />
                            </>)
                        :
                        <NotFoundNotice />
                    }
                </div>
            </div>
        </>
    )
}
type WorkExpProp = {
    exp: IWorkExperience
}

const WorkExpView: FC<WorkExpProp> = ({ exp }) => (
    <>
        <div className='row mb-2'>
            <div className="col-3">
                <label className='fw-bold text-muted mx-2'> Company Name:</label>
                <span className='fw-bolder fs-6 text-dark'>
                    {exp.companyName}
                </span>
            </div>
            <div className="col-3">
                <label className='fw-bold text-muted mx-2'>Designation</label>
                <span className='fw-bolder fs-6 text-dark'>
                    {exp.designation}
                </span>
            </div>
            <div className="col-3">
                <label className='fw-bold text-muted mx-2'>Start Date</label>
                <span className='fw-bolder fs-6 text-dark'>
                    {moment(exp.startDate).format(DATE_FORMAT_DD_MMM_YYYY)}
                </span>
            </div>
            <div className="col-3">
                <label className='fw-bold text-muted mx-2'>End Date</label>
                <span className='fw-bolder fs-6 text-dark'>
                    {moment(exp.endDate).format(DATE_FORMAT_DD_MMM_YYYY)}
                </span>
            </div>
            <div className="col-12 my-2">
                <label className='fw-bold text-muted mx-2'>Job Description</label>
                <span className='fw-bolder fs-6 text-dark'>
                    {exp.description}
                </span>
            </div>
        </div>
    </>)