/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC, useEffect, useState } from 'react'
import { Redirect, Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { MasterLayout } from '../../theme/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout, AuthPage } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { RootState } from '../../setup'
import { MasterInit } from '../../theme/layout/MasterInit'
import { NotificationProvider } from '../modules/common/NotificationContext'
import { MessageProvider } from '../modules/common/MessageContext'

const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual);
  const location = useLocation();
  const history = useHistory();

  const [returnUrl, setReturnUrl] = useState('');

  useEffect(() => {
    if (location.pathname
      && location.pathname.indexOf('auth') === -1) {
      if (location.pathname.indexOf('impersonate') !== -1) {
        setReturnUrl('/');
      } else {
        // other url
        setReturnUrl(location.pathname);
      }
    }
  }, [location.pathname, setReturnUrl])

  useEffect(() => {
    if (returnUrl && location.pathname.indexOf('auth') > -1) {
      history.push(`${location.pathname}?returnUrl=${returnUrl}`);
    }
    //eslint-disable-next-line
  }, [returnUrl])

  return (
    <>
      <Switch>
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from='/auth' to={returnUrl || '/'} />
        )}

        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to={`/auth/login?returnUrl=${location.pathname}${location.search}`} />
        ) : (
          <>
            <NotificationProvider>
              <MessageProvider>
                <MasterLayout>
                  <PrivateRoutes />
                </MasterLayout>
              </MessageProvider>
            </NotificationProvider>
          </>
        )}
      </Switch>
      <MasterInit />
    </>
  )
}

export { Routes }
