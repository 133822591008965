/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react"
import Notice from "../../../modules/common/components/Notice";
import { suggestionStateOptions } from "../../../modules/common/DropdownOptions";
import { ISuggestedUniversityResponseDto, IUniversityBasicDetailsDto, SuggestedUniversityStatus, UniversitySuggestionState } from "../models/UniversitySuggestionDtos";
import { Suggestion } from "./Suggestion"
import { CommentForm, SuggestionComments } from "./SuggestionComments";
import { getPartners } from "../../partner-management/services/PartnerService";
import { IPartner } from "../../partner-management/models/PartnerRequests";
import { Spinner } from "react-bootstrap-v5";
import { ConfirmationModal } from "../../../modules/common/components/ConfirmationModal";
import { UpdateSuggestionPanel } from "./UpdateSuggestionPanel";

type SuggestUniversitiesMetaProps = {
    formik: any,
    suggestionOptions: IUniversityBasicDetailsDto[],
}

export const SuggestUniversitiesForm: FC<SuggestUniversitiesMetaProps> = ({ formik, suggestionOptions }) => {
    const className = 'mb-5';
    const handleDelete = (universityId: number) => {
        if (window.confirm('Do you want to delete this suggestion? note that you will still have to click save to persist this action')) {
            const filtered = formik.values.suggestions.filter((x: any) => x.universityId !== universityId);
            formik.setFieldValue('suggestions', filtered);
        }
    }
    const [suggToEdit, setSuggToEdit] = useState<ISuggestedUniversityResponseDto>();
    const handleEdit = (sugg: ISuggestedUniversityResponseDto) => {
        setSuggToEdit(sugg);
    }
    //get all partners
    const [partners, setPartners] = useState<IPartner[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchData = () => {
            setLoading(true);
            getPartners('').then((resp) => {
                setPartners(resp.data.data);
            }).finally(() => { setLoading(false); })
        }
        fetchData();
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        console.log(formik.values.suggestions)
    }, [formik.values.suggestions]);

    if (loading) {
        return <Spinner variant="primary" animation={"grow"} />
    }

    return <>
        {suggToEdit && <ConfirmationModal onCancel={() => {
            setSuggToEdit(undefined);
        }} onConfirm={function (): void {
            setSuggToEdit(undefined);
        }} title={"Edit Suggestion"}
            children={<UpdateSuggestionPanel data={suggToEdit}
                onUpdate={(data: ISuggestedUniversityResponseDto) => {
                    const edited: ISuggestedUniversityResponseDto = formik.values.suggestions.find((x: ISuggestedUniversityResponseDto) => x.id === data.id);
                    edited.suggestionLevel = data.suggestionLevel;
                    edited.isDirect = data.isDirect;
                    edited.universityId = data.universityId;
                    edited.university = data.university;
                    var index = formik.values.suggestions.indexOf(edited);
                    const currentSet = formik.values.suggestions;
                    currentSet[index] = edited;

                    formik.setFieldValue('suggestions', currentSet.map((x: any) => x));
                    setSuggToEdit(undefined);
                }}
                options={suggestionOptions} />}
            showFooter={false}
        />}

        {formik.values.suggestions.length === 0 && <Notice message="" type="warning" title="No universities suggested" />}
        {formik.values.suggestions.length > 0 && <>
            <h4 className="text-center mb-7 mt-5">Suggestions</h4>

            {(formik.values.state === UniversitySuggestionState.WaitingOnAdmin || formik.values.state === UniversitySuggestionState.WaitingOnSuperAdmin)
                && <Notice type="primary" message="Waiting admin response."
                    title={suggestionStateOptions.find(x => x.value === formik.values.state)?.label} />}
            {formik.values.state === UniversitySuggestionState.Accepted && <Notice type="success" message="University Suggestions have been accepted" title="Accepted" />}
            {formik.values.state === UniversitySuggestionState.Finalized && <Notice type="success" message="Student has finalized the university" title="Finalized" />}
            {formik.values.suggestions
                .sort((a: ISuggestedUniversityResponseDto, b: ISuggestedUniversityResponseDto) => a.suggestionLevel - b.suggestionLevel)
                .map((sugg: any, idx: number) => {
                    return <div className={`card ${className}`} key={idx}>
                        <Suggestion index={idx + 1} suggestion={sugg} onEdit={handleEdit} onDelete={handleDelete} renderCallback={(suggestion) => {
                            return <>
                                <span className={`fw-bold badge m-1 w-20 badge-light-success`}>{suggestion.isDirect ? 'Direct' : 'Non-Direct'}</span>
                                {<span className={`fw-bold badge m-1 w-20 badge-light-danger`}>{partners?.find(x => x.id === suggestion.partnerId)?.name ?? 'No Partner'}</span>}
                            </>
                        }} />
                        <div className='separator mb-4'></div>
                        <SuggestionComments comments={sugg.comments} />
                        <div className='separator mb-4'></div>
                        {
                            formik.getFieldMeta(`suggestions[${idx}].status`).value === SuggestedUniversityStatus.RequestedChanges &&
                            <CommentForm onChange={(comment) => {
                                formik.setFieldValue(`suggestions[${idx}].comment`, comment)
                                formik.setFieldTouched(`suggestions[${idx}]`);
                            }} />
                        }
                    </div>
                })}
        </>}
    </>;
}
