import axios from 'axios';
import { StudentTypeEnum } from '../../../modules/common/enums/StudentTypeEnum';
import { GraduationType, IAcademicProfile, IBachelorDegreeDetail } from '../models';
import { ScheduledJobResponseDto } from '../models/EmailAlertSchedule';
import { IStudentConfig, IStudentExamDetails, StudentProfile } from '../models/StudentProfile';
import { UpdateStudentProfileRequest } from '../models/UpdateStudentProfileRequest';

const API_URL = process.env.REACT_APP_API_URL;
export const PROFILE_API = `${API_URL}/api/v1/student-profile`

export function getStudentProfile(id: number) {
    return axios.get<StudentProfile>(`${PROFILE_API}/${id}`);
}

export function saveStudentProfile(profile: UpdateStudentProfileRequest) {
    return axios.put(`${PROFILE_API}/${profile.id}`, profile);
}

export function getStudentAcademicProfile(profileId: number) {
    return axios.get<IAcademicProfile>(`${PROFILE_API}/${profileId}/academics/`);
}

export function saveStudentAcademicProfile(profile: IAcademicProfile) {
    return axios.put(`${PROFILE_API}/${profile.profileId}/academics/`, transformGraduationDetails(profile));
}

export function getExamDetails(profileId: number) {
    return axios.get<IStudentExamDetails>(`${PROFILE_API}/${profileId}/exams/`);
}

export function updateExamDetails(profileId: number, examDetails: IStudentExamDetails) {
    return axios.put(`${PROFILE_API}/${profileId}/exams/`, examDetails);
}

function transformGraduationDetails(profile: IAcademicProfile) {
    switch (profile.graduationDetail.graduationType) {
        case GraduationType.Bachelors:
            delete profile.graduationDetail.juniorCollegeDetail;
            delete profile.graduationDetail.diplomaDegreeDetail;
            delete profile.graduationDetail.masterDegreeDetail;
            delete profile.graduationDetail.diplomaPlusBachelorDegreeDetail;
            transformBachelors(profile.graduationDetail.bachelorDegreeDetail);
            break;
        case GraduationType.Diploma:
            delete profile.graduationDetail.juniorCollegeDetail
            delete profile.graduationDetail.bachelorDegreeDetail
            delete profile.graduationDetail.masterDegreeDetail
            delete profile.graduationDetail.diplomaPlusBachelorDegreeDetail
            break;
        case GraduationType.JuniorCollege:
            delete profile.graduationDetail.bachelorDegreeDetail
            delete profile.graduationDetail.diplomaDegreeDetail
            delete profile.graduationDetail.masterDegreeDetail
            delete profile.graduationDetail.diplomaPlusBachelorDegreeDetail
            break;
        case GraduationType.DiplomaPlusBachelorDegree:
            delete profile.graduationDetail.juniorCollegeDetail
            delete profile.graduationDetail.diplomaDegreeDetail
            delete profile.graduationDetail.masterDegreeDetail
            delete profile.graduationDetail.bachelorDegreeDetail
            transformBachelors(profile.graduationDetail.diplomaPlusBachelorDegreeDetail?.bachelorDegreeScore);
            break;
        case GraduationType.Masters:
            delete profile.graduationDetail.juniorCollegeDetail
            delete profile.graduationDetail.diplomaDegreeDetail
            delete profile.graduationDetail.bachelorDegreeDetail
            delete profile.graduationDetail.diplomaPlusBachelorDegreeDetail
            transformBachelors(profile.graduationDetail.masterDegreeDetail?.bachelorDegreeScore);
            break;
        default:
            break;
    }
    return profile;

    function transformBachelors(bachelorDegreeDetail?: IBachelorDegreeDetail) {
        if (bachelorDegreeDetail?.fourthYearScore && !bachelorDegreeDetail.fourthYearScore.score) {
            delete bachelorDegreeDetail.fourthYearScore;
        }
        if (bachelorDegreeDetail?.fifthYearScore && !bachelorDegreeDetail.fifthYearScore.score) {
            delete bachelorDegreeDetail.fifthYearScore;
        }
    }
}

export function submitProfile(profileId: number) {
    return axios.post(`${PROFILE_API}/${profileId}/submit-profile/`);
}

export function approveProfile(profileId: number) {
    return axios.post(`${PROFILE_API}/${profileId}/approve-profile/`);
}

export function getConfig(profileId: number) {
    return axios.get<IStudentConfig>(`${PROFILE_API}/${profileId}/config/`);
}

export function getTimeline(profileId: number) {
    return axios.get(`${PROFILE_API}/${profileId}/timeline/`);
}

export function getEmailAlerts(profileId: number) {
    return axios.get<Array<ScheduledJobResponseDto>>(`${PROFILE_API}/${profileId}/email-alerts/`);
}

export function updateEmailAlerts(profileId: number, jobs: Array<ScheduledJobResponseDto>) {
    return axios.post(`${PROFILE_API}/${profileId}/email-alerts/`, jobs);
}

export function updateStudentType(profileId: number, studentType?: StudentTypeEnum) {
    return axios.put(`${PROFILE_API}/${profileId}/student-type/`, { studentType, profileId });
}

export function addProfileComment(profileId: number, comment: string) {
    return axios.post(`${PROFILE_API}/${profileId}/comments`, { profileId, comment });
}