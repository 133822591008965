/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap-v5';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '../../../modules/common/components/ConfirmationModal';
import Notice from '../../../modules/common/components/Notice';
import { submitLor } from '../services/LorService';
import { ViewLorResponse } from './ViewLorQuestionnairePage';

type Props = {
    profileId: string
}

const LorSubmitPage: FC<RouteComponentProps<Props>> = ({ history, match: { params: { profileId } } }) => {
    const [confirm, setConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleSubmit = async () => {
        try {
            setLoading(true);
            await submitLor(+profileId);
            toast('LOR Submitted.', { type: 'success' });
            history.push('/');
        } finally {
            setLoading(false);
        }
    }
    return <>
        {loading && <Spinner variant="primary" animation="grow" />}
        <ViewLorResponse profileId={profileId} />
        <div className="row mt-10 bg-white py-5">
            <div className="col-xl-3">
                <Button className='btn btn-lg m-2' onClick={() => setConfirm(true)}>Submit</Button>
                <Button className='btn btn-lg btn-secondary' onClick={() => history.push('/')}>Cancel</Button>
            </div>
        </div>
        {confirm && <ConfirmationModal title='Submit LOR?' onConfirm={() => {
            handleSubmit();
            setConfirm(false);
        }}
            onCancel={() => setConfirm(false)}
        >
            <Notice message='Once approved, you will not be able to change the answers.' />
        </ConfirmationModal>}
    </>
}

export default withRouter(LorSubmitPage);
