export enum ExamType {
    GRE = 1,
    GMAT,
    SAT,
    ACT,
    TOEFL,
    IELTS,
}

export enum CompetitiveExamType {
    GRE = 1,
    GMAT,
    SAT,
    ACT,
}

export enum LanguageExam {
    TOEFL = 5,
    IELTS = 6,
}

export interface BaseExamDetailDto {
    isExamGiven: boolean;
    examDate: string;
    profileId: number;
    updatedAt: string;
    updatedBy: string;
    email: string;
    examType: ExamType;
}

export interface ExamDetailResponseDto extends BaseExamDetailDto {
    attemptedExam: BaseExamScoreDto;
}

export interface CreateExamDetailRequestDto extends BaseExamDetailDto {
    greScore: GREExamScoreDto;
    toeflScore: TOEFLExamScoreDto;
    ieltsScore: IELTSExamScoreDto;
    gmatScore: GMATExamScoreDto;
    satScore: SATExamScoreDto;
    actScore: ACTExamScoreDto;
}

export interface UpdateeExamDetailRequestDto extends CreateExamDetailRequestDto {
    id: number;
}

export interface BaseExamScoreDto {
    id: number;
    updatedAt: string;
    updatedBy: string;
}

export interface GREExamScoreDto extends BaseExamScoreDto {
    verbal: number;
    quantitative: number;
    awa: number;
    total: number;
}

export interface TOEFLExamScoreDto extends BaseExamScoreDto {
    reading: number;
    listening: number;
    speaking: number;
    writing: number;
    total: number;
}

export interface GMATExamScoreDto extends BaseExamScoreDto {
    verbal: number;
    quantitative: number;
    awa: number;
    integratedReasoning: number;
    total: number;
}

export interface IELTSExamScoreDto extends BaseExamScoreDto {
    reading: number;
    listening: number;
    speaking: number;
    writing: number;
    total: number;
}

export interface SATExamScoreDto extends BaseExamScoreDto {
    criticalReading: number;
    math: number;
    writing: number;
    total: number;
}

export interface ACTExamScoreDto extends BaseExamScoreDto {
    english: number;
    math: number;
    reading: number;
    science: number;
    total: number;
}