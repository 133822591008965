import { FC } from "react"
import { IELTSExamScoreDto } from "../../../student/models/ExamModels"

type Props = {
    data: IELTSExamScoreDto,
}

export const IELTSScoreView: FC<Props> = ({ data }) => {
    return <div className='row mb-2'>
        <div className="row mb-5">
            <label className="fw-bold text-dark mt-5">Score Breakdown</label>
        </div>
        <div className="col-6 mb-2">
            <label className='fw-bold text-muted'>Total: </label>
            <span className='fw-bolder fs-6 text-dark mx-2'>
                {data.total}
            </span>
        </div>
        <div className="row">
            <div className="col-3 mb-2">
                <label className=' fw-bold text-muted'>Listening</label>
                <span className='fw-bolder fs-6 text-dark mx-2'>
                    {data.listening}
                </span>
            </div>

            <div className="col-3 mb-2">
                <label className=' fw-bold text-muted'>Reading</label>
                <span className='fw-bolder fs-6 text-dark mx-2'>
                    {data.reading}
                </span>
            </div>

            <div className="col-3 mb-2">
                <label className=' fw-bold text-muted'>Writing</label>
                <span className='fw-bolder fs-6 text-dark mx-2'>
                    {data.writing}
                </span>
            </div>

            <div className="col-3 mb-2">
                <label className=' fw-bold text-muted'>Speaking</label>
                <span className='fw-bolder fs-6 text-dark mx-2'>
                    {data.speaking}
                </span>
            </div>
        </div>
    </div>
}