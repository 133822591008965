/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {
  IStudentResponseToUniversitySuggestionDto,
  IUniversitySuggestionMetaResponseDto,
  SuggestedUniversityStatus,
  UniversitySuggestionState,
} from './models/UniversitySuggestionDtos'
import * as api from './service/UniversitySuggestion.service'
import {PageTitle} from '../../../theme/layout/core'
import {SuggestionsWithResponse} from './components/SuggestionsWithResponse'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import {IStudentConfig} from '../student/models'
import {getConfig} from '../student/services/StudentProfileService'
import Notice from '../../modules/common/components/Notice'
import {useSharedState} from '../../modules/common/SharedStateProvider'

type Props = {
  profileId: string
}

const SuggestedUniversityStudentPage: FC<RouteComponentProps<Props>> = ({
  history,
  match: {
    params: {profileId},
  },
}) => {
  const defaultMeta: IUniversitySuggestionMetaResponseDto = {
    suggestions: [],
    archivedSuggestions: [],
    profileId: +profileId,
    id: 0,
    state: UniversitySuggestionState.WaitingOnStudent,
  }
  const [meta, setMeta] = useState<IUniversitySuggestionMetaResponseDto>(defaultMeta)
  const [loading, setLoading] = useState<boolean>(false)
  const [actionLoading, setActionLoading] = useState<boolean>(false)
  const [refreshKey, setRefreshKey] = useState<number>(0)
  const [config, setConfig] = useState<IStudentConfig>()
  const {isStudent} = useSharedState()

  const formik = useFormik({
    onSubmit: (values) => {
      handleSuggest(values)
    },
    initialValues: meta,
  })

  useEffect(() => {
    const doWork = () => {
      getConfig(+profileId).then(({data}) => {
        setConfig(data)
      })
    }
    profileId && doWork()
  }, [profileId])

  useEffect(() => {
    const fetchData = () => {
      setLoading(true)
      api
        .getSuggestionsMeta(+profileId)
        .then(({data}) => {
          if (!data?.suggestions) data = {...data, suggestions: []}
          return data
        })
        .then((data) => {
          setMeta(data)
          formik.setValues(data)
        })
        .finally(() => setLoading(false))
    }

    if (profileId && config) {
      fetchData()
    }
    //eslint-disable-next-line
  }, [profileId, refreshKey, config])

  const handleSuggest = (values: IUniversitySuggestionMetaResponseDto) => {
    const isInvalid = values.suggestions.some(
      (x) =>
        (x.status === SuggestedUniversityStatus.RequestedChanges && !x.comment) ||
        x.status === SuggestedUniversityStatus.Suggested
    )
    if (isInvalid) {
      formik.setStatus(
        'You must respond to every suggestion by accepting or requesting changes (with comment).'
      )
      window.scrollTo({top: 0})
      return
    }
    const cwidRequiredCheck = values.suggestions.some(
      (x) => x.status === SuggestedUniversityStatus.Finalized && !x.cwidNumber
    )

    if (cwidRequiredCheck) {
        formik.setStatus(
          'You must input CWID number after finalizing university.'
        )
        window.scrollTo({top: 0})
        return
      }

    formik.setStatus(undefined)

    const responses: Array<IStudentResponseToUniversitySuggestionDto> = values.suggestions.map(
      (s) => {
        return {
          suggestionId: s.id as number,
          comment: s.comment,
          universityId: s.universityId,
          status: s.status as any,
          cwidNumber: s.cwidNumber
        }
      }
    )
    setActionLoading(true)
    api
      .saveResponse(+profileId, responses)
      .then(() => {
        toast('Saved response successfuly.', {type: 'success'})
        setRefreshKey(Math.random())
      })
      .finally(() => setActionLoading(false))
  }

  const handleCancel = () => {
    history.push('/')
  }

  if (!config) {
    return <Spinner variant='primary' animation='grow' />
  }
  if (!config.profileState.isApproved) {
    return <Notice message='This module is not yet active as your profile is not approved.' />
  }
  if (config.balanceFees !== 0 && !config.overridePaymentRule) {
    return <Notice message='This module is not yet active due to pending payment.' />
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>University Suggestions</PageTitle>
      {loading && <Spinner animation={'grow'} variant='primary' />}
      {!loading && (
        <>
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
          <SuggestionsWithResponse meta={meta} formik={formik} />

          {meta.suggestions?.length !== 0 && isStudent && (
            <>
              <div className='d-flex align-items-center mt-5 mb-5'>
                <Button
                  className='btn btn-sm col-xl-2 m-5'
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                >
                  {actionLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                  {!actionLoading && 'Save'}
                </Button>
                <Button className='btn btn-sm btn-secondary col-xl-2 m-5' onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default withRouter(SuggestedUniversityStudentPage)
