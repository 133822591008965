import clsx from 'clsx'
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { useMessageContext } from '../../../../app/modules/common/MessageContext'
import { useNotificationContext } from '../../../../app/modules/common/NotificationContext'
import { useSharedState } from '../../../../app/modules/common/SharedStateProvider'
import { RootState } from '../../../../setup'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, Search } from '../../../partials'
import { HeaderMessagesMenu } from '../../../partials/layout/header-menus/HeaderMessagesMenu'
import { useLayout } from '../../core'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const { config } = useLayout()
  const sharedState = useSharedState();
  const { alerts, handleTransition } = useNotificationContext();
  const { messages } = useMessageContext();
  const unreadCount = messages.length ? messages.map(x => x.unreadMessageCount).reduce((a, b) => a + b) : 0;
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      {!sharedState.isStudent && !sharedState.isContentWriter && <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div>}

      {/* CHAT */}
      {!sharedState.isContentWriter && <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Menu wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          // id='kt_drawer_chat_toggle'
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com012.svg'
            className={toolbarButtonIconSizeClass}
          />
          {unreadCount > 0 && <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'>
            <span className='badge badge-danger'>{unreadCount}</span>
          </span>}
          <HeaderMessagesMenu conversations={messages} />
        </div>
        {/* end::Menu wrapper */}
      </div>}

      {/* NOTIFICATIONS */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Menu- wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen022.svg'
            className={toolbarButtonIconSizeClass}
          />
          {alerts?.length > 0 && <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'>
            <span className='badge badge-danger'>{alerts?.length === 50 ? "50+" : alerts?.length}</span>
          </span>}
        </div>
        <HeaderNotificationsMenu alerts={alerts} handleTransition={handleTransition} />
        {/* end::Menu wrapper */}
      </div>

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img alt='Logo' src={user.profilePictureUri ?? toAbsoluteUrl('/media/avatars/blank.png')} />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export { Topbar }
