/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap-v5';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '../../../modules/common/components/ConfirmationModal';
import Notice from '../../../modules/common/components/Notice';
import { submitEssays } from '../services/EssayService';
import { ViewEssayResponse } from './ViewEssayPage';

type Props = {
    profileId: string
}

const EssaySubmitPage: FC<RouteComponentProps<Props>> = ({ history, match: { params: { profileId } } }) => {
    const [confirm, setConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleSubmit = async () => {
        try {
            setLoading(true);
            await submitEssays(+profileId);
            toast('Essay Submitted.', { type: 'success' });
            history.push('/');
        } finally {
            setLoading(false);
        }
    }
    return <>
        {loading && <Spinner variant="primary" animation="grow" />}
        <ViewEssayResponse profileId={profileId} />
        <div className="row mt-10 bg-white py-5">
            <div className="col-xl-3">
                <Button className='btn btn-lg m-2' onClick={() => setConfirm(true)}>Submit</Button>
                <Button className='btn btn-lg btn-secondary' onClick={() => history.push('/')}>Cancel</Button>
            </div>
        </div>
        {confirm && <ConfirmationModal title='Submit Essay?' onConfirm={() => {
            handleSubmit();
            setConfirm(false);
        }}
            onCancel={() => setConfirm(false)}
        >
            <Notice message='Once submitted, you will not be able to change the answers.' />
        </ConfirmationModal>}
    </>
}

export default withRouter(EssaySubmitPage);
