/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import { FC, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap-v5";
import { toast } from "react-toastify";
import { BulkFileUploader } from "../../common/components/FileUploader";
import { CreateMessageDto } from "../models/ConversationModel";
import * as Yup from 'yup'
import * as messageService from '../messageService'
import clsx from "clsx";
import { AxiosResponse } from "axios";

const createConversationSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Minimum 3 symbols')
        .max(30, 'Maximum 30 symbols')
        .required('Subject is required'),
    message: Yup.string()
        .min(2, 'Minimum 3 symbols')
        .required('Message is required'),
})

export const StartNewThreadButton: FC<{ className?: string, showIcon?: boolean }> = ({ className = "btn btn-primary", showIcon = false }) => {
    const [showModal, setShowModal] = useState(false);
    return <>
        <button className={className} onClick={() => { setShowModal(true) }}>
            <i className="bi bi-envelope text-white"></i>
            {!showIcon && `Start New Thread`}</button>
        <NewMessageDialog visible={showModal} setVisibility={setShowModal} onSend={messageService.startConversation} />
    </>
}

type Props = { visible: boolean, setVisibility: (vis: boolean) => void, onSend: (req: any) => Promise<AxiosResponse<any>> }

export const NewMessageDialog: FC<Props> =
    ({ visible, setVisibility, onSend }) => {
        const [base64File, setFile] = useState<any>();
        const [loading, setLoading] = useState(false);

        const formik = useFormik<CreateMessageDto>({
            initialValues: { title: "", message: "" },
            onSubmit: (values, { setStatus, setSubmitting }) => {
                setLoading(true);
                const req: CreateMessageDto = { ...values, ...base64File };
                onSend(req)
                    .then(() => {
                        setLoading(false);
                        handleClose();
                    })
                    .catch(() => {
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('Error starting new conversation thread.')
                    })
            },
            validationSchema: createConversationSchema
        });

        const handleClose = () => {
            setVisibility(false);
        }
        const onUpload = (files: any) => {
            const reader = new FileReader();
            const file = files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64 = reader.result?.toString().split(";base64,")[1] as string;
                setFile({ base64File: base64, fileName: file.name });
            };
        }

        return <>
            <Modal show={visible}
                onHide={handleClose}
                backdrop="static"
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Send</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        {formik.status && (
                            <div className='mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{formik.status}</div>
                            </div>
                        )}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="How do I get transcripts?"
                                autoFocus
                                {...formik.getFieldProps('title')}
                                className={clsx(
                                    'form-control form-control-sm ',
                                    { 'is-invalid': formik.touched.title && formik.errors.title },
                                    { 'is-valid': formik.touched.title && !formik.errors.title }
                                )}
                            />
                            {formik.touched.title && formik.errors.title && (
                                <div className='fv-plugins-message-container'>
                                    <span role='alert'>{formik.errors.title}</span>
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                {...formik.getFieldProps('message')}
                                className={clsx(
                                    'form-control form-control-sm ',
                                    { 'is-invalid': formik.touched.message && formik.errors.message },
                                    { 'is-valid': formik.touched.message && !formik.errors.message }
                                )}
                            />
                            {formik.touched.message && formik.errors.message && (
                                <div className='fv-plugins-message-container'>
                                    <span role='alert'>{formik.errors.message}</span>
                                </div>
                            )}
                        </Form.Group>
                        {base64File ?
                            <div className="d-flex col-3">
                                <a href="" className="d-flex flex-grow-1">{base64File.fileName}</a>
                                <button className="btn btn-sm btn-icon" onClick={() => {
                                    setFile(undefined);
                                }}>
                                    <i className="bi bi-trash text-danger"></i>
                                </button>
                            </div>
                            :
                            <BulkFileUploader
                                onUpload={(files) => {
                                    if (files.length > 1)
                                        toast('You can send only 1 file per message.', { type: 'warning' });
                                    onUpload(files)
                                }}
                                maxFiles={1}
                                uploadBtnText="Attach to message" />
                        }
                        <div className="separator"></div>
                        <div className="d-flex">
                            <Button variant="secondary" className="m-5" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" className="m-5" type="submit" disabled={formik.isSubmitting || !formik.isValid}>
                                {!loading && <span className='indicator-label'>Send</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal></>
    }