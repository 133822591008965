import { FC } from 'react'
import { ApiResponse } from '../../../../modules/common/ApiResponse';
import { AxiosResponse } from 'axios';
import { GraduationDetailStep, WorkExperienceStep, FutureStudyDetailStep } from './steps';
import { profileElementSchemas, stepHeadings } from './ProfileEditSchema';
import { Stepper } from '../../../../modules/stepper';
import { IAcademicProfile } from '../../models';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

type Props = {
    profile: IAcademicProfile,
    submitFn: (profile: any) => Promise<AxiosResponse<ApiResponse<string>>>
}

export const AcademicProfileWizard: FC<Props> = ({ profile, submitFn }) => {
    const history = useHistory();
    return <Stepper
        formValues={profile}
        submitFn={(formValues) => {
            return submitFn({ ...formValues }).then(resp => {
                if (resp.data.failed) {
                    return resp;
                } else {
                    toast(`Saved information successfuly`, { type: 'success' });
                    history.push(`/student/${profile.profileId}/edit/exam`);
                    return resp;
                }
            })
        }}
        validationSchemas={profileElementSchemas}
        stepComponents={[GraduationDetailStep, FutureStudyDetailStep, WorkExperienceStep]}
        stepHeadings={stepHeadings} />
}
