import MaterialTable from 'material-table';
import { FC, useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap-v5';
import Select from 'react-select';
import tableIcons from '../../modules/common/components/MaterialTableIcons';
import { allRoleOptions } from '../../modules/common/DropdownOptions';
import { AllRoles } from '../../modules/common/enums/Roles';
import * as service from './PermissionService';
import { PageTitle } from '../../../theme/layout/core'

interface IRolePermission {
    permission: string,
    isAssigned: boolean
}

export const PermissionManager: FC = () => {
    const [role, selectRole] = useState<AllRoles>();
    const [rolePermissions, setRolePerms] = useState<IRolePermission[]>([]);
    const [allPermissions, setAllPerms] = useState<string[]>([]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const { data: permissions } = await service.getAllPermissions();
            setAllPerms(permissions)
        }
        fetchData();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const fetchData = async (role: AllRoles) => {
            setLoading(true);
            try {
                const { data: permissions } = await service.getRolePermissions(role);
                const unassignedPerms: IRolePermission[] = allPermissions
                    .filter(x => !permissions.includes(x))
                    .map(x => ({ permission: x, isAssigned: false }));

                const rolePerms: IRolePermission[] = permissions
                    .map(x => ({ permission: x, isAssigned: true }))
                    .concat(unassignedPerms);
                setRolePerms(rolePerms);
            } finally {
                setLoading(false);
            }
        }
        if (role)
            fetchData(role);
        //eslint-disable-next-line
    }, [role, refresh]);

    const handleUpdatePermissions = async (permissions: IRolePermission[]) => {
        await service.updateRolePermissions(role as AllRoles, permissions.map(x => x.permission));
        setRefresh(!refresh);
    };

    if (loading) {
        return <Spinner variant='primary' animation='grow' />
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>Permission Manager</PageTitle>
            <Card>
                <Card.Header>
                    <div className="row mt-10 mb-5">
                        <label className="fw-bold fs-6 required">Select Role</label>
                        <Select
                            options={allRoleOptions}
                            value={allRoleOptions.find(x => x.value === role)}
                            onChange={(opt) => opt && selectRole(opt.value)} />
                    </div>
                </Card.Header>
                <Card.Body>
                    {role && <MaterialTable
                        icons={tableIcons}
                        title={`Manage ${allRoleOptions.find(x => x.value === role)?.label} Permissions`}
                        columns={[{
                            title: 'Permission',
                            field: 'permission',
                            render: (rowData) => {
                                return rowData.permission.split('-').map(x => `${x[0].toUpperCase()}${x.substring(1)}`).join(' ')
                            }
                        },
                        {
                            title: 'Is Assigned',
                            field: 'isAssigned',
                            type: 'boolean',
                            sorting: true,
                            render: (rowData) => {
                                return rowData.isAssigned ? <> <i className='bi bi-check-circle text-success'></i> </> : <> <i className='bi bi-x-circle text-danger'></i> </>
                            }
                        }
                        ]}
                        data={rolePermissions}
                        actions={[
                            {
                                icon: tableIcons.Check as any,
                                tooltip: `Add Selected Permissions`,
                                disabled: false,
                                onClick: (event, rowData) => {
                                    if (rowData && Array.isArray(rowData)) {
                                        const added = rowData.filter(x => !x.isAssigned);
                                        handleUpdatePermissions([...rolePermissions.filter(x => x.isAssigned), ...added]);
                                    }
                                },
                            },
                            {
                                icon: tableIcons.Clear as any,
                                tooltip: `Clear Selected Permissions`,
                                disabled: false,
                                onClick: (event, rowData) => {
                                    if (rowData && Array.isArray(rowData)) {
                                        const newPerms = rolePermissions.filter(x => x.isAssigned).filter(x => !rowData.includes(x));
                                        handleUpdatePermissions(newPerms);
                                    }
                                },
                            },
                            {
                                icon: tableIcons.ViewColumn as any,
                                tooltip: `Replace Selected Permissions`,
                                disabled: false,
                                onClick: (event, rowData) => {
                                    if (rowData && Array.isArray(rowData)) {
                                        handleUpdatePermissions(rowData);
                                    }
                                },
                            }
                        ]}
                        options={{
                            selection: true,
                            pageSize: 5,
                            pageSizeOptions: [5, 10, 20, 50, 100, 200]
                        }}
                    />}
                </Card.Body>
            </Card>
        </>)
}