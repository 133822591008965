/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import { FC } from 'react'
import Notice from '../../../modules/common/components/Notice'
import { competitiveExamOptions, languageExamOptions } from '../../../modules/common/DropdownOptions'
import { IStudentExamDetails } from '../../student/models'
import { CreateExamDetailRequestDto, ExamType } from '../../student/models/ExamModels'
import { GREScoreView, GMATScoreView, TOEFLScoreView, IELTSScoreView, SATScoreView, ACTScoreView } from './internal'
import { Divider } from '@material-ui/core'
import { DATE_FORMAT_DD_MMM_YYYY } from '../../../modules/common/Helper'

type Props = {
    data?: IStudentExamDetails
}

export const ExamScoresOverview: FC<Props> = ({ data }) => {
    const NotFoundNotice: FC = () => (<Notice title='No exam details found!' message='Exam Details not found for student account.' />);

    const renderExamScore = (exam: CreateExamDetailRequestDto) => {
        if (!exam.isExamGiven) return null;

        switch (exam.examType) {
            case ExamType.GRE:
                return exam.greScore && <GREScoreView data={exam.greScore} />
            case ExamType.GMAT:
                return exam.gmatScore && <GMATScoreView data={exam.gmatScore} />
            case ExamType.TOEFL:
                return exam.toeflScore && <TOEFLScoreView data={exam.toeflScore} />
            case ExamType.IELTS:
                return exam.ieltsScore && <IELTSScoreView data={exam.ieltsScore} />
            case ExamType.SAT:
                return exam.satScore && <SATScoreView data={exam.satScore} />
            case ExamType.ACT:
                return exam.actScore && <ACTScoreView data={exam.actScore} />
        }
    }
    return (
        <>
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Exam Details</h3>
                    </div>
                </div>

                <div className='card-body'>
                    {data ?
                        <div className='row'>
                            <div className='col-3 mb-2 '>
                                <label className=' fw-bold text-muted'>Qualifying Exam</label>
                                <span className='fw-bolder fs-6 text-light badge badge-info mx-2'>
                                    {competitiveExamOptions.find(x => x.value === data.competitiveExam?.examType)?.label}
                                </span>
                            </div>
                            <div className='col-3 mb-2 '>
                                <label className=' fw-bold text-muted'>Exam Date</label>
                                <span className='fw-bolder fs-6 text-dark mx-2'>
                                    {moment(data.competitiveExam?.examDate).format(DATE_FORMAT_DD_MMM_YYYY)} ({moment(data.competitiveExam?.examDate).fromNow()})
                                </span>
                            </div>
                            {data.competitiveExam?.isExamGiven && <div className='row mb-2'>
                                {renderExamScore(data.competitiveExam)}
                            </div>
                            }
                            <Divider variant='middle' className='bg-success my-5' />
                            <div className='col-3 mb-2 '>
                                <label className=' fw-bold text-muted'>Language Exam</label>
                                <span className='fw-bolder fs-6 text-light badge badge-info mx-2'>
                                    {languageExamOptions.find(x => x.value === data.languageExam?.examType)?.label}
                                </span>
                            </div>
                            <div className='col-3 mb-2 '>
                                <label className=' fw-bold text-muted'>Exam Date</label>
                                <span className='fw-bolder fs-6 text-dark mx-2'>
                                    {moment(data.languageExam?.examDate).format(DATE_FORMAT_DD_MMM_YYYY)} ({moment(data.languageExam?.examDate).fromNow()})
                                </span>
                            </div>

                            {data.languageExam?.isExamGiven && <div className='row mb-2 '>
                                {renderExamScore(data.languageExam)}
                            </div>
                            }
                        </div>
                        :
                        <NotFoundNotice />
                    }
                </div>
            </div>
        </>
    )
}
