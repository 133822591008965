import { Action } from '@reduxjs/toolkit'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetError: '[Set Error] Action',
  ResetError: '[Reset Error] Action',
}

const initialErrorState: IErrorState = {
  statusCode: undefined,
  errorMessage: undefined
}

export interface IErrorState {
  statusCode?: number,
  errorMessage?: string
}

export const reducer = (state: IErrorState = initialErrorState, action: ActionWithPayload<IErrorState>) => {
  switch (action.type) {
    case actionTypes.SetError: {
      const statusCode = action.payload?.statusCode;
      const errorMessage = action.payload?.errorMessage;
      return { statusCode, errorMessage }
    }
    case actionTypes.ResetError: {
      return initialErrorState
    }
    default:
      return state
  }
}

export const actions = {
  setError: (error: IErrorState) => {
    return { type: actionTypes.SetError, payload: error };
  },
  resetError: () => ({ type: actionTypes.ResetError }),
}