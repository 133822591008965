import {FormControlLabel, Checkbox} from '@material-ui/core'
import {FC, useState, useEffect} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import Select from 'react-select'
import TooltipInfo from '../../modules/common/components/TooltipInfo'
import {branchOptions} from '../../modules/common/DropdownOptions'
import {DropdownOption, useSharedState} from '../../modules/common/SharedStateProvider'
import {GenerateReportRequestDto} from './GenerateReportRequestDto'

type Props = {
  onFilterChanged: (field: StudentFilterType, value: any) => void
  onSearch: () => void
  disabledFilters?: StudentFilterType[]
  initialFilter?: GenerateReportRequestDto
  isLoading: boolean
}
export type StudentFilterType =
  | 'termIds'
  | 'statuses'
  | 'branches'
  | 'examDate'
  | 'hasLanguageExamScores'
  | 'hasCompetitiveExamScores'

export const ReportFilters: FC<Props> = ({
  onFilterChanged,
  onSearch,
  disabledFilters,
  initialFilter,
  isLoading,
}) => {
  const [termOptions, setTermOptions] = useState<DropdownOption[]>([])
  const sharedState = useSharedState()
  const [selectedTerm, setSelectedTerm] = useState()

  useEffect(() => {
    const options = sharedState.terms.map((x) => ({label: x.name, value: x.id}))
    setTermOptions(options)
  }, [sharedState.terms])

  return (
    <div className='row fv-row m-2'>
      <div className='d-flex mb-2'>
        <div className='d-flex flex-grow-1'>
          <h4>Report Filters</h4>
          {isLoading && <Spinner animation='grow' variant='primary' />}
        </div>
      </div>
      <div>
        <div className='row'>
          {!disabledFilters?.includes('termIds') && (
            <div className='col-xl-3'>
              <label className='mb-2 fw-bold text-dark'>Term</label>
              {/* {termOptions?.length > 0 && <Select
                        options={termOptions}
                        onChange={(option) => {
                            var ids = Array.from(option?.values()).map(x => x.value);
                            onFilterChanged('termIds', ids)
                            setSelectedTerm(ids as any);
                        }}
                        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                        isMulti={true}
                    />} */}

              {termOptions?.length > 0 && (
                <Select
                  options={termOptions}
                  defaultValue={termOptions[0]}
                  onChange={(option) => {
                    onFilterChanged('termIds', [option?.value])
                    setSelectedTerm(option?.value)
                  }}
                  styles={{menu: (base) => ({...base, zIndex: 9999})}}
                />
              )}
            </div>
          )}

          {/* {!disabledFilters?.includes('statuses') && <div className="col-xl-3">
                    <label className='mb-2 fw-bold text-dark'>Profile Status</label>
                    <Select
                        options={profileStatusOptions}
                        onChange={(option) => {
                            onFilterChanged('statuses', Array.from(option.values()).map(x => x.value))
                        }}
                        isMulti={true}
                        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>} */}

          {!disabledFilters?.includes('branches') && (
            <div className='col-xl-3'>
              <label className='mb-2 fw-bold text-dark'>
                Assigned Branch
                <TooltipInfo
                  message={"This filter won't have any effect for Branch level logins"}
                />
              </label>
              <Select
                options={branchOptions}
                onChange={(option) => {
                  onFilterChanged(
                    'branches',
                    Array.from(option.values()).map((x) => x.value)
                  )
                }}
                isMulti={true}
                styles={{menu: (base) => ({...base, zIndex: 9999})}}
              />
            </div>
          )}

          {!disabledFilters?.includes('examDate') && (
            <div className='col-xl-3'>
              <label className='mb-2 fw-bold text-dark form-label'>Exam Date</label>
              <input
                placeholder='Exam Date'
                type='date'
                autoComplete='off'
                className='form-control form-control-sm'
                onChange={(ev) => {
                  console.log(ev.target.valueAsDate)
                  onFilterChanged('examDate', ev.target.valueAsDate)
                }}
                defaultValue={undefined}
              />
            </div>
          )}
        </div>
        <div className='row mt-2'>
          {!disabledFilters?.includes('hasLanguageExamScores') && (
            <div className='col-xl-3'>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(ev) => onFilterChanged('hasLanguageExamScores', ev.target.checked)}
                    checked={initialFilter?.hasLanguageExamScores}
                  />
                }
                label='Has Language Exam Scores?'
              />
            </div>
          )}
          {!disabledFilters?.includes('hasCompetitiveExamScores') && (
            <div className='col-xl-3'>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(ev) =>
                      onFilterChanged('hasCompetitiveExamScores', ev.target.checked)
                    }
                    checked={initialFilter?.hasCompetitiveExamScores}
                  />
                }
                label='Has Qualifying Exam Scores?'
              />
            </div>
          )}
        </div>
        <div className='row mt-5 mb-2'>
          <p className='text-info'>
            You can download report for a one term at a time to avoid long delays
          </p>
          <div className='col-xl-3'>
            <Button
              variant='outline'
              className='btn btn-sm btn-outline btn-outline-primary'
              onClick={onSearch}
              disabled={isLoading || !selectedTerm}
            >
              <i className='bi bi-search text-dark'></i>
              {!isLoading && <span className='indicator-label'>Generate Report</span>}
              {isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
