/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {GenericTooltip} from '../../../../app/modules/common/components/TooltipInfo'
import {searchProfilesByQuery} from '../../../../app/pages/student-profiles/services/StudentManager'
import {SearchComponent} from '../../../assets/ts/components'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

const Search: FC = () => {
  const [results, setResults] = useState<any[]>([])
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)
  const history = useHistory()

  const processs = async (search: SearchComponent) => {
    const searchTerm = search.inputElement.value
    const resp = await searchProfilesByQuery(searchTerm)
    setResults(resp.data)
    if (resp.data) {
      showResults()
    } else {
      showEmptyMessage()
    }
    // Complete search
    search.complete()
  }

  const showResults = () => {
    // Show results
    resultsElement.current!.classList.remove('d-none')
    // Hide empty message
    emptyElement.current!.classList.add('d-none')
  }

  const showEmptyMessage = () => {
    // Hide results
    resultsElement.current!.classList.add('d-none')
    // Show empty message
    emptyElement.current!.classList.remove('d-none')
  }

  const clear = (search: SearchComponent) => {
    // Hide results
    resultsElement.current!.classList.add('d-none')
    // Hide empty message
    emptyElement.current!.classList.add('d-none')
  }

  useEffect(() => {
    // Initialize search handler
    const searchObject = SearchComponent.createInsance('#kt_header_search')

    // Search handler
    searchObject!.on('kt.search.process', processs)

    // Clear handler
    searchObject!.on('kt.search.clear', clear)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1' />
          </div>
        </div>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'
        >
          <div ref={wrapperElement} data-kt-search-element='wrapper'>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative mb-3'
              autoComplete='off'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
              />

              <input
                type='text'
                className='form-control form-control-flush ps-10'
                name='search'
                placeholder='Search...'
                data-kt-search-element='input'
              />

              <span
                className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
                data-kt-search-element='spinner'
              >
                <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
              </span>

              <span
                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                data-kt-search-element='clear'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 svg-icon-lg-1 me-0'
                />
              </span>
            </form>

            <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
              <div className='scroll-y mh-200px mh-lg-350px'>
                <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
                  Users
                </h3>

                {results.map((x) => {
                  const names = x.fullName.toUpperCase().split(' ')
                  const initials = names[0][0] +""+ (names[1] ? names[1][0] : names[0][0])
                  //eslint-disable-next-line
                  return (
                    <a key={x.id} className='d-flex text-dark align-items-center mb-5'>
                      <div className='symbol symbol-40px me-4'>
                        {initials ? (
                          <div data-initials={initials}></div>
                        ) : (
                          <img
                            src={x.profilePictureUri || toAbsoluteUrl('/media/avatars/blank.png')}
                            alt=''
                          />
                        )}
                      </div>
                      <div className='d-flex flex-row flex-grow-1'>
                        <div className='d-flex flex-column justify-content-start fw-bold flex-grow-1'>
                          <Link to={`/student/${x.id}`}>
                            <span className='fs-6 fw-bold'>{x.fullName}</span>
                          </Link>
                          <span className='fs-7 fw-bold text-muted'>{x.branchName}</span>
                        </div>
                        {/* <GenericTooltip message={'Add to watchlist'} childEle={<i onClick={() => { console.log('do nothing'); }} className="bi bi-bookmark-heart text-hover-primary cursor-pointer"></i>} /> */}
                        <GenericTooltip
                          message={'Impersonate'}
                          childEle={
                            <i
                              onClick={() => {
                                history.push(`/impersonate/${x.email}`)
                              }}
                              className='bi bi-person-bounding-box text-hover-primary cursor-pointer'
                            ></i>
                          }
                        />
                      </div>
                    </a>
                  )
                })}
              </div>
            </div>

            <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
              <div className='pt-10 pb-10'>
                <KTSVG
                  path='/media/icons/duotune/files/fil024.svg'
                  className='svg-icon-4x opacity-50'
                />
              </div>

              <div className='pb-15 fw-bold'>
                <h3 className='text-gray-600 fs-5 mb-2'>No result found</h3>
                <div className='text-muted fs-7'>Please try again with a different query</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Search}
