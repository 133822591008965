import axios from 'axios'
import { ApiResponse } from '../../common/ApiResponse'
import {AuthModel} from '../models/AuthModel'
import { ResetPasswordModel } from '../models/ResetPasswordModel'
import {UserModel} from '../models/UserModel'
import { ChangePasswordModel } from '../models/ChangePasswordModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/identity/me`
export const LOGIN_URL = `${API_URL}/api/identity/token`
export const VERIFY_EMAIL_URL = `${API_URL}/api/identity/confirm-email`
export const RESET_PASSWORD_URL = `${API_URL}/api/identity/reset-password`
export const CHANGE_PASSWORD_URL = `${API_URL}/api/identity/change-password`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/api/identity/forgot-password`
export const IMPERSONATE_URL = `${API_URL}/api/v1/users/impersonate`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {email, password})
}

export function getImpersonatedUserToken(email: string) {
  return axios.post(`${IMPERSONATE_URL}/${email}`)
}

// Server should return AuthModel
export function register(email: string, firstName: string, lastName: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstName,
    lastName,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<ApiResponse<UserModel>>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function verifyEmail(userId: string, code: string) {
  return axios.get(`${VERIFY_EMAIL_URL}?userId=${userId}&code=${code}`)
}

export function resetPassword(request: ResetPasswordModel) {
  return axios.post(`${RESET_PASSWORD_URL}`, request);
}

export function changePassword(request: ChangePasswordModel) {
  return axios.post(`${CHANGE_PASSWORD_URL}`, request);
}