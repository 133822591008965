import axios, {AxiosResponse} from 'axios'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ApiResponse} from '../../../modules/common/ApiResponse'
import {AllRoles} from '../../../modules/common/enums/Roles'
import {
  CreateStudentAccountRequest,
  CreateUserAccountRequest,
  UpdateUserAccountRequest,
} from '../models/CreateUserAccountRequest'

const API_URL = process.env.REACT_APP_API_URL
export const USER_API = `${API_URL}/api/v1/users`
export const CREATE_USER_API = `${USER_API}/create-user-account`
export const DELETE_USER_API = `${USER_API}/delete-user-account`

export function createUserAccount(
  request: CreateUserAccountRequest
): Promise<AxiosResponse<ApiResponse<string>>> {
  return axios.post<ApiResponse<string>>(CREATE_USER_API, request)
}

export function createStudentAccount(
  request: CreateStudentAccountRequest
): Promise<AxiosResponse<ApiResponse<string>>> {
  return axios.post<ApiResponse<string>>(`${USER_API}/create-student-account`, request)
}

export function getUserById(userId: string): Promise<AxiosResponse<UserModel>> {
  return axios.get<UserModel>(`${USER_API}/${userId}`)
}

export function getUserByEmail(email: string): Promise<AxiosResponse<ApiResponse<string>>> {
  return axios.get<ApiResponse<string>>(`${USER_API}/find/${email}`)
}

export function updateUserAccount(
  request: UpdateUserAccountRequest
): Promise<AxiosResponse<ApiResponse<string>>> {
  const api =
    request.roleId === AllRoles.Student
      ? `${USER_API}/student/${request.id}`
      : `${USER_API}/${request.id}`
  return axios.put<ApiResponse<string>>(`${api}`, request)
}

export function deleteUserAccount(email: string) {
  return axios.delete(`${DELETE_USER_API}/${email}`)
}

export function getAllUsersPaginated(
  searchTerm: string,
  pageNumber: number,
  pageSize: number,
  branches: number[],
  roles: number[],
  field: string = 'firstName',
  dir: string = 'asc'
) {
  return axios.post(`${USER_API}`, {
    searchTerm,
    pageNumber,
    pageSize,
    branches,
    roles,
    sortField: field,
    sortOrder: dir,
  })
}

export function getAllCWWithWork(
  pageNumber: number,
  pageSize: number,
) {
  return axios.post(`${USER_API}/content-writer-with-work`, {
    pageNumber,
    pageSize,
  })
}

export function getAllBranchUsers() {
  return axios.get(`${USER_API}/branch-level-users`)
}


export function activateUserAccount(email: string) {
  return axios.post(`${USER_API}/activate/${email}`)
}

export function deactivateUserAccount(email: string) {
  return axios.post(`${USER_API}/deactivate/${email}`)
}
