export const enumToKeyValue = (enumType: any, keyName: string = 'label', valueName: string = 'value') => {
    const StringIsNumber = (val: any) => isNaN(Number(val)) === false;
    return Object.keys(enumType)
        .filter(StringIsNumber)
        .map(key => {
            return {
                [keyName]: enumType[key],
                [valueName]: key
            }
        });
}

export function camelToSentenceCase(rawKey: string) {
    const result = rawKey.replace(/([A-Z])/g, " $1")
    const sentenceCase = result.charAt(0).toUpperCase() + result.slice(1)
    return sentenceCase
}