import clsx from 'clsx'
import { FC, useState } from 'react'
import { Button } from 'react-bootstrap-v5'
import { IWorkExperience } from '../../../models'
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';

type Props = {
    formik: any,
}
export const WorkExperienceStep: FC<Props> = ({ formik }) => {
    const validate = (exp: IWorkExperience[]) => {
        const isValid = exp.filter(x => x.isOngoing).length <= 1;
        if (!isValid) {
            formik.setFieldError('workExperiences_ongoingJobs', 'You can have maximum 1 ongoing job.')
        } else {
            formik.setFieldError('workExperiences_ongoingJobs');
        }
    }
    return <div className="w-100">
        <div className='pb-10 pb-lg-15'>
            <h2 className='fw-bolder d-flex align-items-center text-dark'>
                Work Experience
            </h2>
        </div>
        {formik.values.workExperiences?.map((exp: IWorkExperience, index: number) => {
            return <div key={index}>
                <h3 className='fw-bolder text-dark mb-5'>
                    <span style={{ marginRight: '1.2rem' }}>Experience #{index + 1}</span>
                    <i
                        className="bi bi-archive text-danger"
                        title="Delete"
                        style={{
                            fontSize: "1.2rem"
                        }}
                        onClick={() => {
                            const curr = [...formik.values.workExperiences]
                            curr.splice(index, 1);
                            formik.setFieldValue('workExperiences', [...curr])
                        }}
                        role="button">
                    </i>
                </h3>
                <Divider variant='fullWidth' className='m-5 bg-info' />
                <WorkExperience formik={formik} index={index} validate={validate} />
            </div>
        })}

        <div className="fv-row mb-10 row">
            <div className='col-xl-4'>
                <Button
                    className='btn btn-sm btn-outline'
                    variant="outline-info"
                    onClick={() => {
                        formik.setFieldValue('workExperiences', [...formik.values.workExperiences, {}])
                    }}>
                    <i className="bi bi-plus-lg mr-2" style={{ fontSize: "1.2rem" }}></i> Add new
                </Button>
            </div>
        </div>
    </div>
}

type WorkExpProps = {
    formik: any,
    index: number,
    validate: any
}

const WorkExperience: FC<WorkExpProps> = ({ formik, index, validate }) => {
    const [isOngoing, setIsOngoingInternal] = useState(formik.values.workExperiences[index].isOngoing);
    const setIsOngoing = (val: boolean) => {
        formik.setFieldValue(`workExperiences[${index}].isOngoing`, val)
            .then(() => {
                //TODO this is hack
                const exp = formik.values.workExperiences.filter((x: IWorkExperience) => x !== formik.values.workExperiences[index]);
                validate([...exp, { ...formik.values.workExperiences[index], isOngoing: val }]);
            })
        setIsOngoingInternal(val);
    }
    return <>
        <div className="fv-row mb-10 row">
            <div className='col-xl-6'>
                <label className='form-label required'>Company name</label>
                <input
                    placeholder='Company name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps(`workExperiences[${index}].companyName`)}
                    className={clsx(
                        'form-control form-control-sm ',
                        {
                            'is-invalid': formik.getFieldMeta(`workExperiences[${index}].companyName`).touched && formik.getFieldMeta(`workExperiences[${index}].companyName`).error,
                        },
                        {
                            'is-valid': formik.getFieldMeta(`workExperiences[${index}].companyName`).touched && !formik.getFieldMeta(`workExperiences[${index}].companyName`).error,
                        }
                    )} />
                {formik.getFieldMeta(`workExperiences[${index}].companyName`).touched && formik.getFieldMeta(`workExperiences[${index}].companyName`).error && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{formik.getFieldMeta(`workExperiences[${index}].companyName`).error}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='col-xl-6'>
                <label className='form-label required'>Designation</label>
                <input
                    placeholder='Designation'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps(`workExperiences[${index}].designation`)}
                    className={clsx(
                        'form-control form-control-sm ',
                        {
                            'is-invalid': formik.getFieldMeta(`workExperiences[${index}].designation`).touched
                                && formik.getFieldMeta(`workExperiences[${index}].designation`).error,
                        },
                        {
                            'is-valid': formik.getFieldMeta(`workExperiences[${index}].designation`).touched
                                && !formik.getFieldMeta(`workExperiences[${index}].designation`).error,
                        }
                    )} />
                {formik.getFieldMeta(`workExperiences[${index}].designation`).touched
                    && formik.getFieldMeta(`workExperiences[${index}].designation`).error && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger mt-2'>
                                <span role='alert'>{formik.getFieldMeta(`workExperiences[${index}].designation`).error}</span>
                            </div>
                        </div>
                    )}
            </div>
        </div>

        <div className="fv-row row mb-10">
            <div className='col-xl-4'>
                <label className='form-label required'>Start Date</label>
                <input
                    placeholder='Start Date'
                    type='date'
                    autoComplete='off'
                    {...formik.getFieldProps(`workExperiences[${index}].startDate`)}
                    className={clsx(
                        'form-control form-control-sm ',
                        {
                            'is-invalid': formik.getFieldMeta(`workExperiences[${index}].startDate`).touched
                                && formik.getFieldMeta(`workExperiences[${index}].startDate`).error,
                        },
                        {
                            'is-valid': formik.getFieldMeta(`workExperiences[${index}].startDate`).touched
                                && !formik.getFieldMeta(`workExperiences[${index}].startDate`).error,
                        }
                    )}
                    onChange={(ev) => {
                        formik.setFieldValue(`workExperiences[${index}].startDate`, ev.target.valueAsDate);
                    }}
                    value={moment(formik.values.workExperiences[index]?.startDate).format('YYYY-MM-DD')}
                />
                {formik.getFieldMeta(`workExperiences[${index}].startDate`).touched
                    && formik.getFieldMeta(`workExperiences[${index}].startDate`).error && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger mt-2'>
                                <span role='alert'>{formik.getFieldMeta(`workExperiences[${index}].startDate`).error}</span>
                            </div>
                        </div>
                    )}
            </div>
            <div className="col-xl-2 mt-6">
                <FormControlLabel
                    label='Is Ongoing?'
                    control={<Checkbox checked={isOngoing}
                        onChange={(ev) => setIsOngoing(ev.target.checked)} />} />
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block mt-2'>
                        {formik.errors?.workExperiences_ongoingJobs ?
                            <span role='alert' className='text-danger'><i className="bi bi-exclamation-circle text-danger" style={{ fontSize: "1.2rem", marginRight: "10px" }}></i>{formik.errors.workExperiences_ongoingJobs}</span>
                            : <span role='alert' className='text-info'>Max 1 ongoing job allowed</span>
                        }
                    </div>
                </div>
            </div>
            {!isOngoing &&
                <div className='col-xl-4'>
                    <label className={`form-label required`}>End Date</label>
                    <input
                        placeholder='End Date'
                        type='date'
                        autoComplete='off'
                        {...formik.getFieldProps(`workExperiences[${index}].endDate`)}
                        className={clsx(
                            'form-control form-control-sm ',
                            {
                                'is-invalid': formik.getFieldMeta(`workExperiences[${index}].endDate`).touched
                                    && formik.getFieldMeta(`workExperiences[${index}].endDate`).error,
                            },
                            {
                                'is-valid': formik.getFieldMeta(`workExperiences[${index}].endDate`).touched
                                    && !formik.getFieldMeta(`workExperiences[${index}].endDate`).error,
                            }
                        )}
                        onChange={(ev) => {
                            formik.setFieldValue(`workExperiences[${index}].endDate`, ev.target.valueAsDate);
                        }}
                        value={moment(formik.values.workExperiences[index]?.endDate).format('YYYY-MM-DD')}
                    />
                    {formik.getFieldMeta(`workExperiences[${index}].endDate`).touched
                        && formik.getFieldMeta(`workExperiences[${index}].endDate`).error && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block text-danger mt-2'>
                                    <span role='alert'>
                                        {formik.getFieldMeta(`workExperiences[${index}].endDate`).error}
                                    </span>
                                </div>
                            </div>
                        )}
                </div>
            }

        </div>

        <div className="fv-row mb-10 row">
            <div className="col-xl-12">
                <label className='form-label required'>Short Description</label>
                <textarea
                    placeholder='Short Description about roles and responsibilities'
                    autoComplete='off'
                    {...formik.getFieldProps(`workExperiences[${index}].description`)}
                    className={clsx(
                        'form-control form-control-sm '
                    )} />
            </div>
        </div>
    </>
}