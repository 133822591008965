import { FC, useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../../theme/helpers'
import { StepperComponent } from '../../../theme/assets/ts/components'
import { Formik, Form, FormikValues } from 'formik'
import { ApiResponse } from '../common/ApiResponse';
import { AxiosResponse } from 'axios';
import { StepperHeading, StepperHeadingProps } from './StepHeading';
import { setNestedObjectValues } from "formik";

const failureMessage = `Failed to save data.`;

type Props = {
    formValues: any,
    submitFn: (formValues: any) => Promise<AxiosResponse<ApiResponse<string>>>,
    validationSchemas: any[],
    stepHeadings: StepperHeadingProps[],
    stepComponents: FC<any>[],
    reduxActionAfterSubmit?: (data: any) => {}
}

const removeNulls = (obj: any) => {
    const keys = Object.keys(obj);
    const newObj = { ...obj };
    for (let i = 0; i < keys.length; i++) {
        if (obj[keys[i]] == null) {
            delete newObj[keys[i]];
        }
    }
    return newObj;
}

export const Stepper: FC<Props> = ({ formValues, submitFn, validationSchemas, stepHeadings, stepComponents, reduxActionAfterSubmit }) => {
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [currentSchema, setCurrentSchema] = useState(validationSchemas[0])
    const [loading, setLoading] = useState(false);
    const [stepIndex, setStepIndex] = useState(1);

    const [initValues] = useState<any>({
        ...removeNulls(formValues)
    })

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }
        setStepIndex(stepIndex - 1);
        stepper.current.goPrev()
        setCurrentSchema(validationSchemas[stepper.current.currentStepIndex - 1])
    }

    const submitStep = (values: any, actions: FormikValues) => {
        if (!stepper.current) {
            return
        }
        setCurrentSchema(validationSchemas[stepper.current.currentStepIndex])

        if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
            stepper.current.goNext()
            setStepIndex(stepIndex + 1);
        } else {
            setLoading(true)
            actions.setSubmitting(true);
            submitFn(values)
                .then(({ data: { failed, message } }) => {
                    if (failed) {
                        actions.setStatus(message);
                    }
                })
                .catch(() => {
                    actions.setStatus(failureMessage);
                })
                .finally(() => {
                    setLoading(false)
                    actions.setSubmitting(false)
                })
        }
    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }
        loadStepper()
    }, [stepperRef])

    return (
        <div
            ref={stepperRef}
            className='stepper stepper-pills stepper-column   d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_create_account_stepper'
        >
            <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
                {({ ...formik }) => (
                    <>
                        <div className='d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='px-6 px-lg-10 px-xxl-15 py-20'>
                                <div className='stepper-nav'>
                                    {stepHeadings.map(stepProps => <StepperHeading {...stepProps} key={stepProps.index} />)}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
                            <Form className='py-20 w-100 w-xl-1000px px-9'>
                                {/* <div className="row">
                                    <div className="col-12">
                                        <pre>{JSON.stringify(formik.values, null, 2)}</pre>
                                    </div>
                                </div> */}
                                {stepComponents.map((cmp, index) => {
                                    const StepComponent = cmp;
                                    return <div data-kt-stepper-element='content' key={index} className={index === 0 ? 'current' : ''}>
                                        <StepComponent formik={formik} />
                                    </div>
                                })}
                                <div className='d-flex flex-stack pt-10'>
                                    <div className='mr-2'>
                                        <button
                                            onClick={prevStep}
                                            type='button'
                                            className='btn btn-lg btn-light-primary me-3 back-button'
                                            data-kt-stepper-action='previous'
                                        >
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr063.svg'
                                                className='svg-icon-4 me-1'
                                            />
                                            Back
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            type='button'
                                            id='kt_sign_up_submit'
                                            className='btn btn-md btn-outline btn-primary w-100 mb-5 continue-button'
                                            onClick={async () => {
                                                const validationErrors = await formik.validateForm()
                                                if (Object.keys(validationErrors).length > 0) {
                                                    formik.setTouched(setNestedObjectValues(validationErrors, true));
                                                    return;
                                                }
                                                formik.submitForm();
                                            }}
                                        >
                                            {!loading &&
                                                (stepIndex < stepHeadings.length ? 'Continue' : 'Save')
                                            }

                                            {loading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr064.svg'
                                                className='svg-icon-3 ms-2 me-0'
                                            />
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </div>
    )
}
