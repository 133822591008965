import { FC } from "react"
import { ReadingScore, WritingScore, SpeakingScore, ListeningScore, TotalScore } from "./Score"

type Props = {
    formik: any,
    fieldPath: string,
}

export const IELTSScore: FC<Props> = ({ formik, fieldPath }) => {
    const props = { formik, fieldPath: `${fieldPath}.ieltsScore` }

    return <>
        <TotalScore formik={formik} fieldPath={`${props.fieldPath}`} />
        <div className="row fv-row mb-10">
            <div className="col-xl-4">
                <ReadingScore {...props} />
            </div>
            <div className="col-xl-4">
                <ListeningScore {...props} />
            </div>
            <div className="col-xl-4">
                <SpeakingScore {...props} />
            </div>
            <div className="col-xl-4">
                <WritingScore {...props} />
            </div>
        </div>
    </>
}
