import moment from 'moment';
import * as Yup from 'yup';
import { StepperHeadingProps } from '../../../../modules/stepper/StepHeading';

export const allowedFileTypes = new Set<string>(['image/jpg', 'image/png', 'image/jpeg', 'application/pdf']);
export const allowedImageFileTypes = new Set<string>(['image/jpg', 'image/png', 'image/jpeg']);
export const stepHeadings: StepperHeadingProps[] = [
    {
        index: 1,
        title: 'Personal Details',
        desc: 'About you',
        htmlId: 'personal-details',
    },
    {
        index: 2,
        title: 'Passport Details',
        desc: 'For visa related workflows',
        htmlId: 'passport-details',
    },
    {
        index: 3,
        title: 'Social Accounts',
        desc: 'To celebrate your success',
        htmlId: 'social-details',
    }
];

export const profileElementSchemas = [
    Yup.object().shape({
        firstName: Yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('First name is required'),
        email: Yup.string()
            .email('Wrong email format')
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Email is required'),
        lastName: Yup.string()
            .min(1, 'Minimum 1 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Last name is required'),
        phoneNumber: Yup.string()
            .min(12, 'Min 10 symbols')
            .max(12, 'Max 10 symbols')
            .required('Phone Number is required'),
        parentPhone: Yup.string()
            .min(12, 'Min 10 symbols')
            .max(12, 'Max 10 symbols')
            .required('Parent Phone Number is required'),
        dateOfBirth: Yup.string().test(
            "dateOfBirth",
            "Age should be more than 14 years",
            value => {
                return moment().diff(moment(value), 'years') >= 14;
            }
        ).required('Date of birth is required'),
        base64ProfilePicture: Yup.string()
            .when('profilePictureUri', {
                is: (profilePictureUri: string) => !profilePictureUri,
                then: Yup.string().required().label('Profile Picture'),
            }),
        interestedCountries: Yup.array().required().min(1).max(3).label('Interested Countries')
    }),
    Yup.object().shape({
        passportNumber: Yup.string()
            .required('Passport Number is required'),
        base64PassportFront: Yup.string()
            .when('passportFrontUri', {
                is: (passportFrontUri: string) => !passportFrontUri,
                then: Yup.string().required('Passport front page attachment is required'),
            }),
        base64PassportBack: Yup.string()
            .when('passportBackUri', {
                is: (passportBackUri: string) => !passportBackUri,
                then: Yup.string().required('Passport back page attachment is required'),
            }),
    }),
    Yup.object().shape({
        instagramUrl: Yup.string().label('Instagram handle'),
        facebookUrl: Yup.string().label('Facebook handle'),
        linkedinUrl: Yup.string().label('Linkedin handle'),
    })
];

