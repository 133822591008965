import { FC } from "react"
import { SATExamScoreDto } from "../../../student/models/ExamModels"

type Props = {
    data: SATExamScoreDto,
}

export const SATScoreView: FC<Props> = ({ data }) => {

    return <div className='row mb-7'>
        <div className="row mb-5">
            <label className="fw-bold text-dark mt-5">Score Breakdown</label>
        </div>
        <div className="col-6 mb-7">
            <label className=' fw-bold text-muted'>Total</label>
            <span className='fw-bolder fs-6 text-dark'>
                {data.total}
            </span>
        </div>
        <div className="row">
            <div className="col-3 mb-7">
                <label className=' fw-bold text-muted'>Critical Reading</label>
                <span className='fw-bolder fs-6 text-dark'>
                    {data.criticalReading}
                </span>
            </div>

            <div className="col-3 mb-7">
                <label className=' fw-bold text-muted'>Math</label>
                <span className='fw-bolder fs-6 text-dark'>
                    {data.math}
                </span>
            </div>

            <div className="col-3 mb-7">
                <label className=' fw-bold text-muted'>Writing</label>
                <span className='fw-bolder fs-6 text-dark'>
                    {data.writing}
                </span>
            </div>
        </div>
    </div>
}