/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import { FC, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap-v5";
import { toast } from "react-toastify";
import { BulkFileUploader } from "../../../modules/common/components/FileUploader";
import * as Yup from 'yup'
import { AxiosResponse } from "axios";

const createConversationSchema = Yup.object().shape({
    base64File: Yup.string()
        .required('File is required'),
})

export const UploadDocumentBtn: FC<{ className?: string, showIcon?: boolean, onSend: (req: any) => Promise<AxiosResponse<any>> }> = ({ className = "btn btn-primary", showIcon = false, onSend }) => {
    const [showModal, setShowModal] = useState(false);
    return <>
        <button className={className} onClick={() => { setShowModal(true) }}>
            <i className="bi bi-envelope text-white"></i>
            {!showIcon && `Upload New Document`}</button>
        <UploadDocumentDialog visible={showModal} setVisibility={setShowModal} onSend={onSend} />
    </>
}

type Props = { visible: boolean, setVisibility: (vis: boolean) => void, onSend: (req: any) => Promise<AxiosResponse<any>> }

export const UploadDocumentDialog: FC<Props> =
    ({ visible, setVisibility, onSend }) => {
        const [fileDto, setFile] = useState<UploadFileDto>();
        const [loading, setLoading] = useState(false);

        const formik = useFormik<UploadFileDto>({
            initialValues: { base64File: undefined, fileName: undefined },
            onSubmit: (values, { setStatus, setSubmitting }) => {
                setLoading(true);
                onSend(values)
                    .then(() => {
                        setLoading(false);
                        handleClose();
                    })
                    .catch(() => {
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('Error uploading file.')
                    })
            },
            validationSchema: createConversationSchema
        });

        const handleClose = () => {
            setVisibility(false);
        }
        const onUpload = (files: any) => {
            const reader = new FileReader();
            const file = files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64 = reader.result?.toString().split(";base64,")[1] as string;
                setFile({ base64File: base64, fileName: file.name });
                formik.setFieldValue('base64File', base64);
                formik.setFieldValue('fileName', file.name);
            };
        }

        return <>
            <Modal show={visible}
                onHide={handleClose}
                backdrop="static"
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        {formik.status && (
                            <div className='mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{formik.status}</div>
                            </div>
                        )}

                        {fileDto ?
                            <div className="d-flex col-3">
                                <a href="" className="d-flex flex-grow-1">{fileDto.fileName}</a>
                                <button className="btn btn-sm btn-icon" onClick={() => {
                                    setFile(undefined);
                                }}>
                                    <i className="bi bi-trash text-danger"></i>
                                </button>
                            </div>
                            :
                            <BulkFileUploader
                                onUpload={(files) => {
                                    if (files.length > 1)
                                        toast('Upload only 1 file.', { type: 'warning' });
                                    onUpload(files)
                                }}
                                maxFiles={1}
                                uploadBtnText="Add" />
                        }
                        {formik.touched.base64File && formik.errors.base64File && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert'>{formik.errors.base64File}</span>
                            </div>
                        )}
                        <div className="separator"></div>
                        <div className="d-flex">
                            <Button variant="secondary" className="m-5" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" className="m-5" type="submit" disabled={formik.isSubmitting || !formik.isValid}>
                                {!loading && <span className='indicator-label'>Upload</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal></>
    }

export interface UploadFileDto {
    base64File?: string;
    fileName?: string;
}