/* eslint-disable react-hooks/exhaustive-deps, @typescript-eslint/no-unused-vars*/
import clsx from 'clsx';
import { Form, Formik, setNestedObjectValues } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Button, InputGroup, Spinner } from 'react-bootstrap-v5';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Select from 'react-select';
import { getLorTypes } from '../../../modules/common/CommonService';
import Notice from '../../../modules/common/components/Notice';
import { QuestionnaireTypeId } from '../../../modules/common/enums/QuestionnaireTypeId';
import { DropdownOption, useSharedState } from '../../../modules/common/SharedStateProvider';
import { IQuestionnaireDetailResponseDto, IQuestionResponseDto, IProfileState } from '../models';
import { addLorDetails, deleteLorDetails, getLorDetails, getQuestionsForLor, updateLorDetails } from '../services/LorService';
import { getConfig } from '../services/StudentProfileService';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { questionnaireOptions } from '../../../modules/common/DropdownOptions';
import { PageTitle } from '../../../../theme/layout/core';
import { WordCounter } from '../../../modules/common/components/WordCounter';

type Props = {
    profileId: string
}

const validationSchema = Yup.object().shape({
    answers: Yup.array().of(Yup.object().shape({
        answer: Yup.string().required(),
        wordCount: Yup.number().required().max(300)
    })),
});

const LorQuestionnairePage: FC<RouteComponentProps<Props>> = ({ history, match: { params: { profileId } } }) => {
    const [requiresLor, setRequiresLor] = useState<boolean>();
    const [lorTypeOptions, setLorTypeOptions] = useState<Array<DropdownOption>>([]);
    const [lorDetails, setLorDetails] = useState<Array<IQuestionnaireDetailResponseDto>>([]);
    const [questionsMap, setQuestionsMap] = useState<any>();
    const [selectedLor, setSelectedLor] = useState<IQuestionnaireDetailResponseDto>();
    const [selectedQuestionnaireType, setSelectedQuestionnaireType] = useState<QuestionnaireTypeId>();
    const [loading, setLoading] = useState(false);
    const [refreshKey, setRefreshKey] = useState(1);
    const [profileState, setProfileState] = useState<IProfileState>();
    const [hasPendingPayment, setHasPendingPayment] = useState<boolean>(false);
    const sharedState = useSharedState();

    useEffect(() => {
        function fetchData() {
            setLoading(true);
            getConfig(+profileId)
                .then(resp => {
                    setRequiresLor(resp.data.requiresLor);
                    setProfileState(resp.data.profileState);
                    if (resp.data.overridePaymentRule) {
                        setHasPendingPayment(false);
                    } else if (resp.data.balanceFees) {
                        setHasPendingPayment(true);
                    }
                })
                .catch(err => setRequiresLor(false))
                .finally(() => setLoading(false));
        }

        fetchData();
    }, [profileId])

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const { data: lorOptions } = await getLorTypes();
                setLorTypeOptions(lorOptions.filter(x => x.value !== QuestionnaireTypeId.Sop));
                setRefreshKey(Math.random());
            } finally {
                setLoading(false);
            }
        }

        if (requiresLor) {
            fetchData();
        }
    }, [requiresLor]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const { data: currentLorDetails } = await getLorDetails(+profileId);
                setLorDetails(currentLorDetails.map(x => ({ ...x, trackingId: Math.random() })));
                setSelectedLor(undefined);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [refreshKey]);

    const loadLor = (trackingId?: number) => {
        const lor = lorDetails?.find(x => x.trackingId === trackingId);
        if (lor) {
            setSelectedLor(lor);
        }
    }

    const addQuestionnaire = async (selectedQuestionnaireType: QuestionnaireTypeId) => {
        if (lorDetails.length === 3) return;
        try {
            setLoading(true);
            let questions: IQuestionResponseDto[] = [];
            if (questionsMap && questionsMap[selectedQuestionnaireType]) {
                questions = questionsMap[selectedQuestionnaireType];
            } else {
                const { data } = await getQuestionsForLor(selectedQuestionnaireType, +profileId);
                questions = data;
                const updated = { ...questionsMap, [selectedQuestionnaireType]: questions };
                setQuestionsMap(updated);
            }
            const lorDetail: IQuestionnaireDetailResponseDto = {
                id: 0,
                profileId: +profileId,
                questionnaireTypeId: selectedQuestionnaireType,
                trackingId: Math.random(),
                answers: questions.map(x => ({
                    id: 0,
                    questionId: x.id,
                    questionText: x.questionText,
                    answer: "",
                    editable: true,
                    wordCount: 0
                }))
            };
            setLorDetails([...lorDetails, lorDetail]);
            setSelectedLor(lorDetail);
            setSelectedQuestionnaireType(undefined)
        } finally {
            setLoading(false);
        }
    }
    const handleDelete = () => {
        if (!selectedLor) return;
        const hasConfirmed = window.confirm('All unsaved changes to other questionnaires will be lost, do you want to continue?');
        if (!hasConfirmed) {
            return;
        }
        if (selectedLor.id === 0) {
            // remove from local
            setRefreshKey(Math.random());
            return;
        }

        setLoading(true);
        deleteLorDetails(+profileId, selectedLor.id)
            .then(() => {
                setRefreshKey(Math.random());
                toast('Answers deleted successfully', { type: 'success' })
            })
            .finally(() => setLoading(false));
    }
    const handleSubmit = (profileId: string, values: IQuestionnaireDetailResponseDto) => {
        var sumbitFn: any = selectedLor?.id ? updateLorDetails : addLorDetails;
        setLoading(true);
        sumbitFn(+profileId, {
            lorDetailsId: selectedLor?.id,
            profileId: +profileId,
            questionnaireTypeId: values.questionnaireTypeId,
            questionsWithAnswer: values.answers.map(a => {
                return {
                    id: a.id,
                    answer: a.answer,
                    questionId: a.questionId
                };
            })
        })
            .then(() => {
                setRefreshKey(Math.random());
                toast('Changes saved successfully', { type: 'success' })
            })
            .finally(() => setLoading(false));
    }

    const title = questionnaireOptions.find(x => x.value === selectedLor?.questionnaireTypeId)?.label;
    const description = lorTypeOptions?.find(x => x.value === selectedLor?.questionnaireTypeId)?.label;

    if (!loading && profileState && !profileState.isApproved) {
        return <Notice message='This module is not yet active as your profile is not approved.' />
    }

    // if (!loading && (hasPendingPayment)) {
    //     return <Notice message='This module is not yet active due to pending payment.' />
    // }

    return <>
        <PageTitle description='Manage LOR Questionnaires' breadcrumbs={[]}>Manage LOR Questionnaires</PageTitle>
        <div className='card mb-5 mb-xl-10'>
            <div className="card-header">
                <h3 className='card-title'>Manage LOR Questionnaires</h3>
                {lorDetails && lorDetails.length > 0 &&
                    <Button className='btn btn-sm btn-outline' variant='outline'>
                        <Link to={`/student/${profileId}/lor`}>
                            <i className='bi bi-view-list'></i> Preview
                        </Link>
                    </Button>
                }
            </div>
            {!loading && !profileId ? <Notice message='Profile not found' /> : <div className='card-body pt-9 pb-0'>
                <div className='row mb-10'>
                    {loading && <Spinner variant='primary' animation='grow' />}
                    {!requiresLor && <div className='col-xl-6'>
                        <Notice message='Profile does not require LOR.' />
                    </div>
                    }
                    {sharedState.isStudent && profileState && profileState.isLorQuestionnaireApproved && <>
                        <div className='col-xl-12'>
                            <Notice message='LOR has been approved.' />
                        </div>
                    </>}
                    {/* LOR Page */}
                    <LORInstructions />
                    {requiresLor && !loading && profileState && <div
                        className='stepper stepper-pills stepper-column   d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper'
                    >
                        <div className='d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='px-6 px-lg-10 px-xxl-15 py-20'>
                                <div className="row mb-5">
                                    <label className='form-label mb-5 fs-6 fw-bold'>Add LOR Questionnaire</label>
                                    <InputGroup>
                                        <Select
                                            className='form-control form-control-sm p-0'
                                            options={lorTypeOptions}
                                            styles={{ menu: base => ({ ...base, zIndex: 2 }) }}
                                            onChange={(opt) => opt && setSelectedQuestionnaireType(opt.value)}
                                            value={lorTypeOptions.find(x => x.value === selectedQuestionnaireType) || null}
                                        />

                                        <span className="input-group-btn">
                                            <button className="btn btn-sm" type="button"
                                                onClick={() => {
                                                    selectedQuestionnaireType && addQuestionnaire(selectedQuestionnaireType);
                                                }}
                                                disabled={lorDetails.length === 3}>
                                                Add
                                            </button>
                                        </span>
                                    </InputGroup>
                                </div>
                                <h3>Answered Questionnaires</h3>
                                <div className='stepper-nav'>
                                    {
                                        lorDetails && <>
                                            {
                                                lorDetails.map((details, idx) => {
                                                    return <div
                                                        key={`answer_${details.trackingId}_${idx}`}
                                                        className={clsx('stepper-item p-5',
                                                            {
                                                                'border border-dashed border-primary bg-light-primary': details.trackingId === selectedLor?.trackingId
                                                            })}
                                                        data-kt-stepper-element='nav'
                                                        onClick={() => { loadLor(details.trackingId); }}>
                                                        <div className='stepper-icon w-40px h-40px' style={{ cursor: 'pointer' }}>
                                                            <i className='stepper-check fas fa-check'></i>
                                                            <span className='stepper-number'>{idx + 1}</span>
                                                        </div>

                                                        <div className='stepper-label' style={{ cursor: 'pointer' }}>
                                                            <h3 className='stepper-title'>{questionnaireOptions.find(x => x.value === details?.questionnaireTypeId)?.label}</h3>
                                                            <div className='stepper-desc fw-bold'>{lorTypeOptions?.find(x => x.value === details?.questionnaireTypeId)?.label}</div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                            <button
                                                type='button'
                                                disabled={lorDetails?.length < 3 || profileState.isLorQuestionnaireSubmitted}
                                                onClick={() => history.push(`/student/${profileId}/lor/submit`)}
                                                className='btn btn-md btn-secondary me-3'
                                            >
                                                <i className="bi bi-hand-thumbs-up"></i>
                                                Preview and Submit
                                            </button>
                                            {profileState.isLorQuestionnaireSubmitted ? <p className='text-danger mt-2'>You have already submitted your LOR.</p> : <p className='text-muted mt-2'>You need minimum 3 LOR questionnaires to submit</p>}
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                        {selectedLor ?
                            <div className='d-flex flex-row-fluid flex-center bg-white rounded' key={`selectedLor_${selectedLor.id}`}>
                                <Formik initialValues={selectedLor} onSubmit={(values) => {
                                    handleSubmit(profileId, values);
                                }} validationSchema={validationSchema}>
                                    {({ ...formik }) => (
                                        <Form className='py-20 w-100 w-xl-1000px px-9'>
                                            {/* Component */}
                                            {selectedLor &&
                                                <>
                                                    <div className="row text-center">
                                                        <h3 className='card-title mb-2'>
                                                            {title}
                                                        </h3>
                                                        <p className="text-muted">
                                                            {description}
                                                        </p>
                                                    </div>
                                                    {
                                                        selectedLor.answers.map((x, idx) => (
                                                            <div key={`lor_${selectedLor.id}_${idx}`} className='fv-row mb-10 row'>
                                                                
                                                                <label className='form-label fw-bolder text-dark fs-6 required'><span className='badge badge-light-primary m-2'>{idx+1}</span>{x.questionText}</label>
                                                                <div className="col-xl-8">
                                                                    <textarea
                                                                        placeholder='Answer'
                                                                        autoComplete='off'
                                                                        {...formik.getFieldProps(`answers[${idx}].answer`)}
                                                                        className={clsx(
                                                                            'form-control form-control-sm ',
                                                                            {
                                                                                'is-invalid': formik.getFieldMeta(`answers[${idx}].answer`).touched && formik.getFieldMeta(`answers[${idx}].answer`).error,
                                                                            },
                                                                            {
                                                                                'is-valid': formik.getFieldMeta(`answers[${idx}].answer`).touched && !formik.getFieldMeta(`answers[${idx}].answer`).error,
                                                                            }
                                                                        )} />
                                                                    {formik.getFieldMeta(`answers[${idx}].answer`).touched && formik.getFieldMeta(`answers[${idx}].answer`).error && (
                                                                        <div className='fv-plugins-message-container'>
                                                                            <div className='fv-help-block text-danger mt-2'>
                                                                                <span role='alert'>Answer is required and should not exceed 300 words</span>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    <WordCounter input={formik.values.answers[idx].answer} limit={300} onChange={(val) => {
                                                                        formik.setFieldValue(`answers[${idx}].wordCount`, val);
                                                                    }} />
                                                                    {formik.values.answers[idx]?.wordCount && formik.values.answers[idx]?.wordCount > 300 && (
                                                                        <div className='fv-plugins-message-container'>
                                                                            <div className='fv-help-block text-danger mt-2'>
                                                                                <span role='alert'>Answer should not exceed 300 words</span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                            }
                                            <div className='d-flex pt-10'>
                                                <div className='m-2'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-md btn-primary w-100 mb-5'
                                                        onClick={async () => {
                                                            const validationErrors = await formik.validateForm()
                                                            if (Object.keys(validationErrors).length > 0) {
                                                                formik.setTouched(setNestedObjectValues(validationErrors, true));
                                                                alert("Please fix the validation errors");
                                                                return;
                                                            } else {
                                                                formik.submitForm();
                                                            }
                                                        }}
                                                    >
                                                        <i className="bi bi-box-arrow-down"></i>
                                                        {!loading && 'Save'}

                                                        {loading && (
                                                            <span className='indicator-progress' style={{ display: 'inline-block' }}>
                                                                Please wait...{' '}
                                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                            </span>
                                                        )}

                                                    </button>
                                                </div>
                                                <div className='m-2'>
                                                    <button
                                                        onClick={handleDelete}
                                                        type='button'
                                                        className='btn btn-md btn-danger me-3'
                                                    >
                                                        <i className="bi bi-trash"></i>
                                                        {!loading && 'Delete'}

                                                        {loading && (
                                                            <span className='indicator-progress' style={{ display: 'inline-block' }}>
                                                                Please wait...
                                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>)}
                                </Formik>
                            </div>
                            :
                            <div className="row mt-15">
                                <div className="col-12">
                                    <Notice message='Questionnaire will appear here, once you select from left pane' type='warning' />
                                </div>
                            </div>
                        }
                    </div>
                    }
                </div>
            </div>}
        </div>
    </>;
}

const LORInstructions: FC = () => {
    return <div className="col-xl-12 row">
        <h3>Instructions</h3>
        <p>Act 1: You need to provide us with the data within a week.</p>
        <p>Act 2: You will then do the following:</p>
        <ol className='m-5 mt-0'>
            <li>&nbsp;Get the recommendations approved</li>
            <li>&nbsp;Get the recommender to sign the recommendation letters, recommendation forms and the envelopes on the flap.</li>
            <li>&nbsp;You need to get 3 copies of recommendations per recommender.</li>
            <li>&nbsp;Speak with the recommender about sending the LOR Online to the universities</li>
            <li>&nbsp;It is best to use three different fonts for the three different letters</li>
            <li>&nbsp;Please ensure that you speak with your IMFS counselor, if you face any difficulties in acquiring a Letter of Recommendation.</li>
        </ol>
        <p><strong>Note: Please speak to the IMFS office about the recommendation forms before you get the Recommendation Letters signed and sealed.</strong></p>
    </div>
}

export default withRouter(LorQuestionnairePage);
