/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment"
import { FC } from "react"
import { useHistory } from "react-router-dom"
import { Conversation, ConversationStatus, conversationStatusMap } from "../models/ConversationModel"

export const ConversationTile: FC<{ conversation: Conversation }> = ({ conversation }) => {
  const history = useHistory();
  const handleTransition = (conversationId: number) => {
    history.push(`/message/${conversationId}`);
  }

  return <div className='d-flex flex-stack py-4'>
    <div className='d-flex cursor-pointer flex-grow-1'>
      <div className='symbol symbol-45px symbol-circle'>
        <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
          {conversation.title.charAt(0).toUpperCase()}
        </span>
      </div>

      <div className='mb-0 me-2 m-1' onClick={() => {
        handleTransition(conversation.id)
      }}>
        <a className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
          {conversation.title}
        </a>
        <div className='text-gray-400 fs-7'>{conversation.messages[conversation.messages.length - 1]?.messageBody?.substring(0, 30)}...</div>
      </div>
    </div>

    <div className="col-4">
      {conversation.unreadMessageCount ?
        <span className={`badge badge-danger badge-circle fs-8 m-1`}>{conversation.unreadMessageCount}</span>
        :
        <span className={`badge badge-light-${conversation.status === ConversationStatus.Closed ? 'success' : 'danger'} fs-8`}>{conversationStatusMap.find(x => x.value === conversation.status.toString())?.label}</span>
      }
      <span className='badge badge-light fs-8 mb-1'>{moment(conversation.lastUpdatedAt).fromNow()}</span>
    </div>
  </div>
}