/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react"
import { Button, ButtonGroup } from "react-bootstrap-v5";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ConfirmationModal } from "../../modules/common/components/ConfirmationModal";
import Notice from "../../modules/common/components/Notice";
import { ProfileStatus } from "../../modules/common/enums/ProfileStatus";
import { StudentProfile } from "../student/models";
import { ViewLorResponse } from "../student/pages/ViewLorQuestionnairePage";
import { approveLor, rejectLor } from "../student/services/LorService";
import { getStudentProfile } from "../student/services/StudentProfileService";
import { AddCommentPanel } from "../../modules/common/components/ProfileTimeline";

type PathParamsType = {
    profileId: string,
}

const ApproveLorPage: FC<RouteComponentProps<PathParamsType>> = ({ match: { params: { profileId } } }) => {
    const [confimation, showConfirmation] = useState(false);
    const [profile, setProfile] = useState<StudentProfile | undefined>(undefined);
    const history = useHistory();

    useEffect(() => {
        const fetchPersonalProfile = (profileId: number) => {
            getStudentProfile(profileId)
                .then(({ data: serverProfile }) => {
                    serverProfile.dateOfBirth = new Date(serverProfile.dateOfBirth).toLocaleDateString();
                    setProfile(serverProfile);
                }).catch(err => {
                    //notify TODO
                })
        }

        if (profileId) {
            fetchPersonalProfile(+profileId);
        }
    }, [profileId]);

    const handleSubmit = () => {
        if (profile) {
            approveLor(profile.id)
                .then(() => {
                    toast(`${profile.firstName}'s LOR approved successfully`, { type: 'success' })
                    //force refresh
                    history.push('/');
                })
                .finally(() => {
                    showConfirmation(false);
                })
        }
    }

    if (profile && profile.profileStatuses?.indexOf(ProfileStatus.LorQuestionnaireApproved) !== -1) {
        return <Notice title={`LOR Questionnaire Already Approved`}
            message={`${profile?.firstName}'s LOR Questionnaire has already been approved`}
        />
    }

    return <>
        {
            confimation && <ConfirmationModal title={`Confirm ${profile?.firstName} ${profile?.lastName}'s LOR Questionnaire Approval`} onConfirm={() => {
                handleSubmit();
            }}
                onCancel={() => showConfirmation(false)}>
                <Notice title={`Approve ${profile?.firstName}'s LOR Questionnaire?`}
                    message='Make sure student has entered all information correctly before approving.'
                />
            </ConfirmationModal>
        }
        <div className="row">
            <div className="col bg-white pt-2 m-2">
                <ButtonGroup className='mb-5'>
                    <Button className="btn btn-sm btn-outline btn-outline-primary" variant='outline' onClick={() => showConfirmation(true)} disabled={!profile}>
                        <i className='bi bi-check text-dark'></i> Approve
                    </Button>
                    <Button className="btn btn-sm btn-outline btn-outline-primary" variant='outline' disabled={!profile}>
                        <AddCommentPanel profileId={+profileId} onComplete={(comment) => {
                            rejectLor(+profileId, comment).then(x=>{
                                history.push('/');
                            })
                        }} />
                    </Button>
                </ButtonGroup>
            </div>
        </div>
        <ViewLorResponse profileId={profileId} />
    </>
}

export default withRouter(ApproveLorPage);