/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Card, Spinner } from 'react-bootstrap-v5';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Notice from '../../../modules/common/components/Notice';
import { IAcademicProfile, IQuestionnaireDetailResponseDto, StudentProfile } from '../models';
import { getSopDetails } from '../services/LorService';
import { getConfig, getStudentAcademicProfile, getStudentProfile } from '../services/StudentProfileService';
import { PageTitle } from '../../../../theme/layout/core';
import { ProfileHeader } from '../../student-profiles/components/ProfileHeader';
import { PDFExport } from '@progress/kendo-react-pdf';
import Divider from '@material-ui/core/Divider';
import { ExamScoresOverview } from '../../student-profiles/components/ExamScoresOverview';
import { FutureStudyOverview } from '../../student-profiles/components/FutureStudyOverview';
import { GraduationDetailsOverview } from '../../student-profiles/components/GraduationDetailsOverview';
import { WorkDetailsOverview } from '../../student-profiles/components/WorkDetailsOverview';

type Props = {
    profileId: string
}

const ViewSopResponsePage: FC<RouteComponentProps<Props>> = ({ history, match: { params: { profileId } } }) => {
    return <ViewSopResponse profileId={profileId} />
}

export default withRouter(ViewSopResponsePage);

export const ViewSopResponse: FC<Props> = ({ profileId }) => {
    const [requiresSop, setRequiresSop] = useState<boolean>();
    const [sopDetail, setSopDetail] = useState<IQuestionnaireDetailResponseDto>();
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState<StudentProfile>();
    const pdfExportComponent = useRef(null);

    useEffect(() => {
        const fetchPersonalProfile = (profileId: number) => {
            getStudentProfile(+profileId)
                .then(({ data: serverProfile }) => {
                    setProfile(serverProfile);
                })
                .finally(() => setLoading(false));
        }

        if (profileId) {
            fetchPersonalProfile(+profileId);
        }
    }, [profileId]);


    const exportPDF = () => {
        if (pdfExportComponent.current) {
            (pdfExportComponent.current as any).save();
        }
    };

    useEffect(() => {
        function fetchData() {
            setLoading(true);
            getConfig(+profileId)
                .then(resp => {
                    setRequiresSop(resp?.data?.requiresSop);
                })
                .catch(err => setRequiresSop(false))
                .finally(() => setLoading(false));
        }
        fetchData();
    }, [profileId])

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const { data: currentSopDetail } = await getSopDetails(+profileId);
                setSopDetail(currentSopDetail || null);
            } finally {
                setLoading(false);
            }
        }
        requiresSop && fetchData();
    }, [requiresSop]);
    const [academicProfile, setAcademicProfile] = useState<IAcademicProfile>();

    useEffect(() => {
        const fetchAcademicProfile = (profileId: number) => {
            getStudentAcademicProfile(profileId)
                .then(({ data: serverProfile }) => {
                    if (!serverProfile.workExperiences) {
                        serverProfile = { ...serverProfile, workExperiences: [] };
                    }
                    setAcademicProfile(serverProfile);
                }).catch(err => {
                    //notify TODO
                    console.error(err);
                })
        }
        fetchAcademicProfile(+profileId);
    }, [profileId]);

    if (!loading && !profileId) {
        <Notice message='Profile not found' />
    }

    return <>
        <PageTitle description='Manage SOP Questionnaires' breadcrumbs={[]}>View SOP Questionnaire Answers</PageTitle>
        <div className="row">
            <div className="col bg-white pt-2 m-2">
                <ButtonGroup className='mb-5'>
                    <Button className="btn btn-sm btn-outline btn-outline-primary" variant='outline' onClick={exportPDF} disabled={!profile || !requiresSop}>
                        <i className='bi bi-download text-dark'></i> Download
                    </Button>
                </ButtonGroup>
            </div>
        </div>
        <div className='card mb-5 mb-xl-10'>
            <div className="card-header">
                <h3 className='card-title mb-2'>
                    SOP Questionnaire Response
                </h3>
            </div>
            <div className='card-body pt-9 pb-0'>
                {profile && <ProfileHeader showActions={false} exportPDF={null} profile={profile} />}
                <PDFExport
                    ref={pdfExportComponent}
                    paperSize="auto"
                    margin={40}
                    fileName={`SOP Export - ${profile?.email}.pdf`}
                    author="Aniket Dandawate"
                    creator="Aniket Dandawate">
                    <div className='row mb-10'>
                        {!requiresSop && <div className='col-xl-6'>
                            <Notice message='Profile does not require SOP.' />
                        </div>
                        }
                        {loading && <Spinner variant='primary' animation='grow' />}
                        {academicProfile &&
                            <><GraduationDetailsOverview academicsData={academicProfile} />
                                <FutureStudyOverview academicsData={academicProfile} />
                                <WorkDetailsOverview data={academicProfile.workExperiences || []} />
                            </>}
                        {profile && <ExamScoresOverview data={{
                            competitiveExam: profile.competitiveExam,
                            languageExam: profile?.languageExam, profileId: profile.id
                        }} />}
                        {requiresSop && !loading &&
                            <div
                                className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                                id='kt_create_account_stepper'
                            >
                                {sopDetail == null ?
                                    <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
                                        <div className='col-xl-6'>
                                            <Notice message='SOP Questionnaire is not responded by student.' />
                                        </div>
                                    </div>
                                    :
                                    <div className='row bg-white rounded'>
                                        {sopDetail.answers.map((a, idx) => {
                                            return <Card className="mb-5 border border-primary p-5 text-justify" key={a.id}>
                                                <div className='row mb-5'>
                                                    <div className="col-12">
                                                        <span className='badge badge-info badge-light-info m-2'>{idx + 1}</span>
                                                        <span className='badge badge-primary badge-light-primary m-2'>Question</span>
                                                        <p className="text-dark mb-2">
                                                            <label dangerouslySetInnerHTML={{ __html: a.questionText }}></label>
                                                        </p>
                                                    </div>
                                                </div>
                                                <Divider variant='fullWidth' className='bg-danger' />
                                                <div className="row mt-2">
                                                    <div className="col-12 ">
                                                        <p className="text-dark mb-2">
                                                            <span className='badge badge-danger badge-light-danger m-2'>Answer</span>
                                                            <p className='form-label bg-light-warning' dangerouslySetInnerHTML={{ __html: a.answer.replaceAll('\n', '<br/>') }}></p>
                                                        </p>
                                                    </div>
                                                </div>
                                            </Card>
                                        })}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </PDFExport>
            </div>
        </div>

    </>;
}
