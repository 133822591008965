import { FC } from 'react'
import Notice from '../../../modules/common/components/Notice';
import { StudentProfile } from '../../student/models';

type Props = {
    data: StudentProfile
}

export const PassportDetailsOverview: FC<Props> = ({ data }) => (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Passport Details</h3>
            </div>
        </div>

        <div className='card-body'>
            {data ?
                <>
                    <div className='row mb-7 col-lg-6'>
                        <label className='col-lg-4 fw-bold text-muted'>Passport Number</label>
                        <div className='col-lg-4'>
                            <span className='fw-bolder fs-6 text-dark'>
                                {data.passportNumber}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className='mb-7 col-lg-6'>
                            <label className='col-lg-4 fw-bold text-muted'>Passport Front</label>
                            <div className='col-lg-4'>
                                <img className="img-thumbnail rounded" alt='Passport Back' src={data.passportFrontUri} />
                            </div>
                        </div>
                        <div className='mb-7 col-lg-6'>
                            <label className='col-lg-4 fw-bold text-muted'>Passport Back</label>
                            <div className='col-lg-4'>
                                <img className="img-thumbnail rounded" alt='Passport Front' src={data.passportBackUri} />
                            </div>
                        </div>
                    </div>
                </>
                :
                <Notice title='No Passport Info Found' message='Passport information has not been submitted for this account.' />
            }
        </div>
    </div>
)