import { FC, useEffect, useState } from "react";

export const WordCounter: FC<{ input: string, limit: number, onChange: (count: number) => void }> = ({ input, limit, onChange }) => {
    const [wordCount, setWordCount] = useState(0);

    useEffect(() => {
        const countWords = (str: string) => {
            if (!str) return 0;
            if (str.length === 0) {
                return 0;
            } else {
                str = str.replace(/(^\s*)|(\s*$)/gi, "");
                str = str.replace(/[ ]{2,}/gi, " ");
                str = str.replace(/\n /, "\n");
                return str.split(' ').length;
            }
        }
        const count = countWords(input);
        setWordCount(count);
        onChange(count);
        //eslint-disable-next-line
    }, [input]);

    return (
        <>
            <span className='indicator-progress m-2' style={{ display: 'block' }}>
                <span className={`badge badge-light-${wordCount > limit ? 'danger' : 'info'}`}>{wordCount} {limit && <>/{limit} words</>}</span>
            </span>
        </>
    );
}
