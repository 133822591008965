import { FC, useEffect, useState } from "react"
import { AcademicProfileWizard } from "../../components/AcademicProfileEdit/AcademicProfileWizard"
import { IAcademicProfile } from "../../models"
import { getStudentAcademicProfile, saveStudentAcademicProfile } from "../../services/StudentProfileService"
import { Spinner } from "react-bootstrap-v5"
import { Error500 } from "../../../../modules/errors/components/Error500"

type PathParamsType = {
    profileId: string,
}

const EditAcademicProfilePage: FC<PathParamsType> = ({ profileId }) => {
    const [profile, setProfile] = useState<IAcademicProfile>();
    const [failed, setFailed] = useState(false);

    useEffect(() => {
        const fetchProfile = (profileId: number) => {
            getStudentAcademicProfile(profileId)
                .then(({ data: serverProfile }) => {
                    if (!serverProfile.workExperiences) {
                        serverProfile = { ...serverProfile, workExperiences: [] };
                    }
                    setProfile(serverProfile);
                }).catch(err => {
                    //notify TODO
                    setFailed(true);
                    // history.push('/error/404')
                })
        }
        if (profileId) {
            fetchProfile(+profileId);
        }
        // eslint-disable-next-line
    }, [profileId]);
    
    if(failed){
        return <Error500 />
    }
    
    if (!profile) {
        return <Spinner animation="grow" variant="primary" />
    }
    
    return <AcademicProfileWizard profile={profile} submitFn={saveStudentAcademicProfile} />
}

export default EditAcademicProfilePage;