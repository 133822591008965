import { FC, useCallback, useState } from 'react'
import { Spinner } from 'react-bootstrap-v5'
import { toast } from 'react-toastify'
import { ConfirmationModal } from '../../../modules/common/components/ConfirmationModal'
import tableIcons from '../../../modules/common/components/MaterialTableIcons'
import Notice from '../../../modules/common/components/Notice'
import StudentList, { StudentListColumnMap } from '../../student-profiles/components/StudentList'
import { getPendingDocumentApprovalsPaginated } from '../../student-profiles/services/StudentManager'
import { StudentProfile } from '../../student/models'
import { approveOrRejectDocument, uploadLor, uploadSop } from '../../student/services/LorService'
import { DelegationStatus, DocumentType } from '../ContentWriterDashboard'
import { UploadDocumentDialog } from '../_modals/UploadDocumentDialog'
import { useHistory } from 'react-router-dom'

export const StudentsPendingDocuments: FC<{ onLoad?: (count: number) => void }> = ({ onLoad }) => {
  const [refreshKey, setRefreshKey] = useState<number>(0)
  const [selectedStudent, setSelected] = useState<StudentProfile>()
  const [showNotice, setShowNotice] = useState(false)
  const [action, setAction] = useState<DelegationStatus>(DelegationStatus.UnderAdminReview)
  const [loading, setLoading] = useState(false)
  const [rejectionReason, setRejectionReason] = useState<string>();
  const [uploadDialogShown, setUploadDialogShown] = useState<boolean>(false);
  const history = useHistory();

  const confirmAction = (isSubmit: boolean) => {
    if (isSubmit) {
      if (!selectedStudent) return;
      if (action === DelegationStatus.Rejected && !rejectionReason) {
        toast('Please enter the rejection reason', { type: 'error' });
        setShowNotice(false);
        return;
      }
      setLoading(true);
      approveOrRejectDocument({
        profileId: selectedStudent.id,
        status: action,
        documentType: selectedStudent.lorUri ? DocumentType.LOR : DocumentType.SOP,
        rejectionReason
      })
        .then(() => setRefreshKey(Math.random()))
        .finally(() => setLoading(false));
    }
    setShowNotice(!isSubmit);
  }

  const getMessage = useCallback(() => {
    if (action === DelegationStatus.ApprovedAndSent) {
      return "This document will be available for students to download";
    } else {
      return "Please enter rejection reason as comment to help clarify content writer improvements";
    }
  }, [action]);
  return (
    <>
      {showNotice && (
        <ConfirmationModal
          title='Confirm'
          onConfirm={() => {
            confirmAction(true)
          }}
          onCancel={() => { setShowNotice(false) }}
        >
          <Notice
            title='Are you sure?'
            message={getMessage()}
          />
          {action === DelegationStatus.Rejected &&
            <>
              <textarea className='form-control required my-10' onChange={(ev) => {
                setRejectionReason(ev.target.value);
              }} />
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger mt-2'>
                  <span role='alert'>Please enter the comments.</span>
                </div>
              </div>
            </>}
        </ConfirmationModal>
      )}
      {loading && <Spinner animation={'grow'} variant='primary' />}

      {selectedStudent && <UploadDocumentDialog visible={uploadDialogShown} setVisibility={(state) => {
        setUploadDialogShown(state);
        setSelected(undefined);
      }} onSend={(req) => {
        return (selectedStudent.lorUri ?
          uploadLor(selectedStudent.id, req) :
          uploadSop(selectedStudent.id, req))
          .then((res) => {
            setUploadDialogShown(false);
            setRefreshKey(Math.random());
            setSelected(undefined);
            return res;
          });
      }} />}

      <StudentList
        key={refreshKey}
        title='Profiles Pending CW Documents Approval'
        getStudentsFromServer={getPendingDocumentApprovalsPaginated}
        useDefaultActions={false}
        extraColumns={[StudentListColumnMap.QuestionnaireApprovedDate]}
        actions={[
          (rowData: StudentProfile) => ({
            icon: tableIcons.OpenInNew as any,
            tooltip: rowData.lorUri ? "View LOR Questionnaire" : 'View SOP Questionnaire',
            onClick: (event: any, rowData: StudentProfile) => {
              if (rowData.lorUri) {
                history.push(`/student/${rowData.id}/lor`)
              } else {
                history.push(`/student/${rowData.id}/sop`)
              }
            },
          }),
          (rowData: StudentProfile) => ({
            icon: rowData.lorUri ? tableIcons.LOR : tableIcons.SOP,
            tooltip: rowData.lorUri ? "Download LOR" : 'Download SOP',
            onClick: (event: any, rowData: StudentProfile) => {
              const url = rowData.lorUri ? rowData.lorUri : rowData.sopUri;
              const tab = window.open(url, "_blank");
              setTimeout(() => {
                tab?.close();
              }, 2000);
            },
          }),
          {
            icon: tableIcons.Approve as any,
            tooltip: 'Approve',
            onClick: (event: any, rowData: StudentProfile) => {
              setSelected(rowData)
              setShowNotice(true)
              setAction(DelegationStatus.ApprovedAndSent)
            },
          },
          {
            icon: tableIcons.Delete as any,
            tooltip: 'Reject',
            onClick: (event: any, rowData: StudentProfile) => {
              setSelected(rowData)
              setShowNotice(true)
              setAction(DelegationStatus.Rejected)
            },
          },
          {
            icon: tableIcons.Upload as any,
            tooltip: 'Override and Upload yourself',
            onClick: (event: any, rowData: StudentProfile) => {
              setSelected(rowData);
              setUploadDialogShown(true);
            },
          },
        ]}
        disabledFilters={['statuses']}
        onLoad={onLoad}
      />
    </>
  )
}
