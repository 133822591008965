import { FC } from "react"
import { TotalScore, CriticalReadingScore, MathScore, WritingScore } from "./Score"

type Props = {
    formik: any,
    fieldPath: string,
}

export const SATScore: FC<Props> = ({ formik, fieldPath }) => {
    const props = { formik, fieldPath: `${fieldPath}.satScore` }
    return <>
        <div className="row fv-row mb-10">
            <div className="col-xl-4">
                <TotalScore {...props} />
            </div>
        </div>
        <div className="row fv-row mb-10">
            <div className="col-xl-4">
                <CriticalReadingScore {...props} />
            </div>
            <div className="col-xl-4">
                <MathScore {...props} />
            </div>
            <div className="col-xl-4">
                <WritingScore {...props} />
            </div>
        </div>
    </>
}
