export enum GraduationType {
    JuniorCollege = 1,
    Bachelors = 2,
    Masters = 3,
    Diploma = 4,
    DiplomaPlusBachelorDegree = 5
}

export enum GradingSystemId {
    percentage = 0,
    gpaOutOfFour,
    gpaOutOfFive,
    gpaOutOfSeven,
    gpaOutOfTen,
    cgpaOutOfFour,
    cgpaOutOfFive,
    cgpaOutOfSeven,
    cgpaOutOfTen,
    pointerOutOfTen
}

export const gradingSystemValidator = (gradingSystemId: GradingSystemId) => {
    switch (gradingSystemId) {
        case GradingSystemId.gpaOutOfFour:
        case GradingSystemId.cgpaOutOfFour:
            return 4;
        case GradingSystemId.gpaOutOfFive:
        case GradingSystemId.cgpaOutOfFive:
            return 5;
        case GradingSystemId.gpaOutOfSeven:
        case GradingSystemId.cgpaOutOfSeven:
            return 7;
        case GradingSystemId.gpaOutOfTen:
        case GradingSystemId.cgpaOutOfTen:
        case GradingSystemId.pointerOutOfTen:
            return 10;
        default:
            break;
    }
    return 100;
}

export interface IAcademicProfile {
    id?: number,
    userId: string;
    profileId: number;
    graduationDetail: IGraduationDetail;
    workExperiences?: IWorkExperience[]
    desiredDegreeType: number,
    fieldOfStudyId?: number,
    courseId?: number,
    specializationId?: number,
    otherRemarks?: string
}

export interface IGraduationDetail {
    id?: number;
    bachelorDegreeDetail?: IBachelorDegreeDetail;
    diplomaDegreeDetail?: IDiplomaDegreeDetail;
    masterDegreeDetail?: IMasterDegreeDetail;
    diplomaPlusBachelorDegreeDetail?: IDiplomaPlusBachelorDegreeDetail;
    juniorCollegeDetail?: IJuniorCollegeDetail;
    graduationType?: GraduationType;
}

export interface IDegreeDetail {
    id?: number;
    userId?: string;
    collegeDetail: ICollegeDetail;
    gradingSystemId: GradingSystemId;
}

export interface IJuniorCollegeDetail extends IDegreeDetail {
    eleventhStdScore: IGraduationScore;
    twelfthStdScore: IGraduationScore;
    tenthStdScore: IGraduationScore;
}
export class JuniorCollegeDetail implements IJuniorCollegeDetail {
    eleventhStdScore!: IGraduationScore;
    twelfthStdScore!: IGraduationScore;
    tenthStdScore!: IGraduationScore;
    id!: number;
    userId!: string;
    collegeDetail!: ICollegeDetail;
    gradingSystemId!: GradingSystemId;

}
export interface IBachelorDegreeDetail extends IDegreeDetail {
    firstYearScore: IGraduationScore;
    secondYearScore: IGraduationScore;
    thirdYearScore: IGraduationScore;
    fourthYearScore?: IGraduationScore;
    fifthYearScore?: IGraduationScore;
    duration?: number
}

export class BachelorDegreeDetailModel implements IBachelorDegreeDetail {
    firstYearScore!: IGraduationScore;
    secondYearScore!: IGraduationScore;
    thirdYearScore!: IGraduationScore;
    fourthYearScore!: IGraduationScore;
    fifthYearScore!: IGraduationScore;
    id!: number;
    userId!: string;
    collegeDetail!: ICollegeDetail;
    gradingSystemId!: GradingSystemId;
    duration?: number
}

export interface IDiplomaDegreeDetail extends IDegreeDetail {
    firstYearScore: IGraduationScore;
    secondYearScore: IGraduationScore;
    thirdYearScore: IGraduationScore;
}

export class DiplomaDegreeDetail implements IDiplomaDegreeDetail {
    firstYearScore!: IGraduationScore;
    secondYearScore!: IGraduationScore;
    thirdYearScore!: IGraduationScore;
    id!: number;
    userId!: string;
    collegeDetail!: ICollegeDetail;
    gradingSystemId!: GradingSystemId;
}

export interface IMasterDegreeDetail extends IDegreeDetail {
    firstYearScore: IGraduationScore;
    secondYearScore: IGraduationScore;
    bachelorDegreeScore: IBachelorDegreeDetail;
    duration?: number
}

export class MasterDegreeDetail implements IMasterDegreeDetail {
    firstYearScore!: IGraduationScore;
    secondYearScore!: IGraduationScore;
    id!: number;
    userId!: string;
    collegeDetail!: ICollegeDetail;
    gradingSystemId!: GradingSystemId;
    bachelorDegreeScore!: IBachelorDegreeDetail;
}


export interface IDiplomaPlusBachelorDegreeDetail extends IDegreeDetail {
    diplomaDegreeScore: IDiplomaDegreeDetail;
    bachelorDegreeScore: IBachelorDegreeDetail;
}
export class DiplomaPlusBachelorDegreeDetail implements IDiplomaPlusBachelorDegreeDetail {
    diplomaDegreeScore!: IDiplomaDegreeDetail;
    bachelorDegreeScore!: IBachelorDegreeDetail;
    id!: number;
    userId!: string;
    collegeDetail!: ICollegeDetail;
    gradingSystemId!: GradingSystemId;
}

export interface IGraduationScore {
    id?: number;
    score: number;
    backlogs?: number;
    hasYeardrop?: boolean
}

export interface ICollegeDetail {
    id?: number;
    collegeName: string;
    passingYear: number;
    universityId: number;
    fieldOfStudyId: number;
    fieldOfStudyName: string;
}

export interface ICourse {
    id: number;
    name: string;
    fieldOfStudies: IFieldOfStudy[];
}

export interface IFieldOfStudy {
    id: number;
    name: string;
    courseId: number;
    specializations: ISpecialization[]
}

export interface IndianUniversity {
    id: number;
    name: string;
    district: string;
}

export interface ISpecialization {
    id: number;
    name: string;
}

export interface IWorkExperience {
    companyName: string,
    designation: string,
    description: string,
    startDate: string,
    endDate: string,
    isOngoing: boolean
}