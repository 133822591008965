import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../theme/helpers'

export default function Notice({ title, message, type = "warning" }: { title?: string, message: string, type?: string }) {
    return <div className={`notice d-flex bg-light-${type} rounded border-${type} border border-dashed p-6`}>
        <KTSVG
            path={toAbsoluteUrl('/media/icons/duotune/general/gen045.svg')}
            className='svg-icon-2tx svg-icon-danger me-4' />
        <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'> {title}</h4>
                <div className='fs-6 text-gray-600'>
                    {message}
                </div>
            </div>
        </div>
    </div>
}