import { FC } from "react"
import { IGraduationScore } from "../../../student/models"

type GraduationScoreProps = {
    data: IGraduationScore,
    title: string
}

export const GraduationScoreView: FC<GraduationScoreProps> = ({ data, title }) => {
    if (!data) {
        return <div className="row">
            Score not available for {title}
        </div>;
    }
    return <div className='row'>
        <div className="row mb-2">
            <label className='fs-6 text-muted'>{title}</label>
        </div>
        <div className="row">
            <span className='fw-bolder fs-6 text-dark'>
                {data.score} ({data.backlogs || "None"}) ({data.hasYeardrop ? "Drop" : "No Drop"})
            </span>
        </div>
    </div>
}