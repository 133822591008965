import { Action } from '@reduxjs/toolkit'
import { ICourse, IFieldOfStudy, IndianUniversity } from '../models'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    CacheCourses: '[Cache Courses] Action',
    CacheIndianUniversities: '[Cache Indian Universities] Action',
    CacheFieldsOfStudy: '[Cache Fields Of Study] Action',
    CacheColleges: '[Cache Colleges] Action',
}

const initialState: ICommonState = {
    courses: [],
    universities: [],
    fieldsOfStudy: [],
    colleges: [],
}

export interface ICommonState {
    courses: ICourse[]
    universities: IndianUniversity[],
    fieldsOfStudy: IFieldOfStudy[],
    colleges: any[],
}

export const reducer = (state: ICommonState = initialState, action: ActionWithPayload<ICourse[] | IndianUniversity[]>) => {
    switch (action.type) {
        case actionTypes.CacheCourses:
            {
                if (action.payload) {
                    return { ...state, courses: [...action.payload] };
                }
                break;
            }
        case actionTypes.CacheColleges:
            {
                if (action.payload) {
                    return { ...state, colleges: [...action.payload] };
                }
                break;
            }
        case actionTypes.CacheIndianUniversities:
            {
                if (action.payload) {
                    return { ...state, universities: [...action.payload] };
                }
                break;
            }
        case actionTypes.CacheFieldsOfStudy:
            {
                if (action.payload) {
                    return { ...state, fieldsOfStudy: [...action.payload] };
                }
                break;
            }
        default:
            return state;
    }
}

export const actions = {
    cacheCourses: (courses: ICourse[]) => ({ type: actionTypes.CacheCourses, payload: courses }),
    cacheColleges: (colleges: any[]) => ({ type: actionTypes.CacheColleges, payload: colleges }),
    cacheIndianUniversities: (universities: IndianUniversity[]) => ({ type: actionTypes.CacheIndianUniversities, payload: universities }),
    cacheFieldsOfStudy: (fos: IFieldOfStudy[]) => ({ type: actionTypes.CacheFieldsOfStudy, payload: fos }),
}