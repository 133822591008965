import { FC } from 'react';
import { BachelorGradesForm } from './BachelorGradesForm';
import { DiplomaGradesForm } from './DiplomaGradesForm';

type Props = {
    formik: any,
    basePath: string
}

export const DiplomaPlusBachelorGradesForm: FC<Props> = ({ formik, basePath }) => {
    return <>
        <div className="row fv-row mb-10">
            <div className='col-xl-6'>
                <label className='form-label fw-bolder text-dark fs-6 mb-5'>Diploma Plus Bachelors Grades</label>
            </div>
        </div>
        <div className="row fv-row mb-10">
            <DiplomaGradesForm formik={formik} basePath={`${basePath}.diplomaDegreeScore`} />
        </div>

        <div className="row fv-row mb-10">
            <BachelorGradesForm formik={formik} basePath={`${basePath}.bachelorDegreeScore`} />
        </div>
    </>
}