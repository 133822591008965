/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {ButtonGroup, ToggleButton} from 'react-bootstrap-v5'
import {SuggestedUniversityResponseStatus} from '../models/UniversitySuggestionDtos'

type ActionProps = {
  actions: Array<SuggestedUniversityResponseStatus>
  defaultSelected?: SuggestedUniversityResponseStatus
  onSelected: (status?: SuggestedUniversityResponseStatus, studentId?: string) => void,
  currentCwidNumber?: string
}

export const SuggestionActions: FC<ActionProps> = ({actions, defaultSelected, onSelected, currentCwidNumber}) => {
  const [selected, setSelected] = useState<SuggestedUniversityResponseStatus | undefined>(
    defaultSelected
  )
  const map = [
    null,
    'Reqeust Changes',
    'Accept',
    'Got admit',
    'Got rejection',
    'Finalize',
    'Did not Apply',
  ]
  const [cwidNumber, setCwidNumber] = useState(currentCwidNumber);

  return (
    <div className='d-flex align-items-center mb-5 ps-10'>
      <div className='col-xl-6'>
        <ButtonGroup className='mb-2' size='sm'>
          {actions.map((action, index) => {
            const key = `toggle-${index}-${action}-${Math.random()}`
            const name = map[action]

            return (
              <ToggleButton
                key={key}
                id={key}
                type='radio'
                variant='outline-primary'
                className='btn-outline btn-light-primary btn-sm'
                checked={selected === action}
                value={action}
                onChange={(e) => {
                  setSelected(action)
                  onSelected(action)
                }}
              >
                {name?.toUpperCase()}
              </ToggleButton>
            )
          })}
        </ButtonGroup>
        {selected === SuggestedUniversityResponseStatus.Finalized && (
          <div className='row  col-3'>
            <label className="required">Student ID</label>
            <input
              type='text'
              placeholder='CWID number'
              className={`required form-control form-control-sm ${cwidNumber ? 'is-valid' : 'is-invalid'}`}
              defaultValue={currentCwidNumber}
              onChange={(ev) => {
                onSelected(selected, ev.target.value)
                setCwidNumber(ev.target.value);
              }}
            />
            {!cwidNumber && <div className='fv-plugins-message-container'>
              <div className='fv-help-block text-danger mt-2'>
                <span role='alert'>CWID number required</span>
              </div>
            </div>}
          </div>
        )}
      </div>
    </div>
  )
}
