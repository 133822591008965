import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from '../theme/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../theme/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import { SharedStateProvider } from './modules/common/SharedStateProvider'
import { Routes } from './routing/Routes'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <SharedStateProvider>
                <Routes />
              </SharedStateProvider>
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
