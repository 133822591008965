import { FC, useEffect, useState } from 'react';
import { InputGroup } from 'react-bootstrap-v5';
import Select from 'react-select';
import { CollegeDetail } from '../../../Common/CollegeDetail';
import { GradingSystemSelector } from '../../../Common/GradingSystemSelector';
import { GraduationScore } from '../../../Common/GraduationScore';
import { BachelorGradesForm } from './BachelorGradesForm';

type Props = {
    formik: any,
    basePath: string
}
const allowedYearsOptions = [
    { label: '2 years', value: 2 },
    { label: '3 years', value: 3 },
];

const courseConfig = [
    {
        fieldPath: 'firstYearScore',
        title: 'First Year Grades',
        show: true
    },
    {
        fieldPath: 'secondYearScore',
        title: 'Second Year Grades',
        show: true
    },
    {
        fieldPath: 'thirdYearScore',
        title: 'Third Year Grades',
        show: false
    },
]

export const MastersGradesForm: FC<Props> = ({ formik, basePath }) => {
    const [config, setConfig] = useState(courseConfig);
    const [duration, setDuration] = useState<number>(formik.getFieldMeta(`${basePath}.duration`).value as number || 2);

    useEffect(() => {
        if (!duration) return;
        formik.setFieldValue(`${basePath}.duration`, duration);
        const currentConfig = [...config];
        switch (duration) {
            case 2:
                currentConfig[2].show = false
                break;
            case 3:
                currentConfig[2].show = true
                break;
            default:
                break;
        }
        setConfig([...currentConfig]);
        // eslint-disable-next-line
    }, [duration])

    return <>
        <div className="row fv-row mb-10">
            <div className='col-xl-6'>
                <label className='form-label fw-bolder text-dark fs-6 mb-5'>Masters Degree Grades</label>
                <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text className='required'>Course Duration</InputGroup.Text>
                    <Select
                        className="form-control form-control-sm p-0"
                        onChange={(opt: any) => {
                            setDuration(opt?.value);
                        }}
                        defaultValue={{ label: '2 years', value: 2 }}
                        options={allowedYearsOptions}
                        value={allowedYearsOptions.find(x => x.value === duration)}
                    />
                </InputGroup>
            </div>
        </div>

        <div className="row fv-row mb-10">
            <div className="col-xl-6">
                <GradingSystemSelector formik={formik} fieldPath={basePath} />
            </div>
        </div>
        
        {config.filter(x => x.show).map(c => <div className="row fv-row mb-10" key={c.fieldPath}>
            <GraduationScore
                formik={formik}
                fieldPath={`${basePath}.${c.fieldPath}`}
                title={c.title} />
        </div>)}

        <div className="row fv-row mb-10">
            <CollegeDetail formik={formik} fieldPath={`${basePath}.collegeDetail`} />
        </div>
        <div className="row fv-row mb-10">
            <BachelorGradesForm formik={formik} basePath={`${basePath}.bachelorDegreeScore`} />
        </div>
    </>
}