import {FC, useEffect, useState} from 'react'
import Select from 'react-select'
import moment, {isDate} from 'moment'
import {Button, Spinner, Tab, Tabs, Modal, Table} from 'react-bootstrap-v5'
import {GenerateReportRequestDto, IReportResponseDto} from './GenerateRequestType'
import {PageTitle} from '../../../theme/layout/core'
import {Column} from 'material-table'
import {DropdownOption, useSharedState} from '../../modules/common/SharedStateProvider'
import {StudentFilterType} from '../reports/ReportFilters'
import TooltipInfo from '../../modules/common/components/TooltipInfo'
import {branchOptions} from '../../modules/common/DropdownOptions'
import {callGenerateReportApi} from './ReportsService'
import CounsellorTab from './counsellorTab'
import SaveAlt from '@material-ui/icons/SaveAlt'
import {ExportToCsv} from 'export-to-csv'

const ReportsNew: FC = () => {
  const [filter, setFilter] = useState<GenerateReportRequestDto>({})
  const [isLoading, setLoading] = useState(false)
  const [termOptions, setTermOptions] = useState<DropdownOption[]>([])
  const [selectedTerm, setSelectedTerm] = useState()
  const [data, setData] = useState<IReportResponseDto[]>([])
  const [finalized, setFinalized] = useState<number>(0)
  const [feesPending, setFeesPending] = useState<number>(0)
  const [partnerUniversity, setPartnerUniversity] = useState<number>(0)
  const [greGiven, setGreGiven] = useState<number>(0)
  const [toeflGiven, setToeflGiven] = useState<number>(0)
  const [satGiven, setSatGiven] = useState<number>(0)
  const [gmatGiven, setGmatGiven] = useState<number>(0)
  const [ieltsGiven, setIeltsGiven] = useState<number>(0)
  const [actGiven, setActGiven] = useState<number>(0)
  // Parter
  const [shoreLightCount, setShoreLightCount] = useState<number>(0)
  const [mosaicCount, setMosaicCount] = useState<number>(0)
  const [educoCount, setEducoCount] = useState<number>(0)
  const [kaplanCount, setKaplanCount] = useState<number>(0)
  const [intoCount, setIntoCount] = useState<number>(0)
  const [siukCount, setSiukCount] = useState<number>(0)
  const [imfsDirectCount, setImfsDirectCount] = useState<number>(0)

  const [activeCounsellor, setActiveCounsellor] = useState<boolean>(false)
  const [activeCounsellorArr, setActiveCounsellorArr] = useState<any>([])
  const [modalTitle, setModalTitle] = useState('View Details')
  const [show, setShow] = useState(false)
  const [viewTableData, setViewTableData] = useState([])

  const sharedState = useSharedState()

  const columns: Column<any>[] = [
    {title: 'Full Name', field: 'fullName', headerStyle: {width: '200px'}},
    {title: 'Email', field: 'email', headerStyle: {width: '200px'}},
    {title: 'Parent Phone Number', field: 'parentPhoneNumber', headerStyle: {width: '200px'}},
    {title: 'Student Phone Number', field: 'studentPhoneNumber', headerStyle: {width: '200px'}},
    {title: 'Finalized University', field: 'finalizedUniversity', headerStyle: {width: '200px'}},
    {title: 'Partners', field: 'finalizedUniversityPartnerName', headerStyle: {width: '200px'}},
  ]

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.title as string),
  }
  const csvExporter = new ExportToCsv(csvOptions)

  const handleExportData = () => {
    const keysToKeep: any = [
      'fullName',
      'email',
      'studentPhoneNumber',
      'parentPhoneNumber',
      'finalizedUniversity',
      'finalizedUniversityPartnerName',
    ]
    const filteredArray = viewTableData.map((obj: any) => {
      const filteredObj: any = {}
      keysToKeep.forEach((key: any) => {
        if (obj.hasOwnProperty(key)) {
          filteredObj[key] = obj[key]
        }
      })
      return filteredObj
    })
    // console.log(filteredArray)
    csvExporter.generateCsv(filteredArray)
  }

  useEffect(() => {
    const options = sharedState.terms.map((x) => ({label: x.name, value: x.id}))
    // console.log(options)
    setTermOptions(options)
  }, [sharedState.terms])

  const onFilterChanged = (field: StudentFilterType, value: any) => {
    switch (field) {
      case 'termIds':
        setFilter({...filter, termIds: value})
        break
      case 'statuses':
        setFilter({...filter, statuses: value})
        break
      case 'branches':
        setFilter({...filter, branches: value})
        break
      case 'examDate':
        setFilter({...filter, examDate: isDate(value) ? moment(value).format() : undefined})
        break
      case 'hasLanguageExamScores':
        setFilter({...filter, hasLanguageExamScores: value})
        break
      case 'hasCompetitiveExamScores':
        setFilter({...filter, hasCompetitiveExamScores: value})
        break
      default:
        break
    }
  }

  function filterByUniqueCreatedBy(applicants: any) {
    const uniqueCreatedBys = new Set() // Using a Set to track unique values
    return applicants.filter((applicant: any) => {
      if (!uniqueCreatedBys.has(applicant.accountCreatedBy)) {
        uniqueCreatedBys.add(applicant.accountCreatedBy)
        return true
      }
      return false
    })
  }

  const onSearch = () => {
    setLoading(true)
    setActiveCounsellor(false)
    setActiveCounsellorArr([])
    console.log(filter)
    callGenerateReportApi(filter)
      .then(({data}) => {
        data = data.filter((el: any) => el.email !== 'aniket.dandawate.nice@gmail.com')
        // console.log(data)
        setData(data)
        getFinalizedUniversityCounts(data)
        if (filter?.branches?.length) {
          setActiveCounsellor(true)
          const filteredApplicants = filterByUniqueCreatedBy(data)
          setActiveCounsellorArr(filteredApplicants)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleClose = () => setShow(false)

  const handleShow = (title: any) => {
    // console.log(filteredCouncellorData)
    const tableData: any = data.filter((element: any) => {
      return (
        element?.finalizedUniversity !== 'DROPPED/CANCELLED' &&
        Number(element?.totalFeesCharged) > Number(element?.feesPaid)
      )
    })
    console.log(tableData)
    setViewTableData(tableData)
    setModalTitle(title)
    setShow(true)
  }

  const getFinalizedUniversityCounts = (data: any) => {
    let finalizedUniverSityCount: number = 0
    let partnerUniversityCount: number = 0
    let feesPendingCount: number = 0
    let greExamGivenCount: number = 0
    let toeflExamGivenCount: number = 0
    let gmatExamGivenCount: number = 0
    let satExamGivenCount: number = 0
    let actExamGivenCount: number = 0
    let ieltsExamGivenCount: number = 0
    // let lorSentCount: number = 0
    // let sopSentCount: number = 0

    // Partner
    let shoreLightCount: number = 0
    let careerMosaicCount: number = 0
    let kaplanCounts: number = 0
    let educoCounts: number = 0
    let intoDirectCount: number = 0
    let imfsDirectCounts: number = 0
    let siukCounts: number = 0

    for (let i = 0; i < data.length; i++) {
      const element = data[i]
      if (
        element.finalizedUniversity !== null &&
        element.finalizedUniversity !== 'DROPPED/CANCELLED'
      ) {
        finalizedUniverSityCount += 1
      }
      if (element.finalizedUniversityPartnerName !== 'No Partner') {
        partnerUniversityCount += 1
        if (
          element.finalizedUniversityPartnerName ===
          '(IMFS-Direct) - Stevens Institute of Technology'
        ) {
          imfsDirectCounts += 1
        }
        if (element.finalizedUniversityPartnerName === 'SHORELIGHT') {
          shoreLightCount += 1
        }
        if (element.finalizedUniversityPartnerName === 'Career Mosaic') {
          careerMosaicCount += 1
        }
        if (element.finalizedUniversityPartnerName === 'KAPLAN') {
          kaplanCounts += 1
        }
        if (element.finalizedUniversityPartnerName === 'EDUCO') {
          educoCounts += 1
        }
        if (element.finalizedUniversityPartnerName === 'INTO (Direct)') {
          intoDirectCount += 1
        }
        if (element.finalizedUniversityPartnerName === 'SIUK') {
          siukCounts += 1
        }
      }
      if (element?.profileStatuses.includes('ProfileApproved|LorSent')) {
        // lorSentCount += 1
      }
      if (element?.profileStatuses.includes('ProfileApproved|LorSent|SopSent')) {
        // sopSentCount += 1
      }
      if (
        element?.finalizedUniversity !== 'DROPPED/CANCELLED' &&
        Number(element?.totalFeesCharged) > Number(element?.feesPaid)
      ) {
        feesPendingCount += 1
      }
      if (element.competitiveExam === 'GRE' && element.isCompetitiveExamGiven === 'True') {
        greExamGivenCount += 1
      }
      if (element.languageExam === 'TOEFL' && element.isLanguageExamGiven === 'True') {
        toeflExamGivenCount += 1
      }
      if (element.languageExam === 'IELTS' && element.isLanguageExamGiven === 'True') {
        ieltsExamGivenCount += 1
      }
      if (element.competitiveExam === 'SAT' && element.isCompetitiveExamGiven === 'True') {
        satExamGivenCount += 1
      }
      if (element.competitiveExam === 'GMAT' && element.isCompetitiveExamGiven === 'True') {
        gmatExamGivenCount += 1
      }
      if (element.competitiveExam === 'ACT' && element.isCompetitiveExamGiven === 'True') {
        actExamGivenCount += 1
      }
    }
    // console.log(finalizedUniverSityCount)
    setFinalized(finalizedUniverSityCount)
    setPartnerUniversity(partnerUniversityCount)
    setFeesPending(feesPendingCount)
    setGreGiven(greExamGivenCount)
    setToeflGiven(toeflExamGivenCount)
    setSatGiven(satExamGivenCount)
    setActGiven(actExamGivenCount)
    setGmatGiven(gmatExamGivenCount)
    setIeltsGiven(ieltsExamGivenCount)
    setShoreLightCount(shoreLightCount)
    setMosaicCount(careerMosaicCount)
    setEducoCount(educoCounts)
    setKaplanCount(kaplanCounts)
    setIntoCount(intoDirectCount)
    setImfsDirectCount(imfsDirectCounts)
    setSiukCount(siukCounts)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Generate Statistics</PageTitle>
      <div className='row fv-row m-2'>
        <div className='d-flex mb-2'>
          <div className='d-flex flex-grow-1'>
            <h4>Report Filters</h4>
            {isLoading && <Spinner animation='grow' variant='primary' />}
          </div>
        </div>
        <div>
          <div className='row'>
            <div className='col-xl-3'>
              <label className='mb-2 fw-bold text-dark'>Term</label>

              {termOptions?.length > 0 && (
                <Select
                  options={termOptions}
                  defaultValue={termOptions[0]}
                  onChange={(option) => {
                    onFilterChanged('termIds', [option?.value])
                    setSelectedTerm(option?.value)
                  }}
                  styles={{menu: (base) => ({...base, zIndex: 9999})}}
                />
              )}
            </div>

            <div className='col-xl-3'>
              <label className='mb-2 fw-bold text-dark'>
                Assigned Branch
                <TooltipInfo
                  message={"This filter won't have any effect for Branch level logins"}
                />
              </label>
              <Select
                options={branchOptions}
                onChange={(option) => {
                  onFilterChanged(
                    'branches',
                    Array.from(option.values()).map((x) => x.value)
                  )
                }}
                isMulti={true}
                styles={{menu: (base) => ({...base, zIndex: 9999})}}
              />
            </div>

            <div className='col-xl-3'>
              <Button
                variant='outline'
                className='btn btn-sm btn-outline btn-outline-primary mt-8'
                onClick={onSearch}
                disabled={isLoading || !selectedTerm}
              >
                <i className='bi bi-search text-dark'></i>
                {!isLoading && <span className='indicator-label'>Generate Report</span>}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* {filter.branches && data.length > 0 && <CounsellorTab />} */}
      {data.length > 0 && !isLoading && (
        <div className='row fv-row p-2 mt-2'>
          <div className='row fv-row p-2 mt-2'>
            <Tabs defaultActiveKey='branch' id='fill-tab-example' className='mb-3' fill>
              <Tab eventKey='branch' title='Branch wise Data'>
                <div>
                  <div className='row fv-row p-2 mt-5'>
                    <h5 className='card-title mb-2'>Branch Details</h5>
                    <div className='col-xl-3'>
                      <div className='card text-center'>
                        <div className='card-body'>
                          <h5 className='card-title'>Total Students</h5>
                          <h6>{data?.length}</h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-3'>
                      <div className='card text-center'>
                        <div className='card-body'>
                          <h5 className='card-title'>Finalized Universities</h5>
                          <h6>{finalized}</h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-3'>
                      <div className='card text-center'>
                        <div className='card-body'>
                          <h5 className='card-title'>Applications for Partners</h5>
                          <h6>{partnerUniversity}</h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-3'>
                      <div className='card text-center'>
                        <div className='card-body'>
                          <h5 className='card-title'>Fees pending</h5>
                          <h6
                            style={{cursor: 'pointer', color: 'blue', textDecoration: 'underline'}}
                            onClick={() => handleShow('Pending List')}
                          >
                            {feesPending}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className='row fv-row p-2 mt-5'>
                      <h5 className='card-title mb-2'>Exam Details</h5>
                      <div className='col-xl-2'>
                        <div className='card text-center'>
                          <div className='card-body'>
                            <h5 className='card-title text-primary'>GRE</h5>
                            <h6>{greGiven}</h6>
                          </div>
                        </div>
                      </div>
                      <div className='col-xl-2'>
                        <div className='card text-center'>
                          <div className='card-body'>
                            <h5 className='card-title text-primary'>TOEFL</h5>
                            <h6>{toeflGiven}</h6>
                          </div>
                        </div>
                      </div>
                      <div className='col-xl-2'>
                        <div className='card text-center'>
                          <div className='card-body'>
                            <h5 className='card-title text-primary'>IELTS</h5>
                            <h6>{ieltsGiven}</h6>
                          </div>
                        </div>
                      </div>
                      <div className='col-xl-2'>
                        <div className='card text-center'>
                          <div className='card-body'>
                            <h5 className='card-title text-primary'>SAT</h5>
                            <h6>{satGiven}</h6>
                          </div>
                        </div>
                      </div>
                      <div className='col-xl-2'>
                        <div className='card text-center'>
                          <div className='card-body'>
                            <h5 className='card-title text-primary'>GMAT</h5>
                            <h6>{gmatGiven}</h6>
                          </div>
                        </div>
                      </div>
                      <div className='col-xl-2'>
                        <div className='card text-center'>
                          <div className='card-body'>
                            <h5 className='card-title text-primary'>ACT</h5>
                            <h6>{actGiven}</h6>
                          </div>
                        </div>
                      </div>
                      {/* <div className='col-xl-3'>
              <div className='card text-center'>
                <div className='card-body'>
                  <h5 className='card-title'>TOEFL given</h5>
                  <h6>-</h6>
                </div>
              </div>
            </div> */}
                    </div>
                  </div>
                  <div className='row fv-row mt-2'>
                    <h5 className='card-title mb-2'>Partner Application Details</h5>
                    <div className='col-xl-3 mb-3'>
                      <div className='card text-center'>
                        <div className='card-body'>
                          <h5 className='card-title primary text-success'>IMFS (Stevens)</h5>
                          <h6>{imfsDirectCount}</h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-3'>
                      <div className='card text-center'>
                        <div className='card-body'>
                          <h5 className='card-title primary text-success'>SHORELIGHT</h5>
                          <h6>{shoreLightCount}</h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-3'>
                      <div className='card text-center'>
                        <div className='card-body'>
                          <h5 className='card-title text-success'>Career Mosaic</h5>
                          <h6>{mosaicCount}</h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-3'>
                      <div className='card text-center'>
                        <div className='card-body'>
                          <h5 className='card-title text-success'>KAPLAN</h5>
                          <h6>{kaplanCount}</h6>
                        </div>
                      </div>
                    </div>

                    <div className='col-xl-3'>
                      <div className='card text-center'>
                        <div className='card-body'>
                          <h5 className='card-title text-success'>EDUCO</h5>
                          <h6>{educoCount}</h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-3'>
                      <div className='card text-center'>
                        <div className='card-body'>
                          <h5 className='card-title text-success'>INTO (DIRECT)</h5>
                          <h6>{intoCount}</h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-3'>
                      <div className='card text-center'>
                        <div className='card-body'>
                          <h5 className='card-title text-success'>SIUK</h5>
                          <h6>{siukCount}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey='counselor'
                title='Counselor wise Data'
                disabled={!activeCounsellor || !activeCounsellorArr}
              >
                <>
                  {activeCounsellor && activeCounsellorArr && (
                    <>
                      <h5 className='card-title mt-5 mb-1'>Counsellor Details</h5>
                      <CounsellorTab counsellorData={activeCounsellorArr} branchData={data} />
                    </>
                  )}
                </>
              </Tab>
            </Tabs>
          </div>
          <Modal show={show} onHide={handleClose} size='xl'>
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {viewTableData.length > 0 && (
                <div className='row me-auto'>
                  <p className='text-end' onClick={() => handleExportData()}>
                    <SaveAlt color='primary' fontSize='large' style={{cursor: 'pointer'}} />
                  </p>
                </div>
              )}
              {viewTableData.length > 0 ? (
                <Table striped bordered hover size='lg'>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Contact No</th>
                      <th>Alternate No</th>
                      <th>Finalized University</th>
                      <th>Partner University</th>
                    </tr>
                  </thead>
                  <tbody>
                    {viewTableData.map((el: any, index: any) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{el?.fullName}</td>
                        <td>{el?.email}</td>
                        <td>{el?.studentPhoneNumber}</td>
                        <td>{el?.parentPhoneNumber}</td>
                        <td>{el.finalizedUniversity !== null ? el.finalizedUniversity : '-'}</td>
                        <td>
                          {el?.finalizedUniversityPartnerName
                            ? el?.finalizedUniversityPartnerName
                            : '-'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p className='text-center'>No Data Found!</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* <div className='row fv-row p-2 m-2'>
            <table className='table'>
              <thead>
                <tr>
                  <th scope='col'>#</th>
                  <th scope='col'>First</th>
                  <th scope='col'>Last</th>
                  <th scope='col'>Handle</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope='row'>1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <th scope='row'>2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <th scope='row'>3</th>
                  <td colspan='2'>Larry the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </table>
          </div> */}
        </div>
      )}
    </>
  )
}

export default ReportsNew
