import axios from 'axios';
import { INotification } from './Notification';

const API_URL = process.env.REACT_APP_API_URL;
export const COMMON_API = `${API_URL}/api/v1/notifications`

export function getAll() {
    return axios.get<Array<INotification>>(`${COMMON_API}`)
}

export function getUnread() {
    return axios.get<Array<INotification>>(`${COMMON_API}/unread`)
}

export function markAsRead(notificationId: number) {
    return axios.post(`${COMMON_API}/mark-as-read/${notificationId}`)
}