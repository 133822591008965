import moment from 'moment';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { AlertModel } from '../../../theme/helpers';
import { INotification } from '../notification/Notification';
import * as notificationService from '../notification/notificationService'
import { ProfileStatus } from './enums/ProfileStatus';

export interface INotificationStateModel {
    handleTransition: (id: number) => void,
    markAsRead: (id: number) => void,
    alerts: Array<AlertModel>,
    notifications: Array<INotification>,
}

const NotificationContext = createContext<INotificationStateModel>({
    handleTransition: (id: number) => { },
    markAsRead: (id: number) => { },
    alerts: [],
    notifications: [],
})

const NotificationProvider: React.FC = ({ children }) => {
    const [alerts, setAlerts] = useState<Array<AlertModel>>([]);
    const [notifications, setNotifications] = useState<Array<INotification>>([]);
    const history = useHistory();

    useEffect(() => {
        //get data
        let isSubscribed = true;
        const fetchData = () => {
            notificationService.getUnread().then(({ data }) => {
                if (isSubscribed) {
                    setNotifications(data);
                    setAlertsFromNotifications(data);
                }
            })
        }
        fetchData();
        return () => { isSubscribed = false };
        //eslint-disable-next-line
    }, [])

    useEffect(()=>{console.log(alerts)}, [alerts])

    function setAlertsFromNotifications(data: Array<INotification>) {
        const dto: Array<AlertModel> = data.map(x => ({
            id: x.id,
            description: x.message,
            title: x.notificationHeader,
            time: moment(x.createdAt).local().fromNow(),
            icon: "icons/duotune/technology/teh008.svg",
            state: "primary"
        }));
        setAlerts(dto);
    }
    const markAsRead = (id: number) => {
        return notificationService.markAsRead(id).then(() => {
            const filtered = alerts.filter(z => z.id !== id);
            setAlerts(filtered);
        })
    }

    const handleTransition = useCallback((id: number) => {
        const notification = notifications.find(x => x.id === id);
        const profileId = notification?.profileId;
        markAsRead(id)
        switch (notification?.notificationType) {
            case ProfileStatus.EscalatedIssue:
                history.push(`/messages`);
                return;
        }

        if (!profileId) return;
        switch (notification?.notificationType) {
            case ProfileStatus.AccountVerified:
                history.push(`/student/${profileId}/edit`);
                break;
            case ProfileStatus.ProfileSubmitted:
                //bll gets this, goto student profile
                history.push(`/student/${profileId}/approve`);
                break;
            case ProfileStatus.ProfileApproved:
                history.push(`/student/${profileId}/edit`);
                break;
            case ProfileStatus.ScoresUpdated:
                history.push(`/student/${profileId}/`);
                break;
            case ProfileStatus.LorQuestionnaireSubmitted:
                history.push(`/student/${profileId}/lor/approve`);
                break;
            case ProfileStatus.SopQuestionnaireSubmitted:
                history.push(`/student/${profileId}/sop/approve`);
                break;
            case ProfileStatus.LorQuestionnaireApproved:
                history.push(`/student/${profileId}/lor`);
                break;
            case ProfileStatus.SopQuestionnaireApproved:
                history.push(`/student/${profileId}/sop`);
                break;
            case ProfileStatus.LorSent:
                history.push(`/student/${profileId}/downloads`);
                break;
            case ProfileStatus.SopSent:
                history.push(`/student/${profileId}/downloads`);
                break;
            case ProfileStatus.UniversitiesSuggested:
                history.push(`/student/${profileId}/university-suggestions`);
                break;
            case ProfileStatus.RequestedChanges:
                history.push(`/student/${profileId}/university-suggestions`);
                break;
            case ProfileStatus.AwaitingAdmit:
                history.push(`/student/${profileId}/university-suggestions`);
                break;
            case ProfileStatus.Finalized:
                history.push(`/student/${profileId}/university-suggestions`);
                break;
            case ProfileStatus.Archived:
                break;
            case ProfileStatus.DocumentAssigned:
                break;
            case ProfileStatus.ProfileComment:
                history.push(`/student/${profileId}/edit/activity`);
                break;
            case ProfileStatus.DocumentApproval:
                history.push(`/student/${profileId}/edit/activity`);
                break;
            case ProfileStatus.LorQuestionnaireRejected:
                history.push(`/student/${profileId}/edit/lor`);
                break;
            case ProfileStatus.SopQuestionnaireRejected:
                history.push(`/student/${profileId}/edit/sop`);
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [notifications])

    const value: INotificationStateModel = {
        handleTransition,
        markAsRead,
        notifications,
        alerts
    }
    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
}

export { NotificationContext, NotificationProvider }

export function useNotificationContext() {
    return useContext(NotificationContext)
}
