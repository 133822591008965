/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap-v5'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { RootState } from '../../../setup'
import { PageTitle } from '../../../theme/layout/core'
import { useSharedState } from '../../modules/common/SharedStateProvider'
import StudentListPage from '../student-profiles/StudentListPage'
import { SuperAdminDashboard } from './SuperAdminDashboard'
import { BLLDashboard } from './BLLDashboard'
import { ContentWriterDashboard } from './ContentWriterDashboard'
import { OverallAdminDashboard } from './OverallAdminDashboard'

const DashboardPage: FC = () => {
  const sharedState = useSharedState();
  const user = useSelector((state: RootState) => state.auth.user)
  if (sharedState.isBranchLevelLogin) {
    return <>
      <PageTitle breadcrumbs={[]}>{user?.firstname + "'s Dashboard"}</PageTitle>
      <BLLDashboard />
    </>
  }
  if (sharedState.isAdmin) {
    return <>
      <PageTitle breadcrumbs={[]}>{user?.firstname + "'s Dashboard"}</PageTitle>
      <OverallAdminDashboard />
    </>
  }
  if (sharedState.isSuperAdmin) {
    return <>
      <PageTitle breadcrumbs={[]}>{user?.firstname + "'s Dashboard"}</PageTitle>
      <SuperAdminDashboard />
    </>
  }

  if (sharedState.isContentWriter) {
    return <>
      <PageTitle breadcrumbs={[]}>{user?.firstname + "'s Dashboard"}</PageTitle>
      <ContentWriterDashboard />
    </>
  }

  return <>
    <PageTitle breadcrumbs={[]}>All Students</PageTitle>
    <StudentListPage />
  </>
}

const DashboardWrapper: FC = () => {

  const { user } = useSharedState();

  const [isStudent, setIsStudent] = useState<boolean>();

  useEffect(() => {
    if (user) {
      if (user.ProfileId) {
        setIsStudent(true);
      }
    }
  }, [user]);

  if (isStudent === undefined) {
    <Spinner animation='grow' variant='primary' />
  }

  if (user && isStudent) {
    return <Redirect to={`/student/${user.ProfileId}/edit`} />
  }

  return (
    <>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
