import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getImpersonatedUserToken } from '../../../modules/auth/redux/AuthCRUD';
import * as auth from '../../../modules/auth/redux/AuthRedux'

const ImpersonatePage: FC<RouteComponentProps<{ email: string }>> = ({ match: { params: { email } } }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const doWork = async () => {
            getImpersonatedUserToken(email)
                .then(({ data: { data: { accessToken } } }) => {
                    dispatch(auth.actions.login(accessToken));
                    setTimeout(() => {
                        window.document?.location?.reload();
                    }, 1000);
                })
                .catch(() => {
                })
        }
        doWork();
    }, [email, dispatch]);

    return <>
        You are being redirected to impersonated account. Please refresh the page.
    </>
}

export default withRouter(ImpersonatePage);