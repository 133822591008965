/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap-v5";
import { toast } from "react-toastify";
import * as Yup from 'yup'
import { AxiosResponse } from "axios";
import { AsyncDropdown } from "../../../modules/common/components/AsyncDropdown";
import { getAllCWWithWork } from "../../user-management/services/UserManager";

const assignDocumentSchema = Yup.object().shape({
    assignedToEmail: Yup.string()
        .required('Please select user to delegate'),
})

export const AssignDocumentBtn: FC<{ className?: string, showIcon?: boolean, onSend: (req: any) => Promise<AxiosResponse<any>> }> = ({ className = "btn btn-primary", showIcon = false, onSend }) => {
    const [showModal, setShowModal] = useState(false);
    return <>
        <button className={className} onClick={() => { setShowModal(true) }}>
            <i className="bi bi-envelope text-white"></i>
            {!showIcon && `Delegate Document Writing`}</button>
        <AssignDocumentDialog visible={showModal} setVisibility={setShowModal} onSend={onSend} />
    </>
}

type Props = { visible: boolean, setVisibility: (vis: boolean) => void, onSend: (req: any) => Promise<AxiosResponse<any>> }

export const AssignDocumentDialog: FC<Props> =
    ({ visible, setVisibility, onSend }) => {
        const [loading, setLoading] = useState(false);
        const [users, setUsers] = useState<Array<any>>([]);

        useEffect(() => {
            //get all users with role content writer
            const fetchData = () => {
                getAllCWWithWork(1, 100)
                    .then(({ data }) => {
                        setUsers(data.data.map((x: any) => ({ label: `${x.firstName} ${x.lastName} (Pending ${x.pendingDocsCount})`, value: x.userName })));
                    });
            }
            visible && users.length === 0 && fetchData();
            // eslint-disable-next-line
        }, [visible]);

        const formik = useFormik<{ assignedToEmail: string }>({
            initialValues: { assignedToEmail: "" },
            onSubmit: (values, { setStatus, setSubmitting }) => {
                setLoading(true);
                onSend(values.assignedToEmail)
                    .then(() => {
                        handleClose();
                        toast('Successfully assigned user for document writing', { type: 'success' });
                    })
                    .catch(() => {
                        setStatus('Error delegating.')
                    }).finally(() => {
                        setLoading(false);
                        setSubmitting(false);
                    })
            },
            validationSchema: assignDocumentSchema
        });

        const handleClose = () => {
            setVisibility(false);
        }

        return <>
            <Modal show={visible}
                onHide={handleClose}
                backdrop="static"
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Delegate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        {formik.status && (
                            <div className='mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{formik.status}</div>
                            </div>
                        )}

                        <div className="row">
                            <div className="col-xl-6">
                                <AsyncDropdown
                                    options={users}
                                    onSelected={(val) => {
                                        formik.setFieldValue('assignedToEmail', val);
                                        console.log(formik);
                                    }}
                                    selected={formik.values.assignedToEmail}
                                />
                            </div>
                            {formik.errors.assignedToEmail && (
                                <div className='fv-plugins-message-container'>
                                    <span role='alert'>{formik.errors.assignedToEmail}</span>
                                </div>
                            )}
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex">
                            <Button variant="secondary" className="m-5" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" className="m-5" type="submit" disabled={formik.isSubmitting || !formik.isValid}>
                                {!loading && <span className='indicator-label'>Delegate</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal></>
    }

export interface DelegateDocumentWritingRequestDto {
    assignedToEmail?: string;
    profileId?: number;
}