import axios from 'axios';
import { IBanner } from '../../modules/carousel/models/IBanner';
import { IUpdateBannerCommand, IUploadBannerCommand } from './models/IUploadBannerCommand';

const API_URL = process.env.REACT_APP_API_URL;
export const BANNER_API = `${API_URL}/api/v1/banner`

export const getBanners = () => {
    return axios.get<Array<IBanner>>(`${BANNER_API}`)
}

export const uploadBanners = (req: Array<IUploadBannerCommand>) => {
    return axios.post(`${BANNER_API}`, req);
}

export const deleteBanner = (id: number) => {
    return axios.delete(`${BANNER_API}/${id}`);
}

export const updateBanner = (id: number, req: IUpdateBannerCommand) => {
    return axios.put(`${BANNER_API}/${id}`, req);
}