/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import Notice from '../../../modules/common/components/Notice'
import {
  IUniversitySuggestionMetaResponseDto,
  SuggestedUniversityResponseStatus,
  SuggestedUniversityStatus,
  UniversitySuggestionState,
} from '../models/UniversitySuggestionDtos'
import { Suggestion } from './Suggestion'
import { SuggestionActions } from './SuggestionActions'
import { CommentForm, SuggestionComments } from './SuggestionComments'
import { Spinner } from 'react-bootstrap-v5'
import { IPartner } from '../../partner-management/models/PartnerRequests'
import { getPartners } from '../../partner-management/services/PartnerService'
import { useSharedState } from '../../../modules/common/SharedStateProvider'

type SuggestUniversitiesMetaProps = {
  meta: IUniversitySuggestionMetaResponseDto
  formik: any
}

export const SuggestionsWithResponse: FC<SuggestUniversitiesMetaProps> = ({ meta, formik }) => {
  let actions: Array<SuggestedUniversityResponseStatus> = []
  switch (meta.state) {
    case UniversitySuggestionState.WaitingOnStudent:
      actions = [
        SuggestedUniversityResponseStatus.Accepted,
        SuggestedUniversityResponseStatus.RequestedChanges,
      ]
      break
    case UniversitySuggestionState.Accepted:
      actions = [
        SuggestedUniversityResponseStatus.NotApplied,
        SuggestedUniversityResponseStatus.RejectionReceived,
        SuggestedUniversityResponseStatus.AdmitReceived,
        SuggestedUniversityResponseStatus.Finalized,
      ]
      break
  }
  //get all partners
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [loading, setLoading] = useState(false);
  const { isStudent } = useSharedState();

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      getPartners('').then((resp) => {
        setPartners(resp.data.data);
      }).finally(() => { setLoading(false); })
    }
    fetchData();
    //eslint-disable-next-line
  }, [])

  if (loading) {
    return <Spinner variant="primary" animation={"grow"} />
  }

  return (
    <>
      {meta.suggestions.length === 0 && (
        <Notice
          message='You can come back here when admin suggests universities after reviewing your profile.'
          type='warning'
          title='No universities suggested'
        />
      )}
      {meta.suggestions.length > 0 && (
        <>
          <h4 className='text-center mb-7 mt-5'>Suggestions</h4>

          {(meta.state === UniversitySuggestionState.WaitingOnAdmin ||
            meta.state === UniversitySuggestionState.WaitingOnSuperAdmin) && (
              <Notice
                type='danger'
                message='University Suggestions are locked for editing by student.'
                title='Locked'
              />
            )}
          {meta.state === UniversitySuggestionState.Accepted && (
            <Notice
              type='success'
              message='University Suggestions have been accepted'
              title='Accepted'
            />
          )}
          {meta.state === UniversitySuggestionState.Finalized && (
            <Notice
              type='success'
              message='Student has finalized the university'
              title='Finalized'
            />
          )}
          {meta.state === UniversitySuggestionState.WaitingOnStudent && (
            <Notice
              type='warning'
              message='Please respond to suggestions to proceed, you may accept or reject the suggestions. Your application will be moved to next stage when you are satisfied with/accept all the suggestions.'
              title='Action Required'
            />
          )}

          {meta.suggestions?.map((sugg, idx) => {
            const key = `suggestions[${idx}]`
            return (
              <div className={`card mb-5`} key={idx}>
                <Suggestion suggestion={sugg} renderCallback={(suggestion) => {
                  if (isStudent) return <></>;
                  return <>
                    <span className={`fw-bold badge m-1 w-20 badge-light-primary`}>{suggestion.isDirect ? 'Direct' : 'Non-Direct'}</span>
                    {suggestion.partnerId &&
                      <span className={`fw-bold badge m-1 w-20 badge-light-primary`}>{partners?.find(x => x.id === suggestion.partnerId)?.name}</span>}
                  </>
                }} />
                <div className='separator mb-4'></div>
                <SuggestionComments comments={sugg.comments} />
                <div className='separator mb-4'></div>
                <SuggestionActions
                  actions={actions}
                  defaultSelected={sugg.status as any}
                  currentCwidNumber={sugg.cwidNumber}
                  onSelected={(status?: SuggestedUniversityResponseStatus, cwidNumber?: string) => {
                    if (status) {
                      formik.setFieldValue(`${key}.status`, status)
                      formik.setFieldValue(`${key}.cwidNumber`, cwidNumber)
                      formik.setFieldTouched(`${key}`)
                    }
                  }}
                />
                {formik.getFieldMeta(`suggestions[${idx}].status`).value ===
                  SuggestedUniversityStatus.RequestedChanges && (
                    <CommentForm
                      onChange={(comment) => {
                        formik.setFieldValue(`${key}.comment`, comment)
                        formik.setFieldTouched(`${key}`)
                      }}
                    />
                  )}

                {formik.getFieldMeta(key).touched && formik.getFieldMeta(key).error && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger mt-2'>
                      <span role='alert'>Response required</span>
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </>
      )}
    </>
  )
}
