/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from 'react';
import { Button, Spinner, Card, ButtonGroup } from 'react-bootstrap-v5';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Notice from '../../../modules/common/components/Notice';
import { IAcademicProfile, IQuestionnaireDetailResponseDto, StudentProfile } from '../models';
import { getLorDetails } from '../services/LorService';
import { getConfig, getStudentAcademicProfile, getStudentProfile } from '../services/StudentProfileService';
import { PageTitle } from '../../../../theme/layout/core';
import { QuestionnaireTypeId } from '../../../modules/common/enums/QuestionnaireTypeId';
import { getLorTypes } from '../../../modules/common/CommonService';
import { DropdownOption } from '../../../modules/common/SharedStateProvider';
import { questionnaireOptions } from '../../../modules/common/DropdownOptions';
import { PDFExport } from '@progress/kendo-react-pdf';
import { ProfileHeader } from '../../student-profiles/components/ProfileHeader';
import { Divider } from '@material-ui/core';
import { GraduationDetailsOverview } from '../../student-profiles/components/GraduationDetailsOverview';
import { FutureStudyOverview } from '../../student-profiles/components/FutureStudyOverview';
import { WorkDetailsOverview } from '../../student-profiles/components/WorkDetailsOverview';
import { ExamScoresOverview } from '../../student-profiles/components/ExamScoresOverview';

type Props = {
    profileId: string
}

const ViewLorResponsePage: FC<RouteComponentProps<Props>> = ({ history, match: { params: { profileId } } }) => {
    return <ViewLorResponse profileId={profileId} />
}

export default withRouter(ViewLorResponsePage);

export const ViewLorResponse: FC<Props> = ({ profileId }) => {
    const [requiresLor, setRequiresLor] = useState<boolean>();
    const [lorDetails, setLorDetails] = useState<Array<IQuestionnaireDetailResponseDto>>();
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState<StudentProfile>();
    const [academicProfile, setAcademicProfile] = useState<IAcademicProfile>();
    const [lorTypeOptions, setLorTypeOptions] = useState<Array<DropdownOption>>([]);
    const pdfExportComponent = useRef(null);

    useEffect(() => {
        const fetchPersonalProfile = (profileId: number) => {
            getStudentProfile(+profileId)
                .then(({ data: serverProfile }) => {
                    setProfile(serverProfile);
                })
                .finally(() => setLoading(false));
        }
        if (profileId) {
            fetchPersonalProfile(+profileId);
        }
    }, [profileId]);

    useEffect(() => {
        const fetchAcademicProfile = (profileId: number) => {
            if (!profile) return;
            getStudentAcademicProfile(profileId)
                .then(({ data: serverProfile }) => {
                    if (!serverProfile.workExperiences) {
                        serverProfile = { ...serverProfile, workExperiences: [] };
                    }
                    setAcademicProfile(serverProfile);
                }).catch(err => {
                    //notify TODO
                    console.error(err);
                })
        }
        fetchAcademicProfile(+profileId);
    }, [profile]);

    const exportPDF = () => {
        if (pdfExportComponent.current) {
            (pdfExportComponent.current as any).save();
        }
    };

    useEffect(() => {
        function fetchData() {
            setLoading(true);
            getConfig(+profileId)
                .then(resp => {
                    setRequiresLor(resp?.data?.requiresLor);
                })
                .catch(err => setRequiresLor(false))
                .finally(() => setLoading(false));
        }
        fetchData();
    }, [profileId])

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const { data: currentLorDetail } = await getLorDetails(+profileId);
                setLorDetails(currentLorDetail || null);
            } finally {
                setLoading(false);
            }
        }
        requiresLor && fetchData();
    }, [requiresLor]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const { data: lorOptions } = await getLorTypes();
                setLorTypeOptions(lorOptions.filter(x => x.value !== QuestionnaireTypeId.Sop));
            } finally {
                setLoading(false);
            }
        }

        if (requiresLor) {
            fetchData();
        }
    }, [requiresLor]);

    if (!profileId) {
        <Notice message='Profile not found' />
    }

    return <>
        <PageTitle breadcrumbs={[]}>{`LOR Questionnaire Response: ${profile?.email}`}</PageTitle>
        <div className="row">
            <div className="col bg-white pt-2 m-2">
                <ButtonGroup className='mb-5'>
                    <Button className="btn btn-sm btn-outline btn-outline-primary" variant='outline' onClick={exportPDF} disabled={!profile || !requiresLor}>
                        <i className='bi bi-download text-dark'></i> Download
                    </Button>
                </ButtonGroup>
            </div>
        </div>
        <div className='card mb-5 mb-xl-10'>
            <div className="card-header">
                <h3 className='card-title mb-2'>
                    LOR Questionnaire Response - {profile?.firstName}{' '}{profile?.lastName}
                </h3>
            </div>
            <div className='card-body pt-9 pb-0'>
                {profile && <ProfileHeader showActions={false} exportPDF={null} profile={profile} />}

                <PDFExport
                    ref={pdfExportComponent}
                    paperSize="auto"
                    margin={40}
                    fileName={`LOR Export - ${profile?.email}.pdf`}
                    author="Aniket Dandawate"
                    creator="Aniket Dandawate">
                    <div className='row mb-10'>
                        {!requiresLor && <div className='col-xl-6'>
                            <Notice message='Profile does not require LOR.' />
                        </div>
                        }
                        {loading && <Spinner variant='primary' animation='grow' />}
                        {academicProfile &&
                            <><GraduationDetailsOverview academicsData={academicProfile} />
                                <FutureStudyOverview academicsData={academicProfile} />
                                <WorkDetailsOverview data={academicProfile.workExperiences || []} />
                            </>}
                        {profile && <ExamScoresOverview data={{
                            competitiveExam: profile.competitiveExam,
                            languageExam: profile?.languageExam, profileId: profile.id
                        }} />}
                        {requiresLor && !loading &&
                            <div
                                className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                            >
                                {lorDetails == null ?
                                    <div className='row bg-white rounded'>
                                        <div className='col-xl-6'>
                                            <Notice message='LOR Questionnaire is not responded by student.' />
                                        </div>
                                    </div>
                                    :
                                    <div className='row bg-white rounded'>
                                        {lorDetails.map((details, parentIndex) => {
                                            return <div className='card mb-5 mb-xl-10' key={details.id}>
                                                <div className="card-header">
                                                    <h3 className='card-title mb-2'>
                                                        <span className='badge badge-info badge-light-info m-2'>{parentIndex + 1}.</span>  {questionnaireOptions.find(x => x.value === details?.questionnaireTypeId)?.label}
                                                    </h3>
                                                    <p className='row col-12 text-muted'>{lorTypeOptions?.find(x => x.value === details?.questionnaireTypeId)?.label}</p>
                                                </div>
                                                <div className='card-body pt-2 pb-0'>
                                                    {
                                                        details.answers.map((a, childIndex) => {
                                                            return <Card className="mb-5 border border-primary p-5 text-justify" key={a.id}>
                                                                <div className='row mb-5'>
                                                                    <div className="col-12">
                                                                        <span className='badge badge-info badge-light-info m-2'>{parentIndex + 1}.{childIndex + 1}</span>
                                                                        <span className='badge badge-primary badge-light-primary m-2'>Question</span>
                                                                        <p className="text-dark mb-2">
                                                                            <label dangerouslySetInnerHTML={{ __html: a.questionText }}></label>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <Divider variant='fullWidth' className='bg-danger' />
                                                                <div className="row mt-2">
                                                                    <div className="col-12 ">
                                                                        <p className="text-dark mb-2">
                                                                            <span className='badge badge-danger badge-light-danger m-2'>Answer</span>
                                                                            <p className='form-label bg-light-warning' dangerouslySetInnerHTML={{ __html: a.answer.replaceAll('\n', '<br/>') }}></p>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                        )}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </PDFExport>
            </div>
        </div>
    </>;
}
