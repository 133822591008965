import { FC, useState } from "react";
import { Button, ButtonGroup, Card } from 'react-bootstrap-v5';
import { ConfirmationModal } from '../../../../modules/common/components/ConfirmationModal';
import Notice from '../../../../modules/common/components/Notice';
import { ViewProfilePage } from '../../../student-profiles/ViewProfilePage';
import { submitProfile } from '../../services/StudentProfileService';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

export const VerifyAndSubmit: FC<{ profileId: string, isSubmitted: boolean }> = ({ profileId, isSubmitted }) => {
    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [hasError, setError] = useState(false);

    const history = useHistory();

    const handleSubmit = () => {
        if (!showConfirm) {
            setShowConfirm(true);
        } else {
            setShowConfirm(false);
            //do actual submit call
            setLoading(true);
            submitProfile(+profileId)
                .then(() => {
                    toast('Profile submitted', { type: 'success' });
                })
                .catch(() => {
                    toast('Failed to submit profile. Please check errors and try again.', { type: 'error' });
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }
    const onError = () => {
        setError(true);
    }
    return <>
        <ViewProfilePage profileId={profileId} onError={onError} />
        {
            !isSubmitted &&
            <>
                <Card>
                    <div className='row m-4'>
                        <div className="text-center">
                            <ButtonGroup>
                                <Button disabled={hasError} variant='outline' className='btn btn-outline btn-outline-primary' onClick={handleSubmit}>
                                    <i className="bi bi-send-check text-dark"></i>
                                    {loading ? (
                                        <span className='indicator-progress' style={{ display: 'flex' }}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    ) : "Submit"}

                                </Button>
                                <Button variant='outline' className='btn btn-outline btn-outline-danger' onClick={() => history.push('/')}>
                                    <i className="bi bi-x-lg text-dark"></i> Cancel
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </Card>
                {
                    showConfirm && <ConfirmationModal title='Confirm Profile Submission' onConfirm={() => {
                        handleSubmit();
                    }}
                        onCancel={() => setShowConfirm(false)}>
                        <Notice title='Great! Looks like you have filled up all the information we need.'
                            message='You will not be able to change any data in after you submit your profile.
            For every change, you will have to reach out to your branch.'
                        />
                    </ConfirmationModal>
                }
            </>
        }
    </>
}