/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx"
import { FC, ReactElement } from "react"
import { Link } from "react-router-dom"
import { KTSVG } from "../../../../theme/helpers"
import { GenericTooltip } from "../../../modules/common/components/TooltipInfo"
import { suggestedUniversityStatusOptions } from "../../../modules/common/DropdownOptions"
import { enumToKeyValue } from "../../../modules/common/EnumHelper"
import { useSharedState } from "../../../modules/common/SharedStateProvider"
import { ISuggestedUniversityResponseDto, SuggestedUniversityStatus, SuggestionLevel } from "../models/UniversitySuggestionDtos"

const getStatusName = (val: any) => {
    return suggestedUniversityStatusOptions.find(x => x.value === val)?.label;
}

export const Suggestion: FC<{ index?: number, suggestion: ISuggestedUniversityResponseDto, onEdit?: (suggestion: ISuggestedUniversityResponseDto) => void, onDelete?: (universityId: number) => void, renderCallback?: (sugg: ISuggestedUniversityResponseDto) => ReactElement }> = ({ index, suggestion, onDelete, onEdit, renderCallback }) => {
    const state = useSharedState();
    const url = `/universities/${suggestion.university.id}/${state.isStudent ? state.user?.ProfileId : ''}`;
    if (!suggestion) return null;

    return <div className='card-body pb-0'>
        <div className='d-flex align-items-center mb-3'>
            <div className='d-flex align-items-center flex-grow-1'>
                <div className='symbol symbol-45px me-5'>
                    <img src="/media/icons/duotune/general/mortarboard.svg" alt="" />
                </div>

                <div className='d-flex flex-column flex-grow-1'>
                    <Link to={url} className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                        <span className="badge badge-primary">{index}</span>  {suggestion.university?.universityName}
                    </Link>
                    <div className="d-flex flex-row">
                        <span className={clsx(`fw-bold badge m-1 w-20 badge-light-primary`,
                            {
                                'badge-light-primary': suggestion.status === SuggestedUniversityStatus.Accepted,
                                'badge-light-danger': suggestion.status === SuggestedUniversityStatus.RequestedChanges,
                                'badge-light-success': suggestion.status === SuggestedUniversityStatus.AdmitReceived,
                            }
                        )}>{getStatusName(suggestion.status)}</span>
                        <span className={`fw-bold badge m-1 w-20 badge-light-primary`}>{enumToKeyValue(SuggestionLevel)
                            .find(x => +x.value === +suggestion.suggestionLevel)?.label}</span>
                        {renderCallback && renderCallback(suggestion)}
                    </div>
                </div>
                {onDelete && <div className='my-0'>
                    <GenericTooltip message={"Edit suggestion"} childEle={
                        <button
                            type='button'
                            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                            onClick={() => {
                                onEdit && onEdit(suggestion);
                            }}
                        >
                            <i className="bi bi-pencil"></i>
                        </button>} />
                    <GenericTooltip message={"Delete suggestion"} childEle={
                        <button
                            type='button'
                            className='btn btn-sm btn-icon btn-color-danger btn-active-light-danger'
                            onClick={() => {
                                onDelete(suggestion.universityId);
                            }}
                        >
                            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                        </button>} />

                </div>}
            </div>
        </div>

        <div className='mb-7'>
            <div className='text-gray-800 mb-5'>
                <span className="fw-bold text-dark badge m-2">
                    Application Deadline: {suggestion.university?.applicationDeadline ? suggestion.university?.applicationDeadline : 'missing'}
                </span>
                <span className="fw-bold text-dark badge m-2">
                    Application Fee: {suggestion.university?.applicationFee ? `$${suggestion.university?.applicationFee}` : 'missing'}
                </span>
                <span className="fw-bold text-dark badge m-2">
                    Application Expenditure: {suggestion.university?.annualExpenditure ? `$${suggestion.university?.annualExpenditure}` : 'missing'}
                </span>
                <span className="fw-bold text-dark badge text-dark m-2">
                    Temperature: [{suggestion.university?.minTemperature} &#8451; - {suggestion.university?.maxTemperature} &#8451;]
                </span>
            </div>
        </div>
    </div>
}
