import { FC, useEffect, useState } from "react"
import { Spinner } from "react-bootstrap-v5"
import { useDispatch } from "react-redux"
import { ProfileWizard } from "../../components/ProfileEdit/ProfileWizard"
import { StudentProfile } from "../../models/StudentProfile"
import { UpdateStudentProfileRequest } from "../../models/UpdateStudentProfileRequest"
import { getStudentProfile, saveStudentProfile } from "../../services/StudentProfileService"
import { Error500 } from "../../../../modules/errors/components/Error500"

type PathParamsType = {
    profileId: string,
}

const EditProfilePage: FC<PathParamsType> = ({ profileId }) => {
    const [profile, setProfile] = useState<StudentProfile | UpdateStudentProfileRequest>();
    const [failed, setFailed] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchProfile = (profileId: number) => {
            getStudentProfile(profileId)
                .then(({ data: serverProfile }) => {
                    serverProfile.dateOfBirth = new Date(serverProfile.dateOfBirth).toLocaleDateString();
                    setProfile(serverProfile as any);
                }).catch(err => {
                    //notify TODO
                    setFailed(true);
                })
        }
        if (profileId) {
            fetchProfile(+profileId);
        }
    }, [profileId, dispatch]);

    if(failed){
        return <Error500 />
    }

    if (!profile) {
        return <Spinner animation="grow" variant="primary" />
    }
   
    return <ProfileWizard profile={profile} submitFn={saveStudentProfile} />
}

export default EditProfilePage;