import { FC } from "react"

export type StepperHeadingProps = {
    index: number,
    title: string,
    desc?: string,
    htmlId?: string,
    onClick?: any
}
export const StepperHeading: FC<StepperHeadingProps> = ({ index, title, desc, onClick, htmlId }) => {
    return <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => { onClick && onClick(index) }}>
        <div className='stepper-line w-40px'></div>

        <div className='stepper-icon w-40px h-40px' style={{ cursor: 'pointer' }}>
            <i className='stepper-check fas fa-check'></i>
            <span className='stepper-number'>{index}</span>
        </div>

        <div className={`stepper-label ${htmlId}`} style={{ cursor: 'pointer' }}>
            <h3 className='stepper-title'>{title}</h3>
            <div className='stepper-desc fw-bold'>{desc}</div>
        </div>
    </div>
}