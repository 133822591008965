/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from "react";
import { StudentsPendingApproval } from "./components/StudentsPendingApproval";
import { StudentsPendingLorApproval } from "./components/StudentsPendingLorApproval";
import { StudentsPendingSopApproval } from "./components/StudentsPendingSopApproval";
import { enumToKeyValue } from "../../modules/common/EnumHelper";
import { useHistory } from "react-router-dom";

enum DashboardTabs {
    Profiles,
    Lor,
    Sop
}

export const BLLDashboard: FC = () => {
    const [tab, setTab] = useState<DashboardTabs>(DashboardTabs.Profiles);
    const [tabCounts, setTabCounts] = useState<Array<number>>([]);
    const history = useHistory();
    const tabNames = useMemo(() => {
        return enumToKeyValue(DashboardTabs);
    }, [])

    useEffect(() => {
        if (!history.location.hash) return;
        setTab(+tabNames.find(x => `#${x.label}` === history.location.hash)?.value);
        //eslint-disable-next-line
    }, [history.location.hash]);
    return <>
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item cursor-pointer'>
                            <a className={`nav-link text-active-primary me-6 ${tab === DashboardTabs.Profiles ? 'active' : ''}`} onClick={() => {
                                history.push({ hash: tabNames.find(x => x.value === DashboardTabs.Profiles.toString())?.label });
                            }}>
                                Pending Profile Approval
                                <span className="badge badge-light-primary m-2">{tabCounts[DashboardTabs.Profiles]}</span>
                            </a>
                        </li>
                        <li className='nav-item cursor-pointer'>
                            <a className={`nav-link text-active-primary me-6 ${tab === DashboardTabs.Lor ? 'active' : ''}`} onClick={() => {
                                history.push({ hash: tabNames.find(x => x.value === DashboardTabs.Lor.toString())?.label });
                            }}>
                                Pending LOR Approval
                                <span className="badge badge-light-primary m-2">{tabCounts[DashboardTabs.Lor]}</span>
                            </a>
                        </li>

                        <li className='nav-item cursor-pointer'>
                            <a className={`nav-link text-active-primary me-6 ${tab === DashboardTabs.Sop ? 'active' : ''}`} onClick={() => {
                                history.push({ hash: tabNames.find(x => x.value === DashboardTabs.Sop.toString())?.label });
                            }}>
                                Pending SOP Approval
                                <span className="badge badge-light-primary m-2">{tabCounts[DashboardTabs.Sop]}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className={`${tab !== DashboardTabs.Profiles ? 'd-none' : ''}`}>
            <StudentsPendingApproval onLoad={(count: number) => {
                const counts = tabCounts;
                counts[DashboardTabs.Profiles] = count;
                setTabCounts([...counts]);
            }} />
        </div>
        <div className={`${tab !== DashboardTabs.Lor ? 'd-none' : ''}`}>
            <StudentsPendingLorApproval onLoad={(count: number) => {
                const counts = tabCounts;
                counts[DashboardTabs.Lor] = count
                setTabCounts([...counts]);
            }} />
        </div>
        <div className={`${tab !== DashboardTabs.Sop ? 'd-none' : ''}`}>
            <StudentsPendingSopApproval onLoad={(count: number) => {
                const counts = tabCounts;
                counts[DashboardTabs.Sop] = count
                setTabCounts([...counts]);
            }} />
        </div>
    </>
}