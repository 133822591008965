import * as Yup from 'yup'
import { StepperHeadingProps } from '../../../../modules/stepper/StepHeading'
import { CompetitiveExamType, ExamType, LanguageExam } from '../../models/ExamModels'

export const allowedFileTypes = new Set<string>([
  'image/jpg',
  'image/png',
  'image/jpeg',
  'application/pdf',
])
export const stepHeadings: StepperHeadingProps[] = [
  {
    index: 1,
    title: 'Qualifying Exam',
    desc: 'Exam you want to crack',
    htmlId: 'competitive-details',
  },
  {
    index: 2,
    title: 'Language Exam',
    desc: 'Exam you want to crack',
    htmlId: 'language-details',
  },
]

const greScoreYupShape = Yup.object()
  .nullable()
  .when('isExamGiven', (isExamGiven: boolean, schema) => {
    if (!isExamGiven) return schema
    return schema.shape({
      verbal: Yup.number().min(130).max(170).label('GRE Verbal Score').required(),
      quantitative: Yup.number().min(130).max(170).label('GRE Quantitative Score').required(),
      awa: Yup.number().min(0).max(6).label('GRE AWA Score').required(),
    })
  })

const gmatScoreYupShape = Yup.object()
  .nullable()
  .when('isExamGiven', (isExamGiven: boolean, schema) => {
    if (!isExamGiven) return schema
    return schema.shape({
      verbal: Yup.number().min(0).max(60).label('GMAT Verbal Score').required(),
      quantitative: Yup.number().min(0).max(60).label('GMAT Quantitative Score').required(),
      awa: Yup.number().min(0).max(6).label('GMAT AWA Score').required(),
      integratedReasoning: Yup.number().min(0).max(8).label('GMAT AWA Score').required(),
      total: Yup.number().min(200).max(800).label('GMAT Total Score').required(),
    })
  })

const toeflScoreYupShape = Yup.object()
  .nullable()
  .when('isExamGiven', (isExamGiven: boolean, schema) => {
    if (!isExamGiven) return schema
    return schema.shape({
      reading: Yup.number().min(0).max(30).label('TOEFL Reading Score').required(),
      listening: Yup.number().min(0).max(30).label('TOEFL Listening Score').required(),
      speaking: Yup.number().min(0).max(30).label('TOEFL Speaking Score').required(),
      writing: Yup.number().min(0).max(30).label('TOEFL Writing Score').required(),
    })
  })

const ieltsScoreYupShape = Yup.object()
  .nullable()
  .when('isExamGiven', (isExamGiven: boolean, schema) => {
    if (!isExamGiven) return schema
    return schema.shape({
      reading: Yup.number().min(0).max(9).label('IELTS Reading Score').required(),
      listening: Yup.number().min(0).max(9).label('IELTS Listening Score').required(),
      speaking: Yup.number().min(0).max(9).label('IELTS Speaking Score').required(),
      writing: Yup.number().min(0).max(9).label('IELTS Writing Score').required(),
    })
  })

const satScoreYupShape = Yup.object()
  .nullable()
  .when('isExamGiven', (isExamGiven: boolean, schema) => {
    if (!isExamGiven) return schema
    return schema.shape({
      criticalReading: Yup.number()
        .min(200)
        .max(1600)
        .label('SAT Critical Reading Score')
        .required(),
      math: Yup.number().min(200).max(1600).label('SAT Math Score').required(),
      writing: Yup.number().min(200).max(1600).label('SAT Writing Score').required(),
      total: Yup.number().min(200).max(1600).label('SAT Total Score').required(),
    })
  })

const actScoreYupShape = Yup.object()
  .nullable()
  .when('isExamGiven', (isExamGiven: boolean, schema) => {
    if (!isExamGiven) return schema
    return schema.shape({
      reading: Yup.number().min(1).max(36).label('ACT reading Score').required(),
      math: Yup.number().min(1).max(36).label('ACT math Score').required(),
      science: Yup.number().min(1).max(36).label('ACT science Score').required(),
      english: Yup.number().min(1).max(36).label('ACT english Score').required(),
      total: Yup.number().min(1).max(36).label('ACT Total Score').required(),
    })
  })
export const examDetailsSchemas = [
  Yup.object().shape({
    competitiveExam: Yup.object()
      .nullable()
      .shape({
        greScore: greScoreYupShape.when('examType', (examType: ExamType, schema) => {
          return examType === ExamType.GRE ? schema : Yup.object().nullable()
        }),
        gmatScore: gmatScoreYupShape.when('examType', (examType: ExamType, schema) => {
          return examType === ExamType.GMAT ? schema : Yup.object().nullable()
        }),
        satScore: satScoreYupShape.when('examType', (examType: ExamType, schema) => {
          return examType === ExamType.SAT ? schema : Yup.object().nullable()
        }),
        actScore: actScoreYupShape.when('examType', (examType: ExamType, schema) => {
          return examType === ExamType.ACT ? schema : Yup.object().nullable()
        }),
        examType: Yup.number()
          .min(CompetitiveExamType.GRE)
          .max(CompetitiveExamType.ACT)
          .required()
          .label('Qualifying Exam Type'),
        examDate: Yup.date()
          .required()
          .label('Qualifying Exam Date')
          .when('isExamGiven', (isExamGiven: boolean, schema) => {
            return isExamGiven ? schema.max(new Date()).label('Exam date should be in the past') : schema
          }),
        isExamGiven: Yup.boolean().label('Exam given'),
      })
      .required(),
  }),
  Yup.object().shape({
    languageExam: Yup.object()
      .nullable()
      .shape({
        toeflScore: toeflScoreYupShape.when('examType', (examType: ExamType, schema) => {
          return examType === ExamType.TOEFL ? schema : Yup.object().nullable()
        }),
        ieltsScore: ieltsScoreYupShape.when('examType', (examType: ExamType, schema) => {
          return examType === ExamType.IELTS ? schema : Yup.object().nullable()
        }),
        examType: Yup.number()
          .min(LanguageExam.TOEFL)
          .max(LanguageExam.IELTS)
          .required()
          .label('Language Exam Type'),
        isExamGiven: Yup.boolean().label('Exam given'),
        examDate: Yup.date()
          .required()
          .label('Language Exam Date')
          .when('isExamGiven', (isExamGiven: boolean, schema) => {
            return isExamGiven ? schema.max(new Date()).label('Exam date should be in the past') : schema
          }),
      })
      .required(),
  }),
]
