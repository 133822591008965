import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import clsx from "clsx"
import { FC } from "react"
import { InputGroup } from "react-bootstrap-v5"

type PropTypes = {
    formik: any;
    fieldPath: string;
    title: string;
    hideBacklogs?: boolean;
}

export const GraduationScore: FC<PropTypes> = ({ formik, fieldPath, title, hideBacklogs }) => {
    const scoreKey = `${fieldPath}.score`;
    const backlogsKey = `${fieldPath}.backlogs`;
    const yeardropKey = `${fieldPath}.hasYeardrop`;

    return <div className='fv-row mb-1 row'>
        <div className='col-xl-6'>
            <InputGroup className="mb-3" size="sm">
                <InputGroup.Text className="required">{title}</InputGroup.Text>
                <input {...formik.getFieldProps(scoreKey)}
                    type={'number'}
                    placeholder="3.5"
                    className={clsx('form-control',
                        {
                            'is-invalid': formik.getFieldMeta(scoreKey).touched && formik.getFieldMeta(scoreKey).error,
                        },
                        {
                            'is-valid': formik.getFieldMeta(scoreKey).touched && !formik.getFieldMeta(scoreKey).error
                        })
                    }
                />
                {formik.getFieldMeta(scoreKey).touched && formik.getFieldMeta(scoreKey).error && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>As per selected grading system, {formik.getFieldMeta(scoreKey).error}</span>
                        </div>
                    </div>
                )}
            </InputGroup>
        </div>
        {!hideBacklogs && <>
            <div className='col-xl-3'>
                <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text>Backlogs</InputGroup.Text>
                    <input {...formik.getFieldProps(backlogsKey)} placeholder="0" type={'number'} className='form-control form-control-sm' />
                </InputGroup>
            </div>
            <div className="col-xl-3">
                <FormControlLabel
                    control={<Checkbox
                        checked={formik.getFieldMeta(yeardropKey).value}
                        onChange={(ev) => formik.setFieldValue(yeardropKey, ev.target.checked)}
                    />}
                    label='Has Year drop'
                />
            </div>
        </>
        }
    </div>
}
