import { FC } from "react";
import { useHistory } from "react-router-dom";
import tableIcons from "../../../modules/common/components/MaterialTableIcons";
import StudentList from "../../student-profiles/components/StudentList";
import { getAllOAStudentsPaginated, getStudentsPendingSuggestionPaginated, getStudentsWithSuggestions, getSuggestionChangeRequests } from "../../student-profiles/services/StudentManager";
import { StudentProfile } from "../../student/models";
import { IStudentFilter } from "../../student-profiles/models/IStudentFilter";

export const StudentsPendingUniversitySuggestion: FC<{ onlyIncludeChangeRequests: boolean, onLoad?: (count: number) => void }> = ({ onlyIncludeChangeRequests, onLoad }) => {
  const history = useHistory();

  return <>
    <StudentList
      title={`${!onlyIncludeChangeRequests ? 'Profiles Pending University Suggestion' : 'Change Requests'}`}
      getStudentsFromServer={onlyIncludeChangeRequests ? getSuggestionChangeRequests : getStudentsPendingSuggestionPaginated}
      actions={[{
        icon: tableIcons.Suggest as any,
        tooltip: 'Suggest Universities',
        onClick: (event: any, rowData: StudentProfile) => {
          history.push(`/university-suggestions/${rowData.id}`)
        },
      }]}
      initialFilters={{ hasCompetitiveExamScores: true, hasLanguageExamScores: true }}
      disabledFilters={['statuses']}
      onLoad={onLoad}
    />
  </>
}

export const AllStudentsWithUniversitySuggestion: FC<{ includeFinalized: boolean, onLoad?: (count: number) => void }> = ({ includeFinalized, onLoad }) => {
  const history = useHistory();

  return <>
    <StudentList
      title={'All Students with Univerisities Suggested'}
      getStudentsFromServer={(serachTerm: string | undefined, pageNumber: number, pageSize: number, filter: IStudentFilter, sortOrder?: string, sortField?: string) =>
        getStudentsWithSuggestions(serachTerm, pageNumber, pageSize, filter, sortOrder, sortField, includeFinalized)}
      actions={[{
        icon: tableIcons.Suggest as any,
        tooltip: 'View and revert Suggestions',
        onClick: (event: any, rowData: StudentProfile) => {
          history.push(`/university-suggestions/${rowData.id}`)
        },
      }]}
      disabledFilters={['statuses']}
      onLoad={onLoad}
    />
  </>
}


export const AllOAStudents: FC<{ onLoad?: (count: number) => void }> = ({ onLoad }) => {
  const history = useHistory();

  return <>
    <StudentList
      title={"All Students"}
      getStudentsFromServer={ getAllOAStudentsPaginated}
      actions={[{
        icon: tableIcons.Suggest as any,
        tooltip: 'Suggest Universities',
        onClick: (event: any, rowData: StudentProfile) => {
          history.push(`/university-suggestions/${rowData.id}`)
        },
      }]}
      disabledFilters={['statuses']}
      onLoad={onLoad}
    />
  </>
}
