import { enumToKeyValue } from "../../common/EnumHelper";

export interface Conversation {
    id: number;
    title: string;
    lastUpdatedAt: string;
    status: ConversationStatus;
    assignedToEmail: string;
    studentEmail: string;
    messages: Message[];
    closedBy: string;
    unreadMessageCount: number;
}

export enum ConversationStatus {
    RequiresNoResponse = 1,
    WaitingOnAdmin = 2,
    WaitingOnYou,
    Closed
}

export interface Message {
    id: number;
    messageBody: string;
    messageAttachmentUri: string;
    isRead: boolean;
    parentMessageId?: number;
    createdAt: string;
    creatorBy: string;
    conversationId?: number;
    readAt: string;
}

export const conversationStatusMap = enumToKeyValue(ConversationStatus);

export interface CreateMessageDto extends ReplyMessageDto {
    title: string;
}

export interface ReplyMessageDto{
    base64File?: string;
    fileName?: string;
    message: string;
    parentMessageId?: number;
}