/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { KTSVG, toAbsoluteUrl, AlertModel } from '../../../helpers'
import { Link } from 'react-router-dom'

const HeaderNotificationsMenu: FC<{ alerts: Array<AlertModel>, handleTransition: (id: number) => void }> = ({ alerts, handleTransition }) => {
  return <div
    className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
    data-kt-menu='true'
  >
    <div
      className='d-flex flex-column bgi-no-repeat rounded-top'
      style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')` }}
    >
      <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
        Notifications
      </h3>
    </div>

    <div className='tab-pane ' role='tabpanel'>
      <div className='scroll-y mh-325px my-5 px-8'>
        {alerts.map((alert, index) => (
          <div key={`alert${index}`} className='d-flex flex-stack py-4'>
            <div className='d-flex align-items-center cursor-pointer'>
              <div className='symbol symbol-35px me-4'>
                <span className={clsx('symbol-label', `bg-light-${alert.state}`)}>
                  {' '}
                  <KTSVG
                    path={`/media/${alert.icon}`}
                    className={`svg-icon-2 svg-icon-${alert.state}`}
                  />
                </span>
              </div>

              <div className='mb-0 me-2' onClick={() => {
                handleTransition(alert.id);
              }}>
                <a className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                  {alert.title}
                </a>
                <div className='text-gray-400 fs-7'>{alert.description}</div>
              </div>
            </div>

            <span className='badge badge-light fs-8'>{alert.time}</span>
          </div>
        ))}

        {alerts?.length === 0 && <p className='text-center'>All caught up!</p>}
      </div>

      <div className='py-3 text-center border-top'>
        <Link
          to='/notifications'
          className='btn btn-color-gray-600 btn-active-color-primary'
        >
          View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
        </Link>
      </div>
    </div>
  </div>
}

export { HeaderNotificationsMenu }
