import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as errors from '../../app/modules/errors/redux/ErrorRedux'
import * as common from '../../app/pages/student/redux/CommonReduxActions'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  errors: errors.reducer,
  common: common.reducer
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
