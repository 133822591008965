import { FC, useEffect, useState } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../../theme/layout/core';
import { ProfileTimeline } from '../../../modules/common/components/ProfileTimeline';
import EditProfileHeader from './EditProfileHeader';
import EditAcademicProfilePage from './internal/EditAcademicProfilePage';
import EditExamDetails from './internal/EditExamDetails';
import EditProfilePage from './internal/EditProfilePage';
import { VerifyAndSubmit } from './internal/VerifyAndSubmitPage';
import { getConfig } from '../services/StudentProfileService';
import { Spinner } from 'react-bootstrap-v5';
import { useSharedState } from '../../../modules/common/SharedStateProvider';

type PathParamsType = {
    profileId: string
}

const EditStudentProfileWrapper: FC<RouteComponentProps<PathParamsType>> = ({ history, match: { params: { profileId } } }) => {
    const basePath = `/student/:profileId/edit`
    const accountBreadCrumbs: Array<PageLink> = [
        {
            title: 'Edit Profile',
            path: `/student/${profileId}/edit/personal`,
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]
    const [isSubmitted, setIsSubmiited] = useState<boolean | undefined>(undefined);
    const sharedState = useSharedState();

    useEffect(() => {
        const doWork = async () => {
            if (sharedState.isStudent) {
                getConfig(+profileId).then(({ data }) => {
                    setIsSubmiited(data.profileState.isSubmitted);
                })
            } else {
                setIsSubmiited(false);
            }
        }
        profileId && doWork();
    }, [profileId, sharedState]);

    if (isSubmitted === undefined) {
        return <Spinner animation='grow' variant='primary' />
    }

    return <>
        <EditProfileHeader isSubmitted={isSubmitted} />
        <Switch>
            <Route path={`${basePath}/personal`} sensitive={false} strict={false}>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Personal Details</PageTitle>
                <EditProfilePage profileId={profileId} />
            </Route>
            <Route path={`${basePath}/academic`}>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Academic Details</PageTitle>
                <EditAcademicProfilePage profileId={profileId} />
            </Route>
            <Route path={`${basePath}/exam`}>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Exam Details</PageTitle>
                <EditExamDetails profileId={profileId} />
            </Route>
            <Route path={`${basePath}/submit`}>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Verify & Submit </PageTitle>
                <VerifyAndSubmit profileId={profileId} isSubmitted={isSubmitted} />
            </Route>
            <Route path={`${basePath}/activity`}>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Timeline & Activity </PageTitle>
                <ProfileTimeline profileId={+profileId} />
            </Route>
            <Redirect from={`${basePath}`} exact={true} to={`${basePath}/${isSubmitted ? 'exam' : 'personal'}`} />
            <Redirect to={`${basePath}/${isSubmitted ? 'exam' : 'personal'}`} />
        </Switch>
    </>
}

export default withRouter(EditStudentProfileWrapper);