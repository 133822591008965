import { FC } from "react";
import tableIcons from "../../../modules/common/components/MaterialTableIcons";
import { getStudentsPendingLORApprovalPaginated } from "../../student-profiles/services/StudentManager";
import StudentList from "../../student-profiles/components/StudentList";
import { useHistory } from "react-router-dom";

export const StudentsPendingLorApproval: FC<{ onLoad?: (count: number) => void }> = ({ onLoad }) => {
  const history = useHistory();
  return <>
    <StudentList
      title='Profiles Pending Lor Questionnaire Approval'
      getStudentsFromServer={getStudentsPendingLORApprovalPaginated}
      actions={[
        {
          icon: tableIcons.LOR as any,
          tooltip: 'View & Approve LOR Response',
          disabled: false,
          onClick: (event: any, rowData: any) => {
            const url = `/student/${rowData.id}/lor/approve`;
            history.push(url);
          },
        },
      ]}
      disabledFilters={['examDate', 'statuses', 'hasCompetitiveExamScores', 'hasLanguageExamScores']}
      useDefaultActions={false}
      onLoad={onLoad}
    />
  </>
}

