import axios from 'axios';
import { Conversation, CreateMessageDto, ReplyMessageDto } from './models/ConversationModel';

const API_URL = process.env.REACT_APP_API_URL;
export const MESSAGE_API = `${API_URL}/api/v1/conversations`

export function getAll() {
    return axios.get<Array<Conversation>>(`${MESSAGE_API}`)
}

export function getConversation(conversationId: number) {
    return axios.get<Conversation>(`${MESSAGE_API}/${conversationId}`)
}

export function markAsRead(conversationId: number) {
    return axios.post(`${MESSAGE_API}/mark-as-read/${conversationId}`)
}

export function markAsClosed(conversationId: number) {
    return axios.post(`${MESSAGE_API}/mark-as-closed/${conversationId}`)
}

export function startConversation(request: CreateMessageDto) {
    return axios.post(`${MESSAGE_API}`, request);
}

export function reply(conversationId: number, request: ReplyMessageDto) {
    return axios.post(`${MESSAGE_API}/reply/${conversationId}`, request);
}

export function sendBulkMessage(studentEmails: Array<string>, message: CreateMessageDto) {
    return axios.post(`${MESSAGE_API}/send-bulk`, { studentEmails, message });
}

export function escalate(conversationId: number) {
    return axios.post(`${MESSAGE_API}/escalate/${conversationId}`);
}