import { FC } from "react";
import { useHistory } from "react-router-dom";
import tableIcons from "../../../modules/common/components/MaterialTableIcons";
import StudentList, { StudentListColumnMap } from "../../student-profiles/components/StudentList";
import { getStudentsPendingSuggestionApprovalPaginated } from "../../student-profiles/services/StudentManager";
import { StudentProfile } from "../../student/models";

export const StudentsPendingUniversitySuggestionApproval: FC<{ onLoad?: (count: number) => void }> = ({ onLoad }) => {
  const history = useHistory();

  return <>
    <StudentList
      title='Profiles Pending University Suggestion Review'
      getStudentsFromServer={getStudentsPendingSuggestionApprovalPaginated}
      actions={[{
        icon: tableIcons.Suggest as any,
        tooltip: 'Review University Suggestions',
        onClick: (event: any, rowData: StudentProfile) => {
          history.push(`/university-suggestions/${rowData.id}`)
        },
      }]}
      initialFilters={{ hasCompetitiveExamScores: true, hasLanguageExamScores: true }}
      disabledFilters={['statuses']}
      onLoad={onLoad}
      extraColumns={[StudentListColumnMap.SuggestedBy]}
    />
  </>
}

