import { FormControlLabel, Checkbox, Radio, RadioGroup } from "@material-ui/core";
import { FC, useState, useEffect } from "react";
import { Button } from "react-bootstrap-v5";
import TooltipInfo from "../../../modules/common/components/TooltipInfo";
import { branchOptions } from "../../../modules/common/DropdownOptions";
import { DropdownOption, useSharedState } from "../../../modules/common/SharedStateProvider";
import Select from 'react-select';
import { IStudentFilter } from "../models/IStudentFilter";
import clsx from "clsx";
import { StudentTypeEnum } from "../../../modules/common/enums/StudentTypeEnum";
import { FileUploadType } from "../../../modules/common/enums/FileUploadType";

type Props = {
    onFilterChanged: (field: StudentFilterType, value: any) => void;
    onSearch: () => void,
    disabledFilters?: StudentFilterType[],
    initialFilter?: IStudentFilter
}
export type StudentFilterType = 'termIds' | 'statuses' | 'branches' | 'examDate' | 'hasLanguageExamScores'
    | 'hasCompetitiveExamScores' | 'studentType' | 'countries' | 'documentType'

export const StudentListFilters: FC<Props> = ({ onFilterChanged, onSearch, disabledFilters, initialFilter }) => {
    const [termOptions, setTermOptions] = useState<DropdownOption[]>([]);
    const [countryOptions, setCountryOptions] = useState<DropdownOption[]>([]);
    const [expanded, setExpanded] = useState(true);
    const sharedState = useSharedState();

    useEffect(() => {
        const options = sharedState.terms.map(x => ({ label: x.name, value: x.id }))
        setTermOptions(options);
    }, [sharedState.terms])

    useEffect(() => {
        setCountryOptions(sharedState.countries.map(x => ({ label: x.name, value: x.name })))
    }, [sharedState.countries])

    const toggleExpand = () => {
        setExpanded(!expanded);
    }

    return <div className="row fv-row m-2">
        <div className="d-flex mb-2">
            <div className="d-flex flex-grow-1">
                <h4>Filters</h4>
            </div>
            <button
                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                onClick={toggleExpand}
            >
                <i className={`bi bi-${expanded ? 'dash' : 'plus'}-circle fs-3`}></i>
            </button>
        </div>
        <div className={clsx({
            'd-none': !expanded
        })}>
            <div className='row'>
                {!disabledFilters?.includes('termIds') && <div className="col-xl-3">
                    <label className='mb-2 fw-bold text-dark'>Terms</label>
                    <Select
                        options={termOptions}
                        onChange={(option) => {
                            onFilterChanged('termIds', Array.from(option.values()).map(x => x.value))
                        }}
                        isMulti={true}
                        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>}

                {/* {!disabledFilters?.includes('statuses') && <div className="col-xl-3">
                    <label className='mb-2 fw-bold text-dark'>Profile Status</label>
                    <Select
                        options={profileStatusOptions}
                        onChange={(option) => {
                            onFilterChanged('statuses', Array.from(option.values()).map(x => x.value))
                        }}
                        isMulti={true}
                        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>} */}

                {!disabledFilters?.includes('branches')
                    && !sharedState.isBranchAdmin
                    && !sharedState.isBranchLevelLogin
                    && <div className="col-xl-3">
                        <label className='mb-2 fw-bold text-dark'>
                            Assigned Branch
                            <TooltipInfo message={"This filter won't have any effect for Branch level logins"} />
                        </label>
                        <Select
                            options={branchOptions}
                            onChange={(option) => {
                                onFilterChanged('branches', Array.from(option.values()).map(x => x.value))
                            }}
                            isMulti={true}
                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>}

                {!disabledFilters?.includes('examDate') && <div className="col-xl-3">
                    <label className='mb-2 fw-bold text-dark form-label'>Exam Date</label>
                    <input
                        placeholder='Exam Date'
                        type='date'
                        autoComplete='off'
                        className='form-control form-control-sm'
                        onChange={(ev) => {
                            onFilterChanged('examDate', ev.target.valueAsDate);
                        }}
                        defaultValue={undefined}
                    />
                </div>}

                {!disabledFilters?.includes('countries') && <div className="col-xl-3">
                    <label className='mb-2 fw-bold text-dark'>
                        Interested Countries
                    </label>
                    <Select
                        options={countryOptions}
                        onChange={(option) => {
                            onFilterChanged('countries', Array.from(option.values()).map(x => x.value))
                        }}
                        isMulti={true}
                        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>}
            </div>
            <div className="row mt-2">
                {!disabledFilters?.includes('hasLanguageExamScores') && <div className="col-xl-3">
                    <FormControlLabel
                        control={<Checkbox
                            onChange={(ev) => onFilterChanged('hasLanguageExamScores', ev.target.checked)}
                            checked={initialFilter?.hasLanguageExamScores}
                        />}
                        label='Has Language Exam Scores?'
                    />
                </div>}
                {!disabledFilters?.includes('hasCompetitiveExamScores') && <div className="col-xl-3">
                    <FormControlLabel
                        control={<Checkbox
                            onChange={(ev) => onFilterChanged('hasCompetitiveExamScores', ev.target.checked)}
                            checked={initialFilter?.hasCompetitiveExamScores}
                        />}
                        label='Has Qualifying Exam Scores?'
                    />
                </div>}
                <div className="col-xl-3">
                    <RadioGroup
                        row
                        name="radio-buttons-group"
                        defaultValue={'0'}
                        onChange={(ev) => {
                            const val = +ev.target.value;
                            onFilterChanged('studentType', val ? val : undefined);
                        }}>
                        <FormControlLabel
                            value={'0'}
                            control={<Radio />}
                            label='None'
                        />
                        <FormControlLabel
                            value={StudentTypeEnum.Star.toString()}
                            control={<Radio />}
                            label='Star'
                        />
                        <FormControlLabel
                            value={StudentTypeEnum.Hyper.toString()}
                            control={<Radio />}
                            label='Hyper'
                        />
                    </RadioGroup>
                </div>

                {!disabledFilters?.includes('documentType') && <div className="col-xl-3">
                    <RadioGroup
                        row
                        name="radio-buttons-group"
                        defaultValue={'5'}
                        onChange={(ev) => {
                            const val = +ev.target.value;
                            onFilterChanged('documentType', val ? val : undefined);
                        }}>
                        <FormControlLabel
                            value={FileUploadType.StudentLOR.toString()}
                            control={<Radio />}
                            label='LOR'
                        />
                        <FormControlLabel
                            value={FileUploadType.StudentSOP.toString()}
                            control={<Radio />}
                            label='SOP'
                        />
                    </RadioGroup>
                </div>}

            </div>
            <div className="row mt-5 mb-2">
                <div className="col-xl-3">
                    <Button variant='outline' className="btn btn-sm btn-outline btn-outline-primary" onClick={onSearch}>
                        <i className="bi bi-search text-dark"></i> Search
                    </Button>
                </div>
            </div>
        </div>
    </div>

}