import clsx from "clsx"
import { FC, useEffect, useState } from "react"
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import moment from "moment"
import { FileUploader } from "../../../../../modules/common/components/FileUploader";
import { InputGroup } from "react-bootstrap-v5";
import { ImagePreview } from "../../ImagePreview";
import { allowedImageFileTypes } from "../ProfileEditSchema";
import Select from "react-select";
import { DropdownOption, useSharedState } from "../../../../../modules/common/SharedStateProvider";

type Props = {
    formik: any,
}
const allowedGenderOptions = [
    { label: 'Do not wish to specify', value: 0 },
    { label: 'Female', value: 1 },
    { label: 'Male', value: 2 },
    { label: 'Non binary', value: 3 }
];

export const PersonalStep: FC<Props> = ({ formik }) => {
    const sharedState = useSharedState();
    const [countryOptions, setCountryOptions] = useState<DropdownOption[]>([]);

    useEffect(() => {
        const options = sharedState.countries.map(x => ({ label: x.name, value: x.id }))
        setCountryOptions(options);
    }, [sharedState.countries])

    return <div className='w-100'>
        <div className='pb-10 pb-lg-15'>
            <h2 className='fw-bolder d-flex align-items-center text-dark'>
                Fill in your basic information
            </h2>
        </div>

        {/* begin::Form group Firstname */}
        <div className='fv-row mb-10 row'>
            <div className="col-xl-6">
                <label className='form-label fw-bolder text-dark fs-6 required'>First name</label>
                <input
                    placeholder='First name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('firstName')}
                    className={clsx(
                        'form-control form-control-sm ',
                        {
                            'is-invalid': formik.touched.firstName && formik.errors.firstName,
                        },
                        {
                            'is-valid': formik.touched.firstName && !formik.errors.firstName,
                        }
                    )} />
                {formik.touched.firstName && formik.errors.firstName && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{formik.errors.firstName}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className="col-xl-6">
                <label className='form-label fw-bolder text-dark fs-6'>Middle name</label>
                <input
                    placeholder='Middle name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('middleName')}
                    className={clsx(
                        'form-control form-control-sm ',
                        {
                            'is-invalid': formik.touched.middleName && formik.errors.middleName,
                        },
                        {
                            'is-valid': formik.touched.middleName && !formik.errors.middleName,
                        }
                    )} />
                {formik.touched.middleName && formik.errors.middleName && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{formik.errors.middleName}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>

        <div className='fv-row mb-10 row'>
            <div className="col-xl-6">
                <label className='class="form-label fw-bolder text-dark fs-6 required'>Last name</label>
                <input
                    placeholder='Last name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('lastName')}
                    className={clsx(
                        'form-control form-control-sm ',
                        {
                            'is-invalid': formik.touched.lastName && formik.errors.lastName,
                        },
                        {
                            'is-valid': formik.touched.lastName && !formik.errors.lastName,
                        }
                    )} />
                {formik.touched.lastName && formik.errors.lastName && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{formik.errors.lastName}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className="col-xl-6">
                <label className='class="form-label fw-bolder text-dark fs-6 required'>Birth date</label>
                <input
                    type='date'
                    autoComplete='off'
                    {...formik.getFieldProps(`dateOfBirth`)}
                    className={clsx(
                        'form-control form-control-sm ',
                        {
                            'is-invalid': formik.touched.dateOfBirth && formik.errors.dateOfBirth,
                        },
                        {
                            'is-valid': formik.touched.dateOfBirth && !formik.errors.dateOfBirth,
                        }
                    )}
                    onChange={(ev) => {
                        formik.setFieldValue(`dateOfBirth`, ev.target.valueAsDate);
                    }}
                    value={formik.values.dateOfBirth ? moment(formik.values.dateOfBirth).format('YYYY-MM-DD'): null}
                />
                {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{formik.errors.dateOfBirth}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>

        {/* end::Form group */}
        <div className="fv-row mb-10 row">
            <div className="col-xl-6">
                <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>
                <input
                    placeholder='Email'
                    type='email'
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control form-control-sm ',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                    disabled={true} />
            </div>
            <div className="col-xl-6">
                <label className='class="form-label fw-bolder text-dark fs-6'>Gender</label>
                <select className="form-select form-select-sm mb-3"
                    onChange={(ev) => {
                        formik.setFieldValue("gender", +ev.target.value);
                    }}
                >
                    {allowedGenderOptions.map(x => <option selected={x.value === formik.values.gender} value={x.value} key={x.value}>{x.label}</option>)}
                </select>
            </div>
        </div>

        {/* begin::Form group Email */}
        <div className="fv-row mb-10 row">
            <div className="col-xl-6">
                <label className='form-label fw-bolder text-dark fs-6 required'>Phone Number</label>
                <PhoneInput
                    inputProps={{
                        className: clsx(
                            'form-control form-control-sm ',
                            { 'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber },
                            {
                                'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
                            }
                        )
                    }}
                    {...formik.getFieldProps('phoneNumber')}
                    onChange={(val) => { formik.setFieldValue('phoneNumber', val); }}
                    onFocus={() => { formik.setFieldTouched('phoneNumber'); }}
                    onlyCountries={['in', 'us']}
                    country={'in'} />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{formik.errors.phoneNumber}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className="col-xl-6">
                <label className='form-label fw-bolder text-dark fs-6 required'>Parent Phone Number</label>
                <PhoneInput
                    inputProps={{
                        className: clsx(
                            'form-control form-control-sm ',
                            { 'is-invalid': formik.touched.parentPhone && formik.errors.parentPhone },
                            {
                                'is-valid': formik.touched.parentPhone && !formik.errors.parentPhone,
                            }
                        )
                    }}
                    {...formik.getFieldProps('parentPhone')}
                    onChange={(val) => { formik.setFieldValue('parentPhone', val); }}
                    onFocus={() => { formik.setFieldTouched('parentPhone'); }}
                    onlyCountries={['in', 'us']}
                    country={'in'} />
                {formik.touched.parentPhone && formik.errors.parentPhone && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{formik.errors.parentPhone}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
        {/* end::Form group */}

        <div className='fv-row mb-7 row'>
            <div className='col-xl-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Profile Picture</label>
                <FileUploader
                    selectText={formik.values.base64ProfilePicture || formik.values.profilePictureUri ? 'Choose another' : 'Select File'}
                    allowedFileTypes={allowedImageFileTypes}
                    onFocus={() => formik.setFieldTouched('base64ProfilePicture')}
                    onFileChange={(fileUrl: string, file: File) => {
                        formik.setFieldValue('base64ProfilePicture', fileUrl);
                        formik.setFieldValue('profilePictureFileName', file.name);
                        return '';
                    }}
                    className={'form-control form-control-sm '} />
                {(formik.values.base64ProfilePicture || formik.values.profilePictureUri) &&
                    <InputGroup.Text>
                        <ImagePreview base64Image={formik.values.base64ProfilePicture ? `data:image/png;base64,${formik.values.base64ProfilePicture}` : formik.values.profilePictureUri} />
                    </InputGroup.Text>}
                <p className="text-muted">Please upload professional profile picture </p>
                {formik.touched.base64ProfilePicture && formik.errors.base64ProfilePicture && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{formik.errors.base64ProfilePicture}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className='col-xl-6'>
                <label className='class="form-label fw-bolder text-dark fs-6'>Interested Countries</label>
                <Select
                    options={countryOptions}
                    value={countryOptions.filter(x => formik.values.interestedCountries.indexOf(x.label) > -1)}
                    onFocus={() => {
                        formik.setFieldTouched('interestedCountries')
                    }}
                    onChange={(opt) => {
                        if (opt) {
                            if (opt.length > 1 && sharedState.isStudent) return;
                            formik.setFieldValue('interestedCountries', Array.from(opt.values()).map(x => x.label))
                        } else {
                            formik.setFieldValue('interestedCountries', [])
                        }
                    }}
                    isMulti={true}
                />
                {sharedState.isStudent && <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-info mt-2'>
                        <span role='alert'>You can select only 1 country. Reach out to your Counselor for more.</span>
                    </div>
                </div>}
                {formik.touched.interestedCountries && formik.errors.interestedCountries && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{formik.errors.interestedCountries}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
        <div className='fv-row mb-7 row'>
            <div className='col-xl-6'>
                <label className='form-label fw-bolder text-dark fs-6'> Other Remarks </label>
                <textarea
                    placeholder='Other remarks'
                    autoComplete='off'
                    {...formik.getFieldProps('otherInfo')}
                    className='form-control form-control-sm'
                />
            </div>
        </div>
    </div>
}