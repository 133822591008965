import clsx from "clsx"
import { FC } from "react"
import { InputGroup } from "react-bootstrap-v5"
import { FileUploader } from "../../../../../modules/common/components/FileUploader"
import { ImagePreview } from "../../ImagePreview"
import { allowedFileTypes, allowedImageFileTypes } from "../ProfileEditSchema"

type Props = {
    formik: any,
}
export const PassportStep: FC<Props> = ({ formik }) => {
    return <div className="w-100">
        <div className='pb-10 pb-lg-15'>
            <h2 className='fw-bolder d-flex align-items-center text-dark'>
                Passport related information
            </h2>
        </div>
        <div className='fv-row mb-10 row'>
            <div className='col-xl-6'>
                <label className='class="form-label fw-bolder text-dark fs-6'>Passport Number</label>
                <input
                    placeholder='Passport Number'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('passportNumber')}
                    className={clsx(
                        'form-control form-control-sm ',
                        {
                            'is-invalid': formik.touched.passportNumber && formik.errors.passportNumber,
                        },
                        {
                            'is-valid': formik.touched.passportNumber && !formik.errors.passportNumber,
                        }
                    )}
                />
                {formik.touched.passportNumber && formik.errors.passportNumber && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{formik.errors.passportNumber}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>

        <div className='fv-row mb-10 row'>
            <div className='col-xl-6'>
                <label className='class="form-label fw-bolder text-dark fs-6'>Passport Front</label>
                <FileUploader
                    selectText={formik.values.base64PassportFront || formik.values.passportFrontUri ? 'Choose another' : 'Select File'}
                    allowedFileTypes={allowedImageFileTypes}
                    onFocus={() => formik.setFieldTouched('base64PassportFront')}
                    onFileChange={(fileUrl: string, file: File) => {
                        formik.setFieldValue('base64PassportFront', fileUrl);
                        formik.setFieldValue('passportFrontFileName', file.name);
                        return '';
                    }}
                    className={clsx(
                        'form-control form-control-sm ',
                        {
                            'is-invalid': (formik.touched as any).base64PassportFront && (formik.errors as any).base64PassportFront,
                        },
                        {
                            'is-valid': (formik.touched as any).base64PassportFront && !(formik.errors as any).base64PassportFront,
                        }
                    )}
                />
                {(formik.values.base64PassportFront || formik.values.passportFrontUri) &&
                    <InputGroup.Text>
                        <ImagePreview base64Image={formik.values.base64PassportFront ? `data:image/png;base64,${formik.values.base64PassportFront}` : formik.values.passportFrontUri} />
                    </InputGroup.Text>
                }
            </div>
            <div className='col-xl-6'>
                <label className='class="form-label fw-bolder text-dark fs-6'>Passport Back</label>
                <FileUploader
                    selectText={formik.values.base64PassportBack || formik.values.passportBackUri ? 'Choose another' : 'Select File'}
                    allowedFileTypes={allowedFileTypes}
                    onFocus={() => formik.setFieldTouched('base64PassportBack')}
                    onFileChange={(fileUrl: string, file: File) => {
                        formik.setFieldValue('base64PassportBack', fileUrl);
                        formik.setFieldValue('passportBackFileName', file.name);
                        return '';
                    }}
                    className={clsx(
                        'form-control form-control-sm ',
                        {
                            'is-invalid': (formik.touched as any).base64PassportBack && (formik.errors as any).base64PassportBack,
                        },
                        {
                            'is-valid': (formik.touched as any).base64PassportBack && !(formik.errors as any).base64PassportBack,
                        }
                    )}
                />
                {(formik.errors as any).base64PassportBack && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger mt-2'>
                            <span role='alert'>{(formik.touched as any).base64PassportBack && (formik.errors as any).base64PassportBack}</span>
                        </div>
                    </div>
                )}
                {(formik.values.base64PassportBack || formik.values.passportBackUri) &&
                    <InputGroup.Text>
                        <ImagePreview base64Image={formik.values.base64PassportBack ? `data:image/png;base64,${formik.values.base64PassportBack}` : formik.values.passportBackUri} />
                    </InputGroup.Text>
                }
            </div>
        </div>
    </div>
}