import axios from 'axios';
import { DropdownOption } from './SharedStateProvider';

const API_URL = process.env.REACT_APP_API_URL;
export const COMMON_API = `${API_URL}/api/v1/common`

export function getCommonData() {
    return axios.get(`${COMMON_API}/common-data`);
}

export function getProfileIdFromUserId(userId: string) {
    return axios.get<number>(`${COMMON_API}/get-profile-id?userId=${userId}`);
}

export function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export function getLorTypes() {
    return axios.get<Array<DropdownOption>>(`${COMMON_API}/get-lor-types`)
}

export function getCommonDownloads() {
    return axios.get<Array<any>>(`${COMMON_API}/downloads`)
}
