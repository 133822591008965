import { FC, useState } from 'react';
import StudentList, { StudentListColumnMap } from '../../pages/student-profiles/components/StudentList';
import { getAllStudentsPaginated } from '../../pages/student-profiles/services/StudentManager';
import tableIcons from '../common/components/MaterialTableIcons'
import { NewMessageDialog } from './components/StartNewThreadButton';
import * as messageService from './messageService'

export const BulkMessagePage: FC = () => {
    const [messageDialogVisible, setVisible] = useState<boolean>(false);
    const [students, setStudents] = useState<Array<string>>([]);

    return <>
        <NewMessageDialog visible={messageDialogVisible} setVisibility={setVisible} onSend={(req) => {
            return messageService.sendBulkMessage(students, req);
        }} />
        <StudentList getStudentsFromServer={getAllStudentsPaginated}
            useDefaultActions={false}
            allowSelection={true}
            disabledFilters={['examDate', 'hasCompetitiveExamScores', 'hasLanguageExamScores']}
            extraColumns={[StudentListColumnMap.Status]}
            actions={[{
                icon: tableIcons.Publish as any,
                onClick: (ev, data) => {
                    if (data?.length > 0) {
                        setStudents(data.map((x: any) => x.email));
                        setVisible(true);
                    }
                },
                position: 'toolbarOnSelect'
            }]} />
    </>
}