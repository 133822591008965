import React, { ReactElement } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap-v5';

const renderTooltip = (message: string, props: any) => (
    <Tooltip id="button-tooltip" {...props} className='tooltip-dark'>
        {message}
    </Tooltip>
);

export default function TooltipInfo({ message }: { message: string }) {
    return <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => { return renderTooltip(message, props) }}
    >
        <i className='bi bi-info-circle text-dark p-0 fs-6'></i>
    </OverlayTrigger>
}

type Props = {
    message: string,
    childEle: ReactElement
}
export const GenericTooltip: React.FC<Props> = ({ message, childEle }) => {
    return <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => { return renderTooltip(message, props) }}
    >
        {childEle}
    </OverlayTrigger>
}