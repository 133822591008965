/* eslint-disable react-hooks/exhaustive-deps, @typescript-eslint/no-unused-vars*/
import clsx from 'clsx';
import { Form, Formik, setNestedObjectValues } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap-v5';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Notice from '../../../modules/common/components/Notice';
import { IProfileState, IStudentConfig } from '../models';
import { getConfig } from '../services/StudentProfileService';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { PageTitle } from '../../../../theme/layout/core';
import { deleteEssayForStudent, getEssaysForStudent, StudentEssayRequestDto, StudentEssayResponseDto, upsertEssayForStudent } from '../services/EssayService';

type Props = {
    profileId: string
}

const validationSchema = Yup.object().shape({
    header: Yup.string().required().label('Topic'),
    essay: Yup.string().required().label('essay'),
});

const StudentEssayPage: FC<RouteComponentProps<Props>> = ({ history, match: { params: { profileId } } }) => {
    const [loading, setLoading] = useState(false);
    const [conf, setConfig] = useState<IStudentConfig>();
    const [essays, setEssays] = useState<StudentEssayResponseDto[]>();

    const [refreshKey, setRefreshKey] = useState(1);
    const [profileState, setProfileState] = useState<IProfileState>();
    const [hasPendingPayment, setHasPendingPayment] = useState<boolean>(false);

    useEffect(() => {
        function fetchData() {
            setLoading(true);
            getConfig(+profileId)
                .then(resp => {
                    setConfig(resp.data);
                    setProfileState(resp.data.profileState);
                    if (resp.data.overridePaymentRule) {
                        setHasPendingPayment(false);
                    } else if (resp.data.balanceFees) {
                        setHasPendingPayment(true);
                    }
                })
                .catch(err => setHasPendingPayment(true))
                .finally(() => setLoading(false));
        }

        fetchData();
    }, [profileId])

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const { data } = await getEssaysForStudent(+profileId);
                setEssays(data);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [refreshKey]);

    const handleDelete = (essayId: number) => {
        if (essayId === 0) {
            // remove from local
            setRefreshKey(Math.random());
            return;
        }

        setLoading(true);
        deleteEssayForStudent(essayId)
            .then(() => {
                setRefreshKey(Math.random());
                toast('Answers deleted successfully', { type: 'success' })
            })
            .finally(() => setLoading(false));
    }

    const handleSubmit = (profileId: string, values: StudentEssayRequestDto) => {
        setLoading(true);
        upsertEssayForStudent(values)
            .then(() => {
                setRefreshKey(Math.random());
                toast('Changes saved successfully', { type: 'success' })
            })
            .finally(() => setLoading(false));
    }

    if (!loading && profileState && !profileState.isApproved) {
        return <Notice message='This module is not yet active as your profile is not approved.' />
    }

    // if (!loading && (hasPendingPayment)) {
    //     return <Notice message='This module is not yet active due to pending payment.' />
    // }

    return <>
        <PageTitle description='Manage Essays' breadcrumbs={[]}>Essays</PageTitle>
        {
            !loading && !profileId ? <Notice message='Profile not found' /> :
                <div className='card mb-5 mb-xl-10'>
                    <div className="card-header">
                        <h3 className='card-title mb-2'>
                            Essays
                        </h3>
                        <Button className='btn btn-sm btn-outline' variant='outline'>
                            <Link to={`/student/${profileId}/essays`}>
                                <i className='bi bi-view-list'></i> Preview
                            </Link>
                        </Button>
                    </div>
                    <div className='card-body pt-9 pb-0'>
                        <div className='row mb-10'>
                            {!conf?.requiresEssay && <div className='col-xl-6'>
                                <Notice message='Profile does not require Essay.' />
                            </div>
                            }
                            {loading && <Spinner variant='primary' animation='grow' />}

                            {/* {sharedState.isStudent && profileState && profileState.isSopQuestionnaireApproved && <>
                                <div className='col-xl-12'>
                                    <Notice message='ESSAY has been approved.' />
                                </div>
                            </>} */}

                            {conf?.requiresEssay && !loading &&
                                <>

                                    <Button className='btn btn-sm btn-primary col-2' onClick={() => {
                                        if (conf?.numberOfEssays && essays?.length && conf?.numberOfEssays <= essays.length) {
                                            toast(`Max ${conf.numberOfEssays} essays`, { type: 'error' });
                                            return;
                                        }
                                        setEssays([...(essays || []), { header: '', essay: '', createdAt: '', createdBy: "", updatedBy: '', updatedAt: '', id: 0, profileId: +profileId }])
                                    }}>Add Another</Button>

                                    {essays &&
                                        essays.map(essayDetail => {
                                            return <div className='d-flex flex-row-fluid flex-center bg-white rounded' key={`selectedLor_${essayDetail.id}`}>
                                                <Formik initialValues={essayDetail} onSubmit={(values) => {
                                                    handleSubmit(profileId, values);
                                                }} validationSchema={validationSchema}>
                                                    {({ ...formik }) => (
                                                        <Form className='row w-60'>
                                                            <div className='col-xl-12 mb-10'>
                                                                <label className='form-label fw-bolder text-dark fs-6 required'>{formik.values.header || 'Essay Topic'}</label>
                                                                <input
                                                                    placeholder='Essay Topic'
                                                                    autoComplete='off'
                                                                    {...formik.getFieldProps(`header`)}
                                                                    className={clsx(
                                                                        'form-control form-control-sm ',
                                                                        {
                                                                            'is-invalid': formik.touched.header && formik.errors.header,
                                                                        },
                                                                        {
                                                                            'is-valid': formik.touched.header && !formik.errors.header,
                                                                        }
                                                                    )}
                                                                />
                                                                {formik.touched.header && formik.errors.header && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <div className='fv-help-block text-danger mt-2'>
                                                                            <span role='alert'>Answer is Required</span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div className="col-xl-10 my-5">
                                                                    <label className='form-label fw-bolder text-dark fs-6 required'>{'Essay Content'}</label>
                                                                    <textarea
                                                                        placeholder='Essay Content'
                                                                        autoComplete='off'
                                                                        rows={20}
                                                                        {...formik.getFieldProps(`essay`)}
                                                                        className={clsx(
                                                                            'form-control form-control-sm ',
                                                                            {
                                                                                'is-invalid': formik.touched.essay && formik.errors.essay,
                                                                            },
                                                                            {
                                                                                'is-valid': formik.touched.essay && !formik.errors.essay,
                                                                            }
                                                                        )} />
                                                                    {formik.touched.essay && formik.errors.essay && (
                                                                        <div className='fv-plugins-message-container'>
                                                                            <div className='fv-help-block text-danger mt-2'>
                                                                                <span role='alert'>Answer is Required</span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className='d-flex pt-10'>
                                                                <div className='m-2'>
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-md btn-primary w-100 mb-5'
                                                                        onClick={async () => {
                                                                            const validationErrors = await formik.validateForm()
                                                                            if (Object.keys(validationErrors).length > 0) {
                                                                                formik.setTouched(setNestedObjectValues(validationErrors, true));
                                                                                return;
                                                                            }
                                                                            formik.submitForm();
                                                                        }}
                                                                    >
                                                                        <i className="bi bi-box-arrow-down"></i>
                                                                        {!loading && 'Save'}
                                                                        {loading && (
                                                                            <span className='indicator-progress' style={{ display: 'inline-block' }}>
                                                                                Please wait...{' '}
                                                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                            </span>
                                                                        )}

                                                                    </button>
                                                                </div>
                                                                <div className='m-2'>
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-md btn-secondary me-3'
                                                                        onClick={() => {
                                                                            history.push(`/student/${profileId}/essay/submit`);
                                                                        }}
                                                                    >
                                                                        <i className="bi bi-hand-thumbs-up"></i>

                                                                        Preview and Submit
                                                                    </button>
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-md btn-secondary me-3'
                                                                        onClick={() => {
                                                                            handleDelete(essayDetail.id)
                                                                        }}
                                                                    >
                                                                        <i className="bi bi-hand-thumbs-up"></i>
                                                                        Remove
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Form>)}
                                                </Formik>
                                            </div>
                                        })
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>}
    </>;
}

export default withRouter(StudentEssayPage);
