import { FC } from 'react';
import { PageTitle } from '../../../theme/layout/core';
import StudentList from './components/StudentList';
import { getAllStudentsPaginated } from './services/StudentManager';

const StudentListPage: FC = () => {
    return <>
        <PageTitle breadcrumbs={[]}>All Students</PageTitle>
        <StudentList getStudentsFromServer={getAllStudentsPaginated} />;
    </>
}

export default StudentListPage;