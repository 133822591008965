import clsx from "clsx"
import { FC, useEffect, useState } from "react"
import { InputGroup } from "react-bootstrap-v5"
import { AsyncDropdown } from "../../../../modules/common/components/AsyncDropdown"
import { useSharedState } from "../../../../modules/common/SharedStateProvider"
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

type PropTypes = {
    formik: any;
    fieldPath: string;
}

export const CollegeDetail: FC<PropTypes> = ({ formik, fieldPath }) => {
    const sharedState = useSharedState();
    const [collegeName, setCollegeName] = useState(formik.getFieldMeta(`${fieldPath}.collegeName`).value);

    useEffect(() => {
        if (!collegeName) return;
        formik.setFieldValue(`${fieldPath}.collegeName`, collegeName.label);
        //eslint-disable-next-line
    }, [collegeName]);

    return <>
        <div className="row fv-row mb-10">
            <label className='form-label fw-bolder text-dark fs-6 required'>College Details</label>
        </div>
        <div className='fv-row mb-5 row'>
            <div className='col-xl-12'>
                <InputGroup className="mb-3 w-100" size="sm">
                    <InputGroup.Text id="basic-addon1" className="required w-20">College</InputGroup.Text>
                    <div className="col-8">
                        <GooglePlacesAutocomplete
                            apiKey="AIzaSyAVcq8CIOZIMk-YozGvUHR8wTOhAKEGj40"
                            debounce={200}
                            autocompletionRequest={
                                { componentRestrictions: { country: ['us', 'ca', 'in', 'au'] } }
                            }
                            selectProps={{ value: collegeName, onChange: setCollegeName, placeholder: "Select new college" }}
                        />
                    </div>
                </InputGroup>
                <p className="text-bold">Selected College: <b className="text-info">{formik.getFieldMeta(`${fieldPath}.collegeName`).value}</b></p>
            </div>
            <div className='col-xl-12'>
                <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text id="basic-addon1" className="required w-20">University </InputGroup.Text>
                    <AsyncDropdown
                        className={clsx('form-control form-control-sm p-0 ',
                            {
                                'is-invalid': formik.getFieldMeta(`${fieldPath}.universityId`).touched && formik.getFieldMeta(`${fieldPath}.universityId`).error,
                            },
                            {
                                'is-valid': formik.getFieldMeta(`${fieldPath}.universityId`).touched && !formik.getFieldMeta(`${fieldPath}.universityId`).error
                            })
                        }
                        options={sharedState.universities}
                        onFocus={() => { formik.setFieldTouched(`${fieldPath}.universityId`) }}
                        onSelected={(val) => {
                            formik.setFieldValue(`${fieldPath}.universityId`, val);
                        }}
                        selected={formik.getFieldMeta(`${fieldPath}.universityId`).value}
                    />
                    <div className="col-12">
                        {formik.getFieldMeta(`${fieldPath}.universityId`).touched && formik.getFieldMeta(`${fieldPath}.universityId`).error && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block text-danger mt-2'>
                                    <span role='alert'>{formik.getFieldMeta(`${fieldPath}.universityId`).error}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </InputGroup>
            </div>
        </div>
        <div className='fv-row mb-10 row'>
            <div className='col-xl-6'>
                <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text id="basic-addon1" className="required">Year of Passing</InputGroup.Text>
                    <input {...formik.getFieldProps(`${fieldPath}.passingYear`)}
                        className={clsx('form-control ',
                            {
                                'is-invalid': formik.getFieldMeta(`${fieldPath}.passingYear`).touched && formik.getFieldMeta(`${fieldPath}.passingYear`).error,
                            },
                            {
                                'is-valid': formik.getFieldMeta(`${fieldPath}.passingYear`).touched && !formik.getFieldMeta(`${fieldPath}.passingYear`).error
                            })
                        }
                    />
                    {formik.getFieldMeta(`${fieldPath}.passingYear`).touched && formik.getFieldMeta(`${fieldPath}.passingYear`).error && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger mt-2'>
                                <span role='alert'>{formik.getFieldMeta(`${fieldPath}.passingYear`).error}</span>
                            </div>
                        </div>
                    )}
                </InputGroup>
            </div>
            <div className='col-xl-6'>
                <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text id="basic-addon1" className="required">Field Of Study</InputGroup.Text>
                    <input {...formik.getFieldProps(`${fieldPath}.fieldOfStudyName`)}
                        className={clsx('form-control ',
                            {
                                'is-invalid': formik.getFieldMeta(`${fieldPath}.fieldOfStudyName`).touched && formik.getFieldMeta(`${fieldPath}.fieldOfStudyName`).error,
                            },
                            {
                                'is-valid': formik.getFieldMeta(`${fieldPath}.fieldOfStudyName`).touched && !formik.getFieldMeta(`${fieldPath}.fieldOfStudyName`).error
                            })
                        }
                    />
                    {formik.getFieldMeta(`${fieldPath}.fieldOfStudyName`).touched && formik.getFieldMeta(`${fieldPath}.fieldOfStudyName`).error && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger mt-2'>
                                <span role='alert'>{formik.getFieldMeta(`${fieldPath}.fieldOfStudyName`).error}</span>
                            </div>
                        </div>
                    )}
                </InputGroup>
            </div>
        </div>
    </>
}
